/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type2/ScannerType2@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding">
        <div class="flex flex-row justify-around">
          <div fxLayout="column">
            <div v-for="(row, i) in images" :key="`row-${i}`" class="flex flex-row justify-around">
              <img
                v-for="image in row"
                :key="image.id"
                v-cache
                class="flex borderedImage imageMedium"
                :src="image.imageUrl"
                :class="{ borderGreen: image.borderGreen, borderRed: image.borderRed }"
                style="display: block; margin: 10px"
                @click="clickAnswer(image)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scanner-word flex flex-row justify-around">
      <span class="borderedWordBox" @click="playWordAudio()">
        {{ taskRound.instructionBeforeWord }}
        <span class="wordHighlight">{{ taskRound.word === StemType.Stem ? task.stem : task.morphedStem }}</span>
        {{ taskRound.instructionAfterWord }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, computed, Ref } from 'vue'
  import { TaskTracking } from '@/models/main'
  // import { useI18n } from 'vue-i18n'
  // import useCMSStore, { CMSStoreType } from '@/store/useCMSStore'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES, StemType } from '@/constants'
  import { Tasktype2 } from '@/models/tasktypes/Tasktype2'

  interface Image {
    id: string
    index: number
    imageUrl: string
    name: string
    correct1: boolean
    correct2: boolean
    enabled: boolean
    borderGreen: boolean
    borderRed: boolean
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype2> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  /* const messages = {
    no: {},
    sv: {},
    en: {},
  } */
  //const { t } = useI18n({ messages })
  //const cmsStore: CMSStoreType = useCMSStore()
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype2,
    correct: 0,
    of: 2,
    answerOptions: task.value.images.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  const roundData = { round1: { correct: 0, of: 0 }, round2: { correct: 0, of: 0 } }
  const images: Ref<Image[][]> = ref([])
  const opacity = ref(0)
  let playingAudio = false
  let textAudio: MP3Audio | WebAudio
  let instructionAudio: MP3Audio | WebAudio
  let unforgivingTestMode = false
  let round = ref(1)
  let attempts = 0
  let finished = false

  const taskRound = computed(() => (round.value === 1 ? task.value.round1 : task.value.round2))

  const setupTask = async () => {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
    finished = false
    playingAudio = false
    images.value = []
    if (typeof task === 'undefined' || task === null) {
      alert('A Type 2 task does not exist - check your Session layout in the CMS')
      return
    }

    let tempImages = []
    for (let i = 1; i < task.value.images.length; i++) {
      const entry = task.value.images[i]
      let image: Image = {
        id: `image-id-${i}`,
        index: i,
        imageUrl: entry.file || '',
        name: entry.name,
        correct1: entry.correct1,
        correct2: entry.correct2,
        enabled: true,
        borderGreen: false,
        borderRed: false,
      }

      if (image.correct1 && round.value === 1) {
        roundData.round1.of++
        tracking.details.of++
      }
      if (image.correct2 && round.value === 2) {
        roundData.round2.of++
        tracking.details.of++
      }
      tempImages.push(image)
    }
    tempImages = shuffleItems(tempImages)
    const set1 = tempImages.splice(0, 3)
    const set2 = tempImages
    images.value.push(set1, set2)

    stateActions.progress.progressShow({ stars: round.value === 1 ? roundData.round1.of : roundData.round2.of })

    if (taskRound.value.wordAudio) {
      textAudio = await createSound(taskRound.value.wordAudio)
      textAudio.onended = () => {
        playingAudio = false
      }
    }

    setTimeout(() => {
      opacity.value = 1
      introduceChallenge()
    }, 1000)
  }

  const introduceChallenge = async () => {
    attempts = 0
    stateSetters.speakerIsPlaying = true
    if (taskRound.value.instructionAudio) {
      instructionAudio = await createSound(taskRound.value.instructionAudio)
      instructionAudio.onended = () => {
        stateActions.setSpeakerSound([taskRound.value.instructionAudio])
        stateSetters.speakerIsPlaying = false
      }
    }
    setTimeout(() => {
      if (taskRound.value.instructionAudio) {
        instructionAudio.playWhenReady()
      }
    }, 500)
  }

  const playWordAudio = () => {
    if (!playingAudio && textAudio) {
      playingAudio = true
      textAudio.playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const clickAnswer = (item: Image) => {
    const resultsRound = round.value === 1 ? roundData.round1 : roundData.round2
    if (item.enabled && !finished) {
      attempts++
      if (item.correct1 && round.value === 1) {
        tracking.details.correct++
        resultsRound.correct++
        tracking.details.answer_details.push({
          attempt: `CMS image: ${item.index} - ${item.name} (round 1)`,
          correct: true,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        stateActions.progress.completeAStar()
        item.borderGreen = true
      } else if (item.correct2 && round.value === 2) {
        tracking.details.correct++
        resultsRound.correct++
        tracking.details.answer_details.push({
          attempt: `CMS image: ${item.index} - ${item.imageUrl} (round 2)`,
          correct: true,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        stateActions.progress.completeAStar()
        item.borderGreen = true
      } else {
        item.borderRed = true
        tracking.details.incorrectAttempts++
        tracking.details.answer_details.push({
          attempt: `CMS image: ${item.index} - ${item.imageUrl} (round ${round.value})`,
          correct: false,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        //dataService.tryAgain()
      }
      item.enabled = false

      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if ((unforgivingTestMode && attempts === resultsRound.of) || (!unforgivingTestMode && resultsRound.of === resultsRound.correct)) {
        finished = true
        nextRound()
      }
    }
  }

  const nextRound = () => {
    setTimeout(() => {
      opacity.value = 0
      setTimeout(() => {
        if (round.value === 1) {
          round.value = 2
          setupTask()
        } else {
          if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
            stateActions.speakLocalised(
              SpeechSounds.instructions.warmups.T2,
              () => {
                opacity.value = 0
                completeTask()
              },
              1000,
              false,
            )
          } else {
            opacity.value = 0
            completeTask()
          }
        }
      }, 500)
    }, 500)
  }

  const completeTask = () => {
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }
  setupTask()
</script>

<style scoped>
  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 10%;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 10%;
  }
  .scanner-padding {
    padding: 7% 5%;
  }
  .scanner-word {
    margin-top: -50px;
    position: relative;
    z-index: 100;
  }
</style>
