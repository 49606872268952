const globalTranslations = {
  no: {
    cancel: 'Avbryt',
    accept: 'Bekreft',
    done: 'Ferdig',
    back: 'Tilbake',
    send: 'Send',
    save: 'Lagre',
    add: 'Legg til',
    remove: 'Fjerne',
    edit: 'Rediger',
    continue: 'Fortsett',
    ready: 'Klar',
    start: 'Start',
    next: 'Neste',
    previous: 'Forrige',
    skipOver: 'Hopp over',
  },
  sv: {
    cancel: 'Avbryt',
    done: 'Färdig',
    back: 'Tillbaka',
    save: 'Spara',
    add: 'Lägg till',
    remove: 'Ta bort',
    edit: 'Redigera',
    continue: 'Fortsätt',
    ready: 'Klar',
    start: 'Start',
    next: 'Nästa',
    skipOver: 'Hoppa över',
  },
  en: {
    cancel: 'Cancel',
    accept: 'Accept',
    done: 'Done',
    back: 'Back',
    send: 'Send',
    save: 'Save',
    add: 'Add',
    remove: 'Remove',
    edit: 'Edit',
    continue: 'Continue',
    ready: 'Ready',
    start: 'Start',
    next: 'Next',
    previous: 'Previous',
    skipOver: 'Skip',
  },
}

export { globalTranslations }
