/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type1/ScannerType1@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding">
        <div v-if="showing === 'example'" class="flex flex-row justify-around">
          <div class="fadeInOut" :style="{ opacity: exampleLeft.opacity }">
            <img
              v-cache
              :src="exampleLeft.imageUrl"
              class="borderedImage borderColour1 imageVeryLarge"
              :class="{ borderGreen: exampleLeft.borderGreen, borderRed: exampleLeft.borderRed }"
            />
          </div>

          <div class="fadeInOut" :style="{ opacity: exampleRight.opacity }">
            <img
              v-cache
              :src="exampleRight.imageUrl"
              class="borderedImage imageVeryLarge"
              :class="{ borderGreen: exampleRight.borderGreen, borderRed: exampleRight.borderRed }"
            />
          </div>
        </div>

        <div v-if="showing === 'challenge'" class="flex flex-row justify-around">
          <div class="fadeInOut flex flex-col place-content-center" :style="{ opacity: challengeLeft.opacity }">
            <img
              v-cache
              :src="challengeLeft.imageUrl"
              class="borderedImage imageVeryLarge"
              :class="{ borderGreen: challengeLeft.borderGreen, borderRed: challengeLeft.borderRed }"
              @click="clickAnswer('left')"
            />
          </div>

          <div class="fadeInOut flex flex-col place-content-center" :style="{ opacity: challengeRight.opacity }">
            <img
              v-cache
              class="borderedImage imageVeryLarge"
              :src="challengeRight.imageUrl"
              :class="{ borderGreen: challengeRight.borderGreen, borderRed: challengeRight.borderRed }"
              @click="clickAnswer('right')"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="showing === 'example'" class="scanner-word flex flex-row justify-around">
      <span :class="{ highlightByScaling: exampleLeft.highlight }" class="borderedWordBox fadeInOut" :style="{ opacity: exampleLeft.opacity }">
        <span class="wordHighlight">{{ exampleLeft.word }}</span>
      </span>

      <span :class="{ highlightByScaling: exampleRight.highlight }" class="borderedWordBox fadeInOut" :style="{ opacity: exampleRight.opacity }">
        <span class="wordHighlight">{{ exampleRight.word }}</span>
      </span>
    </div>

    <div v-if="showing === 'challenge'" class="scanner-word flex flex-row justify-around" @click="playChallengeAudioWord()">
      <span class="borderedWordBox fadeInOut" :style="{ opacity: challengeLeft.opacity }">
        {{ task.challenge.instructionBeforeWord }}
        <span class="wordHighlight">{{ task.challenge.word === 'stem' ? task.stem : task.morphedStem }}</span>
        {{ task.challenge.instructionAfterWord }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs } from 'vue'
  // import { useI18n } from 'vue-i18n'
  // import useCMSStore, { CMSStoreType } from '@/store/useCMSStore'
  import useState from '@/composition/useState'
  import { TaskMode, TASK_TYPES, SpeechSounds } from '@/constants'
  import { Tasktype1 } from '@/models/tasktypes/Tasktype1'
  import { TaskTracking } from '@/models/main'
  import { MP3Audio, WebAudio, createSound } from '@/utilities'

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype1> },
    myIndex: { required: false, type: Number, default: 0 },
  })

  /* const messages = {
    no: {},
    sv: {},
    en: {},
  } */
  //const { t } = useI18n({ messages })
  const { task } = toRefs(props)
  //const cmsStore: CMSStoreType = useCMSStore()
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()

  // Display setup
  const showing = ref('example')
  const opacity = ref(0)
  // We need a mutatable copy of results that we will submit to the store at Task completion
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype1,
    correct: 0,
    of: 1,
    answerOptions: 2,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  let challengeActive = false
  let playingAudio = false
  let textAudio: MP3Audio | WebAudio
  let exampleLeftAudio: MP3Audio | WebAudio
  let exampleRightAudio: MP3Audio | WebAudio
  let introAudio: MP3Audio | WebAudio

  const exampleLeft = ref({
    imageUrl: '',
    audioUrl: '',
    captionBefore: '',
    word: '',
    captionAfter: '',
    borderGreen: false,
    borderRed: false,
    highlight: false,
    opacity: 0,
  })
  const exampleRight = ref({
    imageUrl: '',
    audioUrl: '',
    captionBefore: '',
    word: '',
    captionAfter: '',
    borderGreen: false,
    borderRed: false,
    highlight: false,
    opacity: 0,
  })
  const challengeLeft = ref({
    imageUrl: '',
    borderGreen: false,
    borderRed: false,
    opacity: 0,
    correct: false,
  })
  const challengeRight = ref({
    imageUrl: '',
    borderGreen: false,
    borderRed: false,
    opacity: 0,
    correct: false,
  })
  const unforgivingTestMode = false

  const setupTask = async () => {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
    stateActions.progress.progressShow({ stars: 1 })

    if (typeof task === 'undefined' || task === null) {
      alert('A Type 1 task does not exist - check your Session layout in the CMS')
      return
    }
    playingAudio = false
    exampleLeft.value.imageUrl = typeof task.value.example.stemImage !== 'undefined' ? task.value.example.stemImage : ''
    exampleRight.value.imageUrl = typeof task.value.example.morphedStemImage !== 'undefined' ? task.value.example.morphedStemImage : ''
    exampleLeft.value.audioUrl = typeof task.value.example.stemAudio !== 'undefined' ? task.value.example.stemAudio : ''
    exampleRight.value.audioUrl = typeof task.value.example.morphedStemAudio !== 'undefined' ? task.value.example.morphedStemAudio : ''
    exampleLeft.value.captionBefore = task.value.example.captionBeforeStem
    exampleRight.value.captionBefore = task.value.example.captionBeforeMorphedStem
    exampleLeft.value.word = task.value.example.customStem || task.value.stem
    exampleRight.value.word = task.value.example.customMorphedStem || task.value.morphedStem
    exampleLeft.value.captionAfter = task.value.example.captionAfterStem
    exampleRight.value.captionAfter = task.value.example.captionAfterMorphedStem

    challengeLeft.value.imageUrl = typeof task.value.challenge.image1 !== 'undefined' ? task.value.challenge.image1 : ''
    challengeRight.value.imageUrl = typeof task.value.challenge.image2 !== 'undefined' ? task.value.challenge.image2 : ''
    challengeLeft.value.correct = task.value.challenge.correctImage === '1'
    challengeRight.value.correct = !challengeLeft.value.correct

    if (typeof task.value.challenge.wordAudio !== 'undefined' && task.value.challenge.wordAudio !== null) {
      textAudio = await createSound(task.value.challenge.wordAudio, () => (playingAudio = false))
    }

    exampleLeftAudio = await createSound(exampleLeft.value.audioUrl)
    exampleLeftAudio.onerror = (error) => {
      console.log(error.toString())
    }
    exampleLeftAudio.onended = () => {
      exampleLeft.value.highlight = false
      if (!exampleRight.value.audioUrl) {
        setTimeout(() => {
          introduceChallenge()
        }, 2000)
      } else {
        setTimeout(() => {
          exampleRight.value.highlight = true
          exampleRightAudio.playWhenReady()
        }, 500)
      }
    }

    exampleRightAudio = await createSound(exampleRight.value.audioUrl)
    exampleRightAudio.onended = () => {
      setTimeout(() => {
        exampleLeft.value.opacity = 0
        exampleRight.value.opacity = 0
        exampleRight.value.highlight = false
        setTimeout(() => {
          introduceChallenge()
        }, 500)
      }, 500)
    }

    introAudio = await createSound(task.value.challenge.instructionAudio)
    introAudio.onended = () => {
      stateSetters.speakerIsPlaying = false
      stateActions.setSpeakerSound([task.value.challenge.instructionAudio])
    }

    setTimeout(() => {
      opacity.value = 1
      exampleLeft.value.opacity = 1
      exampleRight.value.opacity = 1
      introduceExample()
    }, 500)
  }

  const introduceExample = () => {
    stateSetters.speakerIsPlaying = true
    if (exampleLeft.value.audioUrl === '') {
      setTimeout(() => {
        introduceChallenge()
      }, 2000)
    } else {
      setTimeout(() => {
        exampleLeft.value.highlight = true
        exampleLeftAudio.playWhenReady()
      }, 3000)
    }
  }

  const introduceChallenge = () => {
    showing.value = 'challenge'
    setTimeout(() => {
      challengeLeft.value.opacity = 1
      challengeRight.value.opacity = 1
      challengeActive = true
      if (typeof task.value.challenge.instructionAudio !== 'undefined') {
        setTimeout(() => {
          introAudio.playWhenReady()
        }, 500)
      }
    }, 500)
  }

  const playChallengeAudioWord = () => {
    if (!playingAudio && textAudio !== null) {
      playingAudio = true
      textAudio.playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const completeTask = () => {
    challengeActive = false
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T1,
        () => {
          opacity.value = 0
          emit('completed', true, tracking)
        },
        1000,
        false,
      )
    } else {
      //setTimeout(() => {
      opacity.value = 0
      setTimeout(() => {
        // Call this when the task is complete
        emit('completed', true, tracking)
      }, 500)
      //}, 1000)
    }
  }

  const clickAnswer = (choice: string) => {
    if (challengeActive) {
      if (choice === 'left') {
        if (challengeLeft.value.correct) {
          tracking.details.correct++
          tracking.details.answer_details.push({ attempt: 'image 1', correct: true })
          challengeLeft.value.borderGreen = true
          stateActions.progress.completeAStar()
          completeTask()
        } else {
          challengeLeft.value.borderRed = true
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({ attempt: 'image 1', correct: false })
          //dataService.tryAgain()
          if (unforgivingTestMode) {
            completeTask()
          }
        }
      } else {
        if (challengeRight.value.correct) {
          tracking.details.correct++
          tracking.details.answer_details.push({ attempt: 'image 2', correct: true })
          challengeRight.value.borderGreen = true
          stateActions.progress.completeAStar()
          completeTask()
        } else {
          challengeRight.value.borderRed = true
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({ attempt: 'image 2', correct: false })
          //dataService.tryAgain()
          if (unforgivingTestMode) {
            completeTask()
          }
        }
      }
    }
  }
  setupTask()
</script>

<style scoped>
  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 10%;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 10%;
    padding-top: 10%;
  }
  .scanner-padding {
    padding: 5%;
  }
  .scanner-word {
    margin-top: -45px;
  }
</style>
