<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row">
    <div class="px-2 border-r-2 border-grey">
      <div class="flex flex-col text-left">
        <SelectionBox
          id="select-locationfilter"
          v-model="selectedGroup"
          :label="'Filter by owners in a group'"
          :options="groupOptionList"
          class="m-2"
          @change="filterGames"
        ></SelectionBox>
        <SelectionBox
          id="select-userfilter"
          v-model="selectedUser"
          :label="'Filter by a single owner'"
          :options="userOptionList"
          class="m-2"
          @change="filterGames"
        ></SelectionBox>
        <div class="flex flex-col flex-grow">
          <p class="font-bold">Games for selected group or user</p>
          <ul class="m-2">
            <li v-for="(g, i) in games" :key="'ap-' + i" class="cursor-pointer" @click="selectGame(g)">
              <span v-if="selectedGame && g._id === selectedGame._id">✔︎&nbsp;</span><span v-else></span> {{ g.profile.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-grow py-4 text-left m-2 overflow-y-auto">
      <h1 class="font-bold">Game Details</h1>
      <div v-if="selectedGame && selectedGame._id">
        <MonitorGameItem :game="selectedGame" :edit="true" :selected="true" class="w-full">
          <Button class="my-2 mr-2" @click="removeParticipant()"> Remove </Button>
        </MonitorGameItem>
      </div>
      <hr />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, Ref, computed } from 'vue'
  import { Group, Game, User } from '@/models/main'
  import useUserStore from '@/store/useUserStore'
  import useGameStore from '@/store/useGameStore'
  import SelectionBox from '@/components/base/SelectionBox.vue'
  import Button from '@/components/base/Button.vue'
  import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'

  interface GroupOptionListItem {
    itemName: string
    item: Group | undefined
  }
  interface UserOptionListItem {
    itemName: string
    item: User | undefined
  }

  const { getters: userGetters } = useUserStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()

  const selectedGroup: Ref<GroupOptionListItem> = ref({
    item: undefined,
    itemName: 'no group selected',
  })
  const selectedUser: Ref<UserOptionListItem> = ref({
    item: undefined,
    itemName: 'no user selected',
  })

  const games = computed(() => {
    return gameGetters.games.value
      .filter((g) => !selectedGroup.value.item || (selectedGroup.value.item && g.sharing.groups.includes(selectedGroup.value.item._id)))
      .filter((g) => !selectedUser.value.item || (selectedUser.value.item && g.owner == selectedUser.value.item._id))
  })

  const selectedGame = gameGetters.selectedGame

  // --------------- Participant functions -----------------

  const selectGame = (g: Game) => {
    gameActions.selectGame(g)
  }

  const filterGames = () => {
    const groupId = selectedGroup.value.item ? selectedGroup.value.item._id : ''
    const userId = selectedUser.value.item ? selectedUser.value.item._id : ''
    gameActions.getGames(groupId, userId).then(() => {
      if (games.value.length > 0) selectGame(games.value[0])
    })
  }

  const removeParticipant = async () => {
    //participantAction.removeParticipant(selectedGame.value._id)
    // TODO:  Remove participant at server, Update user front end, update user
    // !!!  LOW PRIORITY.  THIS SHOULD INCLUDE CONFIRMATION / WARNING !!!
  }

  const groupOptionList = computed((): GroupOptionListItem[] => {
    const gol: GroupOptionListItem[] = userGetters.allGroups.value.map((g) => ({
      item: g,
      itemName: g.name,
    }))
    gol.unshift({
      item: undefined,
      itemName: '(none)',
    })
    return gol
  })

  const userOptionList = computed((): UserOptionListItem[] => {
    const uol: UserOptionListItem[] = userGetters.allUsers.value.map((u) => ({
      item: u,
      itemName: u.profile.username,
    }))
    uol.unshift({
      item: undefined,
      itemName: '(none)',
    })
    return uol
  })

  // Fetch participants inital value for the default location
  gameActions.getGames('', '')
</script>

<style scoped></style>
