<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative h-full">
    <div v-if="lackingDetails" class="flex flex-col items-center h-full pb-8">
      <div class="flex flex-col flex-grow justify-center items-center">
        <AnswerInput
          v-model="theUser.profile.ageGroup"
          v-model:valid="validators.ageGroup"
          mode="number"
          :label="t('ageGroup')"
          custom-size="24"
          :underline="false"
          class="my-8"
        />
        <MultipleChoiceInput
          v-model="theUser.profile.languagesSpoken"
          :label="t('myFirstLanguage')"
          mode="multiChoice"
          :options="userLanguages"
          :underline="false"
          class="m-2 w-96"
        />
        <Button class="mt-8" :disabled="!allValid" :childclass="'w-32 text-black'" :backgroundcolour="'bg-white'" @click="usernameDone()"
          >{{ t('continue') }}...</Button
        >
      </div>
    </div>

    <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 p-24">
      <!--div class="flex flex-row mx-6 pt-12 sm:pt-4">
        <div class="h-12 inline-flex items-center p-4">
          <p>{{ t('selectGame') }}</p>
        </div>
      </div-->

      <p v-if="gameList.length === 0" class="text-white">{{ t('noGames') }}</p>
      <ItemSelection v-for="g in gameList" :key="g._id" class="w-48" :selected="g.selected" :corners="editingGame._id == g._id">
        <div :id="`div-selectGame-${g.profile.name}`" class="cursor-pointer flex flex-col h-full" @click="selectGame(g)">
          <div v-if="editingGame._id == g._id" class="flex flex-col justify-center items-center py-1">
            <AnswerInput
              v-model="editingGame.profile.name"
              mode="text"
              :label="t('name')"
              :border="true"
              placeholder="Sample name"
              custom-size="8"
              :underline="false"
              class="m-2 w-36"
            />
            <MultipleChoiceInput
              v-model="editingGame.sharing.groups"
              :label="t('sharing')"
              mode="multiChoice"
              :options="usersGroups"
              :underline="false"
              class="m-2 w-36"
              @change="testme"
            />
            <Button id="button-monitor" :childclass="'w-32'" :backgroundcolour="'bg-white'" :textcolour="'text-black'" @vclick="saveEditedGame()">{{
              t('save')
            }}</Button>
          </div>
          <div v-else class="flex flex-col h-full p-4">
            <p class="flex place-items-center justify-center h-10 font-semibold text-lg">
              {{ g.profile.name }}
            </p>
            <div v-if="g.owner === theUser._id && user.status.canEditGames" class="flex flex-row justify-center py-1">
              <Button id="button-monitor" :childclass="'w-32'" :backgroundcolour="'bg-white'" :textcolour="'text-black'" @vclick="editGame(g)">{{
                t('edit')
              }}</Button>
            </div>
          </div>
        </div>
      </ItemSelection>
    </div>
    <div class="absolute bottom-0 left-0 w-full h-24 flex flex-row justify-end">
      <Button v-if="user.status.canEditGames" :backgroundcolour="'bg-white'" @click="newGame()">{{ t('newGame') }}</Button>
      <Button
        v-show="gameSelected"
        id="button-done"
        class="m-8 mb-12 place-self-center"
        :childclass="'w-56 h-16 text-xl'"
        :backgroundcolour="'bg-green-500'"
        :textcolour="'text-white'"
        @vclick="done()"
        >{{ t('continue') }}...</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, Ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { ActivityPhase } from '@/constants'
  import useGameStore from '@/store/useGameStore'
  import useUserStore from '@/store/useUserStore'
  import useCMSStore from '@/store/useCMSStore'
  import useStateService from '@/composition/useState'
  import ItemSelection from '@/components/base/ItemSelection.vue'
  import Button from '@/components/base/Button.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import MultipleChoiceInput from '@/components/base/MultipleChoiceInput.vue'

  /* Interfaces */
  import { Game, User } from '@/models/main'

  const messages = {
    no: {
      selectGame: 'Velg et spill eller lag et nytt',
      playersInGame: 'Deltager i dette spillet',
      otherPlayersAvailable: 'Andre spillere tilgjengelig',
      enterDetails: 'Vennligst skriv inn dine opplysninger',
      newGame: 'Nytt spill',
      noGames: 'Ingen spill funnet',
      begin: 'Begynne!',
      name: 'navn',
      sharing: 'del til grupper',
      ageGroup: 'alder',
      myFirstLanguage: 'Mitt førstespråk',
      norwegian: 'Norsk',
      annet: 'Annet språk',
    },
    sv: {
      selectGame: 'Välj ett spel eller skapa ett nytt',
      playersInGame: 'Deltar i detta spel',
      otherPlayersAvailable: 'Andra spelare tillgängliga',
      enterDetails: 'Vänligen ange din information',
      newGame: 'Nytt spel',
      noGames: 'Inga spel hittades',
      begin: 'Börja!',
      name: 'namn',
      sharing: 'dela in sig i grupper',
      ageGroup: 'ålder',
      myFirstLanguage: 'Mitt första språk',
      norwegian: 'Norsk',
      annet: 'Annat språk',
    },
    en: {
      selectGame: 'Select a Game or create a new one',
      playersInGame: 'Players in this Game',
      otherPlayersAvailable: 'Other players available',
      enterDetails: 'Please enter your details',
      newGame: 'New game',
      noGames: 'No games found',
      begin: 'Begin!',
      name: 'name',
      sharing: 'share to groups',
      myFirstLanguage: 'My first language',
      norwegian: 'Norwegian',
      annet: 'Other language',
    },
  }

  interface GroupShareOptionListItem {
    title: string
    id: string
  }
  interface LanguageOptionListItem {
    title: string
    id: string
  }

  const { t } = useI18n({ messages })
  const stateService = useStateService()
  const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
  // const telRegex = new RegExp(/^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{2,6}$/im)
  const gameStore = useGameStore()
  const userStore = useUserStore()
  const cmsStore = useCMSStore()
  const usersGroups: Ref<GroupShareOptionListItem[]> = ref([])
  const userLanguages: Ref<LanguageOptionListItem[]> = ref([])
  const editingGame: Ref<Game> = ref(new Game())
  const validators: Ref<Record<string, boolean>> = ref({})
  const theUser: Ref<User> = ref(new User())
  const lackingDetails = ref(false)
  const user = userStore.getters.selectedUser

  onMounted(() => {
    const u = userStore.getters.selectedUser.value
    theUser.value.update(u)
    lackingDetails.value =
      (cmsStore.getters.activityPhase.value === ActivityPhase.BroadRelease ||
        cmsStore.getters.activityPhase.value === ActivityPhase.BroadReleaseTesting) &&
      (u.profile.username === 'username unknown' ||
        !emailRegex.test(u.profile.email as string) || // || !telRegex.test(u.profile.mobil as string)
        u.profile.languagesSpoken.length === 0 ||
        !u.profile.ageGroup)
    usersGroups.value = theUser.value.groups.map((g) => {
      return {
        id: g._id,
        title: g.name,
      }
    })
    userLanguages.value = [
      { title: 'norsk', id: 'norsk' },
      { title: 'annet språk', id: 'annet' },
    ]
  })

  const done = () => {
    // Retrieve game details and start the state machine, which should move us to the 'ship' view
    const sp = gameStore.getters.selectedGame.value
    if (sp)
      gameStore.actions.getGameDetails(sp._id).then(() => {
        stateService.setters.fade = true
        setTimeout(() => {
          cmsStore.actions.setActivityID(theUser.value.status.currentActivityId)
          stateService.actions.begin()
        }, 1000)
      })
  }

  const usernameDone = () => {
    userStore.actions.updateUser(theUser.value).then(() => {
      userStore.actions.selectUser(theUser.value)
      lackingDetails.value = false
    })
  }

  const allValid = computed(() => {
    return Object.values(validators.value).every((v) => v)
  })

  const gameList = computed(() => {
    const games = gameStore.getters.games.value
    return games.filter((game) => {
      // Mobile device may have stored previous games that should not be shown anymore
      return (
        game.owner === theUser.value._id ||
        theUser.value.groups.some((grp) => game.sharing.groups.includes(grp._id) || game.sharing.users.some((us) => us === theUser.value._id))
      )
    })
  })

  function selectGame(item: Game) {
    gameStore.actions.selectGame(item)
  }

  const gameSelected = computed(() => gameList.value.some((g) => g.selected))

  function newGame() {
    gameStore.actions.addGame()
  }

  function editGame(g: Game) {
    editingGame.value.update(g)
  }

  async function saveEditedGame() {
    await gameStore.actions.updateGame(editingGame.value)
    editingGame.value = new Game()
  }

  function testme(value: Event) {
    console.log(value.target)
  }
</script>
