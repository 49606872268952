import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype4Query = gql`
  fragment Tasktype4 on Tasktype4 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      round1 {
        __language {
          boxImage {
            url
            slug
          }
          sentenceBeforeWord
          audioBeforeWord {
            url
            slug
          }
          sentenceAfterWord
          audioAfterWord {
            url
            slug
          }
          mergeBefore
          mergeAfter
        }
      }
      round2 {
        __language {
          boxImage {
            url
            slug
          }
          sentenceBeforeWord
          audioBeforeWord {
            url
            slug
          }
          sentenceAfterWord
          audioAfterWord {
            url
            slug
          }
          mergeBefore
          mergeAfter
        }
      }
      draggableText {
        __language {
          audio {
            url
            slug
          }
          text
        }
      }
      correctWord1 {
        iv
      }
      correctWord1Audio {
        __language {
          url
          slug
        }
      }
      correctWord2 {
        iv
      }
      correctWord2Audio {
        __language {
          url
          slug
        }
      }
    }
  }
`

interface Type4Attributes {
  round1: LC<{
    boxImage: { url: string; slug: string }[]
    sentenceBeforeWord: string
    audioBeforeWord: { url: string; slug: string }[]
    sentenceAfterWord: string
    audioAfterWord: { url: string; slug: string }[]
    mergeBefore: boolean
    mergeAfter: boolean
  }>
  round2: LC<{
    boxImage: { url: string; slug: string }[]
    sentenceBeforeWord: string
    audioBeforeWord: { url: string; slug: string }[]
    sentenceAfterWord: string
    audioAfterWord: { url: string; slug: string }[]
    mergeBefore: boolean
    mergeAfter: boolean
  }>
  draggableText: LC<
    {
      audio: { url: string; slug: string }[]
      text: string
    }[]
  >
  correctWord1: LC<string>
  correctWord1Audio: LC<{ url: string; slug: string }[]>
  correctWord2: LC<string>
  correctWord2Audio: LC<{ url: string; slug: string }[]>
}
export interface Tasktype4Data extends TasktypeData {
  data: CommonTaskAttributes & Type4Attributes
}
export class Tasktype4 extends Tasktype {
  round1 = {
    boxImage: '', // URL
    sentenceBeforeWord: '',
    audioBeforeWord: '', // URL
    sentenceAfterWord: '',
    audioAfterWord: '', // URL
    mergeBefore: false,
    mergeAfter: false,
  }
  round2 = {
    boxImage: '', // URL
    sentenceBeforeWord: '',
    audioBeforeWord: '', // URL
    sentenceAfterWord: '',
    audioAfterWord: '', // URL
    mergeBefore: false,
    mergeAfter: false,
  }
  draggableText: {
    audioURL: string // URL
    text: string
  }[] = []
  correctWord1 = ''
  correctWord1Audio = '' // URL
  correctWord2 = ''
  correctWord2Audio = '' // URL

  constructor(spec: Tasktype4Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype4
    this.parent = parent
    let rd = spec.data.round1[language]
    if (rd) {
      this.round1 = {
        boxImage: rd.boxImage[0]?.url + rd.boxImage[0]?.slug || '',
        sentenceBeforeWord: rd.sentenceBeforeWord || '',
        audioBeforeWord: rd.audioBeforeWord?.[0]?.url + rd.audioBeforeWord?.[0]?.slug || '',
        sentenceAfterWord: rd.sentenceAfterWord || '',
        audioAfterWord: rd.audioAfterWord?.[0]?.url + rd.audioAfterWord?.[0]?.slug || '',
        mergeBefore: rd.mergeBefore,
        mergeAfter: rd.mergeAfter,
      }
    } else console.log('Type4: GraphQL data null')
    rd = spec.data.round2[language]
    if (rd) {
      this.round2 = {
        boxImage: rd.boxImage[0]?.url + rd.boxImage[0]?.slug || '',
        sentenceBeforeWord: rd.sentenceBeforeWord || '',
        audioBeforeWord: rd.audioBeforeWord?.[0]?.url + rd.audioBeforeWord?.[0]?.slug || '',
        sentenceAfterWord: rd.sentenceAfterWord || '',
        audioAfterWord: rd.audioAfterWord?.[0]?.url + rd.audioAfterWord?.[0]?.slug || '',
        mergeBefore: rd.mergeBefore,
        mergeAfter: rd.mergeAfter,
      }
    } else console.log('Type4: GraphQL data null')
    spec.data.draggableText[language]?.forEach((dt) => {
      this.draggableText.push({ audioURL: dt.audio?.[0]?.url + dt.audio?.[0]?.slug || '', text: dt.text })
    })
    this.correctWord1 = spec.data.correctWord1[LanguageCodes.iv] || 'undefined'
    const cwa = spec.data.correctWord1Audio[language]
    if (cwa) this.correctWord1Audio = cwa[0]?.url + cwa[0]?.slug || ''
    this.correctWord2 = spec.data.correctWord2[LanguageCodes.iv] || 'undefined'
    const cwa2 = spec.data.correctWord2Audio[language]
    if (cwa2) this.correctWord2Audio = cwa2[0]?.url + cwa2[0]?.slug || ''
  }

  get assetList(): string[] {
    const list = [
      this.round1.boxImage,
      this.round1.audioBeforeWord,
      this.round1.audioAfterWord,
      this.round2.boxImage,
      this.round2.audioBeforeWord,
      this.round2.audioAfterWord,
      this.correctWord1Audio,
      this.correctWord2Audio,
    ]
    this.draggableText.forEach((t) => list.push(t.audioURL))
    return list
  }
}
