<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row py-4 rounded" :class="[showSave ? 'bg-green-200' : 'bg-blue-200']">
    <template v-if="edit">
      <div class="flex flex-row items-center w-full">
        <AnswerInput
          v-model="editableGroup.name"
          class="m-2"
          mode="text"
          label="Group Name"
          :border="false"
          custom-size="12"
          @change="saveActive()"
        ></AnswerInput>
        <AnswerInput
          v-model="editableGroup.location"
          class="m-2"
          mode="text"
          label="Group Location"
          :border="false"
          custom-size="12"
          @change="saveActive()"
        ></AnswerInput>
        <div class="flex flex-row justify-end w-full px-2">
          <Button v-if="showSave" class="mr-2" @click="saveGroup()"> Save </Button>
          <Button @click="deleteGroup()"> Delete </Button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-row w-full items-center">
        <div class="flex flex-row justify-around w-full">
          <p>
            <strong>{{ group.name }}</strong>
          </p>
          <p>
            <strong v-if="group.location">{{ group.location }}</strong>
            <strong v-else><i style="color: grey">unknown</i></strong>
          </p>
        </div>
        <slot />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, reactive, computed } from 'vue'
  import { Group } from '@/models/main'
  import useUserStore from '@/store/useUserStore'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import Button from '@/components/base/Button.vue'

  export default defineComponent({
    name: 'MonitorGroupItem',
    components: {
      AnswerInput,
      Button,
    },
    props: {
      group: {
        type: Object as PropType<Group>,
        required: true,
      },
      edit: {
        type: Boolean,
      },
      selected: {
        type: Boolean,
      },
    },
    emits: ['selected'],
    setup(props, context) {
      const { actions: userActions } = useUserStore()
      const showSave = ref(false)

      const g = new Group(props.group)
      const editableGroup = reactive<Group>(g)

      const saveGroup = async () => {
        await userActions.updateGroup(editableGroup)
        showSave.value = false
      }

      const deleteGroup = async () => {
        await userActions.deleteGroup(editableGroup)
        showSave.value = false
      }

      const saveActive = () => {
        showSave.value = true
      }

      const selectMe = () => {
        context.emit('selected')
      }

      return {
        // Computed
        showSave: computed(() => showSave.value),
        editableGroup,

        // Methods
        saveGroup,
        saveActive,
        selectMe,
        deleteGroup,
      }
    },
  })
</script>

<style scoped></style>
