/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { Sett, CmsGQLData, SetData, DISPLAY_MODE } from './main'
import { QuestionUnion } from './tasktypes'
import { MORFOLOGICAL_INTROS, TASK_TYPES, QUESTION_MODE, ActivityPhase } from '@/constants'
import { shuffleItems } from '@/utilities'

interface Dictionary<T> {
  [Key: string]: T
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

export type { Dictionary, EnumDictionary }

interface LocationData {
  value: number
  label: string
  x: number
  y: number
  image: [{ url: string }] | undefined
}
export class Location {
  value: number
  label: string
  x: number
  y: number
  image: string

  constructor(data: LocationData) {
    this.value = data ? data.value : 0
    this.label = data ? data.label : ''
    this.x = data ? data.x : 0
    this.y = data ? data.y : 0
    this.image = data && data.image ? data.image[0].url : ''
  }

  get leftPC() {
    // Includes scaling adjustmets for new version
    return this.x * 0.6 + '%'
  }

  get topPC() {
    // Includes scaling adjustmets for new version
    return this.y + 2.6 + '%'
  }
}

export interface SessionData {
  id: string
  flatData: {
    name: string
    index: number
    description: string
    searchKey: string
    password: string
    location: LocationData
    morfologicalIntro: MORFOLOGICAL_INTROS
    consolidation: boolean
    shuffleWarmups: boolean
    shuffleTests: boolean
  }
}
export class Session extends Sett {
  searchKey = ''
  sortIndex = 0
  password = ''
  consolidation = false
  morfologicalIntro: MORFOLOGICAL_INTROS = MORFOLOGICAL_INTROS.None
  location?: Location

  scenes: {
    ins: number[]
    morphologicals: number[]
    outs: number[]
  }

  warmups: { [key in TASK_TYPES]: QuestionUnion[] }
  shuffleWarmups = true
  warmupTaskCount = 0

  tests: { [key in TASK_TYPES]: QuestionUnion[] }
  shuffleTests = true
  testTaskCount = 0

  // Local
  activated = false
  completed = false
  skipped = false

  constructor(spec?: SessionData, parent?: Episode, index?: number) {
    const data = {
      id: spec ? spec.id : '',
      title: spec ? spec.flatData.name : '',
      displayMode: DISPLAY_MODE.linear,
      ...(spec ? spec.flatData : []),
      index, // index order of retrieval from CMS
      parent,
      thumbnail: '',
      consolidation: false,
    }
    super(data as SetData)
    this.scenes = {
      ins: [],
      morphologicals: [],
      outs: [],
    }
    this.warmups = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype3: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype10: [],
      Tasktype11: [],
      Tasktype12: [],
      Tasktype13: [],
    }
    this.tests = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype3: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype10: [],
      Tasktype11: [],
      Tasktype12: [],
      Tasktype13: [],
    }
    if (spec) this.updateSession(spec)
  }

  private createNewTaskLists() {
    this.warmups = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype3: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype10: [],
      Tasktype11: [],
      Tasktype12: [],
      Tasktype13: [],
    }
    this.tests = {
      Tasktype1: [],
      Tasktype2: [],
      Tasktype3: [],
      Tasktype4: [],
      Tasktype5: [],
      Tasktype6: [],
      Tasktype7: [],
      Tasktype8: [],
      Tasktype9: [],
      Tasktype10: [],
      Tasktype11: [],
      Tasktype12: [],
      Tasktype13: [],
    }
  }

  private updateSession(data: SessionData) {
    this.searchKey = data.flatData.searchKey
    this.sortIndex = data.flatData.index || 0
    this.searchKey = data.flatData.searchKey || ''
    this.password = data.flatData.password || ''
    this.morfologicalIntro = data.flatData.morfologicalIntro || MORFOLOGICAL_INTROS.None
    this.consolidation = data.flatData.consolidation
    this.location = new Location(data.flatData.location)
    this.shuffleWarmups = data.flatData.shuffleWarmups
    this.shuffleTests = data.flatData.shuffleTests
  }

  public addWarmupQuestion(model: QuestionUnion, tasktype: TASK_TYPES) {
    model.mode = QUESTION_MODE.warmup
    this.warmups[tasktype].push(model)
    this.warmupTaskCount++
  }
  public addTestQuestion(model: QuestionUnion, tasktype: TASK_TYPES) {
    model.mode = QUESTION_MODE.test
    this.tests[tasktype].push(model)
    this.testTaskCount++
  }

  public getTotalTaskCount() {
    return this.testTaskCount + this.warmupTaskCount
  }

  public clearTasks() {
    this.testTaskCount = 0
    this.warmupTaskCount = 0
    this.createNewTaskLists()
  }

  public getTasks(taskMode: QUESTION_MODE, getShuffled: boolean): QuestionUnion[] {
    const taskSet = taskMode === QUESTION_MODE.warmup ? this.warmups : this.tests
    //  setName is 'warmups' or 'tests'
    const tasksToShuffle = []
    const allTasks = []

    for (let i = 2; i < 14; i++) {
      if (i !== 12) {
        const taskType = ('Tasktype' + i) as TASK_TYPES
        // Type 12 should be the last item in the test run
        const moreTasks = taskSet[taskType]
        tasksToShuffle.push(...moreTasks)
      }
    }

    let shuffledTasks = tasksToShuffle
    if ((getShuffled && taskMode === QUESTION_MODE.warmup && this.shuffleWarmups) || (getShuffled && QUESTION_MODE.test && this.shuffleTests)) {
      shuffledTasks = shuffleItems(tasksToShuffle)
    }

    // Add Type 1 tasks to the beginning if they exist
    if (taskSet.Tasktype1) allTasks.push(...taskSet.Tasktype1)
    // Add shuffled tasks in-between
    allTasks.push(...shuffledTasks)
    // Add Type 12 to the end if it exists
    if (taskSet.Tasktype12) allTasks.push(...taskSet.Tasktype12)

    return allTasks
  }

  public activateSession(password = ''): boolean {
    if (password === this.password) {
      return (this.activated = true)
    } else {
      return false
    }
  }

  public get hasPassword(): boolean {
    return !!this.password
  }
}

export interface EpisodeData {
  id: string
  flatData: {
    name: string
    index: number // user-specified sort index from CMS
    slug: string
    subtitle: string
    searchKey: string
    displayMode: DISPLAY_MODE
    sets: SessionData[]
    location: LocationData
  }
}
// This should match the second Navigation level in Squidex
export class Episode extends Sett {
  name: string
  slug: string
  sortIndex: number
  searchKey: string
  location: Location
  sets: Session[]
  completed?: boolean

  constructor(spec: EpisodeData, parent: Activity, index: number) {
    const data = {
      id: spec.id,
      title: spec.flatData.name,
      description: spec.flatData.subtitle,
      ...spec.flatData,
      index, // index order of retrieval from CMS
      parent,
      thumbnail: '',
      consolidation: false,
    }
    super(data as SetData)
    this.subtitle = spec.flatData.subtitle
    this.parent = parent
    this.sortIndex = spec.flatData.index
    this.searchKey = spec.flatData.searchKey
    // At this point we will request questions for a Sett in a separate GraphQL call
    // cmsStore checks this list's length to see if it should request data
    this.questions = []

    this.name = data.name
    this.location = new Location(data.location)
    this.slug = data.slug
    this.sets = []
    if (spec && spec.flatData.sets) {
      spec.flatData.sets.forEach((s, i) => this.sets.push(new Session(s, this, i)))
    }
  }
}

// This should match the first Navigation level in Squidex
export interface ActivityData extends CmsGQLData {
  id: string
  flatData: {
    index: number
    name: string
    word: string
    description: string
    searchKey: ActivityPhase
    displayMode: DISPLAY_MODE
    sets: EpisodeData[]
    beginDate: Date
    finishDate: Date
    openingHour: string
    closingHour: string
    skipTaskPassword: string
  }
}
export class Activity extends Sett {
  activityPhase: ActivityPhase
  sets: Episode[]
  beginDate: Date
  finishDate: Date
  openingHour: number
  closingHour: number
  skipTaskPassword: string

  constructor(spec: ActivityData, index: number) {
    if (isNaN(spec.flatData.index)) spec.flatData.index = index
    const data = {
      id: spec.id,
      ...spec.flatData,
      parent: undefined,
      name: spec.flatData.name,
      description: spec.flatData.description,
      title: spec.flatData.name,
      thumbnail: '',
      consolidation: false,
      skipTaskPassword: spec.flatData.skipTaskPassword,
    }
    super(data as SetData)
    this.activityPhase = data.searchKey
    this.beginDate = new Date(data.beginDate)
    this.finishDate = new Date(data.finishDate)
    this.openingHour = parseInt(data.openingHour)
    this.closingHour = parseInt(data.closingHour)
    this.skipTaskPassword = data.skipTaskPassword || ''
    this.sets = []
    if (spec && spec.flatData.sets) {
      spec.flatData.sets.forEach((s, i) => this.sets.push(new Episode(s, this, i)))
    }
  }
}
