/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type9/ScannerType9@2x.png" />

    <div class="scanner-content" @touchmove="mouseMoving" @mousemove="mouseMoving" @touchend="checkTouchEnd" @mouseup="checkMouseEnd">
      <div class="scanner-padding">
        <div class="taskNoUserSelect fadeInOut" :style="{ opacity: opacity }">
          <div
            v-if="words[0]"
            id="word1"
            class="borderedWordBox type9-word"
            style="top: 27%; left: 15%"
            @mousedown="mouseDownOnWord($event, words[0])"
            @touchstart="mouseDownOnWord($event, words[0])"
          >
            <span class="pointer-events-none">{{ words[0].text }}</span>
          </div>

          <div
            v-if="words[1]"
            id="word2"
            class="borderedWordBox type9-word"
            style="top: 10%; left: 35%"
            @mousedown="mouseDownOnWord($event, words[1])"
            @touchstart="mouseDownOnWord($event, words[1])"
          >
            <span class="pointer-events-none">{{ words[1].text }}</span>
          </div>

          <div
            v-if="words[2]"
            id="word3"
            class="borderedWordBox type9-word"
            style="top: 20%; left: 60%"
            @mousedown="mouseDownOnWord($event, words[2])"
            @touchstart="mouseDownOnWord($event, words[2])"
          >
            <span class="pointer-events-none">{{ words[2].text }}</span>
          </div>

          <!-- Combined word -->
          <transition mode="out-in" name="fade">
            <div v-if="combinedWord" class="borderedWordBox type9-word" style="top: 35%; left: 40%; z-index: 100">
              <span>
                {{ combinedWord }}
              </span>
            </div>
          </transition>

          <div
            v-if="words[3]"
            id="word4"
            class="borderedWordBox type9-word"
            style="top: 55%; left: 15%"
            @mousedown="mouseDownOnWord($event, words[3])"
            @touchstart="mouseDownOnWord($event, words[3])"
          >
            <span class="pointer-events-none">{{ words[3].text }}</span>
          </div>

          <div
            v-if="words[4]"
            id="word5"
            class="borderedWordBox type9-word"
            style="top: 70%; left: 35%"
            @mousedown="mouseDownOnWord($event, words[4])"
            @touchstart="mouseDownOnWord($event, words[4])"
          >
            <span class="pointer-events-none">{{ words[4].text }}</span>
          </div>

          <div
            v-if="words[5]"
            id="word6"
            class="borderedWordBox type9-word"
            style="top: 60%; left: 60%"
            @mousedown="mouseDownOnWord($event, words[5])"
            @touchstart="mouseDownOnWord($event, words[5])"
          >
            <span class="pointer-events-none">{{ words[5].text }}</span>
          </div>
        </div>

        <div v-if="discoveredCombinations.length > 0" class="completedWords taskNoUserSelect">
          <ul>
            <li v-for="(word, i) in discoveredCombinations" :key="`word-d-${i}`">{{ word }}</li>
          </ul>
        </div>
      </div>
    </div>
    <Drawing ref="drawing" class="type9drawingCanvas"></Drawing>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES, WordIndexName } from '@/constants'
  import { Tasktype9, Type9WordType } from '@/models/tasktypes/Tasktype9'
  import type { Type9Correct } from '@/models/tasktypes/Tasktype9'
  import Drawing from '@/components/task/Drawing.vue'
  import type { LinkedWord, Word } from '@/components/task/Drawing.vue'

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype9> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype9,
    correct: 0,
    of: task.value.correct.length,
    answerOptions: task.value.words.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const drawing = ref<InstanceType<typeof Drawing>>()
  const opacity = ref(0)

  let linkedWord: LinkedWord | undefined

  const words: Ref<Word[]> = ref([])

  let linkedUnconfirmedItem1: Word | undefined
  let linkedUnconfirmedItem2: Word | undefined
  let linkedUnconfirmedItem3: Word | undefined
  let linkedUnconfirmedItem4: Word | undefined

  const combinedWord = ref('')
  const discoveredCombinations: Ref<string[]> = ref([])

  let move = 1
  let tappedItem: Word | undefined = undefined
  let audioPlaying = false

  async function setupTask() {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (!task.value) {
      alert('A Type 9 task does not exist - check your Session layout in the CMS')
      return
    }

    let tempWords: Word[] = []
    for (let i = 0; i < task.value.words.length; i++) {
      const word = task.value.words[i]
      const audio = await createSound(word.audioURL)
      if (word.text) {
        const w: Word = {
          enabled: true,
          draggable: true,
          type: word.type as string,
          opacity: 1,
          id: `drag-word-${i}`,
          audio,
          text: word.text,
          reference: word.reference,
        }
        tempWords.push(w)
      }
    }
    tracking.details.of = task.value.minimumCorrect
    stateActions.progress.progressShow({ stars: tracking.details.of })
    words.value = shuffleItems(tempWords)
    words.value.sort((a, b) => {
      if (a.type === b.type) {
        return 0
      } else if (
        (a.type === Type9WordType.prefix && b.type === Type9WordType.root) ||
        (a.type === Type9WordType.root && b.type === Type9WordType.suffix)
      ) {
        return -1
      } else {
        return 1
      }
    })

    // Introduce with instruction audio
    setTimeout(() => {
      opacity.value = 1
      audioPlaying = true
      stateActions.speakLocalised(
        SpeechSounds.instructions.tasks.T9,
        () => {
          audioPlaying = false
        },
        1000,
      )
    }, 1000)
  }

  function checkTouchEnd(event: TouchEvent) {
    event.preventDefault()
    event.stopPropagation()
    let changedTouch = event.changedTouches[0]
    let element = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)
    if (element) {
      const id = parseInt(element.id.substring(4)) - 1
      if (-1 <= id && id < 6) {
        mouseUpOnWord({ clientX: changedTouch.clientX, clientY: changedTouch.clientY }, words.value[id], element)
      } else {
        mouseUpOutsideBox()
      }
    }
  }

  function checkMouseEnd(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    let element = document.elementFromPoint(event.clientX, event.clientY)
    if (element) {
      const id = parseInt(element.id.substring(4)) - 1
      if (-1 < id && id < 6) {
        mouseUpOnWord({ clientX: event.clientX, clientY: event.clientY }, words.value[id], element)
      } else {
        mouseUpOutsideBox()
      }
    }
  }

  function isInOrder(item: Word): boolean {
    if (!task.value.enforceOrder) return true
    else {
      return (
        (move === 1 && task.value.firstWords.indexOf(item.text) > -1) ||
        (move === 2 && task.value.secondWords.indexOf(item.text) > -1) ||
        (move === 3 && task.value.thirdWords.indexOf(item.text) > -1) ||
        (move === 4 && task.value.fourthWords.indexOf(item.text) > -1)
      )
    }
  }

  function mouseMoving(event: MouseEvent | TouchEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (linkedWord && drawing.value) drawing.value.message({ event, message: 'morfologiTaskMousemove' })
  }

  function mouseDownOnWord(event: MouseEvent | TouchEvent, word: Word) {
    tappedItem = word
    if (isInOrder(word)) {
      let x = 0
      let y = 0
      if (!event) return
      if (event.type.includes('touch')) {
        const te = event as TouchEvent
        x = te.changedTouches[0].clientX
        y = te.changedTouches[0].clientY
      } else {
        const me = event as MouseEvent
        x = me.clientX
        y = me.clientY
      }
      const el = document.elementFromPoint(x, y)
      if (el) {
        linkedWord = {
          word,
          startX: x,
          startY: y,
          startElement: el,
          endElement: undefined,
          endX: 0,
          endY: 0,
        }
        if (move === 1) {
          // We won't see the first item again after mouseDown
          attemptToAddNewItem(word)
        }
        if (linkedWord && drawing.value) drawing.value.message({ message: 'morfologiTaskMousedown', word: linkedWord })
      }
    }
  }

  function playWordAudio() {
    if (tappedItem && tappedItem.audio && !audioPlaying) {
      audioPlaying = true
      tappedItem.audio.onended = () => {
        tappedItem = undefined
        audioPlaying = false
      }
      tappedItem.audio.playWhenReady()
    }
  }

  function mouseUpOutsideBox() {
    if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
    if (move === 1) {
      linkedUnconfirmedItem1 = linkedUnconfirmedItem2 = linkedUnconfirmedItem3 = linkedUnconfirmedItem4 = undefined
    }
    linkedWord = undefined
  }

  async function mouseUpOnWord(coords: { clientX: number; clientY: number }, item: Word, el: Element) {
    let foundASlot = false

    if (drawing.value) {
      drawing.value.message({ message: 'morfologiTaskMouseup' })
    }

    // Mouse up was on the same item as mouse down
    if (tappedItem === item) {
      playWordAudio()
      tracking.details.use_audio_content_items++
      linkedWord = undefined
      linkedUnconfirmedItem1 = linkedUnconfirmedItem2 = linkedUnconfirmedItem3 = linkedUnconfirmedItem4 = undefined
    }

    // Mouse down item was not the same item as the last item in the arrow trail
    if (move > 0 && move < 5) {
      let container = undefined
      if (move === 1) container = linkedUnconfirmedItem1
      else if (move === 2) container = linkedUnconfirmedItem2
      else if (move === 3) container = linkedUnconfirmedItem3
      else if (move === 4) container = linkedUnconfirmedItem4
      if (linkedWord && linkedWord.word !== container) linkedWord = undefined
    }

    if (linkedWord) {
      foundASlot = attemptToAddNewItem(item)

      if (foundASlot) {
        let cc: Type9Correct = {
          firstWord: (linkedUnconfirmedItem1 && linkedUnconfirmedItem1.reference) || WordIndexName.None,
          secondWord: (linkedUnconfirmedItem2 && linkedUnconfirmedItem2.reference) || WordIndexName.None,
          thirdWord: (linkedUnconfirmedItem3 && linkedUnconfirmedItem3.reference) || WordIndexName.None,
          fourthWord: (linkedUnconfirmedItem4 && linkedUnconfirmedItem4.reference) || WordIndexName.None,
          audioURL: '',
          audio: undefined,
          matched: false,
        }

        let result: { status: string; correctRef?: Type9Correct } = task.value.hasCombinationStatus(cc, discoveredCombinations.value) // Result is the correct combination including audio

        linkedWord.endX = coords.clientX
        linkedWord.endY = coords.clientY
        linkedWord.endElement = el

        if (result.status === 'correct' && result.correctRef) {
          // Correct combination

          if (drawing.value) {
            drawing.value.message({ message: 'morfologiTaskLine', word: linkedWord })
          }
          linkedUnconfirmedItem1 = linkedUnconfirmedItem2 = linkedUnconfirmedItem3 = linkedUnconfirmedItem4 = undefined
          move = 1
          combinedWord.value = task.value.combinedWordFromCC(result.correctRef)
          if (discoveredCombinations.value.indexOf(combinedWord.value) === -1) {
            // Did we already find this word?
            tracking.details.correct++
            tracking.details.answer_details.push({
              attempt: task.value.wordsFromCC(cc),
              correct: true,
              elapsed: tracking.elapsedTimeSinceLastCall,
            })
            stateActions.progress.completeAStar()
            // Fuse into one word
            // ToDo: animate...
            // Read completed word audio
            if (result.correctRef.audioURL) {
              const correctAudio = await createSound(result.correctRef.audioURL)
              correctAudio.playWhenReady()
            }
            // Add completed word to completed list
            discoveredCombinations.value.push(combinedWord.value)
          }
          // Remove lines from screen
          setTimeout(() => {
            combinedWord.value = ''
            if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
          }, 2000)

          if (tracking.details.of === tracking.details.correct) {
            setTimeout(() => {
              if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
                stateActions.speakLocalised(SpeechSounds.instructions.warmups.T9, () => completeTask(), 1000, false)
              } else {
                completeTask()
              }
            }, 1500)
          }
        } else if (result.status === 'some') {
          // Allow user to continue adding to the unconfirmed set..
          if (drawing.value) drawing.value.message({ message: 'morfologiTaskLine', word: linkedWord })
          move++
        } else {
          //Incorrect choice
          if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
          linkedWord = undefined
          tracking.details.incorrectAttempts++
          if (result.status !== 'duplicate') {
            tracking.details.answer_details.push({
              attempt: task.value.wordsFromCC(cc),
              correct: false,
              elapsed: tracking.elapsedTimeSinceLastCall,
            })
          }
          linkedUnconfirmedItem1 = linkedUnconfirmedItem2 = linkedUnconfirmedItem3 = linkedUnconfirmedItem4 = undefined
          move = 1
        }
      }
    }
  }

  // Ensure each linked item is a different word
  function attemptToAddNewItem(item: Word): boolean {
    let success = false
    if (!linkedUnconfirmedItem1) {
      linkedUnconfirmedItem1 = item
      success = true
    } else if (linkedUnconfirmedItem1 === item) {
      success = false
      // linkedUnconfirmedItem1 = null;
    } else if (!linkedUnconfirmedItem2) {
      if (linkedUnconfirmedItem1.reference !== item.reference) {
        linkedUnconfirmedItem2 = item
      }
      success = true
    } else if (linkedUnconfirmedItem2 === item) {
      success = false
      // linkedUnconfirmedItem2 = null;
    } else if (!linkedUnconfirmedItem3) {
      if (linkedUnconfirmedItem1.reference !== item.reference && linkedUnconfirmedItem2.reference !== item.reference) {
        linkedUnconfirmedItem3 = item
      }
      success = true
    } else if (linkedUnconfirmedItem3 === item) {
      success = false
      // linkedUnconfirmedItem3 = null;
    } else if (!linkedUnconfirmedItem4) {
      if (
        linkedUnconfirmedItem1.reference !== item.reference &&
        linkedUnconfirmedItem2.reference !== item.reference &&
        linkedUnconfirmedItem3.reference !== item.reference
      ) {
        linkedUnconfirmedItem4 = item
      }
      success = true
    } else if (linkedUnconfirmedItem4 === item) {
      success = false
      //linkedUnconfirmedItem4 = null;
    }
    if (!success && move === 1) {
      linkedUnconfirmedItem1 = undefined
    }
    return success
  }

  const completeTask = () => {
    opacity.value = 0
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }

  setupTask()
</script>

<style scoped lang="postcss">
  .type9-word-area {
    position: relative;
  }

  .type9-word {
    position: absolute;
  }

  .task-container {
    width: 100vw;
    position: relative;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
    padding-top: 5%;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 5%;
  }

  .completedWords {
    position: absolute;
    top: 50%;
    right: 10%;
    color: #00ff45;
    font-size: 14pt;
    text-align: left;
    outline: none;

    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 15px 10px 10px;
    border-radius: 20px;
  }
  .completedWords ul {
    margin: 0;
    outline: none;
  }
  .completedWords ul li {
    margin: 0;
    outline: none;
  }

  .type9drawingCanvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
</style>
