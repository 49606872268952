<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col cursor-default relative bg-white min-h-screen">
    <div class="flex flex-col p-4 bg-white">
      <ul class="flex border-b">
        <router-link to="/monitor/profile">
          <li
            v-if="hasMonitorRole"
            id="li-userstab"
            class="-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold"
            :class="{ tabSelected: selectedTab === 'users' }"
            @click="selectTab('users', 'profile')"
          >
            Users
          </li>
        </router-link>
        <router-link to="/monitor/groups">
          <li
            v-if="hasAdminRole"
            id="li-groupstab"
            class="-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold"
            :class="{ tabSelected: selectedTab === 'groups' }"
            @click="selectTab('groups', 'groups')"
          >
            Kindergartens
          </li>
        </router-link>
        <router-link :to="{ name: 'MonitorGames' }">
          <li
            v-if="hasMonitorRole"
            id="li-gamestab"
            class="-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold"
            :class="{ tabSelected: selectedTab === 'games' }"
            @click="selectTab('games', 'games')"
          >
            Games
          </li>
        </router-link>
        <router-link to="/monitor/mastery">
          <li
            v-if="hasMonitorRole"
            id="li-masterytab"
            class="-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold"
            :class="{ tabSelected: selectedTab === 'mastery' }"
            @click="selectTab('mastery', 'mastery')"
          >
            Progress
          </li>
        </router-link>
        <router-link to="/monitor/logs">
          <li
            v-if="hasLogsRole"
            id="li-masterytab"
            class="-mb-px mr-1 bg-white inline-block py-2 px-4 text-blue-500 hover:sl-blue-800 font-semibold"
            :class="{ tabSelected: selectedTab === 'logs' }"
            @click="selectTab('logs', 'logs')"
          >
            Logs
          </li>
        </router-link>
      </ul>
    </div>

    <div class="flex flex-col p-4 flex-grow bg-white">
      <router-view></router-view>
    </div>
    <div class="absolute top-0 right-0 m-4">
      <a id="button-return" class="bg-white inline-block py-2 px-4 text-green-600 hover:sl-blue-800 font-semibold cursor-pointer" @click="restart()"
        >Return</a
      >
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { USER_ROLE } from '@/constants'
  import useUserStore from '@/store/useUserStore'
  import useCmsStore from '@/store/useCMSStore'
  import { LicenseManager } from 'ag-grid-enterprise'

  LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY as string)

  export default defineComponent({
    name: 'MonitorComponent',
    setup() {
      const router = useRouter()
      const { actions: userActions, getters: userGetters } = useUserStore()
      const { actions: cmsActions } = useCmsStore()

      const selectedTab = ref('users')
      const selectedSubTab = ref('profile')

      const selectTab = (main: string, secondary: string) => {
        selectedTab.value = main
        selectedSubTab.value = secondary
      }

      const restart = () => {
        cmsActions.resetStorage()
        router.push(`/postlogin`)
      }

      /*Computed functions */
      const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))
      const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))
      const hasLogsRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs))

      return {
        // Computed
        hasAdminRole,
        hasMonitorRole,
        hasLogsRole,
        selectedTab,
        selectedSubTab,

        // Methods
        restart,
        selectTab,
      }
    },
  })
</script>

<style scoped lang="postcss">
  @import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
  @import '../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
  .tabSelected {
    @apply border-l border-t border-r border-b-0 rounded-t text-blue-800;
  }
</style>
