import { Tasktype1Query, Tasktype1, Tasktype1Data } from './Tasktype1'
import { Tasktype2Query, Tasktype2, Tasktype2Data } from './Tasktype2'
import { Tasktype3Query, Tasktype3, Tasktype3Data } from './Tasktype3'
import { Tasktype4Query, Tasktype4, Tasktype4Data } from './Tasktype4'
import { Tasktype5Query, Tasktype5, Tasktype5Data } from './Tasktype5'
import { Tasktype6Query, Tasktype6, Tasktype6Data } from './Tasktype6'
import { Tasktype7Query, Tasktype7, Tasktype7Data } from './Tasktype7'
import { Tasktype8Query, Tasktype8, Tasktype8Data } from './Tasktype8'
import { Tasktype9Query, Tasktype9, Tasktype9Data } from './Tasktype9'
import { Tasktype10Query, Tasktype10, Tasktype10Data } from './Tasktype10'
import { Tasktype11Query, Tasktype11, Tasktype11Data } from './Tasktype11'
import { Tasktype12Query, Tasktype12, Tasktype12Data } from './Tasktype12'
import { Tasktype13Query, Tasktype13, Tasktype13Data } from './Tasktype13'

export type QuestionDataIntersection = Tasktype1Data &
  Tasktype2Data &
  Tasktype3Data &
  Tasktype4Data &
  Tasktype5Data &
  Tasktype6Data &
  Tasktype7Data &
  Tasktype8Data &
  Tasktype9Data &
  Tasktype10Data &
  Tasktype11Data &
  Tasktype12Data &
  Tasktype13Data

export type QuestionUnion =
  | Tasktype1
  | Tasktype2
  | Tasktype3
  | Tasktype4
  | Tasktype5
  | Tasktype6
  | Tasktype7
  | Tasktype8
  | Tasktype9
  | Tasktype10
  | Tasktype11
  | Tasktype12
  | Tasktype13

export const QuestionQueries = {
  Tasktype1: Tasktype1Query,
  Tasktype2: Tasktype2Query,
  Tasktype3: Tasktype3Query,
  Tasktype4: Tasktype4Query,
  Tasktype5: Tasktype5Query,
  Tasktype6: Tasktype6Query,
  Tasktype7: Tasktype7Query,
  Tasktype8: Tasktype8Query,
  Tasktype9: Tasktype9Query,
  Tasktype10: Tasktype10Query,
  Tasktype11: Tasktype11Query,
  Tasktype12: Tasktype12Query,
  Tasktype13: Tasktype13Query,
}

export const TaskTypes = {
  Tasktype1,
  Tasktype2,
  Tasktype3,
  Tasktype4,
  Tasktype5,
  Tasktype6,
  Tasktype7,
  Tasktype8,
  Tasktype9,
  Tasktype10,
  Tasktype11,
  Tasktype12,
  Tasktype13,
}
