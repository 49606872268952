<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col bg-slate-200 w-full h-full opacity-100">
    <div class="relative bg-slate-200 w-full h-full opacity-100 p-2 flex flex-col">
      <img class="w-12 h-12 fixed top-1 right-1" src="@/assets/images/map/backToCockpitButton1@2x.png" @click="returnToShip()" />
      <div class="overflow-y-auto flex flex-col flex-grow">
        <div class="border-b-2 border-blue-500 py-2">
          <button class="bg-white p-1 text-base rounded-md" @click="testOldProgress()">Test old progress</button>
          <button class="bg-white p-1 text-base rounded-md ml-2" @click="testNewProgress()">Test new progress</button>
          <button class="bg-white p-1 text-base rounded-md ml-2" @click="testSingleProgress()">Test single progress</button>
          <button class="bg-white p-1 text-base rounded-md ml-2" @click="testGetGames()">Test get games</button>
        </div>
        <div v-for="item of cacheList" :key="item.index" class="border-b-2 border-blue-500 flex flex-row w-full">
          <div class="flex flex-col w-full">
            <p class="text-gray-400 text-xs">
              Cached as: <span class="text-black">{{ item.cachename }}</span>
            </p>
            <p class="text-gray-400 text-xs">
              Original file: <span class="text-black">{{ item.realname }}</span>
            </p>
            <div class="flex flex-row justify-between my-2">
              <button class="bg-white p-1 text-base rounded-md" @click="playLocalURIMP3CachedAudio(item)">Cached MP3Audio</button>
              <button class="bg-white p-1 text-base rounded-md" @click="playRemoteURIMP3Audio(item)">Uncached MP3Audio</button>
              <button class="bg-white p-1 text-base rounded-md" @click="playAudio(item)">HTML Audio</button>
              <button class="bg-white p-1 text-base rounded-md" @click="playWebAudio(item)">Web Audio Local</button>
              <button class="bg-white p-1 text-base rounded-md" @click="playRemoteURIWebAudio(item)">Web Audio Remote</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row h-24 w-full bg-white text-black">
        <pre ref="scroller" class="whitespace-pre-line overflow-y-auto w-full text-xs">{{ scrollback }}</pre>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, Ref, onMounted, nextTick } from 'vue'
  import useDeviceService from '@/composition/useDevice'
  import useGameStore from '@/store/useGameStore'
  import useStateService from '@/composition/useState'
  import { MP3Audio, createSound } from '@/utilities'
  import { SceneMode, ShipMode, StateVariables, ViewState } from '@/constants'

  interface CacheItem {
    index: number
    realname: string
    cachename: string
  }

  const { getters: deviceGetters, actions: deviceActions } = useDeviceService()
  const { actions: gameActions } = useGameStore()
  const stateService = useStateService()

  const cacheList: Ref<CacheItem[]> = ref([])
  const scrollback = ref('')
  let counter = 0
  const audioContext = new AudioContext()
  const scroller: Ref<HTMLPreElement | undefined> = ref()

  onMounted(() => {
    // key = remote filename
    // value = local cache filename
    const fullList = deviceGetters.cacheListing
    const keys = Object.keys(fullList)
    cacheList.value.push({
      index: 0,
      realname: '/assets/sounds/general/StarPopping.mp3',
      cachename: '(not cached) - local sound',
    })
    cacheList.value.push({
      index: 0,
      realname: '/assets/sounds/sv/computer_narrator/41C.mp3',
      cachename: '(not cached) - local sound',
    })
    keys
      .filter((k) => !k.includes('.jpg') && !k.includes('.png') && !k.includes('jpeg'))
      .forEach((key, index) =>
        cacheList.value.push({
          index,
          realname: key,
          cachename: fullList[key],
        }),
      )
  })

  function log(text: string) {
    console.log(text)
    scrollback.value += `\n${counter}: ${text}`
    counter++
    nextTick(() => {
      if (scroller.value) scroller.value.scrollTo(0, scroller.value.scrollHeight + 100)
    })
  }

  async function playLocalURIMP3CachedAudio(item: CacheItem) {
    const path = deviceActions.getCachedMediaURI(item.realname)
    const audio = new MP3Audio(path)
    audio.onended = () => console.log(`Ended MP3Audio ${item.realname}`)
    audio.onready = () => log(`Ready Cached MP3Audio ${item.realname}`)
    audio.onerror = (event, source, lineno, colno, error) => {
      if (error) log(error.message)
    }
    audio.playWhenReady()
  }

  function playRemoteURIMP3Audio(item: CacheItem) {
    const audio = new MP3Audio(item.realname)
    audio.onload = () => console.log(`Loaded MP3Audio ${item.realname}`)
    audio.onended = () => console.log(`Ended MP3Audio ${item.realname}`)
    audio.onready = () => log(`Ready MP3Audio ${item.realname}`)
    audio.onerror = (event, source, lineno, colno, error) => {
      if (error) log(error.message)
    }
    audio.playWhenReady()
  }

  function playAudio(item: CacheItem) {
    const audio = new Audio(item.realname)
    audio.onload = () => console.log(`Loaded regular audio ${item.realname}`)
    audio.onended = () => console.log(`Ended regular audio ${item.realname}`)
    audio.oncanplaythrough = () => log(`Ready regular audio ${item.realname}`)
    audio.onerror = (error) => {
      const el = error as unknown as ErrorEvent
      const target = el.currentTarget as HTMLAudioElement
      const errorCode = target.error?.code
      const errorMessage = `MediaError code ${errorCode || 'unknown'}`
      log(`Error HTMLAudio ${item.realname}: ${errorMessage}`)
    }
    audio.load()
    audio.play()
  }

  async function playWebAudio(item: CacheItem) {
    const audio = await createSound(item.realname)
    audio.onended = () => console.log(`Ended WebAudio ${item.realname}`)
    audio.onready = () => log(`Ready Cached WebAudio ${item.realname}`)
    audio.onerror = (event, source, lineno, colno, error) => {
      if (error) log(error.message)
    }
    audio.playWhenReady()
  }

  async function getFile(audioContext: AudioContext, filepath: string) {
    try {
      const response = await fetch(filepath)
      const arrayBuffer = await response.arrayBuffer()
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
      return audioBuffer
    } catch (error) {
      const err = error as unknown as Error
      log(`Error WAA ${filepath}: ${err.message}`)
    }
  }

  async function playRemoteURIWebAudio(item: CacheItem) {
    const audioBuffer = await getFile(audioContext, item.realname)
    try {
      const sampleSource = new AudioBufferSourceNode(audioContext, {
        buffer: audioBuffer,
        playbackRate: 1,
      })
      sampleSource.connect(audioContext.destination)
      sampleSource.start(0)
    } catch (error) {
      const err = error as unknown as Error
      log(`Error WAA ${item.realname}: ${err.message}`)
    }
  }

  async function testOldProgress() {
    log(`Testing old progress method...`)
    const d1 = new Date()
    await gameActions.testGameProgress('old')
    const time = new Date().getTime() - d1.getTime()
    log(`Completed 'game/progressold' test using old method in ${time}ms`)
  }
  async function testGetGames() {
    log(`Testing 'GET games/list?progress=true' method...`)
    const d1 = new Date()
    await gameActions.getGames('', '', true)
    const time = new Date().getTime() - d1.getTime()
    log(`Completed 'GET games/list?progress=true' test in ${time}ms`)
  }
  async function testNewProgress() {
    log(`Testing new progress method...`)
    const d1 = new Date()
    await gameActions.testGameProgress('new')
    const time = new Date().getTime() - d1.getTime()
    log(`Completed 'game/progress' test using new method in ${time}ms`)
  }
  async function testSingleProgress() {
    log(`Testing single progress item...`)
    const d1 = new Date()
    await gameActions.testGameProgress('')
    const time = new Date().getTime() - d1.getTime()
    log(`Completed 'POST game/:ID/progress' test (single progress entry) in ${time}ms`)
  }

  const returnToShip = () => {
    const sv: StateVariables = {
      sceneMode: SceneMode.Finished,
      viewState: ViewState.Ship,
      shipMode: ShipMode.ReturnedFromInteractiveMap,
    }
    stateService.actions.updateState(false, sv)
  }
</script>

<style scoped></style>
