/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="fadeInOut" :style="{ opacity: opacity }">
    <div class="type13SentenceContainer">
      <span class="type13Sentence taskNoUserSelect"
        >{{ task.textBefore }}<span class="theWordType13">&nbsp;{{ task.word }}&nbsp;</span>{{ task.textAfter }}</span
      >
    </div>

    <div class="choice-container flex flex-row justify-center">
      <div v-for="aRow of [1, 3, 5]" :key="`row-key-${aRow}`" class="flex flex-col justify-around">
        <div v-for="aCol of [0, 1]" :key="`col-key-${aCol}`" class="flex flex-row justify-center place-items-center">
          <div
            v-if="task.answers[aCol + aRow] && task.answers[aCol + aRow].text"
            class="type13AnswerBox taskNoUserSelect flex flex-row justify-around place-items-center"
            @click="chooseAnswer(task.answers[aCol + aRow])"
          >
            <p class="flex">{{ task.answers[aCol + aRow].text }}</p>
            <div class="type13AnswerCheckbox flex ml-1" :class="{ 'type13AnswerCheckbox-selected': task.answers[aCol + aRow].checked }">
              <img :src="task.answers[aCol + aRow].checked ? starSelected : noSelection" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center" style="margin-top: 30px">
      <img
        style="height: 120px"
        :src="submitActive ? sendEnabledButton : sendDisabledButton"
        :class="{ 'sendInButton-active': submitActive }"
        @click="finish()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs } from 'vue'
  import { TaskTracking } from '@/models/main'
  import { createSound } from '@/utilities'
  import useState from '@/composition/useState'
  import { TASK_TYPES } from '@/constants'
  import { Tasktype13 } from '@/models/tasktypes/Tasktype13'
  import type { Type13Answer } from '@/models/tasktypes/Tasktype13'

  import starSelected from '@/assets/images/tasks/type13/StarSelected@2x.png'
  import noSelection from '@/assets/images/tasks/type13/NoSelectionImage@2x.png'
  import sendEnabledButton from '@/assets/images/tasks/type13/SendButtonEnabled@2x.png'
  import sendDisabledButton from '@/assets/images/tasks/type13/SendButtonDisabled@2x.png'

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype13> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype13,
    correct: 0,
    of: 1,
    answerOptions: task.value.answers.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const submitActive = ref(false)
  let soundPlaying = false
  let chosenAnswer: Type13Answer | undefined = undefined

  async function setupTask() {
    stateActions.progress.progressShow({ stars: 0 })
    opacity.value = 1
    if (task.value.audioURL) {
      let instructionAudio = await createSound(task.value.audioURL)
      instructionAudio.onended = () => {
        stateActions.setSpeakerSound([task.value.audioURL])
        stateSetters.speakerIsPlaying = false
        soundPlaying = false
      }
      stateSetters.speakerIsPlaying = true
      instructionAudio.playWhenReady()
    }
  }

  async function chooseAnswer(answer: Type13Answer) {
    if (chosenAnswer && chosenAnswer.checked) {
      chosenAnswer.checked = false
    }
    if (!soundPlaying && answer.audioURL) {
      soundPlaying = true
      const answerAudio = await createSound(answer.audioURL)
      answerAudio.onerror = (error) => {
        console.log(error.toString())
      }
      answerAudio.playWhenReady()
    }
    tracking.details.answer_details.push({ attempt: answer.text, elapsed: tracking.elapsedTimeSinceLastCall })
    chosenAnswer = answer
    if (task.value.correct === answer.option) tracking.details.correct++
    chosenAnswer.checked = true
    submitActive.value = true
  }

  function finish() {
    if (submitActive.value && chosenAnswer) {
      tracking.details.answer_details.push({ attempt: chosenAnswer.text })
      completeTask()
    }
  }

  function completeTask() {
    opacity.value = 0
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  .type13AnswerBox {
    height: 120px;
    margin: 10px;

    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    outline: none;
    border: solid rgba(152, 152, 152, 1) 5px;
    cursor: pointer;
    font-size: 24pt;
    font-weight: 500;
    text-align: center;
    color: white;
  }
  .type13AnswerBox p {
    margin: 0 0;
    font-size: 0.8em;
  }

  .type13AnswerCheckbox img {
    width: 60px;
    height: 60px;
  }

  .choice-container {
    padding-top: 100px;
    width: 100vw;
  }
  .type13Sentence {
    font-size: 1.8em;
    padding: 60px 150px 30px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
  .type13SentenceContainer {
    position: relative;
    text-align: center;
    padding: 10px;
  }
  .theWordType13 {
    font-weight: bold;
    font-style: italic;
  }
</style>
