<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative w-screen h-screen overflow-hidden z-0">
    <router-view></router-view>
    <!-- Right panel -->
    <div id="div-menu-logout" class="w-full flex flex-row items-center justify-end h-24 absolute top-0 right-0 z-10">
      <div class="bg-orange-400 rounded-full m-8">
        <img src="@/assets/icons/svg/logout.svg" alt="log out" class="w-10 h-10 p-2.5" @click="logout()" />
      </div>
    </div>
    <!--div
      class="absolute top-0 right-0 z-10 h-full transform transition duration-500 ease-in-out flex flex-row pointer-events-none"
      :class="rightMenuActive ? 'translate-x-0 ease-out transition-medium' : 'translate-x-64 trans ease-in transition-medium'"
    >
      <div class="flex flex-row flex-wrap justify-end h-32 w-64">
        <img class="w-16 h-16 cursor-pointer pointer-events-auto" :src="menuImage" alt="main menu image" @click="toggleRightMenu" />
      </div>
      <div class="bg-gray-100 w-64 overflow-hidden">
        <transition mode="out-in" :name="rightMenuTransitionName">
          <div v-if="rightMenuLevel == 'main'" class="w-full flex flex-col cursor-pointer pointer-events-auto font-serious text-sm">
            <p v-if="game" class="py-24 font-playful font-bold text-lg ml-4">
              {{ game.name }}
            </p>
            <hr />
            <div v-if="hasMonitorRole" id="div-menu-monitor" class="w-full flex flex-row items-center h-14" @click="monitor()">
              <div class="mx-4 w-10" />
              <p class="flex flex-grow">Monitor</p>
            </div>
            <hr />
            <div id="div-menu-participants" class="w-full flex flex-row items-center h-14" @click="goToGames()">
              <div class="mx-4 w-10" />
              <p class="flex flex-grow">Games</p>
            </div>
            <hr />
            <hr />
            <div id="div-menu-settings-on" class="w-full flex flex-row items-center h-14" @click="toggleSettings()">
              <div class="bg-blue-400 rounded-full mx-4">
                <img src="@/assets/icons/svg/settings.svg" alt="settings" class="w-10 h-10 p-2" />
              </div>
              <p class="flex flex-grow">Settings</p>
              <p class="mr-4 font-bold">→</p>
            </div>
            <hr />
            <div id="div-menu-logout" class="w-full flex flex-row items-center h-14" @click="logout()">
              <div class="bg-blue-400 rounded-full mx-4">
                <img src="@/assets/icons/svg/logout.svg" alt="log out" class="w-10 h-10 p-2.5" />
              </div>
              <p class="flex flex-grow">Log Out</p>
            </div>
            <hr />
          </div>
          <div v-else-if="rightMenuLevel == 'settings'" class="w-full flex flex-col cursor-pointer pointer-events-auto">
            <div id="div-menu-settings-off" class="w-full flex flex-row items-center pt-8 m-2" @click="toggleSettings()">
              <p class="mr-4">←</p>
            </div>
            <div class="w-full flex flex-row items-center pt-8">
              <input id="input-settings-rememberme" v-model="rememberMe" class="w-8 mx-4" type="checkbox" />
              <p>
                Remember Me<br />
                (expires in 3 days)
              </p>
            </div>
            <div v-if="rememberMe" class="w-full flex flex-col items-center pt-8">
              <p class="mx-4">Set a password</p>
              <AnswerInput v-if="localUser" id="input-pin" v-model="localUser.pin" mode="text" :border="false" />
            </div>
          </div>
        </transition>
      </div>
    </div-->
  </div>
</template>

<script setup lang="ts">
  // @ is an alias to /src
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { apiRequest } from '@/api/apiRequest'
  import useAppStore from '@/store/useAppStore'
  import useUserStore from '@/store/useUserStore'
  //import useGameStore from '@/store/useGameStore'
  import useStateService from '@/composition/useState'

  //import AnswerInput from '@/components/base/AnswerInput.vue'
  //import { USER_ROLE } from '@/constants'
  import { XHR_REQUEST_TYPE } from '@/models/main'

  //import menuImage from '@/assets/images/login/Rocket.gif'

  const router = useRouter()
  const { actions: appActions } = useAppStore()
  const { actions: userActions } = useUserStore()
  //const { getters: userGetters, actions: userActions } = useUserStore()
  //const { getters: gameGetters } = useGameStore()

  const { setters: stateSetters } = useStateService()

  //const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))
  const rememberMe = ref(false)
  //const rightMenuTransitionName = ref('slideprev')
  //const rightMenuActive = ref(false)
  //const rightMenuLevel = ref('main')
  stateSetters.fade = false

  // --------------------------------------------------------------

  // Auto-determine the currently selected Game
  //const game = gameGetters.selectedGame

  const logout = () => {
    const completeLogout = () => {
      appActions.logout(rememberMe.value)
      userActions.selectUser()
      userActions.clearMyUser()
      router.push(`/`)
    }
    apiRequest({
      route: '/auth/logout',
      method: XHR_REQUEST_TYPE.GET,
      credentials: true,
    })
      .then(() => {
        completeLogout()
      })
      .catch((error) => {
        // Exchange was not accepted, clear the token and redirect to login page
        console.log(error)
        completeLogout()
      })
  }

  /* const goToGames = () => {
    router.push('/dashboard/games')
  }

  const toggleRightMenu = () => {
    rightMenuActive.value = !rightMenuActive.value
    rightMenuLevel.value = 'main'
  }

  const toggleSettings = () => {
    rightMenuTransitionName.value = rightMenuTransitionName.value === 'slidenext' ? 'slideprev' : 'slidenext'
    rightMenuLevel.value = rightMenuLevel.value === 'main' ? 'settings' : 'main'
  }

  const localUser = appGetters.currentLocalUser
  const monitor = () => router.push('/monitor') */
</script>

<style scoped lang="postcss">
  .slide-rightmenu-enter-from {
    transform-box: border-box;
    transform: translateX(100%);
  }
  .slide-rightmenu-enter-active,
  .slide-rightmenu-leave-active {
    transition: all 1s ease;
  }
  .slide-rightmenu-leave-to {
    transform-box: border-box;
    transform: translateX(100%);
  }

  .widthTransition {
    transition: transform 1s ease;
  }
  @layer {
    .-translate-x-full {
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }

    .translate-x-0 {
      -webkit-transform: translateX(256px);
      transform: translateX(256px);
    }
  }
  /* .slidemenu-right-enter-from {
    transform: translateX(-100%);
  }
  .slidemenu-right-enter-active,
  .slidemenu-right-leave-active {
    transition: all 1s ease;
  }
  .slidemenu-right-leave-to {
    transform: translateX(100%);
  } */
</style>
