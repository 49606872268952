<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col py-4 rounded">
    <p class="text-2xl">{{ game.profile.name }}</p>
    <div class="flex flex-row mb-2 justify-end">
      <Button :disabled="!showSave" :textcolour="'text-black'" @click="saveParticipant()"> Save </Button>
    </div>
    <hr />
    <div class="flex flex-row mt-4">
      <div class="flex flex-col pr-2">
        <div class="flex flex-row items-center">
          <span class="text-gray-700 mb-4">COMPLETED</span>
          <span class="text-gray-700 mb-4 pl-8">ACCESS</span>
          <span class="text-gray-700 mb-4 pl-80">RESULTS</span>
        </div>
        <!-- No Access Option -->
        <div class="flex flex-row items-center">
          <div class="w-3 inline-block"></div>
          <p id="p-access-noaccess" class="rounded-sm ml-32 p-1 border-dashed border border-gray-400" @click="settAccess('noaccess')">No Access</p>
        </div>
        <!-- CMS-based levels -->
        <div v-for="(activity, index) in cmsroot" :key="activity._id" class="flex flex-col">
          <p class="mt-4">{{ index + 1 }}</p>
          <hr />
          <div class="flex flex-row items-center my-1">
            <input
              :id="`input-completed-${activity._id}`"
              v-model="hierarchyAsDict[activity._id].completed"
              type="checkbox"
              @change="settComplete(activity._id)"
            />
            <p :id="`p-access-${activity._id}`" class="ml-32 w-96" @click="settAccess(activity._id)">
              <span
                :class="{
                  'bg-green-300 !border-solid': hierarchyAsDict[activity._id].accessible,
                }"
                class="rounded-sm p-1 border-dashed border border-gray-400"
                >{{ activity.name }}</span
              >
              <span class="text-xs text-gray-300 ml-1">Activity</span>
            </p>
            <SetResultItem :completions="results[activity._id]" />
          </div>
          <div v-for="episode in activity.sets" :key="episode._id" class="flex flex-col">
            <div class="flex flex-row items-center my-1">
              <input
                :id="`input-completed-${episode._id}`"
                v-model="hierarchyAsDict[episode._id].completed"
                type="checkbox"
                @change="settComplete(episode._id)"
              />
              <p :id="`p-access-${episode._id}`" class="pl-4 ml-32 w-96" @click="settAccess(episode._id)">
                <span
                  class="rounded-sm p-1 border-dashed border border-gray-400"
                  :class="{
                    'bg-green-300 !border-solid': hierarchyAsDict[episode._id].accessible,
                  }"
                  >{{ episode.name }}</span
                >
                <span class="text-xs text-gray-300 ml-1">Episode</span>
              </p>
              <SetResultItem :completions="results[episode._id]" />
            </div>
            <div v-for="session in episode.sets" :key="session._id" class="flex flex-col">
              <div class="flex flex-row items-center my-1">
                <input
                  :id="`input-completed-${session._id}`"
                  v-model="hierarchyAsDict[session._id].completed"
                  type="checkbox"
                  @change="settComplete(session._id)"
                />
                <p :id="`p-access-${session._id}`" class="pl-8 ml-32 w-96" @click="settAccess(session._id)">
                  <span
                    class="rounded-sm p-1 border-dashed border border-gray-400"
                    :class="{
                      'bg-green-300 !border-solid': hierarchyAsDict[session._id].accessible,
                    }"
                    >{{ session.name }}</span
                  >
                  <span class="text-xs text-gray-300 ml-1">Session</span>
                </p>
                <SetResultItem :completions="results[session._id]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PropType, ref, Ref, watch, toRefs } from 'vue'
  import { Game, Sett, SpecialRequestData } from '@/models/main'
  import useGameStore from '@/store/useGameStore'
  import SetResultItem from './SetResultItem.vue'
  import Button from '@/components/base/Button.vue'
  // import SelectionBox from '@/components/base/SelectionBox.vue'
  const props = defineProps({
    game: {
      type: Object as PropType<Game>,
      required: true,
    },
    cmsroot: {
      type: Object as PropType<Sett[]>,
      required: true,
    },
    results: {
      type: Object as PropType<SpecialRequestData['data']>,
      required: true,
    },
  })
  const { cmsroot } = toRefs(props)
  const {
    actions: gameActions,
    // getters: gameGetters,
  } = useGameStore()

  const showSave = ref(false)
  const g = new Game(props.game)
  const theGame = ref(g)

  // Create a structure to track selected Setts in a flattened sequence
  interface HAA {
    id: string // An empty ID means no set is allowed access
    parentId: string
    completed: boolean
    accessible: boolean
    item?: Sett
  }
  const hierarchyAsArray: HAA[] = []
  const hierarchyAsDict: Ref<Record<string, HAA>> = ref({})

  const newHAA = (id: string, parentId: string): HAA => {
    const completed = theGame.value.itemIsComplete(id, parentId)
    return {
      id,
      parentId,
      completed,
      accessible: theGame.value.status.allowedSets.indexOf(id) > -1,
    }
  }

  const flatten = (array: Sett[], level: number) => {
    array.forEach((sett) => {
      const h = newHAA(sett._id, sett.parent?._id || '')
      hierarchyAsArray.push(h)
      hierarchyAsDict.value[h.id] = h

      // Traverse to the next level down
      if (sett.sets && sett.sets.length > 0) flatten(sett.sets, level + 1)
    })
  }

  // 'No Access' list option
  const nah = newHAA('noaccess', '')
  hierarchyAsArray.push(nah)
  hierarchyAsDict.value[nah.id] = nah

  flatten(cmsroot.value, 0)

  const initialiseMasterySet = (t: Game) => {
    theGame.value = new Game(t)
    /* selectedSet.value = hierarchyAsArray.find(
        (i) => i.id === theGame.value.progress.limitSet
      ) */
  }
  // During initial setup phase 'watch' does not fire
  initialiseMasterySet(props.game)

  watch(
    () => props.game,
    (newValue: Game) => {
      initialiseMasterySet(newValue)
    },
  )

  // ----------------- Mastery functions -------------------

  // Set the given Sett and all previous Setts to 'accessible'
  // In the case of SLPlus project, a set is accessible if it comes before or equal to the latest 'allowed set'
  // In terms of data structure, accessible sets are those included in   mastery.allowedSets
  // SLPlus sets are accessed in order, by traversing the Sett tree hierarchy in a down-then-forward direction
  const settAccess = (id: string) => {
    theGame.value.status.allowedSets.length = 0
    Object.values(hierarchyAsDict.value).forEach((v) => (v.accessible = false))
    let i = -1
    if (id) {
      do {
        const item: HAA = hierarchyAsArray[++i]
        if (item) {
          theGame.value.status.allowedSets.push(item.id)
          hierarchyAsDict.value[item.id].accessible = true
        }
      } while (hierarchyAsArray[i].id !== id)
    }
    showSave.value = true
  }

  // When a given Sett is to be marked completed, we also need to complete Questions inside the Sett
  // As we use v-model on the template input, the changed value is waiting for us in hierarchyAsDict
  const settComplete = (id: string) => {
    // At this point, 'had' will contain the updated completion state
    const had = hierarchyAsDict.value[id]
    console.log(`Marking task completions for Set ID: ${id} to ${had.completed}`)
    // The ID of the Sett is the parent part of the Question's ID/PARENT ID pair from the progress.records Map
    // We need to brak apart the Progress keys to check if the parent part matches
    let foundExactSet = false // true if we find a record for this Sett ID in Progress
    theGame.value.progress.forEach((value, key) => {
      const keys = key.split(':')
      const parentKey = keys.length > 1 ? keys[1] : ''
      let match = keys[0] === id || parentKey === id
      if (keys[0] === id) foundExactSet = true
      if (match) {
        console.log(`ItemKey: ${keys[0]} ParentKey: ${parentKey} ValueCompleted: ${value.completed} NewCompleted: ${had.completed}`)
        value.completed = had.completed
      }
    })
    // If we did not find the Sett itself, and want to mark completed: true we need to create a new Progress entry
    // Only mark completed: true. We do not add completion timestamp here... TODO: Is it correct to do this?
    if (!foundExactSet && had.completed) {
      theGame.value.createProgress(had.id, had.parentId, had.item?.name).completed = true
      console.log('Created new Progress for set')
    }
    showSave.value = true
  }

  // Example:
  // To allow access to all of Week Sett 'loud', we must
  /* const renewProgress = () => {
      // Which sets are to be marked 'completed'
      const accessibleSetIDs = hierarchyAsArray
        .filter((s) => s.selected)
        .map((s) => s.id)
      theGame.value.progress.records.forEach((value, key) => {
        const setSearchKey = key.split(':')[0]
        // const parentSearchKey =
        value.completed = accessibleSetIDs.includes(setSearchKey)
      })
    } */

  // ------------------------------------------------------

  // Update Mastery status of the current Barn
  const saveParticipant = async () => {
    if (!showSave.value) return
    // Update a selected Barn
    await gameActions.updateGameControl(theGame.value)
    showSave.value = false
  }
</script>

<style lang="postcss" scoped></style>
