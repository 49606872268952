import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype13Query = gql`
  fragment Tasktype13 on Tasktype13 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      morfCategory {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      textBefore {
        __language
      }
      word {
        __language
      }
      textAfter {
        __language
      }
      audio {
        __language {
          url
          slug
        }
      }
      answers {
        __language {
          text
          audio {
            url
            slug
          }
        }
      }
      correct {
        iv
      }
    }
  }
`

export enum Type13AnswerOption {
  Answer1 = 'Answer 1',
  Answer2 = 'Answer 2',
  Answer3 = 'Answer 3',
  Answer4 = 'Answer 4',
  Answer5 = 'Answer 5',
  Answer6 = 'Answer 6',
  None = 'None',
}

interface Type13Attributes {
  textBefore: LC<string>
  word: LC<string>
  textAfter: LC<string>
  audio: LC<{ url: string; slug: string }[]>
  answers: LC<
    {
      text: string
      audio: { url: string; slug: string }[]
    }[]
  >
  correct: LC<Type13AnswerOption> // iv
}

export interface Tasktype13Data extends TasktypeData {
  data: CommonTaskAttributes & Type13Attributes
}
export interface Type13Answer {
  option: Type13AnswerOption
  text: string
  checked: boolean
  audioURL: string // URL
  audio: HTMLAudioElement | undefined
}

export class Tasktype13 extends Tasktype {
  textBefore = ''
  word = ''
  textAfter = ''
  audioURL = '' // URL
  answers: Type13Answer[] = []
  correct = Type13AnswerOption.None // iv

  constructor(spec: Tasktype13Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype13
    this.parent = parent

    const aoAsArray = Object.values(Type13AnswerOption)

    this.textBefore = spec.data.textBefore[language] || ''
    this.textAfter = spec.data.textAfter[language] || ''
    this.word = spec.data.word[language] || ''
    const a = spec.data.audio[language]
    if (a) this.audioURL = a[0]?.url + a[0]?.slug || ''
    this.correct = spec.data.correct.iv || Type13AnswerOption.None
    spec.data.answers[language]?.forEach((a, i) => {
      const audioURL = a.audio[0]?.url + a.audio[0]?.slug || ''
      this.answers.push({
        option: aoAsArray[i] || Type13AnswerOption.None,
        text: a.text,
        checked: false,
        audioURL,
        audio: undefined,
      })
    })
  }

  get assetList(): string[] {
    const list: string[] = [this.audioURL]
    this.answers.forEach((a) => list.push(a.audioURL))
    return list
  }
}
