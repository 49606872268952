<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="map-container">
    <div class="map-scrolling-pane">
      <img class="map-background-image" src="@/assets/images/map/IslandMap@2x.png" />

      <div class="map-stage-container">
        <div class="relative w-full h-full">
          <div
            v-for="session of flagSessions"
            :id="'map-session-' + session._id"
            :key="'map-session-' + session._id"
            class="map-stage"
            :style="{ top: session.location && session.location.topPC, left: session.location && session.location.leftPC }"
          >
            <img
              v-if="!session.activated && sessionIsCompleted(session) && !session.consolidation && !session.skipped"
              class="flagStatic"
              src="@/assets/images/map/FlagStatic500x700.png"
            />
            <img
              v-if="showActiveSession && session.activated && sessionIsCompleted(session) && !session.skipped"
              class="flagStatic"
              src="@/assets/images/map/FlagAnimated500x700.gif"
            />
            <img
              v-if="
                activeSession &&
                activeSession._id === session._id &&
                showActiveSession &&
                session.activated &&
                !sessionIsCompleted(session) &&
                !session.skipped
              "
              class="sessionActive"
              src="@/assets/images/map/NextMission200x200.gif"
              @click="startSelectedSession()"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="returnToShipButtonActive" class="returnToShipButton" @click="returnToShip()">
      <img src="@/assets/images/map/backToCockpitButton1@2x.png" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { ShipMode, ViewState, SceneMode, SpeechSounds, SceneType, StateVariables } from '@/constants'
  import { Session } from '@/models/navigationModels'
  import useStateService from '@/composition/useState'
  import useCMSStore from '@/store/useCMSStore'
  import useAppStore from '@/store/useAppStore'
  import useGameStore from '@/store/useGameStore'

  const stateService = useStateService()
  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: appGetters } = useAppStore()
  const { actions: gameActions } = useGameStore()

  // const fullPaneHeightOnMobile = appGetters.status.value.isMobileApp

  let idleTimer: ReturnType<typeof setTimeout>
  let warningGiven = false
  let showActiveSession = ref(false)

  const flagSessions = computed(() => cmsGetters.selectedEpisode.value.episode?.sets || [])
  const activeSession = computed(() => cmsGetters.selectedSession.value.session)

  const sessionIsCompleted = (s: Session) => {
    return s && s.parent ? gameActions.itemIsCompleted(s._id, s.parent._id) : false
  }

  const scrollToActiveStage = () => {
    const as = activeSession.value
    if (stateService.getters.state.value.shipMode !== ShipMode.SessionCompleted) {
      setTimeout(() => {
        if (as && as.location && as.location.x > 80) {
          // eslint-disable-next-line no-undef
          const sivo: ScrollOptions = { behavior: 'smooth' }
          const ms = document.querySelector('#map-session-' + as._id) as Element
          ms.scrollIntoView(sivo)
        }
        setTimeout(() => {
          showActiveSession.value = true
          if (as && stateService.getters.state.value.shipMode === ShipMode.SessionEnding) {
            setTimeout(() => {
              cmsActions.selectSession(as)
              cmsActions.setSessionActivation(false)
              const sv: StateVariables = {
                sceneMode: SceneMode.Ready,
                sceneType: SceneType.GeneralScene,
                viewState: ViewState.Ship,
              }
              clearTimeout(idleTimer)
              stateService.actions.updateState(true, sv)
            }, 5000)
          }
        }, 1000)
      }, 0)
    }
  }

  const resetIdleTimeout = () => {
    if (returnToShipButtonActive.value) {
      if (idleTimer && warningGiven) clearTimeout(idleTimer)

      idleTimer = setTimeout(() => {
        if (warningGiven && !appGetters.disableDelays.value) {
          stateService.actions.speakLocalised(SpeechSounds.computer.C62, () => {
            // stateService.actions.exitToDashboard()
          })
        } else {
          warningGiven = true
          resetIdleTimeout()
          stateService.actions.speakLocalised(SpeechSounds.computer.C66)
        }
      }, 60000)
    }
  }

  const startSelectedSession = () => {
    clearTimeout(idleTimer)
    stateService.actions.startSession()
  }

  const returnToShipButtonActive = computed(() => {
    return true //!!cmsGetters.selectedSession.value.session?.completed && !cmsGetters.selectedSession.value.session?.activated
  })

  const returnToShip = () => {
    if (returnToShipButtonActive.value) {
      clearTimeout(idleTimer)
      const sv: StateVariables = {
        sceneMode: SceneMode.Finished,
        viewState: ViewState.Ship,
        shipMode: ShipMode.ReturnedFromInteractiveMap,
      }
      stateService.actions.updateState(false, sv)
    }
  }

  resetIdleTimeout()

  setTimeout(() => {
    scrollToActiveStage()
  }, 500)
</script>

<style scoped>
  .map-container {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
    position: relative;
  }

  .map-scrolling-pane {
    height: 75vw;
    width: 218.75vh;
    position: relative;
  }
  .paneHeightForMobile {
    height: 75vw !important;
  }

  .map-background-image {
    height: 100%;
    width: 100%;
  }

  .map-stage-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    white-space: nowrap;
  }

  .map-stage {
    display: inline-block;
    position: absolute;
  }

  .map-stage img {
    display: block;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  @-webkit-keyframes colorPulse {
    0% {
      -webkit-filter: sepia(0%);
      transform: scale(1.05);
    }
    50% {
      -webkit-filter: sepia(100%);
      transform: scale(0.95);
    }
    100% {
      -webkit-filter: sepia(0%);
      transform: scale(1.05);
    }
  }
  @keyframes colorPulse {
    0% {
      -webkit-filter: sepia(0%);
      transform: scale(1.05);
    }
    50% {
      -webkit-filter: sepia(100%);
      transform: scale(0.95);
    }
    100% {
      -webkit-filter: sepia(0%);
      transform: scale(1.05);
    }
  }
  .activePulsate {
    -webkit-animation: colorPulse 5s infinite alternate;
    animation: colorPulse 5s infinite alternate;
  }

  .returnToShipButton {
    position: fixed;
    top: 30px;
    right: 30px;
  }
  .returnToShipButton img {
    width: 80px;
  }
  .flagStatic {
    width: 70px;
    height: 98px;
  }
  .sessionActive {
    width: 100px;
  }
</style>
