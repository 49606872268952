<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div>
    <div class="flex flex-col w-full h-full bg-black opacity-30"></div>
    <div class="absolute grid grid-cols-1 place-content-center items-center w-full h-full top-0 left-0 text-center text-white">
      <p v-if="viewState === DelayMode.AwaitingDownload">{{ t('downloading') }}</p>
      <p v-if="viewState === DelayMode.NoActivitiesFound">{{ t('noActivityFound') }}</p>
      <p v-if="viewState === DelayMode.NoSessionsFound">{{ `${t('noSessionFound')} ${episodeName}` }}</p>
      <p v-if="viewState === DelayMode.DemoComplete">{{ t('demoCompleted') }}</p>
      <p v-if="viewState === DelayMode.AccessDenied">{{ t('todaysWorkDone') }}</p>
      <!--div *ngIf="viewState === DelayMode.WarmupsFinished"><p>Da er du ferdig med å øve</div-->
      <!--div *ngIf="viewState === DelayMode.WarmupsStarting">Nå skal du få øve litt</div-->
      <div class="col-start-1 col-end-1 p-4 flex flex-row justify-center">
        <img v-if="showExitButton" src="@/assets/images/map/backToCockpitButton1@2x.png" style="width: 150px" @click="exitToLogin()" />
        <img v-if="showStartButton" src="@/assets/images/login/StartButtonInactive@2x.png" style="width: 50%" @click="start()" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, Ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { DelayMode, SceneMode, ViewState, SpeechSounds, ActivityPhase, EpisodePhase, StateVariables } from '@/constants'
  import useCMSStore from '@/store/useCMSStore'
  import useStateService from '@/composition/useState'

  const messages = {
    no: {
      downloading: 'Vennligst vent, laster ned data...',
      todaysWorkDone: 'Dagens arbeid er fullført',
      demoCompleted: 'Du har fullført demoen! Bra gjort!',
      noActivityFound: 'Ingen aktiviteter ble funnet for denne studenten',
      noSessionFound: 'Ingen økter ble funnet for episoden',
    },
    sv: {
      downloading: 'Vänta, laddar ner data...',
      todaysWorkDone: 'Dagens arbete är klart',
      demoCompleted: 'Du har slutfört demon! Bra gjort!',
      noActivityFound: 'Inga aktiviteter hittades för denna elev',
      noSessionFound: 'Inga sessioner hittades för avsnittet',
    },
    en: {
      downloading: 'Please wait, downloding data...',
      todaysWorkDone: "Today's work has been completed",
      demoCompleted: "You've completed the demo! Well done!",
      noActivityFound: 'No Activity was found for this student',
      noSessionFound: 'No session found for Episode',
    },
  }

  const { t } = useI18n({ messages })
  const stateService = useStateService()
  const { getters: CMSGetters } = useCMSStore()

  const viewState: Ref<DelayMode> = ref(DelayMode.None)
  const showExitButton = ref(false)
  const showStartButton = ref(false)
  const episodeName = ref('')

  onMounted(() => {
    showExitButton.value = false
    showStartButton.value = false
    switch (stateService.getters.state.value.delayMode) {
      case DelayMode.AwaitingDownload:
        showExitButton.value = false
        viewState.value = DelayMode.AwaitingDownload
        break
      case DelayMode.NoActivitiesFound:
        viewState.value = DelayMode.NoActivitiesFound
        showExitButton.value = true
        break
      case DelayMode.NoSessionsFound:
        episodeName.value = CMSGetters.selectedEpisode.value.episode?.name || ''
        viewState.value = DelayMode.NoSessionsFound
        showExitButton.value = true
        break
      case DelayMode.DemoComplete:
        viewState.value = DelayMode.DemoComplete
        showExitButton.value = true
        break
      case DelayMode.WarmupsStarting:
        viewState.value = DelayMode.WarmupsStarting
        stateService.actions.speakLocalised(SpeechSounds.narrator.N56, () => (showStartButton.value = true), 1000)
        break
      case DelayMode.WarmupsFinished:
        viewState.value = DelayMode.WarmupsFinished
        if (CMSGetters.activityPhase.value === ActivityPhase.RCT && CMSGetters.episodePhase.value === EpisodePhase.First) {
          stateService.actions.speakLocalised(SpeechSounds.narrator.N29, () => (showStartButton.value = true), 1000, false)
        } else {
          stateService.actions.speakLocalised(SpeechSounds.narrator.N45, () => (showStartButton.value = true), 1000, false)
        }
        break
      case DelayMode.NoWarmups:
        viewState.value = DelayMode.WarmupsFinished
        stateService.actions.speakLocalised(SpeechSounds.narrator.N64, () => (showStartButton.value = true), 1000, false)
        break
      case DelayMode.AccessDenied:
        showExitButton.value = true
        viewState.value = DelayMode.AccessDenied
        break
    }
  })

  const exitToLogin = () => {
    stateService.actions.exitToDashboard()
  }

  const start = () => {
    const newState: StateVariables = {
      sceneMode: SceneMode.Finished,
      viewState: ViewState.Tasks,
    }
    stateService.actions.updateState(false, newState)
  }
</script>

<style scoped></style>
