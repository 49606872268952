<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col p-4 justify-center items-center h-screen">
    <p v-if="status.loading" class="text-white">Loading...</p>
    <Button v-if="hasMonitorRole" class="m-4 focus:outline-none" @click="monitor()">Monitor</Button>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { USER_ROLE, LanguageCodes } from '@/constants'
  import { fetchToken } from '@/api/cmsService'
  import { LocalUser } from '@/models/main'
  import useAppStore from '@/store/useAppStore'
  import useUserStore from '@/store/useUserStore'
  import useGameStore from '@/store/useGameStore'
  import useCMSStore from '@/store/useCMSStore'
  import useDeviceService from '@/composition/useDevice'
  import Button from '@/components/base/Button.vue'

  // This component completes setup of the app after login

  const { locale } = useI18n({ useScope: 'global' })
  const router = useRouter()
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { actions: gameActions } = useGameStore()
  const { actions: cmsActions } = useCMSStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { getters: deviceGetters, actions: deviceActions } = useDeviceService()

  const isMobileDevice: boolean = deviceGetters.deviceReady.value
  const isOnline: boolean = deviceGetters.deviceOnline.value
  const currentLocalUser: LocalUser | undefined = appGetters.currentLocalUser.value

  // This should re-calculate when the user is loaded
  const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

  fetchToken().then((newToken) => {
    console.log('Fetched new CMS token')
    localStorage.setItem('squidex-token', newToken)
  })
  // We have just fulfilled Sync sequence Stage A - Login. Moving on to the next sync steps..
  // NOTE: Refer to docs/syncing.md

  // Sync sequence Stage B. Load Data from server and/or disk
  const getData = async (): Promise<void> => {
    if (isMobileDevice && !isOnline && currentLocalUser) {
      userActions.setCordovaPath(currentLocalUser._id)
      await userActions.loadData() // Load user, project and players from local disk
    } else {
      // Load user from server, select it (including user.participants) then save it locally
      await userActions.getMyUser()
      await userActions.saveData()
    }

    const myUser = userGetters.myUser.value
    cmsActions.setActivityID(myUser.status.currentActivityId)
    setTimeout(() => appActions.setFade(false))
    const languageCode = myUser.profile.language
    if (languageCode !== 'system') {
      locale.value = languageCode as string
      appActions.setLanguageCode(locale.value as LanguageCodes)
    } else if (Object.keys(LanguageCodes).some((language) => language == locale.value)) {
      // NOTE: Activate supported languages in constants.ts
      const lang: LanguageCodes = locale.value as LanguageCodes
      appActions.setLanguageCode(lang)
    } else appActions.setLanguageCode(LanguageCodes.no)

    // Get data from Squidex
    appActions.logFeedback('Downloading CMS data..')
    await cmsActions.getSets(appGetters.languageCode.value)

    // Mobile device storage path. Must be set before requesting games, trackings etc.
    gameActions.setCordovaPath(myUser._id)

    // Load the Games & Players for this user
    // 1. From server
    // 2. From disk (replacing any matches from server)
    // Stage F. sync should remove a Player if it doesn't exist on server
    await gameActions.getGames('', '', true)
    // Games must be loaded before Trackings as Trackings are stored in 'user/userID/games/gameID/trackings.json'
    // await gameActions.loadGames() // If games have been removed server-side or User was removed from a Group, incorrect Games may be loaded here..
    // Stage F. sync should remove a Game if it doesn't exist on server
    await gameActions.loadTrackings()

    return Promise.resolve()
  }

  // NOTE: Removed due to performance issues when many users at once
  // Synce sequence Stage E. and F. Post Trackings and Update Games
  // updateGameProgress() also saves to disk Stage D.
  /* const updateGames = (): Promise<void> => {
    // We don't need to wait for trackings post to complete, just let in run
    gameActions.sendTrackings()
    if (isOnline && isMobileDevice) {
      // Synchronise any locally stored Game data with the server
      return gameActions.updateGameProgress(true)
    } else return Promise.resolve()
  } */

  getData()
    .then(async () => {
      // await updateGames()
      // We don't need to wait for trackings post to complete, just let in run
      gameActions.sendTrackings()
      await deviceActions.loadMediaCache()
      router.push('/dashboard/games')
    })
    .catch((error: Error) => {
      console.log(error.message)
      router.push('/')
    })

  const status = appGetters.status
  const monitor = () => router.push('/monitor')
</script>

<style scoped></style>
