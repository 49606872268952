<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="m-2 relative transform ease-linear duration-75"
    :class="{
      'border-4 border-green-400 border-solid': selected,
      'scale-95': mouseIsDown,
      'rounded-xl': corners,
      'rounded-full': !corners,
    }"
    :style="{ backgroundColor: colour }"
    @mousedown="mouseIsDown = true"
    @mouseup="mouseIsDown = false"
    @touchstart="mouseIsDown = true"
    @touchend="mouseIsDown = false"
  >
    <slot />
    <div v-if="selected" class="absolute top-0 right-0 -m-2 rounded-full p-2 bg-green-400" :class="{ '-m-3': corners }">
      <img class="w-4 h-4" alt="selected" src="@/assets/icons/svg/checked.svg" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  defineProps({
    selected: {
      type: Boolean,
      required: true,
    },
    colour: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    corners: {
      type: Boolean,
    },
  })
  const mouseIsDown = ref(false)
</script>

<style lang="postcss" scoped></style>
