/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <div id="word-source-box" style="height: 100px" class="fadeInOut flex flex-row justify-around" :style="{ opacity: opacityWords }">
      <Draggable
        v-if="currentWord"
        :class="`${activeDrags ? 'pointer-events-none' : ''}`"
        :position="currentWord.position"
        class="z-10"
        @move="(e: any) => onPointerMove(e)"
        @start="(e: any) => onStart()"
        @stop="(e: any) => onDrop(e, currentWord)"
      >
        <div
          class="fadeInOut taskNoUserSelect borderedWordBox flex flex-col place-items-center justify-center"
          :style="{ opacity: currentWord.opacity }"
          :class="{ highlightByScaling: highlightWord }"
        >
          <span>{{ currentWord.text }}</span>
        </div>
      </Draggable>
    </div>

    <div class="flex flex-row justify-around">
      <!-- Red Scanner -->
      <div class="two-scanners">
        <img class="scanner-image" src="@/assets/images/tasks/type3/ScannerRed@2x.png" />

        <div class="scanner-content flex flex-col place-items-center justify-center">
          <div
            id="red-scanner"
            ref="redScanner"
            v-draggable="{ axis: 'none' }"
            class="absolute flex flex-col place-items-center justify-center w-full h-full drop-target rounded-lg"
            :class="{ hoverhighlight1: box1Highlight }"
            style="margin-top: 10px"
            @start="onStart"
            @stop="onDrop"
          ></div>
          <div
            id="red-scanner-words"
            class="flex flex-col place-items-center justify-center w-full h-full pointer-events-none"
            style="margin-top: 10px"
          >
            <p
              v-for="word in correctBox['red-scanner'].items"
              :key="word.id"
              class="generalTextStyle inline-block z-10"
              :class="{ smallerText: word.text.length > 10 }"
              style="padding: 15px; color: white"
              @click="clickBoxedWord(word)"
            >
              {{ word.text }}
            </p>
          </div>
        </div>

        <div class="scanner-word flex flex-row justify-around">
          <span class="borderedWordBox red-border wordHighlight" :class="{ highlightByScaling: highlight1 }" @click="clickWord(1)">{{
            task.stem
          }}</span>
        </div>
      </div>

      <!-- Blue Scanner -->
      <div class="two-scanners">
        <img class="scanner-image" src="@/assets/images/tasks/type3/ScannerBlue@2x.png" />

        <div class="scanner-content flex flex-col place-items-center justify-center">
          <div
            id="blue-scanner"
            ref="blueScanner"
            v-draggable="{ axis: 'none' }"
            class="absolute flex flex-col place-items-center justify-center w-full h-full drop-target rounded-lg"
            :class="{ hoverhighlight2: box2Highlight }"
            style="margin-top: 10px"
            @start="onStart"
            @stop="onDrop"
          ></div>
          <div
            id="blue-scanner-words"
            class="flex flex-col place-items-center justify-center w-full h-full pointer-events-none"
            style="margin-top: 10px"
          >
            <p
              v-for="word in correctBox['blue-scanner'].items"
              :key="word.id"
              class="generalTextStyle inline-block z-10"
              :class="{ smallerText: word.text.length > 10 }"
              style="padding: 15px; color: white"
              @click="clickBoxedWord(word)"
            >
              {{ word.text }}
            </p>
          </div>
        </div>

        <div class="scanner-word flex flex-row justify-around">
          <span class="borderedWordBox blue-border wordHighlight" :class="{ highlightByScaling: highlight2 }" @click="clickWord(2)">{{
            task.morphedStem
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, nextTick, Ref } from 'vue'
  import { Draggable, ControlPosition, DraggableEvent } from '@braks/revue-draggable'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES } from '@/constants'
  import { Tasktype3 } from '@/models/tasktypes/Tasktype3'

  type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

  interface Word {
    text: string
    correct: string
    enabled: boolean
    draggable: boolean
    opacity: number
    element?: HTMLElement
    id: string
    position: ControlPosition
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype3> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype3,
    correct: 0,
    of: 0,
    answerOptions: task.value.options.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const activeDrags = ref(0)

  let unforgivingTestMode = false
  let attempts = 0
  let opacityWords = ref(0.1)
  const redScanner = ref()
  const blueScanner = ref()

  // public draggableWords = [];
  const wordStore: Ref<Word[]> = ref([])
  const currentWord: Ref<Word | undefined> = ref()
  const droppedObjects1: Ref<Word[]> = ref([])
  const droppedObjects2: Ref<Word[]> = ref([])

  let draggingNow = false
  let highlight1 = ref(false)
  let highlight2 = ref(false)
  let highlightWord = ref(false)
  const box1Highlight = ref(false)
  const box2Highlight = ref(false)
  let instructionAudio1: MP3Audio | WebAudio
  let instructionAudio2: MP3Audio | WebAudio
  let taskCompleted = false
  const boxAudio: { [key: number]: MP3Audio | WebAudio } = {}
  const wordAudio: { [key: string]: MP3Audio | WebAudio } = {}
  let introDone = false
  let audioPlaying = false

  const correctBox: Ref<{ [key: string]: { name: string; items: Word[] } }> = ref({
    'blue-scanner': { name: 'Morphed Stem', items: [] },
    'red-scanner': { name: 'Stem', items: [] },
  })

  // ---------      Dragula functions -------------------

  const onStart = () => {
    activeDrags.value++
  }

  // A 'dropbox' element should incude the class 'drop-target'
  const getDropboxElement = (e: DraggableEvent): Element | undefined => {
    let x = 0
    let y = 0
    if (!e.event) return
    if (e.event && e.event.type.includes('touch')) {
      const te = e.event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else if (e.event) {
      const me = e.event as MouseEvent
      x = me.clientX
      y = me.clientY
    } else return
    const el = document.elementFromPoint(x, y)
    return el && el.classList.contains('drop-target') ? el : undefined
  }

  const onDrop = (e: DraggableDIVElementEvent, word?: Word) => {
    activeDrags.value = 0
    box1Highlight.value = false
    box2Highlight.value = false
    const el = getDropboxElement(e)
    if (word && el) {
      currentWord.value = undefined
      // if (word && el && el.classList.contains('drop-target')) {
      const boxID = el.id || undefined
      attempts++
      console.log(`Dropped word index ${word.text} onto box ${boxID}.`)
      /* redScanner.value.classList.remove('saturate-200')
      blueScanner.value.classList.remove('saturate-200') */
      nextTick(() => {
        if (boxID && word.correct === correctBox.value[boxID].name) {
          correctBox.value[boxID].items.push(word)
          onDropComplete(word)
        } else {
          word.opacity = 0
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({ attempt: word.text, correct: false })
          wordStore.value.push(word)
          wordStore.value = shuffleItems(wordStore.value)
          setTimeout(() => {
            const w = wordStore.value.pop()
            if (w) currentWord.value = w
            setTimeout(() => {
              if (currentWord.value) currentWord.value.opacity = 1
              draggingNow = false
              playWordAudio()
            }, 1000)
          }, 0)
        }
      })
    } else if (word) {
      clickBoxedWord(word)
      word.position = { x: 0, y: 0 }
    }
  }

  /*   function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'red-scanner') redScanner.value.classList.add('saturate-200')
      else blueScanner.value.classList.add('saturate-200')
    }
  }

  function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'red-scanner') redScanner.value.classList.remove('saturate-200')
      else blueScanner.value.classList.remove('saturate-200')
    }
  } */

  function rectContainsPoint(rect: DOMRect, x: number, y: number) {
    const isTrue = rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height
    if (isTrue) {
      return true
    }
    return false
  }

  function pointerInDropArea(ev: DraggableEvent): number {
    let x = 0
    let y = 0
    const e = ev.event
    if (!e) return 0
    const box1Rect = redScanner.value.getBoundingClientRect()
    const box2Rect = blueScanner.value.getBoundingClientRect()
    if (e.type && e.type.includes('touch')) {
      const te = e as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    if (rectContainsPoint(box1Rect, x, y)) return 1
    if (rectContainsPoint(box2Rect, x, y)) return 2
    else return 0
  }

  const onPointerMove = (ev: DraggableEvent) => {
    const n = pointerInDropArea(ev)
    if (n === 1) box1Highlight.value = true
    else if (n === 2) box2Highlight.value = true
    else {
      box1Highlight.value = false
      box2Highlight.value = false
    }
  }

  // ---------------  Setup --------------------------

  const playWordAudio = () => {
    if (!draggingNow && introDone && !audioPlaying) {
      audioPlaying = true
      highlight2.value = false
      highlight1.value = false
      highlightWord.value = true
      if (currentWord.value) {
        wordAudio[currentWord.value.text].onended = () => {
          highlightWord.value = false
          audioPlaying = false
        }
        wordAudio[currentWord.value.text].playWhenReady()
      } else {
        highlightWord.value = false
        audioPlaying = false
      }
    }
  }

  const clickWord = (box: number) => {
    if (!draggingNow && !audioPlaying && boxAudio[box]) {
      audioPlaying = true
      boxAudio[box].playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const clickBoxedWord = (word?: Word) => {
    if (word && !draggingNow && !audioPlaying && wordAudio[word.text]) {
      audioPlaying = true
      wordAudio[word.text].playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const setupTask = async () => {
    // Choose random locations
    // let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    // let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (typeof task === 'undefined' || task === null) {
      alert('A Type 3 task does not exist - check your Session layout in the CMS')
      return
    }
    audioPlaying = false
    taskCompleted = false
    // box1Image = typeof task.box1Image !== 'undefined' ? task.box1Image.url : 'img/placeholder.jpg';
    // box2Image = typeof task.box2Image !== 'undefined' ? task.box2Image.url : 'img/placeholder.jpg';
    //box1Image = '@/assets/images/placeholder.jpg'
    //box2Image = '@/assets/images/placeholder.jpg'

    boxAudio[1] = await createSound(task.value.audio)
    if (boxAudio[1]) {
      boxAudio[1].onended = () => {
        audioPlaying = false
        highlightWord.value = false
      }
    }

    boxAudio[2] = await createSound(task.value.audio2)
    if (boxAudio[2]) {
      boxAudio[2].onended = () => {
        audioPlaying = false
        highlightWord.value = false
      }
    }

    droppedObjects1.value = []
    droppedObjects2.value = []
    let tempWords: Word[] = []
    for (let i = 0; i < task.value.options.length; i++) {
      const o = task.value.options[i]
      if (o.text) {
        const word: Word = {
          text: o.text,
          correct: o.correct,
          enabled: false,
          draggable: true,
          opacity: 0,
          position: { x: 0, y: 0 },
          id: 'draggable-word-' + i,
        }

        wordAudio[word.text] = await createSound(o.audio)
        if (wordAudio[word.text]) {
          wordAudio[word.text].onended = () => {
            audioPlaying = false
            highlightWord.value = false
          }
        }

        if (o.correct) tracking.details.of++
        const occurances = o.repeated
        for (let r = 0; r < occurances; r++) {
          tempWords.push({ ...word })
        }
      }
    }
    stateActions.progress.progressShow({ stars: tempWords.length })
    tempWords = shuffleItems(tempWords)

    wordStore.value = tempWords // .splice(0,3);
    // words[1] = tempWords;

    const w = wordStore.value.pop()
    if (w) currentWord.value = w
    if (currentWord.value) currentWord.value.enabled = true

    setTimeout(() => {
      if (currentWord.value) currentWord.value.opacity = 1
    }, 1000)

    if (task.value.audio) instructionAudio1 = await createSound(task.value.audio)
    if (task.value.audio2) instructionAudio2 = await createSound(task.value.audio2)

    introduceChallenge()
  }

  const introduceChallenge = () => {
    attempts = 0
    opacity.value = 1
    opacityWords.value = 1

    const onSoundCompleted = () => {
      if (task.value.audio) {
        if (task.value.audio2) {
          instructionAudio1.onended = () => {
            highlight1.value = false
            setTimeout(() => {
              highlight2.value = true
              instructionAudio2.playWhenReady()
            }, 500)
          }
          instructionAudio2.onended = () => {
            introDone = true
            highlight2.value = false
            setTimeout(() => {
              audioPlaying = false
              stateSetters.speakerIsPlaying = false
              playWordAudio()
            }, 1000)
          }
        } else {
          instructionAudio1.onended = () => {
            introDone = true
            highlight1.value = false
            setTimeout(() => {
              audioPlaying = false
              stateSetters.speakerIsPlaying = false
              playWordAudio()
            }, 1000)
          }
        }
        setTimeout(() => {
          highlight1.value = true
          instructionAudio1.playWhenReady()
        }, 500)
      } else {
        introDone = true
        audioPlaying = false
        stateSetters.speakerIsPlaying = false
      }
    }
    // Play generic audio including two box audios
    audioPlaying = true
    stateSetters.speakerIsPlaying = true
    stateActions.speakLocalised(SpeechSounds.instructions.tasks.T3, () => onSoundCompleted(), 1000)
  }

  const onDropComplete = (item: Word) => {
    highlightWord.value = false
    tracking.details.correct++
    tracking.details.answer_details.push({
      attempt: item.text,
      correct: true,
      elapsed: tracking.elapsedTimeSinceLastCall,
    })
    item.opacity = 0
    item.enabled = false
    stateActions.progress.completeAStar()
    if (wordStore.value.length > 0) {
      const w = wordStore.value.pop()
      if (w) currentWord.value = w
      if (currentWord.value) currentWord.value.enabled = true
    }
    setTimeout(() => {
      opacityWords.value = 1
      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if ((unforgivingTestMode && attempts === tracking.details.of) || (!unforgivingTestMode && tracking.details.of === tracking.details.correct)) {
        if (!taskCompleted) {
          taskCompleted = true
          opacityWords.value = 0
          if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
            stateActions.speakLocalised(
              SpeechSounds.instructions.warmups.T3,
              () => {
                fadeOut()
              },
              1000,
              false,
            )
          } else fadeOut()
        }
      }
      if (currentWord.value) currentWord.value.opacity = 1
      draggingNow = false
      setTimeout(() => {
        audioPlaying = false
        playWordAudio()
      }, 1000)
    }, 1000)
  }

  const fadeOut = () => {
    //setTimeout(() => {
    opacity.value = 0
    completeTask()
    //}, 1000)
  }

  const completeTask = () => {
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }
  setupTask()
</script>

<style scoped lang="postcss">
  .gu-mirror {
    margin-left: 100px;
  }

  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 10%;
  }
  .hoverhighlight1 {
    background-color: rgba(255, 0, 0, 0.3);
  }
  .hoverhighlight2 {
    background-color: rgba(0, 255, 255, 0.3);
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .scanner-word {
    margin-top: -50px;
    position: relative;
    z-index: 100;
  }
  .two-scanners {
    width: 45%;
    position: relative;
  }

  .red-border {
    border-color: #dc1d43;
  }

  .blue-border {
    border-color: #066ec0;
  }

  .smallerText {
    font-size: 1.2em;
  }
  .hovered {
    @apply bg-gray-500;
  }
</style>
