<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <!--div class="progress-bar-background">
    <div class="progress-bar-sub-container">
      <img id="progress-bar-background-leftpart" src="@/assets/images/bar/LeftPart@2x.png" />
      <img id="progress-bar-background-middlepart" class="progress-bar-responsive-width" src="@/assets/images/bar/MiddlePart@2x.png" />

      <div id="progress-bar-background-rightpart">
        <img src="@/assets/images/bar/RightPartStar@2x.png" />
        <img v-if="progress === 100" id="progress-bar-beating-star" src="@/assets/images/bar/BeatingStar@2x.gif" />
      </div>

      <div class="stardust-container">
        <div class="stardust-sub-container">
          <img id="progress-bar-stardust-leftpart" src="@/assets/images/bar/stardust-leftpart.png" />
          <img
            id="progress-bar-stardust"
            src="@/assets/images/bar/stardust.png"
            :style="{ width: getWidth }"
            class="transition-width ease-linear duration-1000"
          />
        </div>
      </div>
    </div>
  </div-->

  <div class="absolute w-full h-20 bottom-5 pointer-events-none px-5">
    <div class="relative w-full h-full">
      <div class="absolute flex flex-row flex-grow w-full">
        <img class="justify-self-start h-20" src="@/assets/images/bar/LeftPart@2x.png" />
        <img class="flex flex-grow h-20" src="@/assets/images/bar/MiddlePart@2x.png" />
        <div class="relative justify-self-end">
          <img v-if="progress === 100" class="absolute z-10 h-20" src="@/assets/images/bar/BeatingStar@2x.gif" />
          <img class="h-20" src="@/assets/images/bar/RightPartStar@2x.png" />
        </div>
      </div>
      <div class="absolute flex flex-row place-items-center h-full w-full">
        <img src="@/assets/images/bar/stardust-leftpart.png" class="relative justify-self-start ml-4 h-4" />
        <img src="@/assets/images/bar/stardust.png" :style="{ width: getWidth }" class="transition-width ease-linear duration-1000 h-4" />
        <div :class="progress === 100 ? 'w-16' : 'w-24'"></div>
      </div>
    </div>
  </div>

  <!--div class="absolute bottom-0 inset-x-0 w-full pointer-events-none">
    <div class="relative flex flex-row m-4">
      <img class="w-6" src="@/assets/images/bar/LeftPart@2x.png" />
      <div class="flex flex-grow">
        <img src="@/assets/images/bar/MiddlePart@2x.png" />
      </div>

      <div class="relative">
        <img v-if="progress === 100" class="h-full absolute z-10" src="@/assets/images/bar/BeatingStar@2x.gif" />
        <img class="h-full" src="@/assets/images/bar/RightPartStar@2x.png" />
      </div>

      <div class="absolute flex flex-row items-center h-full">
        <div class="relative flex flex-row justify-start m-2 ml-3 h-8">
          <img src="@/assets/images/bar/stardust-leftpart.png" />
          <div :style="{ width: getWidth }" class="transition-width ease-linear duration-1000">
            <img class="h-full" src="@/assets/images/bar/stardust.png" />
          </div>

          <div :class="progress === 100 ? 'w-16' : 'w-24'"></div>
        </div>
      </div>
    </div>
  </div-->
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import useState from '@/composition/useState'
  const { getters } = useState()
  const progress = computed(() => {
    return getters.progress.value.barData.completedPercent
  })
  const getWidth = computed(() => {
    return Math.floor(progress.value) + '%'
  })
</script>

<style lang="postcss">
  .progress-bar-background {
    bottom: 10px;
    position: absolute;
    width: 100%;
    height: 93px;
  }

  .progress-bar-background img {
    width: 100%;
  }

  .progress-bar-sub-container {
    position: relative;
    width: 95%;
    height: 100%;
    font-size: 0;
    margin-left: auto;
    margin-right: auto;
  }
  #progress-bar-background-leftpart {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 25px;
  }
  #progress-bar-background-middlepart {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 80%;
  }
  #progress-bar-background-rightpart {
    position: relative;
    display: inline-block;
    height: 50%;
    width: 110px;
  }
  #progress-bar-beating-star {
    position: absolute;
    height: 100%;
    width: 112px;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .stardust-container {
    position: absolute;
    top: 35px;
    left: 15px;
  }

  .stardust-sub-container {
    position: relative;
  }
  #progress-bar-stardust-leftpart {
    position: relative;
    display: inline-block;
    height: 25px;
    width: 15px;
  }
  #progress-bar-stardust {
    position: relative;
    display: inline-block;
    height: 25px;
    width: 0%;

    -webkit-transition: width 1s linear;
    -moz-transition: width 1s linear;
    -ms-transition: width 1s linear;
    -o-transition: width 1s linear;
    transition: width 1s linear;
  }

  .progress-bar-full {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .progress-bar-part {
    position: fixed;
    height: 50px;
    width: 0;
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
    background-color: rgba(255, 222, 24, 1);
  }

  @media (max-width: 1024px) {
    .progress-bar {
      height: 35px;
    }

    .progress-bar-full {
      height: 35px;
    }

    .progress-bar-part {
      height: 35px;
    }
  }
</style>
