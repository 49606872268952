import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, WordIndexName } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype9Query = gql`
  fragment Tasktype9 on Tasktype9 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..

      totalCorrect {
        iv
      }
      enforceOrder {
        iv
      }
      words {
        __language {
          text
          type
          audio {
            url
            slug
          }
        }
      }
      correct {
        __language {
          firstWord
          secondWord
          thirdWord
          fourthWord
          audio {
            url
            slug
          }
        }
      }
    }
  }
`

export enum Type9WordType {
  prefix = 'prefix',
  root = 'root',
  suffix = 'suffix',
}
const wordIndexes = {
  [WordIndexName.None]: -1,
  [WordIndexName.Word1]: 0,
  [WordIndexName.Word2]: 1,
  [WordIndexName.Word3]: 2,
  [WordIndexName.Word4]: 3,
  [WordIndexName.Word5]: 4,
  [WordIndexName.Word6]: 5,
  [WordIndexName.Preselected]: 5,
}
const wordsFromIndex = {
  '-1': WordIndexName.None,
  0: WordIndexName.Word1,
  1: WordIndexName.Word2,
  2: WordIndexName.Word3,
  3: WordIndexName.Word4,
  4: WordIndexName.Word5,
  5: WordIndexName.Word6,
}

export interface Type9Correct {
  firstWord: WordIndexName
  secondWord: WordIndexName
  thirdWord: WordIndexName
  fourthWord: WordIndexName
  audioURL: string // URL
  audio: HTMLAudioElement | undefined
  matched: boolean
}

interface Type9Attributes {
  totalCorrect: LC<number> // iv
  enforceOrder: LC<boolean> // iv
  words: LC<
    {
      text: string
      type: Type9WordType
      audio: { url: string; slug: string }[]
    }[]
  >
  correct: LC<
    {
      firstWord: WordIndexName
      secondWord: WordIndexName
      thirdWord: WordIndexName
      fourthWord: WordIndexName
      audio: { url: string; slug: string }[]
    }[]
  >
}
export interface Tasktype9Data extends TasktypeData {
  data: CommonTaskAttributes & Type9Attributes
}
export class Tasktype9 extends Tasktype {
  minimumCorrect = 0
  enforceOrder = false
  words: {
    text: string
    type: Type9WordType
    reference: WordIndexName
    audioURL: string // URL
  }[] = []
  correct: Type9Correct[] = []

  firstWords: string[] = []
  secondWords: string[] = []
  thirdWords: string[] = []
  fourthWords: string[] = []

  constructor(spec: Tasktype9Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype9
    this.parent = parent

    this.minimumCorrect = spec.data.totalCorrect.iv || 0
    this.enforceOrder = spec.data.enforceOrder.iv || false
    spec.data.words[language]?.forEach((w, i) => {
      const wordKey = i as keyof typeof wordsFromIndex
      this.words.push({
        text: w.text,
        type: w.type as Type9WordType,
        reference: wordsFromIndex[wordKey],
        audioURL: w.audio[0]?.url + w.audio[0]?.slug || '',
      })
    })
    spec.data.correct[language]?.forEach((c) => {
      const audioURL = c.audio[0]?.url + c.audio[0]?.slug || ''
      this.correct.push({
        firstWord: c.firstWord || WordIndexName.None,
        secondWord: c.secondWord || WordIndexName.None,
        thirdWord: c.thirdWord || WordIndexName.None,
        fourthWord: c.fourthWord || WordIndexName.None,
        audioURL,
        audio: undefined,
        matched: false,
      })
    })
    this.setupWordLists()
  }

  get assetList(): string[] {
    const list: string[] = []
    this.words.forEach((w) => list.push(w.audioURL))
    this.correct.forEach((c) => list.push(c.audioURL))
    return list
  }

  setupWordLists() {
    let correctItem: Type9Correct, word: string
    let total_correct_answers = 0
    let correct_answer_found = false
    this.correct.forEach((c) => {
      correct_answer_found = false
      correctItem = c
      if (correctItem.firstWord !== WordIndexName.None) {
        correct_answer_found = true
        word = this.words[wordIndexes[correctItem.firstWord]].text
        if (this.firstWords.indexOf(word) === -1) {
          this.firstWords.push(word)
        }
      }
      if (correctItem.secondWord !== WordIndexName.None) {
        correct_answer_found = true
        word = this.words[wordIndexes[correctItem.secondWord]].text
        if (this.secondWords.indexOf(word) === -1) {
          this.secondWords.push(word)
        }
      }
      if (correctItem.thirdWord !== WordIndexName.None) {
        correct_answer_found = true
        word = this.words[wordIndexes[correctItem.thirdWord]].text
        if (this.thirdWords.indexOf(word) === -1) {
          this.thirdWords.push(word)
        }
      }
      if (correctItem.fourthWord !== WordIndexName.None) {
        correct_answer_found = true
        word = this.words[wordIndexes[correctItem.fourthWord]].text
        if (this.fourthWords.indexOf(word) === -1) {
          this.fourthWords.push(word)
        }
      }

      if (correct_answer_found) {
        total_correct_answers++
      }
    })
    if (this.minimumCorrect === 0) {
      this.minimumCorrect = total_correct_answers
    }
  }

  public matchesWords(cc: Type9Correct, compareWith: Type9Correct): string {
    let status = 'some'
    let matchesRemaining = 4
    const ccWords = [cc.firstWord, cc.secondWord, cc.thirdWord, cc.fourthWord]
    const correctWords = [compareWith.firstWord, compareWith.secondWord, compareWith.thirdWord, compareWith.fourthWord]

    for (let i = 0; i < 4; i++) {
      if (ccWords[i] === correctWords[i]) {
        matchesRemaining--
      } else if (ccWords[i] !== WordIndexName.None) {
        status = 'incorrect'
      }
    }

    if (matchesRemaining === 0) {
      status = 'correct'
    }
    return status
  }

  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(cc: Type9Correct, completedWords: string[]): { status: string; correctRef?: Type9Correct } {
    let result: { status: string; correctRef?: Type9Correct } = { status: 'incorrect' }

    const availableCorrect = this.correct.filter((c) => !c.matched)
    for (let i = 0; i < availableCorrect.length; i++) {
      const compare: Type9Correct = availableCorrect[i]
      const status = this.matchesWords(cc, compare)
      const completedWord = this.combinedWordFromCC(compare)
      if (status === 'some') {
        result.status = 'some'
      } else if (status === 'correct' && completedWords.indexOf(completedWord) === -1) {
        result = { status: 'correct', correctRef: compare }
        break
      } else if (status === 'correct' && completedWords.indexOf(completedWord) > -1) {
        result = { status: 'duplicate', correctRef: compare }
        break
      }
    }
    if (result.status === 'correct' && result.correctRef) result.correctRef.matched = true
    return result
  }

  // Return the fully concatenated word for the given word parts
  public combinedWordFromCC(cc: Type9Correct): string {
    let word = cc.firstWord !== WordIndexName.None ? this.words[wordIndexes[cc.firstWord]].text : ''
    word += cc.secondWord !== WordIndexName.None ? this.words[wordIndexes[cc.secondWord]].text : ''
    word += cc.thirdWord !== WordIndexName.None ? this.words[wordIndexes[cc.thirdWord]].text : ''
    word += cc.fourthWord !== WordIndexName.None ? this.words[wordIndexes[cc.fourthWord]].text : ''
    return word
  }

  // A string showing the words inside the given Type7Correct class
  public wordsFromCC(cc: Type9Correct) {
    let w1 = '',
      w2 = '',
      w3 = '',
      w4 = ''
    if (cc.firstWord !== WordIndexName.None) {
      w1 = this.words[wordIndexes[cc.firstWord]].text
    }
    if (cc.secondWord !== WordIndexName.None) {
      w2 = ', ' + this.words[wordIndexes[cc.secondWord]].text
    }
    if (cc.thirdWord !== WordIndexName.None) {
      w3 = ', ' + this.words[wordIndexes[cc.thirdWord]].text
    }
    if (cc.fourthWord !== WordIndexName.None) {
      w4 = ', ' + this.words[wordIndexes[cc.fourthWord]].text
    }
    return w1 + w2 + w3 + w4
  }
}
