/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type2/ScannerType2@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding">
        <div class="flex flex-row justify-around">
          <div class="flex flex-col">
            <div v-for="(row, i) in images" :key="`image-row-${i}`" class="flex flex-row justify-around">
              <img
                v-for="image in row"
                :key="image.id"
                v-cache
                :src="image.imageUrl"
                class="flex borderedImage imageMedium"
                :class="borderColourClass(image)"
                style="display: block; margin: 10px"
                @click="clickAnswer(image)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scanner-word taskNoUserSelect flex flex-row justify-around" @click="playWordAudio()">
      <span class="borderedWordBox wordHighlight">{{ task.word }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES } from '@/constants'
  import { Tasktype11 } from '@/models/tasktypes/Tasktype11'

  interface Image {
    id: string
    imageUrl: string
    imageFilename: string
    name: string
    borderGreen: boolean
    borderRed: boolean
    correct: boolean
    enabled: boolean
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype11> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype11,
    correct: 0,
    of: 0,
    answerOptions: task.value.images.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  let attempts = 0

  let finished = false
  let playingAudio = false
  let textAudio: MP3Audio | WebAudio | undefined = undefined
  let instructionAudio: MP3Audio | WebAudio

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  const images: Ref<Image[][]> = ref([[], []])

  async function setupTask() {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
    finished = false
    playingAudio = false
    if (typeof task === 'undefined' || task === null) {
      alert('A Type 2 task does not exist - check your Session layout in the CMS')
      return
    }

    let tempImages: Image[] = []
    task.value.images.forEach((im, i) => {
      const image: Image = {
        id: `image-id-${i}`,
        imageUrl: im.file,
        imageFilename: im.filename,
        name: im.name,
        correct: im.correct,
        enabled: true,
        borderGreen: false,
        borderRed: false,
      }
      if (image.correct) tracking.details.of++
      if (image.imageUrl) tempImages.push(image)
    })
    tempImages = shuffleItems(tempImages)
    images.value[0] = tempImages.splice(0, tempImages.length / 2)
    images.value[1] = tempImages

    stateActions.progress.progressShow({ stars: tracking.details.of })

    if (task.value.audio) {
      textAudio = await createSound(task.value.audio)
      textAudio.onended = () => {
        playingAudio = false
      }
    }

    if (task.value.instructionAudio) {
      instructionAudio = await createSound(task.value.instructionAudio)
      instructionAudio.onended = () => {
        playingAudio = false
        stateActions.setSpeakerSound([task.value.instructionAudio])
        stateSetters.speakerIsPlaying = false
      }
    }

    introduceChallenge()
  }

  function introduceChallenge() {
    attempts = 0
    setTimeout(() => {
      opacity.value = 1
      if (task.value.instructionAudio) {
        playingAudio = true
        stateSetters.speakerIsPlaying = true
        instructionAudio.playWhenReady()
      }
    }, 1000)
  }

  function playWordAudio() {
    if (!playingAudio && textAudio) {
      playingAudio = true
      textAudio.playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  function clickAnswer(item: Image) {
    if (item.enabled && !finished) {
      attempts++
      if (item.correct) {
        tracking.details.correct++
        tracking.details.answer_details.push({
          attempt: 'CMS image: ' + item.name,
          correct: true,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        stateActions.progress.completeAStar()
        item.borderGreen = true
      } else {
        item.borderRed = true
        tracking.details.answer_details.push({
          attempt: 'CMS image: ' + item.imageFilename,
          correct: false,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        tracking.details.incorrectAttempts++
        if (task.value.unforgiving) {
          stateActions.progress.completeAStar()
        }
        //dataService.tryAgain()
      }
      item.enabled = false

      // Set the allowed number of attempts here - at the moment it is matched with the number of correct answers
      if (
        (task.value.unforgiving && attempts === tracking.details.of) ||
        (!task.value.unforgiving && tracking.details.of === tracking.details.correct)
      ) {
        finished = true
        nextRound()
      }
    }
  }

  function nextRound() {
    setTimeout(() => {
      opacity.value = 0
      if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
        stateActions.speakLocalised(
          SpeechSounds.instructions.warmups.T11,
          () => {
            opacity.value = 0
            completeTask()
          },
          1000,
          false,
        )
      } else {
        setTimeout(() => {
          completeTask()
        }, 500)
      }
    }, 500)
  }

  function completeTask() {
    emit('completed', true, tracking)
  }

  function borderColourClass(image: Image) {
    if (task.value.unforgiving && !image.enabled) return 'borderYellow'
    else {
      if (image.borderGreen) return 'borderGreen'
      else if (image.borderRed) return 'borderRed'
      else return ''
    }
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 10%;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 10%;
  }
  .scanner-padding {
    padding: 7% 5%;
  }
  .scanner-word {
    margin-top: -50px;
    position: relative;
    z-index: 100;
  }
</style>
