<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row">
    <div class="px-2 border-r-2 border-grey w-48">
      <div class="mt-1 flex flex-col text-left">
        <SelectionBox
          id="select-locationfilter"
          v-model="selectedGroup"
          :label="'Filter by location'"
          :options="groupList"
          class="m-2"
          @change="filterGamesByGroup"
        ></SelectionBox>
        <div class="flex flex-col flex-grow">
          <p class="font-serious">Participants by selected user, project & location</p>
          <ul class="m-2">
            <li v-for="(g, i) in games" :id="`li-participant-${g._id}`" :key="'ap-' + i" class="cursor-pointer" @click="selectGame(g)">
              <span v-if="selectedGame && g._id === selectedGame._id">✔︎&nbsp;</span><span v-else></span> {{ g.profile.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-grow py-4 text-left m-2 overflow-y-auto">
      <h1 class="font-bold">Game Details</h1>
      <div v-if="selectedGame._id && cmsRoot">
        <MonitorParticipantMastery :game="selectedGame" :cmsroot="cmsRoot" :results="results" class="w-full"> </MonitorParticipantMastery>
      </div>
      <hr />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, Ref } from 'vue'
  import { Group, Game, SPECIAL_REQUEST_TYPE, TRACKING_TYPE, SpecialRequestData } from '@/models/main'
  import useAppStore from '@/store/useAppStore'
  import useUserStore from '@/store/useUserStore'
  import useCMSStore from '../../store/useCMSStore'
  import useGameStore from '@/store/useGameStore'
  import SelectionBox from '@/components/base/SelectionBox.vue'
  import MonitorParticipantMastery from '@/components/admin/MonitorParticipantProgress.vue'

  interface GroupListItem {
    itemName: string
    item: Group
  }

  const { getters: appGetters } = useAppStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()
  const groups = userGetters.selectedUserGroups
  const selectedGroup: Ref<GroupListItem> = ref({
    item: new Group(),
    itemName: '',
  })
  const selectedGame = ref(new Game())
  const results: Ref<SpecialRequestData['data']> = ref({})
  // Participants filtered by the user's selected project
  const games = gameGetters.games

  // ----------------  CMS --------------------------------

  // Taken from Layout.vue  Refer to that component for comments
  // We require the correct CMS setup to display the Game's progress
  const getCMSData = () => {
    return cmsActions.getSets(appGetters.languageCode.value)
  }

  // --------------- Game functions -----------------

  const selectGame = (p: Game) => {
    gameActions.selectGame(p)
    getCMSData().then(() => {
      gameActions.getSpecialRequest(p._id, SPECIAL_REQUEST_TYPE.successresults, TRACKING_TYPE.all).then((pd) => {
        selectedGame.value = new Game(pd.game)
        results.value = pd.data
      })
    })
  }

  const filterGamesByGroup = (value: GroupListItem) => {
    selectedGroup.value = value
    userActions.selectGroup(selectedGroup.value.item)
    gameActions.getGames('', selectedGroup.value.item._id).then(() => {
      const projectParticipants = games.value
      if (projectParticipants.length > 0) selectGame(projectParticipants[0])
    })
  }

  const groupList = computed((): GroupListItem[] => {
    return groups.value.map((i) => ({
      item: i,
      itemName: i.name,
    }))
  })

  const cmsRoot = computed(() => {
    let newRoot = cmsGetters.root.value
    return newRoot
  })

  if (groups.value[0]) {
    selectedGroup.value.itemName = groups.value[0].name
    selectedGroup.value.item = groups.value[0]

    // Fetch games inital value for the default location
    gameActions.getGames('', selectedGroup.value.item._id)
  }
</script>

<style scoped></style>
