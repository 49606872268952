<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="w-full h-full relative">
    <!-- Skip Scene button -->
    <div v-if="appGetters.disableDelays.value" class="absolute top-0 right-0 text-xs flex flex-row z-50">
      <button class="m-1 text-black bg-white rounded-sm p-2" @click="timelineComplete()">Skip Scene</button>
    </div>
    <div id="animation_container" style="position: absolute">
      <canvas id="canvas" ref="canvas" style="display: block"></canvas>
    </div>
  </div>
</template>

<script setup lang="ts">
  /* eslint-disable no-undef */
  import { onMounted, onUnmounted, Ref, ref } from 'vue'
  import { onBeforeRouteUpdate } from 'vue-router'
  // import * as createjs from 'createjs-module'
  import useStateService from '@/composition/useState'
  import useAppStore from '@/store/useAppStore'
  import useGameStore from '@/store/useGameStore'
  import { Composition, Library } from '@/models/main'
  import type { LoadQueue, Stage, MovieClip } from 'createjs-module'
  import { createSound, WebAudio } from '@/utilities'

  const stateService = useStateService()
  const { getters: appGetters } = useAppStore()
  const { actions: gameActions } = useGameStore()
  const language = appGetters.languageCode.value

  let currentSceneSubpath = ''
  let theScript: HTMLScriptElement
  let complete = true

  let canvas: Ref<HTMLCanvasElement | undefined> = ref()
  let stage: Stage
  let exportRoot: MovieClip

  let anKey: string
  let comp: Composition
  let lib: Library
  let loader: LoadQueue
  //let ss: SpriteSheet

  interface MySound {
    sound: WebAudio
    src: string
  }
  const soundMap: Map<string, MySound> = new Map()

  function begin() {
    if (!stateService.getters.sceneInProgress.value) {
      complete = false
      currentSceneSubpath = stateService.getters.sceneSubpath.value
      //currentSceneSubpath = 'general/3/'
      if (currentSceneSubpath) addScriptToDOM()
    }
  }

  onMounted(() => {
    begin()
  })

  onBeforeRouteUpdate(() => {
    begin()
  })

  onUnmounted(() => {
    const values = soundMap.keys()
    for (let s of values) {
      soundMap.delete(s)
    }
    stopAnimation()
  })

  function addScriptToDOM(): void {
    let head = document.getElementsByTagName('head')[0]
    theScript = document.createElement('script')
    // theScript.src = '/assets/scenes/' + currentSceneSubpath + 'morfScene.js' // ?r=" + Date.now();
    theScript.src = `/assets/scenes/${language}/${currentSceneSubpath}morfScene.js`
    theScript.type = 'text/javascript'
    theScript.onload = () => init()
    theScript.onerror = () => {
      console.log('Aborted scene script load')
      timelineComplete()
    }
    head.appendChild(theScript)
  }

  function removeScriptFromDOM(): void {
    if (theScript.parentNode) theScript.parentNode.removeChild(theScript)
  }

  // Scene IDs must be named 'sceneXY' inside the Adobe Edge JS file.  Refer to the bottom of the file.
  async function init(): Promise<void> {
    let anKeys = Object.keys(AdobeAn.compositions)
    if (anKeys.length !== 1) return
    anKey = anKeys[0]
    comp = AdobeAn.getComposition(anKey)

    lib = comp.getLibrary()
    for (let i = 0; i < lib.properties.manifest.length; i++) {
      const m = lib.properties.manifest[i]
      const s = m.src.substring(0, 6)
      const subPath = m.src.substring(7)
      if (s === 'sounds') {
        m.src = '/assets/' + m.src.replace('__language', language)
        //m.src = `/sounds/${language}/scenes/` + subPath.split('?')[0]
        // const newSound = new Audio(m.src)
        //const newSound: MP3Audio = new MP3Audio(m.src)
        //soundMap.set(m.id, { sound: newSound, src: m.src })
        await createOurSound(m.id, m.src)
      } else if (s === 'images') m.src = '/assets/scenes/images/' + subPath.split('?')[0]
    }

    try {
      loader = new createjs.LoadQueue(false)
      loader.installPlugin(createjs.Sound)
      loader.addEventListener('fileload', (evt) => {
        handleFileLoad(evt as Record<string, unknown>, comp)
      })
      loader.addEventListener('complete', () => {
        handleComplete()
      })
      loader.addEventListener('error', (error) => {
        console.log(`Scene loader error: ${error}`)
      })
      lib = comp.getLibrary()
      loader.loadManifest(lib.properties.manifest)
    } catch (error) {
      console.log(`Error loading sound: ${(error as unknown as Error).toString()}`)
      timelineComplete()
    }

    AdobeAn.bootstrapCallback((compId: string) => {
      console.log('composition loaded: ' + compId)
      /* AdobeAn.Symbol.bindTimelineAction(compId, "stage", "Default Timeline", "complete", (sym, e) => {
        console.log('timeline complete');
      });*/
    })
    // this.handleComplete({});
  }

  async function playOurSound(id: string): Promise<void> {
    const s = soundMap.get(id)
    if (s) {
      s.sound.onerror = (error) => console.log(`Scene play sound error: ${error.toString()}`)
      s.sound.play()
    }
  }

  async function createOurSound(id: string, src: string): Promise<void> {
    const newSound: WebAudio = await createSound(src)
    return new Promise((resolve) => {
      newSound.onready = () => {
        soundMap.set(id, { sound: newSound, src: src })
        resolve()
      }
      newSound.onerror = () => {
        console.log(`Scene load audio failed forever: ${src}`)
        resolve()
      }
    })
  }

  function stopAllSounds(): void {
    for (let [key, value] of soundMap) {
      if (soundMap.has(key)) value.sound.pause()
    }
  }

  function handleFileLoad(evt: Record<string, unknown>, comp: Composition) {
    let images = comp.getImages()
    if (evt && evt.item) {
      const item = evt.item as Record<string, string>
      const itemType = item.type
      if (itemType == 'image') images[item.id] = evt.result
    }
  }

  function handleComplete() {
    lib = comp.getLibrary()
    exportRoot = new lib.morfScene()
    exportRoot.addEventListener('complete', timelineEnded)
    stage = new createjs.Stage(canvas.value as HTMLCanvasElement) // TODO:  check this line!
    stage.addChild(exportRoot)
    createjs.Touch.enable(stage)

    //Registers the "tick" event listener.
    function fnStartAnimation() {
      createjs.Ticker.framerate = lib.properties.fps
      createjs.Ticker.addEventListener('tick', stage)
    }

    //Code to support hidpi screens and responsive scaling.
    let makeResponsive = (isResp: boolean, respDim: string, isScale: boolean, scaleType: number) => {
      let lastW: number,
        lastH: number,
        lastS = 1

      let resizeCanvas = () => {
        let w = lib.properties.width,
          h = lib.properties.height
        let iw = window.innerWidth,
          ih = window.innerHeight
        let pRatio = window.devicePixelRatio || 1,
          xRatio = iw / w,
          yRatio = ih / h,
          sRatio = 1
        if (isResp) {
          if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
            sRatio = lastS
          } else if (!isScale) {
            if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio)
          } else if (scaleType == 1) {
            sRatio = Math.min(xRatio, yRatio)
          } else if (scaleType == 2) {
            sRatio = Math.max(xRatio, yRatio)
          }
        }
        /*         this.canvas.width = w*pRatio*sRatio;
        this.canvas.height = h*pRatio*sRatio;
        this.canvas.style.width = this.anim_container.style.width =  w*sRatio+1+'px';
        this.canvas.style.height = this.anim_container.style.height = h*sRatio+1+'px'; */
        if (canvas.value) {
          canvas.value.width = w * pRatio * sRatio
          canvas.value.height = h * pRatio * sRatio
          canvas.value.style.width = '100vw'
          canvas.value.style.height = '75vw'
        }

        stage.scaleX = pRatio * sRatio
        stage.scaleY = pRatio * sRatio
        lastW = iw
        lastH = ih
        lastS = sRatio
      }

      window.addEventListener('resize', resizeCanvas)
      resizeCanvas()
    }

    makeResponsive(true, 'both', true, 1)
    AdobeAn.compositionLoaded(lib.properties.id)
    fnStartAnimation()
  }

  function stopAnimation() {
    exportRoot.removeEventListener('complete', timelineComplete)
    exportRoot.stop()
    stage.removeChild(exportRoot)
    stopAllSounds()
    removeScriptFromDOM()
    delete AdobeAn.compositions[anKey]
    createjs.Sound.removeAllSounds()
    currentSceneSubpath = ''
  }

  async function timelineEnded() {
    try {
      if (currentSceneSubpath) await gameActions.setIntroSeen(currentSceneSubpath)
    } catch (error) {
      console.log(error)
    }
    timelineComplete()
  }

  function timelineComplete() {
    if (!complete) {
      complete = true
      console.log('Completed timeline for scene ' + currentSceneSubpath)
      stopAnimation()
      stateService.setters.sceneInProgress = false
      stateService.actions.updateState(true)
    }
  }
  window.playSound = playOurSound
</script>

<style scoped></style>
