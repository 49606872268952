import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES, WordIndexName } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype8Query = gql`
  fragment Tasktype8 on Tasktype8 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      presetWordText {
        __language
      }
      presetWordAudio {
        __language {
          url
          slug
        }
      }
      presetWordPosition {
        iv
      }
      introductionAudio {
        __language {
          url
          slug
        }
      }
      unforgiving {
        iv
      }
      words {
        __language {
          text
          audio {
            url
            slug
          }
        }
      }
      correct {
        __language {
          firstWord
          secondWord
          audio {
            url
            slug
          }
        }
      }
    }
  }
`

const wordIndexes = {
  [WordIndexName.None]: -1,
  [WordIndexName.Word1]: 0,
  [WordIndexName.Word2]: 1,
  [WordIndexName.Word3]: 2,
  [WordIndexName.Word4]: 3,
  [WordIndexName.Word5]: 4,
  [WordIndexName.Word6]: 5,
  [WordIndexName.Preselected]: 5, // Preselected and Word6 are the same thing in Squidex (remove Word6 in Squidex when possible)
}
const wordsFromIndex = {
  '-1': WordIndexName.None,
  0: WordIndexName.Word1,
  1: WordIndexName.Word2,
  2: WordIndexName.Word3,
  3: WordIndexName.Word4,
  4: WordIndexName.Word5,
  5: WordIndexName.Preselected,
}
export interface Type8Correct {
  firstWord: WordIndexName
  secondWord: WordIndexName
  audioURL: string
  audio: HTMLAudioElement | undefined // URL
}
interface Type8Attributes {
  presetWordText: LC<string>
  presetWordAudio: LC<{ url: string; slug: string }[]>
  presetWordPosition: LC<string> // iv
  introductionAudio: LC<{ url: string; slug: string }[]>
  unforgiving: LC<boolean> // iv
  words: LC<
    {
      text: string
      audio: { url: string; slug: string }[]
    }[]
  >
  correct: LC<
    {
      firstWord: WordIndexName
      secondWord: WordIndexName
      audio: { url: string; slug: string }[]
    }[]
  >
}
export interface Tasktype8Data extends TasktypeData {
  data: CommonTaskAttributes & Type8Attributes
}
export class Tasktype8 extends Tasktype {
  presetWordText = ''
  presetWordAudio = '' // URL
  presetWordPosition = ''
  introductionAudio = '' // URL
  unforgiving = false
  words: {
    text: string
    reference: WordIndexName
    audioURL: string // URL
  }[] = []
  correct: Type8Correct[] = []

  constructor(spec: Tasktype8Data, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype8
    this.parent = parent

    this.presetWordText = spec.data.presetWordText[language] || 'undefined'
    const pwa = spec.data.presetWordAudio[language]
    if (pwa) this.presetWordAudio = pwa[0]?.url + pwa[0]?.slug || ''
    this.presetWordPosition = spec.data.presetWordPosition.iv || 'undefined'
    const ia = spec.data.introductionAudio[language]
    if (ia) this.introductionAudio = ia[0]?.url + ia[0]?.slug || ''
    this.unforgiving = spec.data.unforgiving.iv || false
    spec.data.words[language]?.forEach((w, i) => {
      const wordKey = i as keyof typeof wordsFromIndex
      this.words.push({
        text: w.text,
        reference: wordsFromIndex[wordKey],
        audioURL: w.audio[0]?.url + w.audio[0]?.slug || '',
      })
    })
    spec.data.correct[language]?.forEach((c) => {
      const audioURL = c.audio[0]?.url + c.audio[0]?.slug || ''
      const a = {
        firstWord: c.firstWord,
        secondWord: c.secondWord,
        audioURL,
        audio: undefined,
      }
      if (a.firstWord === WordIndexName.Word6) a.firstWord = WordIndexName.Preselected
      if (a.secondWord === WordIndexName.Word6) a.secondWord = WordIndexName.Preselected
      this.correct.push(a)
    })
  }

  get assetList(): string[] {
    const list: string[] = [this.presetWordAudio, this.introductionAudio]
    this.words.forEach((w) => list.push(w.audioURL))
    this.correct.forEach((c) => list.push(c.audioURL))
    return list
  }

  // Calculate if the given combination fully or partially matches a correct answer
  public hasCombinationStatus(cc: Type8Correct, compareInput: Type8Correct[]): { status: string; correctRef?: Type8Correct } {
    const result: { status: string; correctRef?: Type8Correct } = { status: 'incorrect', correctRef: undefined }

    // If only one word has been placed, return 'some'
    if (
      (cc.firstWord === WordIndexName.None && cc.secondWord !== WordIndexName.None) ||
      (cc.firstWord !== WordIndexName.None && cc.secondWord === WordIndexName.None)
    ) {
      result.status = 'some'
      return result
    }

    const checkCombinations = (cc: Type8Correct, compare: Type8Correct): boolean => {
      const a = compare.firstWord === cc.firstWord
      const b = compare.secondWord === cc.secondWord

      if (a && b) {
        result.status = 'correct'
        result.correctRef = compare
        return true
      }
      return false
    }

    if (compareInput.length) {
      compareInput.forEach((item) => checkCombinations(cc, item))
    } else {
      this.correct.forEach((item) => checkCombinations(cc, item))
    }
    return result
  }

  // Return the fully concatenated word for the given word parts
  public combinedWordFromCC(cc?: Type8Correct): string {
    if (cc) {
      let word = cc.firstWord !== WordIndexName.None ? this.words[wordIndexes[cc.firstWord]].text : ''
      word += cc.secondWord !== WordIndexName.None ? this.words[wordIndexes[cc.secondWord]].text : ''
      return word
    } else return ''
  }

  // A string showing the words inside the given Type8Correct class
  public wordsFromCC(cc: Type8Correct) {
    let w1 = '',
      w2 = ''
    if (cc.firstWord !== WordIndexName.None) {
      w1 = cc.firstWord === WordIndexName.Preselected ? this.presetWordText : this.words[wordIndexes[cc.firstWord]].text
    }
    if (cc.secondWord !== WordIndexName.None) {
      w2 = cc.secondWord === WordIndexName.Preselected ? this.presetWordText : this.words[wordIndexes[cc.secondWord]].text
    }
    return w1 + w2
  }
}
