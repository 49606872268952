/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type4/ScannerType4@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding flex flex-row justify-around">
        <div class="flex flex-col justify-around">
          <div class="flex flex-row justify-start align-middle">
            <img v-cache class="roundCorners imageVeryLarge" :src="boxImage1" />
          </div>

          <div class="flex flex-row justify-start align-middle" style="position: relative; margin-top: 30px; color: white">
            <span v-show="!showCombinedWord[1]" class="text-2xl mt-auto mb-auto" @click="clickSentence(1)"
              >{{ task.round1.sentenceBeforeWord }}&nbsp;</span
            >

            <!-- Drop boxes -->

            <div
              v-show="displayedItem[1].length === 0"
              id="word-box-1"
              ref="wordBox1"
              class="wordDropBox drop-target"
              @start="onStart"
              @stop="onDrop"
              @mouseenter="onDropAreaMouseEnter"
              @mouseleave="onDropAreaMouseLeave"
            ></div>

            <transition mode="out-in" name="fade">
              <span
                v-show="displayedItem[1].length > 0 || !showCombinedWord[1]"
                class="wordHighlight fadeInOut"
                :class="{ yellowWordText: displayedItem[1].length > 0 }"
                >{{ displayedItem[1] }}
              </span>
            </transition>

            <!-- Combined word -->
            <transition mode="out-in" name="fade">
              <span
                v-show="combinedWord[1].length > 0 || showCombinedWord[1]"
                class="wordHighlight text-2xl"
                style="line-height: 24pt"
                :class="{ yellowWordText: !mergeCombinedWord[1] }"
                @click="playCompleteAudio(1)"
                >{{ combinedWord[1] }}&nbsp;
              </span>
            </transition>

            <span v-show="!showCombinedWord[1]" class="text-2xl">&nbsp;{{ task.round1.sentenceAfterWord }}</span>
          </div>
        </div>

        <div class="flex flex-col justify-around">
          <div class="flex flex-row justify-start align-middle">
            <img v-cache class="roundCorners imageVeryLarge" :src="boxImage2" />
          </div>

          <div class="flex flex-row justify-start align-middle" style="position: relative; margin-top: 30px; color: white">
            <span v-show="!showCombinedWord[2]" class="text-2xl mt-auto mb-auto" @click="clickSentence(2)"
              >{{ task.round2.sentenceBeforeWord }}&nbsp;</span
            >

            <!-- Drop boxes -->

            <div
              v-show="displayedItem[2].length === 0"
              id="word-box-2"
              ref="wordBox2"
              class="wordDropBox drop-target"
              @start="onStart"
              @stop="onDrop"
              @mouseenter="onDropAreaMouseEnter"
              @mouseleave="onDropAreaMouseLeave"
            ></div>

            <transition mode="out-in" name="fade">
              <span
                v-show="displayedItem[2].length > 0 || !showCombinedWord[2]"
                class="wordHighlight fadeInOut"
                :class="{ yellowWordText: displayedItem[2].length > 0 }"
                >{{ displayedItem[2] }}
              </span>
            </transition>

            <!-- Combined word -->
            <transition mode="out-in" name="fade">
              <span
                v-show="combinedWord[2].length > 0 || showCombinedWord[2]"
                class="wordHighlight text-2xl"
                :class="{ yellowWordText: !mergeCombinedWord[2] }"
                @click="playCompleteAudio(2)"
                >{{ combinedWord[2] }}&nbsp;</span
              >
            </transition>

            <span v-show="!showCombinedWord[2]" class="text-2xl">&nbsp;{{ task.round2.sentenceAfterWord }}</span>
          </div>
        </div>
      </div>
    </div>

    <div id="word-source-box" class="fadeInOut flex flex-row justify-around" :style="{ opacity: opacityWords }">
      <!-- Draggable Items -->
      <Draggable
        v-for="(word, i) of words"
        :id="`word-index-${i}`"
        :key="word.id"
        class="fadeInOut flex flex-row justify-around"
        :style="{ opacity: opacityWords }"
        :class="`${activeDrags ? 'pointer-events-none touch-none' : ''}`"
        :position="word.position"
        @pointerdown="onPointerDown"
        @start="(e: any) => onStart(e)"
        @stop="(e: any) => onDrop(e, word)"
      >
        <span class="borderedWordBox" @click="clickImage(word)">{{ word.text }}</span>
      </Draggable>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { Draggable, ControlPosition, DraggableEvent } from '@braks/revue-draggable'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES } from '@/constants'
  import { Tasktype4 } from '@/models/tasktypes/Tasktype4'

  type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

  interface Word {
    text: string
    audio?: MP3Audio | WebAudio
    correctBox: number
    enabled: boolean
    draggable: boolean
    opacity: number
    element?: HTMLElement
    id: string
    position: ControlPosition
  }

  let idleTimer: ReturnType<typeof setTimeout>

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype4> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype4,
    correct: 0,
    of: 2,
    answerOptions: task.value.draggableText.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const activeDrags = ref(0)

  let unforgivingTestMode = false
  let attempts = 0
  let opacityWords = ref(0)
  const wordBox1 = ref()
  const wordBox2 = ref()

  let taskCompleted = false
  const boxAudio: {
    1?: MP3Audio | WebAudio
    2?: MP3Audio | WebAudio
  } = {}
  const correctAudio: {
    1?: MP3Audio | WebAudio
    2?: MP3Audio | WebAudio
  } = {}
  const combinedWordAudio: {
    1?: MP3Audio | WebAudio
    2?: MP3Audio | WebAudio
  } = {}
  const boxCompleted = {
    1: false,
    2: false,
  }
  const displayedItem = {
    1: '',
    2: '',
  }
  const combinedWord = ref({
    1: '',
    2: '',
  })
  const showCombinedWord = ref({
    1: false,
    2: false,
  })
  const mergeCombinedWord = ref({
    1: false,
    2: false,
  })
  let audioPlaying = false
  let finishDone = false

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
  const boxImage1 = ref('')
  const boxImage2 = ref('')
  const words: Ref<Word[]> = ref([])
  const audioSentenceQueue: number[] = []
  let cancelDropEvent = false

  // ---------  Dragula functions -------------------

  // A 'dropbox' element should incude the class 'drop-target'
  const getDropboxElement = (e: DraggableEvent): Element | undefined => {
    let x = 0
    let y = 0
    if (e.event.type.includes('touch')) {
      const te = e.event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e.event as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    return el && el.classList.contains('drop-target') ? el : undefined
  }

  const onStart = (e: DraggableEvent) => {
    if (!e.event) return
    e.event.preventDefault()
    activeDrags.value++
  }

  const onPointerDown = (e: PointerEvent) => {
    const el = e.target as HTMLDivElement
    if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
  }

  const onDrop = (e: DraggableDIVElementEvent, theWord?: Word) => {
    activeDrags.value = 0
    const el = getDropboxElement(e)
    if (theWord && el) {
      const word = words.value.find((w) => w.id === theWord?.id)
      const boxIndex = (getBoxIndex(el) as keyof typeof boxAudio) || undefined
      if (!cancelDropEvent && word) {
        attempts++
        if (word.correctBox === boxIndex) {
          onDropComplete(word, boxIndex)
        } else {
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({
            attempt: word.text,
            correct: false,
            elapsed: tracking.elapsedTimeSinceLastCall,
          })
          theWord.position = { x: 0, y: 0 }
          words.value = shuffleItems(words.value)
        }
      }
    } else if (theWord) {
      clickImage(theWord)
      theWord.position = { x: 0, y: 0 }
    }
  }

  function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'word-box-1') wordBox1.value.classList.add('bg-red-400')
      else wordBox2.value.classList.add('bg-red-400')
    }
  }

  function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'word-box-2') wordBox1.value.classList.remove('bg-red-400')
      else wordBox2.value.classList.remove('bg-red-400')
    }
  }

  const getBoxIndex = (target: Element) => {
    return parseInt(target.id.substring(9), 10) || undefined
  }

  const playCompleteAudio = (wordNumber: keyof typeof combinedWordAudio) => {
    if (!audioPlaying) {
      audioPlaying = true
      const correctWordAudio = combinedWordAudio[wordNumber]
      if (correctWordAudio) {
        correctWordAudio.playWhenReady()
      } else audioPlaying = false
    }
    tracking.details.use_audio_content_items++
  }

  const clickImage = (item: Word) => {
    if (activeDrags.value === 0 && item.audio) {
      item.audio.playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const clickSentence = (boxNumber: number) => {
    const box = boxNumber as keyof typeof boxAudio
    const audio = boxAudio[box]
    if (audioPlaying) {
      audioSentenceQueue.push(box)
    } else if (box && audio) {
      audioPlaying = true
      audio.onended = () => {
        audioPlaying = false
      }
      audio.playWhenReady()
      tracking.details.use_audio_content_items++
    } else if (!unforgivingTestMode && tracking.details.of === tracking.details.correct && !finishDone) {
      fadeOut()
    }
  }

  const setupSentence = async (box: keyof typeof boxCompleted) => {
    let playAgainOrFadeOut = () => {
      audioPlaying = false
      if (audioSentenceQueue.length > 0) {
        const a = audioSentenceQueue.pop()
        if (a) clickSentence(a)
      } else if (!unforgivingTestMode && tracking.details.of === tracking.details.correct && !finishDone) {
        fadeOut()
      }
    }

    let before: MP3Audio | WebAudio | undefined, after: MP3Audio | WebAudio | undefined
    let word: MP3Audio | WebAudio | undefined = correctAudio[box]
    const round = box === 1 ? task.value.round1 : task.value.round2

    if (round.audioBeforeWord) {
      before = await createSound(round.audioBeforeWord)
      before.onended = () => {
        if (word && boxCompleted[box]) {
          word.playWhenReady()
        } else {
          if (after) {
            setTimeout(() => {
              if (after) after.playWhenReady()
            }, 1000)
          } else {
            playAgainOrFadeOut()
          }
        }
      }
    }

    if (word) {
      word.onended = () => {
        if (after) after.playWhenReady()
        else playAgainOrFadeOut()
      }
    }

    if (round.audioAfterWord) {
      after = await createSound(round.audioAfterWord)
      after.onended = () => {
        playAgainOrFadeOut()
      }
    }

    if (before) boxAudio[box] = before
    else if (after) boxAudio[box] = after
  }

  const setupTask = async () => {
    finishDone = false
    audioPlaying = false
    taskCompleted = false
    stateActions.progress.progressShow({ stars: 2 })

    if (!task.value) {
      alert('A Type 4 task does not exist - check your Session layout in the CMS')
      return
    }

    combinedWordAudio[1] = await createSound(task.value.correctWord1Audio)
    combinedWordAudio[1].onended = () => (audioPlaying = false)
    combinedWordAudio[2] = await createSound(task.value.correctWord2Audio)
    combinedWordAudio[2].onended = () => (audioPlaying = false)

    boxImage1.value = task.value.round1.boxImage ? task.value.round1.boxImage : ''
    boxImage2.value = task.value.round2.boxImage ? task.value.round2.boxImage : ''
    let correctWord1 = parseInt(task.value.correctWord1)
    let correctWord2 = parseInt(task.value.correctWord2)
    let tempWords: Word[] = []
    task.value.draggableText.filter((t) => t.text)
    for (let i = 0; i < task.value.draggableText.length; i++) {
      const t = task.value.draggableText[i]
      let word: Word = {
        text: t.text,
        audio: t.audioURL ? await createSound(t.audioURL) : undefined,
        enabled: true,
        correctBox: 0,
        draggable: true,
        opacity: 1,
        id: 'draggable-word-' + i,
        position: { x: 0, y: 0 },
      }
      if (i + 1 === correctWord1) {
        word.correctBox = 1
        correctAudio[1] = word.audio
      } else if (i + 1 === correctWord2) {
        word.correctBox = 2
        correctAudio[2] = word.audio
      }
      tempWords.push(word)
    }
    words.value = shuffleItems(tempWords)
    setupSentence(1)
    setupSentence(2)
    introduceChallenge()
  }

  const introduceChallenge = () => {
    attempts = 0
    stateSetters.speakerIsPlaying = true
    setTimeout(() => {
      opacity.value = 1
      opacityWords.value = 1
      stateActions.speakLocalised(
        SpeechSounds.instructions.tasks.T4,
        () => {
          stateSetters.speakerIsPlaying = false
        },
        1000,
      )
    }, 1000)
  }

  const completeTheWord = (box: keyof typeof displayedItem, item: Word) => {
    const wordIndex = words.value.findIndex((w) => w.id === item.id)
    showCombinedWord.value[box] = true
    if (wordIndex > -1) words.value.splice(wordIndex, 1)
    const round = box === 1 ? task.value.round1 : task.value.round2
    displayedItem[box] = item.text
    if (round.mergeBefore) combinedWord.value[box] = round.sentenceBeforeWord + item.text
    else combinedWord.value[box] = round.sentenceBeforeWord + ' ' + item.text

    if (round.mergeAfter) combinedWord.value[box] += round.sentenceAfterWord
    else combinedWord.value[box] += ' ' + round.sentenceAfterWord

    setTimeout(() => {
      mergeCombinedWord.value[box] = true
    }, 2000)
  }

  const onDropComplete = async (item: Word, box: keyof typeof boxAudio) => {
    tracking.details.correct++
    tracking.details.answer_details.push({ attempt: item.text, correct: true, elapsed: tracking.elapsedTimeSinceLastCall })
    boxCompleted[box] = true
    item.enabled = false // TODO: Check that mutating a template ref works!?
    // this['dottyText' + box] = ''
    // this['enableZone' + box] = false
    stateActions.progress.completeAStar()

    const newCompletedSentenceAudioUrl = box === 1 ? task.value.correctWord1Audio : task.value.correctWord2Audio
    const done = (unforgivingTestMode && attempts === tracking.details.of && !finishDone) || tracking.details.of === tracking.details.correct

    if (done) opacityWords.value = 0

    if (newCompletedSentenceAudioUrl) {
      let a = await createSound(newCompletedSentenceAudioUrl)
      a.onended = () => {
        // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
        // If number of attempts are limited to correct answers we will finish the task
        if (done) fadeOut()
      }
      if (!unforgivingTestMode) {
        a.playWhenReady()
        idleTimer = setTimeout(() => fadeOut(), 10000)
      } else if (done) fadeOut()
      boxAudio[box] = a
    } else if (done) fadeOut()

    completeTheWord(box, item)
  }

  const finish = () => {
    opacity.value = 0
    if (!taskCompleted) {
      taskCompleted = true
      setTimeout(() => {
        emit('completed', true, tracking)
      }, 500)
    }
  }

  const fadeOut = () => {
    clearTimeout(idleTimer)
    finishDone = true
    opacityWords.value = 0
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(SpeechSounds.instructions.warmups.T4, () => finish(), 1000, false)
    } else {
      finish()
    }
  }

  setupTask()
</script>

<style scoped lang="postcss">
  .type4TextContent {
    display: inline-block;
  }

  .hideMe {
    display: none;
  }

  .wordDropBox {
    display: inline-block;
    width: 170px;
    height: 70px;
    margin: 0 5px;

    border-radius: 75px;
    background-color: black;
    outline: none;
    border: solid white 5px;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  #word-source-box {
    margin-top: 30px;
  }

  .yellowWordText {
    font-size: 24pt;
    color: #ffd700;
  }

  .captionArea {
    position: relative;
    margin-top: 30px;
    color: white;
    vertical-align: baseline;
    display: inline-block;
  }

  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 5%;
  }

  .scanner-image {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 10%;
    padding-top: 5%;
  }
  .scanner-padding {
    padding: 0 5%;
  }
  .scanner-word {
    margin-top: -50px;
  }
  .smallerText {
    font-size: 12pt;
  }
</style>
