<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div v-if="edit" class="flex flex-col py-4 rounded" :class="[showSave ? 'bg-green-200' : 'bg-blue-200']" @click="selectGame()">
    <div class="flex flex-row flex-wrap">
      <div class="flex flex-col items-start">
        <AnswerInput v-model="theGame.profile.name" class="m-2" mode="text" label="Name" :border="false" @change="saveActive"></AnswerInput>
        <p class="m-2">
          Owner:<br />
          {{ ownerName }}
        </p>
      </div>
      <div class="flex flex-col items-start">
        <p class="px-2 text-xs">
          <span class="text-red-600">Warning: </span>changing the Project will reset some Game parameters:<br />
          Current shuffle order, current Mastery set
        </p>
        <MultipleChoiceInput
          v-model="theGame.sharing.groups"
          label="Sharing"
          description="Sets of Users (e.g. kindergartens) who can see this Game"
          mode="multiChoice"
          :options="usersGroups"
          :underline="false"
          class="m-2"
          @change="saveActive"
        />
      </div>
      <div class="flex flex-col items-start mt-4 p-1">
        <p>Progress Control</p>
        <AnswerInput
          v-model:booleanValue="theGame.status.controlActive"
          class="m-2 pt-2"
          mode="binaryChoice"
          :border="false"
          label="Restrict SET progress"
          description="Enforce progress using the Mastery monitor panel (DSLPlus)"
          @change="saveActive"
        ></AnswerInput>
        <AnswerInput
          v-model:booleanValue="theGame.status.redoTasks"
          class="m-2 pt-2"
          mode="binaryChoice"
          :border="false"
          label="Allow access to previous tasks"
          description="Previously completed questions can be tried again.
          Activating this affects the meaning of a 'completed Set', as a Set
          is normally completed immediately after the final Question"
          @change="saveActive"
        ></AnswerInput>
        <AnswerInput
          v-model:booleanValue="theGame.status.skipTasks"
          class="m-2 pt-2"
          mode="binaryChoice"
          :border="false"
          label="Allow access to future tasks"
          description="Future tasks will be accessible even if the current task is not completed"
          @change="saveActive"
        ></AnswerInput>
      </div>
      <div class="flex flex-col items-start mt-4 p-1">
        <p>Player details</p>
        <AnswerInput v-model="theGame.profile.name" class="m-2" mode="text" label="Name" :border="false" @change="saveActive"></AnswerInput>
        <AnswerInput v-model="theGame.profile.ref" class="m-2" mode="text" label="Reference" :border="false" @change="saveActive"></AnswerInput>
        <AnswerInput
          v-model:booleanValue="theGame.status.deleted"
          class="m-2"
          mode="binaryChoice"
          label="Deleted"
          :border="false"
          @change="saveActive"
        ></AnswerInput>
        <div class="flex flex-col items-start">
          <AnswerInput
            v-model="theGame.consent.state"
            class="m-2"
            mode="singleChoice"
            :border="false"
            :options="consentStateList"
            label="Consent"
            @change="saveActive"
          ></AnswerInput>
          <AnswerInput v-model="theGame.consent.id" class="m-2" mode="text" :border="false" label="Consent ID" @change="saveActive"></AnswerInput>
        </div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap justify-end">
      <slot name="closeEdit" />
      <div>
        <Button v-if="showSave" id="button-save" :backgroundcolour="'bg-green-600'" class="col-start-2 my-2 mr-2" @click="saveGame()"
          >Save Game</Button
        >
        <Button class="col-start-2 my-2 mr-2" :backgroundcolour="'bg-red-600'" :textcolour="'text-white'" @click="deleteGame()">Delete Game</Button>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-row w-full items-center justify-between p-1 bg-blue-200">
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-around">
        <p>
          <strong v-if="theGame.profile.name">{{ theGame.profile.name }}</strong>
          <strong v-else><i style="color: grey">unnnamed</i></strong>
        </p>
        <p class="text-blue-600">
          <strong v-if="theGame.profile.name">{{ theGame.profile.name }} ({{ theGame.profile.ref }})</strong>
          <strong v-else><i style="color: grey">unnnamed</i></strong>
        </p>
      </div>
      <div class="flex flex-col justify-start w-full text-xs p-4">
        <p class="mr-2">ID: {{ theGame._id }}</p>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref, Ref, toRefs, computed, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import useUserStore from '@/store/useUserStore'
  import { Game, DialogConfig } from '@/models/main'
  import { consentStates } from '@/constants'
  import { dateToFormattedString } from '@/utilities'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import MultipleChoiceInput from '@/components/base/MultipleChoiceInput.vue'
  import Button from '@/components/base/Button.vue'
  import useGameStore from '@/store/useGameStore'
  import useAppStore from '@/store/useAppStore'

  interface LocationListItem {
    itemName: string
    item: string
  }

  const messages = {
    no: {
      listenAgain: 'Hør igjen',
      dialogDeleteTitle: 'Slett dette Spillet',
      dialogDeleteText: 'Hvis du sletter, du kan ikke angre. All spillerfremgang vil bli fjernet!',
      dialogDeleteConfirm: 'Slette',
      dialogDeleteCancel: 'Avbryt',
    },
    sv: {
      listenAgain: 'Hør igjen',
      dialogDeleteTitle: 'Slett dette Spillet',
      dialogDeleteText: 'Hvis du sletter, du kan ikke angre. All spillerfremgang vil bli fjernet!',
      dialogDeleteConfirm: 'Slette',
      dialogDeleteCancel: 'Avbryt',
    },
    en: {
      listenAgain: 'Listen again',
      dialogDeleteTitle: 'Delete this Game',
      dialogDeleteText: 'Deletion cannot be undone. All player progress will be removed!',
      dialogDeleteConfirm: 'Delete',
      dialogDeleteCancel: 'Cancel',
    },
  }

  interface GroupShareOptionListItem {
    title: string
    id: string
  }

  export default defineComponent({
    name: 'MonitorGameItem',
    components: {
      Button,
      AnswerInput,
      MultipleChoiceInput,
    },
    props: {
      game: {
        type: Object as PropType<Game>,
        required: true,
      },
      edit: {
        type: Boolean,
      },
    },
    emits: ['gamedeleted'],
    setup(props, context) {
      const { t } = useI18n({ messages })
      const { actions: gameActions, getters: gameGetters } = useGameStore()
      const { getters: userGetters } = useUserStore()
      const { actions: appActions } = useAppStore()
      const usersGroups: Ref<GroupShareOptionListItem[]> = ref([])
      const showSave = ref(false)
      const g = new Game(props.game)
      const theGame: Ref<Game> = ref(g)
      const currentLocationName: Ref<LocationListItem> = ref({
        item: '',
        itemName: '',
      })

      const initialise = () => {
        const { game } = toRefs(props)
        theGame.value = new Game(game.value)
        usersGroups.value = userGetters.selectedUser.value.groups.map((g) => {
          return {
            id: g._id,
            title: g.name,
          }
        })
      }
      initialise()

      watch(
        () => props.game,
        () => {
          initialise()
        },
      )

      const ownerName = computed(() => {
        const myOwnerID = theGame.value.owner
        const u = userGetters.allUsers.value.find((u) => u._id === myOwnerID)
        return u ? u.profile.username : 'owner unknown'
      })

      // -------------------------Game functions-----------------------------

      const locationList = computed((): LocationListItem[] => {
        return gameGetters.locations.map((i) => ({
          item: i,
          itemName: i,
        }))
      })

      const consentStateList = Object.values(consentStates).map((v, i) => ({
        id: '' + i,
        title: v,
      }))

      const saveGame = async () => {
        // Update a selected Game
        await gameActions.updateGame(theGame.value)
        showSave.value = false
      }

      const deleteGame = async () => {
        const dialogConfig: DialogConfig = {
          title: t('dialogDeleteTitle'),
          visible: true,
          text: t('dialogDeleteText'),
          cancel: () => appActions.setDialog(false),
          cancelText: t('dialogDeleteCancel'),
          confirm: async () => {
            appActions.setDialog(false)
            context.emit('gamedeleted')
            await gameActions.deleteGame(theGame.value)
          },
          confirmText: t('dialogDeleteConfirm'),
        }
        appActions.setDialog(true, dialogConfig)
        showSave.value = false
      }

      const saveActive = () => {
        showSave.value = true
      }

      const selectGame = () => {
        gameActions.selectGame(theGame.value)
      }

      return {
        // Computed
        showSave: computed(() => showSave.value),
        theGame,
        consentStateList,
        currentLocationName,
        ownerName,

        // Methods
        saveGame,
        deleteGame,
        saveActive,
        selectGame,
        dateToFormattedString,

        locationList,
        usersGroups,
      }
    },
  })
</script>

<style scoped></style>
