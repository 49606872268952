/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import moment from 'moment'
import { uuid } from '@/utilities'
import type { SpriteSheet, Stage, MovieClip } from 'createjs-module'
import { USER_ROLE, taskColours, LanguageNames, TASK_TYPES, CMS_TASK_NAMES, QUESTION_TYPES, QUESTION_MODE } from '@/constants'

// ---------------  Utility -----------------

export interface ColumnDef {
  headerName: string
  field: string

  children?: {
    field: string
    headerName: string
    columnGroupShow?: string
    // eslint-disable-next-line
    [x: string]: any
  }[]
  hide?: boolean | unknown
  editable?: boolean | unknown
  // eslint-disable-next-line
  [x: string]: any
}

export interface LottieOptions {
  loop?: boolean
  autoplay?: boolean
  // eslint-disable-next-line
  animationData?: string | object
  path?: string
  src?: string
  rendererSettings?: {
    preserveAspectRatio: boolean
    clearCanvas: boolean
    progressiveLoad: boolean
    hideOnTransparent: boolean
  }
}

export interface Manifest {
  src: string
  id: string
}
export interface Metadata {
  name: string
  frames: unknown
}
export interface Library {
  properties: {
    id: string
    width: number
    height: number
    fps: number
    color: string
    opacity: number
    manifest: Manifest[]
    preloads: string[]
  }
  ssMetadata: Metadata[]
  Stage: Stage
  morfScene: typeof MovieClip
}
export interface Composition {
  getLibrary: () => Library
  getSpriteSheet: () => SpriteSheet
  getImages: () => Record<string, unknown>
}
export interface AdobeAn {
  compositions: Record<string, Composition>
  getComposition: (key: string) => Composition
  bootstrapCallback: (callback: (compId: string) => void) => void
  compositionLoaded: (id: string) => void
}

// ---------------  Models -----------------

export interface CallbackOneParam<T, U = void> {
  (arg?: T): U
}
export interface Callback {
  (...args: unknown[]): unknown
}

// This defined the additional functions available on a Question Type component
// This allows Question.vue to control the question type child
// The child should implement appropriate code that runs when these functions are called
/* export interface AugmentedQuestionType extends Vue {
  forwardInternal: () => void // Called when the user clicks the white 'forward' arrow
  onIntroductionStart: () => void // Called when introduction begins
  onIntroductionEnd: () => void // Called when introduction ends
} */

export interface LocalUser extends Record<string, unknown> {
  _id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

export interface DialogConfig {
  title: string
  text: string
  visible: boolean
  confirm: Callback
  confirmText: string
  cancel: Callback
  cancelText: string
}

// ------------- Classes -----------------

export interface CordovaDataType {
  readFile?: boolean // Returns the content if true, returns a FileEntry if false  (read)
  asText?: boolean // false   Set to true if reading a text or JSON file, otherwise binary will be used  (read/write)
  asJSON?: boolean // true   Set to false to read/write a file without parsing or stringifying JSON  (read/write)
  overwrite?: boolean // false   Set to true to overwrite an existing file (open file)
  append?: boolean // false   Set to true to append data to the end of the file (write)
  path?: string[] // Path to the file below the root as an array of directory names (read/write)
  fileName?: string // name of the file on disk (read/write)
  data?: unknown | Blob | string // the content to be written  (write)
  file?: FileEntry // the FileEntry object in memory
  fileToMove?: FileEntry | MediaFile // the file entry object in momory for the file to be moved
}
export class CordovaData {
  readFile = false
  asText = false
  asJSON = true
  overwrite = false
  append = false
  path: string[]
  fileName = ''
  data?: unknown | string | Blob
  file?: FileEntry
  fileToMove?: /* data */ FileEntry | MediaFile /* video */

  constructor(data: CordovaDataType) {
    this.path = []
    if (data) {
      this.readFile = data.readFile ? data.readFile : false
      this.asText = data.asText ? data.asText : false
      this.asJSON = data.asJSON ? data.asJSON : true
      this.overwrite = data.overwrite ? data.overwrite : false
      this.append = data.append ? data.append : false
      this.path = data.path ? data.path : []
      this.fileName = data.fileName ? data.fileName : ''
      this.data = data.data
      this.file = data.file
      this.fileToMove = data.fileToMove
    }
  }
}

// ---------------  Base CMS model classes ------------------
// --- Extend these to represent real Squidex model types ---

export enum DISPLAY_MODE {
  linear = 'linear',
  shuffle = 'shuffle',
  mastery = 'mastery',
}

export interface SetData {
  id: string
  index: number
  displayMode: DISPLAY_MODE
  name: string
  description: string
  parent: Sett | undefined
  thumbnail: string
  consolidation: boolean
}

// Sett class should be extended to represent a Project's actual Sett shape
export class Sett {
  _id: string
  index: number
  displayMode: DISPLAY_MODE
  name: string
  title = ''
  subtitle = ''
  description: string
  sets: Sett[] = []
  questions: Question[]
  consolidation?: boolean

  // Frontend only
  disabled = false
  parent?: Sett
  thumbnail = ''

  constructor(spec?: SetData) {
    this._id = spec ? spec.id : ''
    this.index = spec ? spec.index : 0
    this.name = spec ? spec.name : ''
    this.thumbnail = spec ? spec.thumbnail : ''
    this.description = spec ? spec.description : ''
    this.displayMode = spec ? spec.displayMode : DISPLAY_MODE.linear
    this.consolidation = spec ? spec.consolidation : false
    this.parent = spec ? spec.parent : undefined
    this.questions = []
  }

  get root(): Sett {
    return this.parent ? this.parent : this
  }

  addQuestion(q: Question): void {
    this.questions.push(q)
  }
  clearQuestions(): void {
    this.questions.length = 0
  }
}

export interface QuestionData {
  id: string
  __typename: string
  name?: string
  type?: TASK_TYPES | QUESTION_TYPES
  thumbnail?: string
  recordAudio?: boolean
  flatData?: unknown
  data?: unknown
}

// Question class should be extended to represent a Project's actual Question shape (see questionModels.ts)
export abstract class Question {
  _id: string
  name: string
  __typename: string // This string is used to generate components. The component name must match it
  type: TASK_TYPES | QUESTION_TYPES
  mode: QUESTION_MODE
  disabled = false
  word = ''
  thumbnail: string
  recordAudio: boolean

  constructor(spec: QuestionData, mode?: QUESTION_MODE) {
    this._id = spec.id
    this.name = spec.name || ''
    this.__typename = spec.__typename
    this.type = spec.type ? spec.type : QUESTION_TYPES.question
    this.mode = mode || QUESTION_MODE.test
    this.recordAudio = !!spec.recordAudio
    this.thumbnail = spec.thumbnail || ''
  }
}

// ----------  Squidex response shapes --------------

// Data level of a Squidex GraphQL response. Can be supplied as single or array
// Extend this interface to represent different responses for various Sett and Question types
export interface CmsGQLData {
  __typename: string
  id?: string
  flatData?: Record<string, unknown>
  data?: Record<string, unknown>
}
// Shape of the Sett -> Question response
export interface CmsQuestionData extends CmsGQLData {
  flatData: {
    warmups: { [key in CMS_TASK_NAMES]: CmsGQLData[] }
    tests: { [key in CMS_TASK_NAMES]: CmsGQLData[] }
  }
}

// Top level of a Squidex GrapghQL response
export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData | CmsQuestionData
    items?: CmsGQLData[]
  }
  errors?: []
  access_token?: string
}

// ---------------  User & Player -----------------

interface ProgressData {
  itemId: string
  description: string
  parentId: string
  completed?: boolean
  completions?: string[] | Date[]
  attempts?: string[] | Date[]
}
// Progress follows the flattened shape of CMS Sett and Question data
// meaning: Progress tracks the Player's completion status on a particular Sett or Question
export class Progress {
  itemId: string // CMS ID of the tracked item
  description: string // Textual description of the item
  parentId: string // CMS ID of the current parent of this item
  completed = false
  completions: Date[] = [] // Completions marked only if item was not previously completed
  attempts: Date[] = [] // Attempts on this item (increments if already completed, may be larger than completions[])

  constructor(data: ProgressData) {
    this.itemId = data.itemId
    this.description = data.description
    this.parentId = data.parentId
    this.completed = !!data.completed
    if (data.parentId) this.parentId = data.parentId
    if (data.completions && data.completions.length > 0) {
      data.completions.forEach((cp: string | Date) => {
        this.completions.push(new Date(cp))
      })
    }
    if (data.attempts && data.attempts.length > 0) {
      data.attempts.forEach((cp: string | Date) => {
        this.attempts.push(new Date(cp))
      })
    }
  }

  // Return the most recent completion
  get latestCompletion(): Date {
    return this.completions[this.completions.length - 1]
  }

  // Return the most recent attempt
  get latestAttempt(): Date {
    return this.attempts[this.attempts.length - 1]
  }

  // Set this Progress to be 'completed'
  // Add a new timestamp for this completion
  // Returns the total current number of completions
  complete(): number {
    const newDate = new Date()
    if (!this.completed) {
      this.completed = true
      this.completions.push(newDate)
    }
    this.attempts.push(newDate)
    return this.completions.length
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): ProgressData {
    const pojo = { ...this }
    return pojo
  }
}

export enum TRACKING_TYPE {
  interaction = 'interaction',
  task = 'task',
  session = 'session',
  subtask = 'subtask',
  all = 'all',
}
// TrackingData will store information about a 'usage' of a Question, Picture Book etc
interface TrackingDetails {
  user?: string
  project?: string
  rootName?: string
  rootIndex?: number
  rootID?: string
  setID?: string
  taskAttempts?: number
  setCompletions?: number
}

export interface TrackingSearch {
  itemID?: string // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  gameID?: string // ID of the associated Game
  isMedia: boolean // Tracking is usable as a 'media' item
}

export interface TrackingTableLayout {
  oid: string
  type: TRACKING_TYPE
  createdAt: string
  activityID: string
  gameID: string
  itemID: string
  taskDescription: string
  duration: string
  user: string
  project: string
  rootName: string
  rootIndex: number
  rootID: string
  setID: string
  taskAttempts: number
  setCompletions: number
}

export type SessionTrackingTableLayout = TrackingTableLayout & SessionDetails
export type TaskTrackingTableLayout = TrackingTableLayout & Omit<TaskDetails, 'answer_details'> & { answerDetails: string }

export class Tracking {
  itemID = '' // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  gameID = '' // ID of the associated Game
  activityID = '' // ID of the activity in use
  projectID = '' // ID of the associated Project
  type: TRACKING_TYPE = TRACKING_TYPE.task
  isMedia = false

  oid = uuid() // Unique key used to map this item
  createdAt: Date | string = new Date() // the start of the tracking
  description = ''
  duration = 0 // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: TrackingDetails // Holds any kind of data specific to the question type

  // Holds any kind of extra data needed for the Tracking. Extend this with a more specific Type in generalModels.ts
  sessionDetails?: Record<string, unknown>
  taskDetails?: Record<string, unknown>

  audioFileSize?: number // Size of the uploaded file
  videoFileSize?: number // Size of the uploaded file

  // Status
  localSynced = false // saved to disk locally
  serverSynced = false // saved to our server successfully
  storageSynced = false // sent to TSD successfully

  lastCall = Date.now()
  completed = false

  constructor(trackingdata?: Partial<Tracking>) {
    if (trackingdata) this.update(trackingdata)
  }

  update(trackingdata: Partial<Tracking>): void {
    if (this.completed) return console.log(`Warning: Tracking ${this.oid} was already marked completed`)
    if (trackingdata.itemID) this.itemID = trackingdata.itemID
    if (trackingdata.gameID) this.gameID = trackingdata.gameID
    if (trackingdata.activityID) this.activityID = trackingdata.activityID
    if (trackingdata.projectID) this.projectID = trackingdata.projectID
    if (trackingdata.type) this.type = trackingdata.type as TRACKING_TYPE
    if (trackingdata.isMedia) this.isMedia = !!trackingdata.isMedia
    if (trackingdata.oid) this.oid = trackingdata.oid
    if (trackingdata.createdAt) this.createdAt = new Date(trackingdata.createdAt)
    if (trackingdata.duration) this.duration = trackingdata.duration
    if (trackingdata.details) this.details = trackingdata.details

    // Optional
    if (trackingdata.description) this.description = trackingdata.description
    if (trackingdata.audioFile) this.audioFile = trackingdata.audioFile
    if (trackingdata.videoFile) this.videoFile = trackingdata.videoFile
    if (trackingdata.audioFileSize) this.audioFileSize = trackingdata.audioFileSize
    if (trackingdata.videoFileSize) this.videoFileSize = trackingdata.videoFileSize

    if (trackingdata.localSynced) this.localSynced = !!trackingdata.localSynced
    if (trackingdata.serverSynced) this.serverSynced = !!trackingdata.serverSynced
    if (trackingdata.storageSynced) this.storageSynced = !!trackingdata.storageSynced
  }

  // Complete this Tracking by setting its duration and possibly 'data'
  complete(): void {
    const startDate = moment(this.createdAt)
    const endDate = moment()
    this.duration = endDate.diff(startDate, 'seconds')
    this.completed = true
    this.localSynced = false
    this.serverSynced = false
  }

  get elapsedTimeSinceLastCall(): number {
    const t = Date.now() - this.lastCall
    this.lastCall = Date.now()
    return t
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Tracking {
    const pojo = { ...this }
    return pojo
  }

  duplicate(): Tracking {
    return { ...this } as Tracking
  }

  static columnDefs(): ColumnDef[] {
    return [
      { headerName: 'ID', field: 'oid' },
      { headerName: 'Created', field: 'createdAt' },
      { headerName: 'Duration', field: 'duration' },
      { headerName: 'Type', field: 'type' },
      { headerName: 'Game ID', field: 'gameID' },
      {
        headerName: 'General Details',
        field: 'generalDetails',
        children: [
          {
            field: 'user',
            headerName: 'User',
            columnGroupShow: 'closed',
            filter: true,
          },
          {
            field: 'user',
            headerName: 'User',
            columnGroupShow: 'open',
          },
          { headerName: 'Activity ID', field: 'activityID', columnGroupShow: 'open' },
          {
            field: 'rootName',
            headerName: 'Root Name',
            columnGroupShow: 'open',
          },
          {
            field: 'rootIndex',
            headerName: 'Root Index',
            columnGroupShow: 'open',
          },
          { field: 'rootID', headerName: 'Root ID', columnGroupShow: 'open' },
          {
            field: 'setID',
            headerName: 'Set ID',
            columnGroupShow: 'open',
          },
          {
            field: 'itemID',
            headerName: 'Item ID',
            columnGroupShow: 'open',
          },
          {
            field: 'taskAttempts',
            headerName: 'Task Attempts',
            columnGroupShow: 'open',
          },
          {
            field: 'setCompletions',
            headerName: 'Set Completions',
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Session details',
        field: 'sessionDetails',
        children: [
          {
            field: 'tasks_total',
            headerName: 'Total Tasks',
            columnGroupShow: 'closed',
          },
          {
            field: 'tasks_total',
            headerName: 'Total Tasks',
            columnGroupShow: 'open',
          },
          {
            field: 'inactive_count',
            headerName: 'Inactive count',
            columnGroupShow: 'open',
          },
          {
            field: 'inactive_duration',
            headerName: 'Inactive duration',
            columnGroupShow: 'open',
          },
          { field: 'tasks_completed', headerName: 'Tasks completed', columnGroupShow: 'open' },
        ],
      },
      {
        headerName: 'Tracking details',
        field: 'trackingDetails',
        children: [
          {
            field: 'taskType',
            headerName: 'Task Type',
            columnGroupShow: 'closed',
          },
          {
            field: 'taskType',
            headerName: 'Task Type',
            columnGroupShow: 'open',
          },
          {
            field: 'taskDescription',
            headerName: 'Task Name',
            columnGroupShow: 'open',
          },
          {
            field: 'correct',
            headerName: 'Answered correctly',
            columnGroupShow: 'open',
          },
          {
            field: 'of',
            headerName: 'Total correct possible',
            columnGroupShow: 'open',
          },
          {
            field: 'answerOptions',
            headerName: 'Answer options presented',
            columnGroupShow: 'open',
          },
          { field: 'incorrectAttempts', headerName: 'Incorrect attempts', columnGroupShow: 'open' },
          {
            field: 'answerDetails',
            headerName: 'Answer Details',
            columnGroupShow: 'open',
          },
          {
            field: 'use_audio_instructions',
            headerName: 'Use Instruction Audio',
            columnGroupShow: 'open',
          },
          {
            field: 'use_audio_content_items',
            headerName: 'Use Audio Content Items',
            columnGroupShow: 'open',
          },
        ],
      },
    ]
  }

  public get created(): Date {
    return new Date(this.createdAt)
  }

  public get asTableData(): TrackingTableLayout {
    return {
      oid: this.oid,
      type: this.type,
      activityID: this.activityID,
      gameID: this.gameID,
      createdAt: this.createdAt.toLocaleString(),
      taskDescription: this.description,
      duration: this.duration + 's',
      user: this.details?.user || '',
      project: this.details?.project || '',
      rootName: this.details?.rootName || '',
      rootIndex: this.details?.rootIndex || 0,
      rootID: this.details?.rootID || '',
      setID: this.details?.setID || '',
      itemID: this.itemID || '',
      taskAttempts: this.details?.taskAttempts || 0,
      setCompletions: this.details?.setCompletions || 0,
    }
  }
}

interface SessionDetails extends TrackingDetails {
  tasks_total: number
  inactive_count: number
  inactive_duration: number
  tasks_completed: number
}
/* export interface SessionTrackingData extends TrackingData {
  details: SessionDetails
} */

interface AnswerDetails {
  attempt?: string
  correct?: boolean
  edited?: boolean
  elapsed?: number
}
interface TaskDetails extends TrackingDetails {
  taskType: TASK_TYPES
  correct: number // Number of correct answers made by user
  of: number // Total number of correct answers available
  answerOptions: number // Total number of answer options available
  incorrectAttempts: number
  answer_details: AnswerDetails[]
  use_audio_instructions: number
  use_audio_content_items: number
}
/* export interface TaskTrackingData extends Tracking {
  details?: TaskDetails
} */

export class TaskTracking extends Tracking {
  details: TaskDetails = {
    taskType: TASK_TYPES.Tasktype1,
    correct: 0, // Total of correctly answered options
    of: 0, // Total number of correct answer options available
    answerOptions: 0, // Total number of answer options available
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }

  constructor(data?: Partial<TaskTracking>) {
    super(data)
    this.type = TRACKING_TYPE.task
    this.details.answer_details = []
    if (data) this.update(data)
  }

  update(data: Partial<TaskTracking>): void {
    super.update(data)
    this.details = { ...this.details, ...data.details }
  }

  // Mastery-specific Tracking entries
  complete(data?: TaskTracking): void {
    if (data && data.details) this.update(data)
    super.complete()
  }

  public get answerDetailsAsString(): string {
    const s = JSON.stringify(this.details.answer_details)
    return s
  }

  public get asTableData(): TaskTrackingTableLayout {
    return {
      ...super.asTableData,
      taskType: this.details.taskType,
      taskDescription: this.description,
      correct: this.details.correct,
      of: this.details.of,
      answerOptions: this.details.answerOptions,
      incorrectAttempts: this.details.incorrectAttempts,
      answerDetails: this.answerDetailsAsString,
      use_audio_instructions: this.details.use_audio_instructions,
      use_audio_content_items: this.details.use_audio_content_items,
    }
  }
}

export class SessionTracking extends Tracking {
  details: SessionDetails = {
    tasks_total: 0,
    inactive_count: 0,
    inactive_duration: 0,
    tasks_completed: 0,
  }

  constructor(data?: Partial<SessionTracking>) {
    super(data)
    this.type = TRACKING_TYPE.session
    if (data && data.details) this.update(data)
  }

  update(data: Partial<SessionTracking>): void {
    super.update(data)
    this.details = { ...this.details, ...data.details }
  }

  // Mastery-specific Tracking entries
  complete(data?: SessionTracking): void {
    if (data && data.details) this.update(data)
    super.complete()
  }

  public get asTableData(): SessionTrackingTableLayout {
    return {
      ...super.asTableData,
      tasks_total: this.details.tasks_total,
      inactive_count: this.details.inactive_count,
      inactive_duration: this.details.inactive_duration,
      tasks_completed: this.details.tasks_completed,
    }
  }
}

export interface GroupData {
  _id: string
  name: string
  location: string
}
export class Group {
  _id: string
  name: string
  location: string

  constructor(data?: GroupData | Group) {
    this._id = ''
    this.name = ''
    this.location = ''
    if (data) this.update(data)
  }

  update(data: GroupData | Group): void {
    this._id = data._id
    this.name = data.name
    this.location = data.location
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Record<string, unknown> {
    return { _id: this._id, name: this.name, location: this.location }
  }
}
export interface GameData {
  _id?: string
  players: string[] // References or populated Player model
  activePlayers: string[] // References or populated Player model currently active
  owner: string
  consent: {
    id: string
    state: string
  }
  profile: {
    ref: string
    name: string
    thumbnail: string
    colour: string
    avatar: AvatarLayout
  }
  progress: Record<string, ProgressData>
  status: {
    deleted: boolean
    controlActive: boolean // If true, settings in this section will affect the Game (adjust using Monitor)
    redoTasks: boolean // If true, players in the Game can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Game is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: string | undefined
    updatedProgressAt: string | undefined
    introsSeen: string[]
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }
}
export interface SpecialRequestData {
  game: GameData
  data: Record<string, Record<number, { total: number; correct: number }>>
}

export enum SPECIAL_REQUEST_TYPE {
  successresults = 'successresults',
}

export class Game {
  _id: string
  owner: string
  consent: {
    state: string
    id: string
  }
  profile: {
    ref: string
    name: string
    avatar: AvatarLayout
    colour: string
    thumbnail: string
  }
  progress: Map<string, Progress>
  status: {
    deleted: boolean
    controlActive: boolean // If true, settings in this section will affect the Participant (adjust using Monitor)
    redoTasks: boolean // If true, the Participant can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Participant is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: Date | undefined
    updatedProgressAt: Date | undefined
    introsSeen: string[]
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }

  // Front end control
  selected = false
  notSyncedProgress: Progress[] = []

  constructor(data?: GameData | Game) {
    this._id = ''
    this.owner = ''
    this.progress = new Map()
    this.status = {
      deleted: false,
      controlActive: false,
      allowedSets: [],
      lastAdjustedByAdmin: undefined,
      updatedProgressAt: undefined,
      redoTasks: false,
      skipTasks: false,
      introsSeen: [],
    }
    this.consent = {
      id: '',
      state: '',
    }
    this.profile = {
      ref: '',
      name: 'unknown',
      colour: taskColours[Math.floor(Math.random() * taskColours.length)],
      thumbnail: '',
      avatar: {
        eyeShape: '0',
        eyeColour: '#000000',
        hairShape: '13',
        hairColour: '#010300',
        skinColour: '#EFD5CE',
        noseShape: '2',
        lipColour: '#000000',
        accessories: '13',
        backgroundColour: '#999999',
      },
    }
    this.sharing = {
      groups: [],
      users: [],
      pinCode: '',
      url: '',
    }
    if (data) {
      this.updateData(data)
      this.updateProgress(data)
    }
  }

  // PRIVATE member to update progress class attribute
  // using 'private' keyword causes problems with TS compile..
  updateProgress(data: GameData | Game): void {
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin ? new Date(data.status.lastAdjustedByAdmin) : undefined
    this.status.updatedProgressAt = data.status.updatedProgressAt ? new Date(data.status.updatedProgressAt) : undefined
    if (data instanceof Game) this.progress = data.progress
    else {
      for (const pKey in data.progress) {
        if (data.progress[pKey]) {
          const d = data.progress[pKey]
          this.progress.set(pKey, new Progress(d))
        }
      }
    }
  }

  getName(data: GameData | Game): string {
    if (data.profile.name) return data.profile.name
    else if (data.profile.ref) return data.profile.ref
    else if (data._id) return data._id.substring(0, 6) + '...'
    else return 'unknown name'
  }

  updateData(data: GameData | Game): void {
    if (data._id) this._id = data._id
    this.owner = data.owner

    this.status.deleted = !!data.status.deleted
    this.status.controlActive = data.status.controlActive
    this.status.allowedSets = data.status.allowedSets
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin ? new Date(data.status.lastAdjustedByAdmin) : undefined
    this.status.redoTasks = data.status.redoTasks
    this.status.skipTasks = data.status.skipTasks
    this.status.introsSeen = data.status.introsSeen || []

    this.sharing.groups = data.sharing.groups
    this.sharing.users = data.sharing.users
    this.sharing.pinCode = data.sharing.pinCode
    this.sharing.url = data.sharing.url

    if (data.profile) {
      this.profile.ref = data.profile.ref
      this.profile.thumbnail = data.profile.thumbnail
      if (data.profile.colour) this.profile.colour = data.profile.colour
      this.profile.name = this.getName(data)
      if (data.profile.avatar) {
        this.profile.avatar = {
          eyeShape: data.profile.avatar.eyeShape || '0',
          eyeColour: data.profile.avatar.eyeColour || '#000000',
          hairShape: data.profile.avatar.hairShape || '13',
          hairColour: data.profile.avatar.hairColour || '#010300',
          skinColour: data.profile.avatar.skinColour || '#EFD5CE',
          noseShape: data.profile.avatar.noseShape || '2',
          lipColour: data.profile.avatar.lipColour || '#000000',
          accessories: data.profile.avatar.accessories || '13',
          backgroundColour: data.profile.avatar.backgroundColour || '#999999',
        }
      }
    }
    this.consent.state = data.consent.state
    this.consent.id = data.consent.id
  }

  get restrictProgress(): boolean {
    return this.status.controlActive
  }
  get allowRedoTasks(): boolean {
    return this.status.redoTasks
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Record<string, unknown> {
    const progress: Record<string, ProgressData> = {}
    const progressArray = Array.from(this.progress.entries())
    progressArray.forEach((p) => {
      const [key, prog] = p
      progress[key] = prog.asPOJO()
    })
    return {
      _id: this._id,
      consent: this.consent,
      profile: this.profile,
      owner: this.owner,
      status: this.status,
      sharing: this.sharing,
      progress,
    }
  }

  // Server response or Monitor update
  update(data: GameData | Game): void {
    this.updateData(data)
    this.updateProgress(data)
  }

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  itemAttempts(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.attempts.length
    } else return 0
  }

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  itemCompletions(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.completions.length
    } else return 0
  }

  // Return the 'completed' boolean status for a given item
  itemIsComplete(itemId: string, parentId: string): boolean {
    const id = itemId + (parentId ? ':' + parentId : '')
    return !!(this.progress.has(id) && this.progress.get(id)?.completed)
  }

  // Return the most recent completion date for the given ID
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  latestCompletionDateFor(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestCompletion
    } else return
  }

  latestCompletionDate(): Date | undefined {
    return Array.from(this.progress.values())
      .sort((a, b) => a.latestCompletion.valueOf() - b.latestCompletion.valueOf())
      .pop()?.latestCompletion
  }

  // Return the most recent attempt date for the given item
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  latestAttemptDate(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestAttempt
    } else return
  }

  // Get or create a Progress, and return it
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  createProgress(itemId: string, parentId: string, description = ''): Progress {
    let p: Progress
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      p = this.progress.get(id) as Progress
    } else {
      p = new Progress({ itemId, parentId, description })
      this.progress.set(id, p)
      this.status.updatedProgressAt = new Date()
    }
    return p
  }

  // Get a Progress item and mark as completed
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  completeProgress(itemId: string, parentId: string, description = ''): number {
    const p = this.createProgress(itemId, parentId, description)
    this.status.updatedProgressAt = new Date()
    const completed = p.complete()
    this.notSyncedProgress.push(p)
    return completed
  }
}

export interface UserData {
  _id: string
  status: {
    lastLogin: string
    browserLanguage: string
    currentProjectId: string
    currentActivityId: string
    canEditPlayers: boolean // User can make changes to Players (add, edit, remove)
    canEditGames: boolean // User can make changes to Games
  }
  profile: {
    username: string
    fullName: string
    email: string
    mobil: string
    language: string
    role: string
    ageGroup: string
    languagesSpoken: string[]
  }
  // DB IDs of related Models
  groups: GroupData[]
}
export class User {
  _id: string
  status: {
    lastLogin: Date
    browserLanguage: string
    currentProjectId: string
    currentActivityId: string
    canEditPlayers: boolean // User can make their own Players
    canEditGames: boolean
  }
  profile: {
    username: string
    fullName: string
    password: string
    email: string
    mobil: string
    language: LanguageNames // Use a two letter code as the browser does
    role: USER_ROLE
    ageGroup: string
    languagesSpoken: string[]
  }
  groups: Group[] // Populated during User request

  constructor(data?: UserData | User) {
    this._id = ''
    this.status = {
      lastLogin: new Date(),
      currentProjectId: '',
      currentActivityId: '',
      browserLanguage: 'no',
      canEditPlayers: false,
      canEditGames: false,
    }
    this.profile = {
      username: 'initial user',
      fullName: 'initial user',
      password: '',
      email: '',
      mobil: '',
      language: LanguageNames.system, // Use a two letter code as the browser does
      role: USER_ROLE.user,
      ageGroup: '',
      languagesSpoken: [],
    }
    this.groups = []

    if (data) this.update(data)
  }

  public update(data: UserData | User): void {
    this._id = data._id
    this.profile = {
      username: data.profile.username,
      fullName: data.profile.fullName,
      password: '',
      mobil: data.profile.mobil,
      email: data.profile.email,
      language: (data.profile.language as LanguageNames) || LanguageNames.system,
      role: data.profile.role as USER_ROLE,
      ageGroup: data.profile.ageGroup,
      languagesSpoken: data.profile.languagesSpoken || [],
    }
    this.status = {
      lastLogin: new Date(data.status.lastLogin),
      browserLanguage: data.status.browserLanguage,
      currentProjectId: data.status.currentProjectId,
      currentActivityId: data.status.currentActivityId,
      canEditPlayers: data.status.canEditPlayers,
      canEditGames: data.status.canEditGames,
    }
    data.groups.forEach((group: Group | GroupData) => {
      const g = this.groups.find((gr) => gr._id === group._id)
      if (g) g.update(group)
      else this.groups.push(new Group(group))
    })
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO() {
    const groups = this.groups.map((g) => g.asPOJO())
    return { ...this, groups }
  }
}

// -------------- Other UI Types ---------------

export interface AvatarLayout {
  eyeShape: string
  eyeColour: string
  hairShape: string
  hairColour: string
  skinColour: string
  noseShape: string
  lipColour: string
  accessories: string
  backgroundColour: string
}
// ---------------  API -----------------

enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

enum XHR_CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
}

// Augment the Error class with message and status
class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

interface APIRequestPayload {
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | Game | GameData | TaskTracking | SessionTracking | FormData
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
}

interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
}

export type { APIRequestPayload, XHRPayload }
export { XHR_REQUEST_TYPE, HttpException, XHR_CONTENT_TYPE }
