/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

const theHost = import.meta.env.VITE_SERVER_HOST as string
const thePort = import.meta.env.VITE_SERVER_PORT as string
let baseUrl = `${theHost}`
// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (import.meta.env.DEV && theHost && (theHost.includes('localhost') || theHost.includes('10.0.0.') || theHost.includes('192.168.'))) {
  baseUrl = `${theHost}:${thePort}`
}
const cmsUrl: string = (import.meta.env.VITE_CMS_HOST as string) || ''
const assetRoot: string = import.meta.env.VITE_CMS_HOST + '/api/assets' || ''
const cmsTokenUrl = import.meta.env.VITE_SQUIDEX_TOKEN_URL || ''
const cmsClientId = import.meta.env.VITE_SQUIDEX_CLIENT_ID || ''
const cmsClientSecret = import.meta.env.VITE_SQUIDEX_CLIENT_SECRET || ''
const userRolesString = (import.meta.env.VITE_USER_ROLES as string) || ''
const userRoles = userRolesString.split(',')
const consentStatesString = (import.meta.env.VITE_CONSENT_STATES as string) || ''
const consentStates = consentStatesString.split(',')
const projectNamesString = (import.meta.env.VITE_PROJECT_NAMES as string) || ''
const projectNames = projectNamesString.split(',') || []
const projectTypesString = (import.meta.env.VITE_PROJECT_TYPES as string) || ''
const projectTypes = projectTypesString.split(',') || []
let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

const useLocalAssets = !!window['cordova']
const localAssetUrl = 'assets/localCMSAssets' // No trailing slash in path!
const SESSION_TYPES = 'Test, Introduction'
const SKIP_SESSION_PASSWORD = 'Sesameåpne'

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = (import.meta.env.VITE_VERSION as string) || ''

projectTypes.push('none')
projectNames.push('none')

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

enum CordovaPathName {
  root = '.',
  users = 'users',
  players = 'players',
  games = 'games',
}
enum MediaType {
  audio = 'audio',
  video = 'video',
}
const cordovaConstants = {
  videoRecordingMaxDuration: 300000, // 5 minutes
  audioRecordingMaxDuration: 300000, // 5 minutes
}

const taskColours = ['#A861A6', '#F84016', '#009F4B', '#A9BD50', '#FFDE01']

// User roles determine what is displayed on screen UI
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs',
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (!userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) || userRoles.length !== t.length) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

enum ACTIVITY_IDS {
  'Broad Release' = '74b94f1c-4e15-4a9c-b646-9acb587a2d23',
  'Broad Release - testing' = '3431ff54-6a6d-4104-a216-2dc7e64bafb0',
  '2023 Andrea (Norway)' = 'e9c12156-5065-42e7-b89c-9dcca5cf0f78',
  '2023 Andrea CONTROL (Norway)' = '1ebccbd8-7c1b-405d-ab66-d17b66c7de2c',
  'RCT 2018 (Norway)' = 'eedb60a3-2e21-49fd-a4c5-dfc92ba02aaf',
  'RCT 2022 (Sweden)' = '2f55c6e5-7ead-46f7-80e2-e61e3e78567e',
  'Demo' = '427adfa8-d5b3-4de5-b0ed-471921d08190',
  'EngageLab' = '17da718d-5010-4bde-bdde-f153f01d9dfc',
}
const ACTIVITY_NAMES_BY_ID: Record<string, string> = {}
Object.entries(ACTIVITY_IDS).forEach((entry) => (ACTIVITY_NAMES_BY_ID[entry[1]] = entry[0]))

enum MORFOLOGICAL_INTROS {
  'None' = 0,
  'Sammensatt' = 1,
  'Avledninger' = 2,
  'Komplekse' = 3,
}

enum LanguageCodes {
  iv = 'iv', // Invariant code
  nn = 'nn',
  no = 'no',
  // en = 'en', // english is not currently supported
  sv = 'sv',
}
// The keys used for i18n selection (except for 'system') should correlate to LanguageCodes (used for Squidex GraphQL calls)
enum LanguageNames {
  system = 'system', // System default
  //ar = 'العربية', // Arabic
  //bs = 'bosanski', // Bosnian
  //nb_NO = 'Norsk Bokmål',
  nn = 'Nynorsk',
  no = 'Norsk',
  sv = 'Svensk',
  /*  nn_NO = 'Norsk Nynorsk', */
  // en = 'English',
  //ku = 'Soranî', // Kurdish
  //pl = 'Polski', // Polish
  //so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  //ta = 'தமிழ்', // Tamil
  //tr = 'Türkçe',
  //ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

interface LF {
  [key: string]: Array<LanguageCodes>
}
// Note that every language must have a fallback defined!
const LanguageFallbacks: LF = {
  en: [LanguageCodes.no],
  nn: [LanguageCodes.no],
  no: [],
  sv: [LanguageCodes.no],
}

const SpeechSounds = {
  narrator: {
    N01: 'computer_narrator/01N.mp3',
    N02: 'computer_narrator/02N.mp3',
    N03: 'computer_narrator/03N.mp3',
    N04: 'computer_narrator/04N.mp3',
    N06: 'computer_narrator/06N.mp3',
    N11: 'computer_narrator/11N.mp3',
    N29: 'computer_narrator/29N.mp3',
    N31: 'computer_narrator/31N.mp3',
    N34: 'computer_narrator/34N.mp3',
    N35: 'computer_narrator/35N.mp3',
    N37: 'computer_narrator/37N.mp3',
    N39: 'computer_narrator/39N.mp3',
    N45: 'computer_narrator/45N.mp3',
    N56: 'computer_narrator/56N.mp3',
    N64: 'computer_narrator/64N.mp3',
  },
  computer: {
    C05: 'computer_narrator/05C.mp3',
    C07: 'computer_narrator/07C.mp3',
    C08: 'computer_narrator/08C.mp3',
    C10: 'computer_narrator/10C.mp3',
    C12: 'computer_narrator/12C.mp3',
    C13: 'computer_narrator/13C.mp3',
    C14: 'computer_narrator/14C.mp3',
    C15: 'computer_narrator/15C.mp3',
    C16: 'computer_narrator/16C.mp3',
    C17: 'computer_narrator/17C.mp3',
    C18: 'computer_narrator/18C.mp3',
    C19: 'computer_narrator/19C.mp3',
    C20: 'computer_narrator/20C.mp3',
    C21: 'computer_narrator/21C.mp3',
    C22: 'computer_narrator/22C.mp3',
    C23: 'computer_narrator/23C.mp3',
    C24: 'computer_narrator/24C.mp3',
    C25: 'computer_narrator/25C.mp3',
    C26: 'computer_narrator/26C.mp3',
    C27: 'computer_narrator/27C.mp3',
    C28: 'computer_narrator/28C.mp3',
    C30: 'computer_narrator/30C.mp3',
    C32: 'computer_narrator/32C.mp3',
    C36: 'computer_narrator/36C.mp3',
    C38: 'computer_narrator/38C.mp3',
    C40: 'computer_narrator/40C.mp3',
    C41: 'computer_narrator/41C.mp3',
    C41_44: 'computer_narrator/41_44C.mp3',
    C42: 'computer_narrator/42C.mp3',
    C43: 'computer_narrator/43C.mp3',
    C44: 'computer_narrator/44C.mp3',
    C46: 'computer_narrator/46C.mp3',
    C47: 'computer_narrator/47C.mp3',
    C48: 'computer_narrator/48C.mp3',
    C49: 'computer_narrator/49C.mp3',
    C50: 'computer_narrator/50C.mp3',
    C51: 'computer_narrator/51C.mp3',
    C52: 'computer_narrator/52C.mp3',
    C53: 'computer_narrator/53C.mp3',
    C54: 'computer_narrator/54C.mp3',
    C55: 'computer_narrator/55C.mp3',
    C56: 'computer_narrator/56C.mp3',
    C57: 'computer_narrator/57C.mp3',
    C58: 'computer_narrator/58C.mp3',
    C59: 'computer_narrator/59C.mp3',
    C60: 'computer_narrator/60C.mp3',
    C61: 'computer_narrator/61C.mp3',
    C62: 'computer_narrator/62C.mp3',
    C63: 'computer_narrator/63C.mp3',
    C65: 'computer_narrator/65C.mp3',
    C66: 'computer_narrator/66C.mp3',

    C69: 'computer_narrator/69C.mp3',
    C70: 'computer_narrator/70C.mp3',
  },
  child: {
    A09: 'computer_narrator/09A.mp3',
  },
  morfological: {
    MI33: 'computer_narrator/33MI-lavt.mp3',
    MI33_high: 'computer_narrator/33MI_høyt.mp3',
    MI33_best: 'computer_narrator/33MIbest.mp3',
    MI33_org: 'computer_narrator/33MIorg.mp3',
    MI33_orgtest: 'computer_narrator/33MIorgtest2.mp3',
    MI33_test: 'computer_narrator/33MItest.mp3',
  },
  instructions: {
    tasks: {
      T3: 'task_instructions/tasks/type3.mp3',
      T4: 'task_instructions/tasks/type4.mp3',
      T6: 'task_instructions/tasks/type6.mp3',
      T7: 'task_instructions/tasks/type7.mp3',
      T9: 'task_instructions/tasks/type9.mp3',
      T10: 'task_instructions/tasks/type10.mp3',
      T10C: 'task_instructions/tasks/type10_compound.mp3',
      T12C: 'task_instructions/tasks/type12_compound.mp3',
      T12E1: 'task_instructions/tasks/type12_ending_alt1.mp3',
      T12E2: 'task_instructions/tasks/type12_ending_alt2.mp3',
    },
    warmups: {
      T1: 'task_instructions/warmups/type1.mp3',
      T2: 'task_instructions/warmups/type2.mp3',
      T3: 'task_instructions/warmups/type3.mp3',
      T4: 'task_instructions/warmups/type4.mp3',
      T5: 'task_instructions/warmups/type5.mp3',
      T6: 'task_instructions/warmups/type6.mp3',
      T7: 'task_instructions/warmups/type7.mp3',
      T8: 'task_instructions/warmups/type8.mp3',
      T9: 'task_instructions/warmups/type9.mp3',
      T10: 'task_instructions/warmups/type10.mp3',
      T11: 'task_instructions/warmups/type11.mp3',
    },
  },
}

enum QUESTION_TYPES {
  question = 'question',
  mastery = 'mastery',
  picturebook = 'picturebook',
}

enum TASK_TYPES {
  Tasktype1 = 'Tasktype1',
  Tasktype2 = 'Tasktype2',
  Tasktype3 = 'Tasktype3',
  Tasktype4 = 'Tasktype4',
  Tasktype5 = 'Tasktype5',
  Tasktype6 = 'Tasktype6',
  Tasktype7 = 'Tasktype7',
  Tasktype8 = 'Tasktype8',
  Tasktype9 = 'Tasktype9',
  Tasktype10 = 'Tasktype10',
  Tasktype11 = 'Tasktype11',
  Tasktype12 = 'Tasktype12',
  Tasktype13 = 'Tasktype13',
}

enum CMS_TASK_NAMES {
  Tasktype1 = 'type1',
  Tasktype2 = 'type2',
  Tasktype3 = 'type3',
  Tasktype4 = 'type4',
  Tasktype5 = 'type5',
  Tasktype6 = 'type6',
  Tasktype7 = 'type7',
  Tasktype8 = 'type8',
  Tasktype9 = 'type9',
  Tasktype10 = 'type10',
  Tasktype11 = 'type11',
  Tasktype12 = 'type12',
  Tasktype13 = 'type13',
}
enum WordIndexName {
  None = 'None',
  Word1 = 'Word 1',
  Word2 = 'Word 2',
  Word3 = 'Word 3',
  Word4 = 'Word 4',
  Word5 = 'Word 5',
  Word6 = 'Word 6',
  Preselected = 'Preselected',
}
/* --------- State Variables ---------  */

enum ViewState {
  None = 'None',
  Login = 'Login',
  Loggedin = 'Loggedin',
  Ship = 'Ship',
  Map = 'Map',
  Delay = 'Delay',
  Tasks = 'Tasks',
}
enum LoginMode {
  AppStarted = 'AppStarted',
  LoggedOut = 'LoggedOut',
  LoggedIn = 'LoggedIn',
}

enum ShipMode {
  SessionLocked = 'SessionLocked',
  SessionUnlocked = 'SessionUnlocked',
  SessionEnding = 'SessionEnding',
  SessionCompleted = 'SessionCompleted',
  ReturnedFromInteractiveMap = 'ReturnedFromInteractiveMap',
}
enum TaskMode {
  Tests = 'Tests',
  Warmups = 'Warmups',
  Sample = 'Sample',
}
enum DelayMode {
  None = 'None',
  AwaitingDownload = 'AwaitingDownload',
  NoActivitiesFound = 'NoActivitiesFound',
  NoSessionsFound = 'NoSessionsFound',
  DemoComplete = 'DemoComplete',
  WarmupsStarting = 'WarmupsStarting',
  WarmupsFinished = 'WarmupsFinished',
  NoWarmups = 'NoWarmups',
  AccessDenied = 'AccessDenied',
}
enum SceneMode {
  Ready = 'Ready',
  InProgress = 'InProgress',
  Finished = 'Finished',
}
enum SceneType {
  None = 'None',
  GeneralScene = 'GeneralScene',
  EpisodeInScene = 'EpisodeInScene',
  EpisodeOutScene = 'EpisodeOutScene',
  SessionInScene = 'SessionInScene',
  ConsolidationInScene = 'ConsolidationInScene',
  MorfologicalScene = 'MorfologicalScene',
}

enum SpeakerType {
  narrator,
  computer,
}

enum QUESTION_MODE {
  warmup = 'warmup',
  test = 'test',
}

enum ActivityPhase {
  None = 'None',
  Demo = 'Demo',
  RCT = 'RCT',
  Regular = 'Regular',
  BroadRelease = 'BroadRelease',
  BroadReleaseTesting = 'BroadReleaseTesting',
  Lab = 'Lab',
}

enum EpisodePhase {
  First = 1, // Training on home planet
  Second, // Earth
  Third, // Post Test
  Fourth, // Post Test
  Fifth, // Post Test
  None,
}

enum SessionPhase {
  First, // First session - 1
  Penultimate, // Second last (final 'normal' session) - Session 39
  Last, // Consolidation 8 (last consolidation (8), final session - 40)
  FirstAndLast, // Only one session in the set (e.g. Episode 1, Session 1)

  One, // Sessions 2 - 5 inclusive (includes consolidation 1)
  Two, // Sessions 6 - 10 inclusive (includes consolidation 2)
  Three, // Sessions 11 - 15 inclusive (includes consolidation 3)
  Four, // Sessions 16 - 20 inclusive (includes consolidation 4)
  Five, // Sessions 21 - 25 inclusive (includes consolidation 5)
  Six, // Sessions 26 - 30 inclusive (includes consolidation 6)
  Seven, // Sessions 31 - 35 inclusive (includes consolidation 7)
  Eight, // Sessions 36 - 38 inclusive

  Unknown, // Session unknown based on calculations
}

export interface StateVariables {
  viewState?: ViewState
  loginMode?: LoginMode
  shipMode?: ShipMode
  taskMode?: TaskMode
  delayMode?: DelayMode
  sceneMode?: SceneMode
  sceneType?: SceneType
}

const EpisodeScenes: {
  [id: number]: { intros: string[]; outros: string[] }
} = {
  // 0: Reserved for Demo
  0: {
    intros: ['episodes/1/intros/4/'],
    //intros: [],
    outros: ['episodes/5/outros/1/'],
  },
  // 1: Pre-Test scenes
  1: {
    intros: ['episodes/1/intros/1/', 'episodes/1/intros/2/', 'episodes/1/intros/3/', 'episodes/1/intros/4/'],
    // intros: ['episodes/1/intros/4/'],
    // intros: [],
    // intros: ['episodes/1/intros/1/','episodes/1/intros/2/','episodes/1/intros/3/','episodes/1/intros/4/'],
    outros: ['episodes/1/outros/1/', 'episodes/1/outros/2/'],
    //outros: []
  },
  // 2: Main Episode scenes
  2: {
    // intros: [],
    // intros: ['episodes/2/intros/3/'],
    intros: [
      'episodes/2/intros/1/',
      'episodes/2/intros/2/',
      'episodes/2/intros/3/',
      'episodes/2/intros/4/',
      'episodes/2/intros/5/',
      'episodes/2/intros/6/',
      'general/1/',
    ],
    outros: ['episodes/2/outros/4/'],
    //outros: []
  },
  // 3-5: Post-Test Scenes
  3: {
    intros: ['episodes/3/intros/1/'],
    outros: ['episodes/3/outros/1/'],
  },
  4: {
    intros: ['episodes/4/intros/1/'],
    outros: ['episodes/4/outros/1/'],
  },
  5: {
    intros: ['episodes/5/intros/1/'],
    outros: ['episodes/5/outros/1/'],
  },
}

const categoryTypes = [
  { value: 0, label: 'Real word' },
  { value: 1, label: 'Trained item' },
  { value: 2, label: 'Non-word' },
]

enum CategoryType {
  RealWord = 'Real word',
  TrainedItem = 'Trained item',
  NonWord = 'Non-word',
}

const stemTypes = [
  { value: 1, label: 'Stem' },
  { value: 2, label: 'Morphed Stem' },
]

enum StemType {
  Stem = 'Stem',
  MorphedStem = 'Morphed Stem',
  None = 'None',
}

export {
  deviceType,
  baseUrl,
  cmsUrl,
  assetRoot,
  userRoles,
  USER_ROLE,
  SESSION_TYPES,
  ACTIVITY_IDS,
  ACTIVITY_NAMES_BY_ID,
  consentStates,
  cmsTokenUrl,
  cmsClientId,
  cmsClientSecret,
  cordovaConstants,
  appVersion,
  taskColours,
  LanguageCodes,
  LanguageNames,
  LanguageFallbacks,
  useLocalAssets,
  localAssetUrl,
  SKIP_SESSION_PASSWORD,
  MORFOLOGICAL_INTROS,
  EpisodeScenes,
  SpeechSounds,
  stemTypes,
  StemType,
  categoryTypes,
  CategoryType,
  SessionPhase,
  EpisodePhase,
  QUESTION_MODE,
  QUESTION_TYPES,
  ActivityPhase,
  SpeakerType,
  SceneType,
  SceneMode,
  DelayMode,
  TaskMode,
  ShipMode,
  LoginMode,
  ViewState,
  CMS_TASK_NAMES,
  TASK_TYPES,
  WordIndexName,
  CordovaPathName,
  MediaType,
}
