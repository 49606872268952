<template>
  <div class="flex flex-col cursor-default relative h-screen">
    <!-- Table Content -->
    <div class="w-full">
      <div class="w-full flex justify-center relative">
        <div>Trackings</div>
      </div>

      <div class="w-full flex justify-left items-center relative">
        <input
          id="wardtable-filter-text-box"
          v-model="trackingTableFilterText"
          type="text"
          placeholder="Filter..."
          class="border-2"
          @input="onTableSearchTextChanged"
        />
      </div>
      <div class="w-full">
        <AgGridVue
          style="width: 100%; height: 75vh"
          class="ag-theme-alpine"
          :grid-options="gridOptions"
          :column-defs="columnDefs"
          :default-col-def="{
            sortable: true,
            enableRowGroup: true,
            enableValue: true,
          }"
          :row-data="rowData"
          :row-selection="'single'"
          @cell-clicked="cellClicked"
          @first-data-rendered="dataReady"
        >
        </AgGridVue>
        <div v-if="selectedTracking && selectedTracking.type === TRACKING_TYPE.task" class="w-full flex flex-row m-4">
          <p class="ml-4">Answer Details: {{ (selectedTracking as TaskTracking).answerDetailsAsString }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref, Ref, onMounted, computed } from 'vue'
  // import router from '../../router'
  // import { useUserStore } from '../../store/useUserStore'
  import useGameStore from '../../store/useGameStore'
  import useAppStore from '@/store/useAppStore'
  import 'ag-grid-community/styles/ag-grid.css'
  import 'ag-grid-community/styles/ag-theme-alpine.css'
  import { AgGridVue } from 'ag-grid-vue3'
  import { GridOptions, ICellEditorParams } from 'ag-grid-enterprise'
  import { CellEvent } from 'ag-grid-community'
  // Class
  import { SessionTracking, TRACKING_TYPE, TaskTracking, Tracking } from '@/models/main'

  // const { getters: userGetters } = useUserStore()
  const { actions: gameActions, getters: gameGetters } = useGameStore()
  const selectedTracking: Ref<TaskTracking | SessionTracking | undefined> = ref(undefined)
  const gridOptions: GridOptions = {
    suppressScrollOnNewData: true,
    stopEditingWhenCellsLoseFocus: true,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agFilteredRowCountComponent',
          align: 'center',
        },
      ],
    },
    sideBar: true,
    enableCharts: true,
    enableRangeSelection: true,
  }
  // Store Tables
  const trackingTableFilterText = ref('')
  const trackingTable = gameGetters.allTrackings
  //const rowCount = computed(() => trackingTable.value.length)

  // Translate the headerNames
  let columnDefs = Tracking.columnDefs().map((col) => {
    col.filter = true

    switch (col.field) {
      case 'oid':
        col.valueGetter = (params: ICellEditorParams) => {
          return params.data?.oid || ''
        }
        break
      /* case 'details':
        col.valueGetter = (params: ICellEditorParams) => {
          return params.data?.details
        }
        break
 */
      default:
        break
    }
    return col
  })

  const rowData = computed(() => {
    const ts = trackingTable.value.map((t) => t.asTableData)
    return ts
  })

  onMounted(() => {
    useAppStore().actions.setLoading(true)
    // Get table data
    gameActions.getAllTrackingDetails().catch((error) => console.log(error))
  })

  //Table Filter
  // Filter all columns based on the text
  // If one of the columns contain the text, the table will show them
  const onTableSearchTextChanged = () => {
    gridOptions.api?.setQuickFilter(trackingTableFilterText.value)
    // rowCount.value = gridOptions.api?.getDisplayedRowCount() || 0
  }
  const dataReady = () => {
    useAppStore().actions.setLoading(false)
  }
  // Handle Table Events
  const cellClicked = (event: CellEvent) => {
    if (event.data === undefined) return
    const tracking = trackingTable.value.find((t) => t.oid == event.data.oid)
    selectedTracking.value = tracking
  }
</script>
