/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type4/ScannerType4@2x.png" />

    <div class="scanner-content flex flex-col justify-center">
      <div class="flex flex-row justify-around scanner-padding">
        <div class="flex flex-col justify-start">
          <div class="flex flex-row justify-start" style="position: relative; margin-top: 30px">
            <span @click="clickFirstSentence()">{{ task.sentence1text }}</span>
          </div>

          <div class="flex flex-row justify-start" style="position: relative; margin-top: 30px">
            <div class="flex flex-col justify-center">
              <span @click="clickSecondSentenceBefore()">{{ task.sentence2TextBefore }}&nbsp;</span>
            </div>
            <transition mode="out-in" name="fade">
              <div v-if="showPlaceholders" class="flex flex-col justify-center">
                <span class="wordHighlight" @click="clickSecondSentenceBefore()">{{ task.sentence2TrailingWord }}</span>
              </div>
            </transition>

            <!-- Drop boxes -->

            <div
              v-if="!droppedItem1 && showPlaceholders && !box1disabled"
              id="word-box-1"
              class="wordDropBox drop-target"
              @start="onStart"
              @stop="onDrop"
              @touchstart="preventDropOnTap"
            ></div>
            <transition mode="out-in" name="fade">
              <div class="flex flex-col justify-center">
                <span
                  v-if="droppedItem1 && showPlaceholders"
                  class="wordHighlight"
                  :class="{ capitaliseDroppedItem: capitaliseDroppedItem, yellowWordText: droppedItem1.id !== 'drag-word-preselected' }"
                  @click.stop="playWordAudio(droppedItem1)"
                  >{{ droppedItem1.text || '' }}</span
                >
              </div>
            </transition>

            <div
              v-if="!droppedItem2 && showPlaceholders && !box2disabled"
              id="word-box-2"
              class="wordDropBox drop-target"
              @start="onStart"
              @stop="onDrop"
              @touchstart="preventDropOnTap"
            ></div>
            <transition mode="out-in" name="fade">
              <div class="flex flex-col justify-center">
                <span
                  v-if="droppedItem2 && showPlaceholders"
                  class="wordHighlight"
                  :class="{ capitaliseDroppedItem: capitaliseDroppedItem, yellowWordText: droppedItem2.id !== 'drag-word-preselected' }"
                  @click.stop="playWordAudio(droppedItem2)"
                  >{{ droppedItem2.text || '' }}&nbsp;</span
                >
              </div>
            </transition>

            <!-- Combined word -->
            <transition mode="out-in" name="fade">
              <div class="flex flex-col justify-center">
                <span v-if="combinedWord && showCombinedWord" class="wordHighlight" :class="{ yellowWordText: !mergeCombinedWord }"
                  >{{ combinedWord }}&nbsp;</span
                >
              </div>
            </transition>

            <transition mode="out-in" name="fade">
              <span v-if="showPlaceholders" class="wordHighlight" @click="clickSecondSentenceAfter()"
                >{{ task.sentence2SecondTrailingWord }}&nbsp;</span
              >
            </transition>
            <div class="flex flex-col justify-center">
              <span @click="clickSecondSentenceAfter()">{{ task.sentence2TextAfter }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="word-source-box" class="flex flex-row justify-around fadeInOut mt-4" :style="{ opacity: opacityWords }">
      <!-- Draggable Items -->
      <template v-for="word in words" :key="word.id">
        <Draggable
          id="word-index-{{word.id}}"
          :position="word.position"
          :class="`${activeDrags ? 'pointer-events-none touch-none' : ''}`"
          @pointerdown="onPointerDown"
          @start="(e: any) => onStart(e)"
          @stop="(e: any) => onDrop(e, word)"
        >
          <span class="taskNoUserSelect borderedWordBox" @click.stop="playWordAudio(word)">{{ word.text }}</span>
        </Draggable>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { Draggable, ControlPosition, DraggableEvent } from '@braks/revue-draggable'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES } from '@/constants'
  import { Tasktype10, Type10CorrectType } from '@/models/tasktypes/Tasktype10'

  type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

  interface Word {
    index: number
    visible: boolean
    enabled: boolean
    draggable: boolean
    opacity: number
    element?: HTMLElement
    id: string
    position: ControlPosition

    audio: MP3Audio | WebAudio | undefined
    text: string
    correct: Type10CorrectType
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype10> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype10,
    correct: 0,
    of: 1,
    answerOptions: task.value.words.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const opacityWords = ref(0)
  const activeDrags = ref(0)

  let attempts = 0
  let attemptLimit = 0
  let cancelDropEvent = false

  // Models for words
  let words: Ref<Word[]> = ref([])

  const droppedItem1: Ref<Word | undefined> = ref(undefined)
  const droppedItem2: Ref<Word | undefined> = ref(undefined)
  const combinedWord = ref('')

  let finishDone = false
  let taskCompleted = false
  let audioPlaying = false

  const capitaliseDroppedItem = ref(false)
  const showPlaceholders = ref(true)
  const showCombinedWord = ref(false)
  const mergeCombinedWord = ref(false)
  const box1disabled = ref(false)
  const box2disabled = ref(false)

  const audioSentenceQueue: (string | MP3Audio | WebAudio)[] = []
  let queuedAudio: MP3Audio | WebAudio | undefined = undefined

  let sentence1audio: MP3Audio | WebAudio
  let sentence2AudioBefore: MP3Audio | WebAudio
  let sentence2AudioAfter: MP3Audio | WebAudio | undefined

  // A 'dropbox' element should incude the class 'drop-target'
  const getDropboxElement = (e: DraggableEvent): Element | undefined => {
    let x = 0
    let y = 0
    if (!e.event) return
    if (e.event.type.includes('touch')) {
      const te = e.event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e.event as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    return el && el.classList.contains('drop-target') ? el : undefined
  }

  const onStart = (e: DraggableEvent) => {
    if (!e.event) return
    e.event.preventDefault()
    activeDrags.value++
  }

  const onPointerDown = (e: PointerEvent) => {
    const el = e.target as HTMLDivElement
    if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
  }

  const setupTask = async () => {
    finishDone = false
    audioSentenceQueue.length = 0
    droppedItem1.value = undefined
    droppedItem2.value = undefined
    showPlaceholders.value = true
    showCombinedWord.value = false
    mergeCombinedWord.value = false

    audioPlaying = false
    taskCompleted = false
    combinedWord.value = ''
    if (typeof task === 'undefined' || task === null) {
      alert('A Type 10 task does not exist - check your Session layout in the CMS')
      return
    }
    const preselectedText = task.value.preselectedText.trim()
    const preselectedCorrect = task.value.preselectedCorrect
    let correctBox1 = Type10CorrectType.None
    let correctBox2 = Type10CorrectType.None

    if (preselectedText && preselectedCorrect !== Type10CorrectType.None) {
      const container = preselectedCorrect === Type10CorrectType.First ? droppedItem1 : droppedItem2
      const audio = await createSound(task.value.preselectedAudio)
      const preselectedWord: Word = {
        enabled: true,
        visible: true,
        draggable: true,
        opacity: 1,
        audio,
        text: preselectedText,
        correct: preselectedCorrect,
        index: -1,
        id: 'drag-word-preselected',
        position: { x: 0, y: 0 },
      }
      container.value = preselectedWord
      if (preselectedCorrect === Type10CorrectType.First) correctBox1 = preselectedCorrect
      else if (preselectedCorrect === Type10CorrectType.Second) correctBox2 = preselectedCorrect
    }

    // Capitalise first word if no prior sentence
    if (!task.value.sentence2TextBefore) capitaliseDroppedItem.value = true

    let tempWords: Word[] = []
    for (let i = 0; i < task.value.words.length; i++) {
      const w = task.value.words[i]
      const audio = await createSound(w.audio)
      if (w.text) {
        const word: Word = {
          enabled: true,
          visible: true,
          draggable: true,
          opacity: 1,
          audio,
          text: w.text,
          correct: w.correct,
          index: i,
          id: `drag-word-${i}`,
          position: { x: 0, y: 0 },
        }
        tempWords.push(word)
      }
      if (w.correct === Type10CorrectType.First) {
        correctBox1 = w.correct
        attemptLimit++
      } else if (w.correct === Type10CorrectType.Second) {
        correctBox2 = w.correct
        attemptLimit++
      }
    }

    // If there is no preselected item and less than two other correct items, we need to disable one (or both!) drop boxes
    if (correctBox1 === Type10CorrectType.None) box1disabled.value = true
    if (correctBox2 === Type10CorrectType.None) box2disabled.value = true

    sentence1audio = await createSound(task.value.sentence1audio)
    sentence2AudioBefore = await createSound(task.value.sentence2AudioBefore)
    // Note: If this value is empty string, a sound decoding error will be printed
    sentence2AudioAfter = task.value.sentence2AudioAfter ? await createSound(task.value.sentence2AudioAfter) : undefined

    // Tracking
    stateActions.progress.progressShow({ stars: tracking.details.of })
    words.value = shuffleItems(tempWords)
    introduceChallenge()
  }

  function preventDropOnTap() {
    cancelDropEvent = true
    setTimeout(() => {
      cancelDropEvent = false
    }, 1000)
  }

  function playWordAudio(word?: Word) {
    if (word && !audioPlaying) {
      audioPlaying = true
      if (word.audio) {
        word.audio.onended = async () => {
          audioPlaying = false
          if (word === droppedItem2.value && task.value.sentence2AudioAfter) {
            await queueAudio(task.value.sentence2AudioAfter, 500)
          }
        }
        word.audio.playWhenReady()
      }
    }
    tracking.details.use_audio_content_items++
  }

  async function clickFirstSentence() {
    if (task.value.sentence1audio) {
      await queueAudio(sentence1audio)
      tracking.details.use_audio_content_items++
    }
  }
  async function clickSecondSentenceBefore() {
    if (task.value.sentence2AudioBefore) {
      await queueAudio(sentence2AudioBefore)
      tracking.details.use_audio_content_items++
    }
  }
  async function clickSecondSentenceAfter() {
    if (task.value.sentence2AudioAfter) {
      if (droppedItem2.value) {
        await queueAudio(droppedItem2.value.audio)
      }
      await queueAudio(sentence2AudioAfter)
      tracking.details.use_audio_content_items++
    }
  }
  async function playCompleteSentence() {
    if (task.value.sentence2AudioComplete) {
      if (queuedAudio) queuedAudio.pause()
      queuedAudio = await createSound(task.value.sentence2AudioComplete)
      queuedAudio.onerror = (error) => {
        console.log(error.toString())
        audioPlaying = false
        checkForWarmup()
      }
      audioPlaying = true
      queuedAudio.playWhenReady()
      queuedAudio.onended = () => {
        audioPlaying = false
        // Check the allowed number of attempts here - cancel the previous audio if ending
        // If number of attempts are limited to correct answers we will finish the task
        checkForWarmup()
      }
    } else {
      checkForWarmup()
    }
  }
  async function queueAudio(audio: string | MP3Audio | WebAudio | undefined, delay = 0) {
    const qaCallback = async () => {
      if (queuedAudio) queuedAudio.removeEventListener('ended', qaCallback)
      audioPlaying = false
      const nextAudio = audioSentenceQueue.pop()
      if (nextAudio) await queueAudio(nextAudio, 500)
    }
    if (audioSentenceQueue.length <= 2 && !finishDone && audio) {
      // Don't allow too many sounds to queue
      if (audioPlaying) audioSentenceQueue.push(audio)
      else {
        queuedAudio = typeof audio === 'string' ? await createSound(audio) : audio
        queuedAudio.addEventListener('ended', qaCallback)
        audioPlaying = true
        setTimeout(() => {
          if (queuedAudio) queuedAudio.playWhenReady()
        }, delay)
      }
    }
  }

  function removeAllWords() {
    if (droppedItem1.value && droppedItem1.value.id !== 'drag-word-preselected') {
      words.value.push(droppedItem1.value)
      droppedItem1.value = undefined
    }
    if (droppedItem2.value && droppedItem2.value.id !== 'drag-word-preselected') {
      words.value.push(droppedItem2.value)
      droppedItem2.value = undefined
    }
    words.value = shuffleItems(words.value)
    //draggingNow = false
  }

  const onDrop = (e: DraggableDIVElementEvent, theWord?: Word) => {
    activeDrags.value = 0
    const el = getDropboxElement(e)
    if (theWord && el) {
      attempts++
      const boxIndex = getBoxIndex(el)
      if (cancelDropEvent || boxIndex === 0) {
        theWord.position = { x: 0, y: 0 }
        return
      } else {
        const container = boxIndex === 1 ? droppedItem1 : droppedItem2
        container.value = theWord
        const i = words.value.indexOf(theWord)
        words.value.splice(i, 1)
      }
      const checkWordCombination = (): string => {
        let status = 'incorrect'

        let a = droppedItem1.value ? droppedItem1.value.correct : undefined
        let b = droppedItem2.value ? droppedItem2.value.correct : undefined
        if ((box2disabled.value && a === Type10CorrectType.First) || (box1disabled.value && b === Type10CorrectType.Second)) status = 'correct'
        else if ((!a && b === Type10CorrectType.Second) || (a === Type10CorrectType.First && !b)) {
          status = 'some'
        } else if (a === Type10CorrectType.First && b === Type10CorrectType.Second) {
          status = 'correct'
        }
        return status
      }

      let status = checkWordCombination()

      if (task.value.unforgiving) {
        //draggingNow = false

        if (status === 'correct') {
          tracking.details.correct++
          tracking.details.answer_details.push({
            attempt:
              (droppedItem1.value ? droppedItem1.value.text : '') +
              (droppedItem1.value && droppedItem2.value ? ', ' : '') +
              (droppedItem2.value ? droppedItem2.value.text : ''),
            correct: true,
            elapsed: tracking.elapsedTimeSinceLastCall,
          })
        } else if (status === 'some') {
          // Do nothing
        } else if (status === 'incorrect') {
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({
            attempt:
              (droppedItem1.value ? droppedItem1.value.text : '') +
              (droppedItem1.value && droppedItem2.value ? ', ' : '') +
              (droppedItem2.value ? droppedItem2.value.text : ''),
            correct: false,
          })
        }

        if (attempts === attemptLimit && !finishDone) {
          stateActions.progress.completeAStar()
          if (queuedAudio) queuedAudio.pause()
          fadeOut()
        }
      } else {
        if (status === 'correct') {
          setTimeout(() => {
            showPlaceholders.value = false
            combinedWord.value =
              task.value.sentence2TrailingWord +
              (droppedItem1.value ? droppedItem1.value.text : '') +
              (droppedItem2.value ? droppedItem2.value.text : '') +
              task.value.sentence2SecondTrailingWord
            stateActions.progress.completeAStar()
            tracking.details.correct++
            tracking.details.answer_details.push({
              attempt:
                (droppedItem1.value ? droppedItem1.value.text : '') +
                (droppedItem1.value && droppedItem2.value ? ', ' : '') +
                (droppedItem2.value ? droppedItem2.value.text : ''),
              correct: true,
              elapsed: tracking.elapsedTimeSinceLastCall,
            })
            // Fuse into one word
            if (tracking.details.correct === tracking.details.of || (task.value.unforgiving && attempts === tracking.details.of && !finishDone)) {
              opacityWords.value = 0
            }
            // Read completed word audio
            playCompleteSentence()
            setTimeout(() => {
              showCombinedWord.value = true
              setTimeout(() => {
                mergeCombinedWord.value = true
              }, 1000)
            }, 1000)
          }, 1000)
        } else if (status === 'some') {
          // draggingNow = false
          // Deactivate the boxIndex position
        } else if (status === 'incorrect') {
          tracking.details.incorrectAttempts++
          tracking.details.answer_details.push({
            attempt:
              (droppedItem1.value ? droppedItem1.value.text : '') +
              (droppedItem1.value && droppedItem2.value ? ', ' : '') +
              (droppedItem2.value ? droppedItem2.value.text : ''),
            correct: false,
          })
          setTimeout(() => {
            removeAllWords()
          }, 100)
        }
      }
    } else if (theWord) {
      playWordAudio(theWord)
      theWord.position = { x: 0, y: 0 }
    }
  }

  function checkForWarmup() {
    if (tracking.details.correct === tracking.details.of || (task.value.unforgiving && attempts === tracking.details.of && !finishDone)) {
      if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
        stateActions.speakLocalised(
          SpeechSounds.instructions.warmups.T10,
          () => {
            fadeOut()
          },
          1000,
          false,
        )
      } else {
        fadeOut(true)
      }
    }
  }

  function getBoxIndex(target: Element) {
    return parseInt(target.id.substring(9), 10) || 0
  }

  function introduceChallenge() {
    attempts = 0
    setTimeout(async () => {
      opacity.value = 1
      opacityWords.value = 1
      audioPlaying = true
      if (task.value.introductionAudio) {
        let instructionAudio = await createSound(task.value.introductionAudio)
        instructionAudio.onerror = (error) => {
          console.log(error.toString())
          audioPlaying = false
        }
        instructionAudio.onended = async () => {
          audioPlaying = false
          stateActions.setSpeakerSound([task.value.introductionAudio])
          stateSetters.speakerIsPlaying = false
          await queueAudio(task.value.sentence1audio, 500)
          await queueAudio(task.value.sentence2AudioBefore)
        }
        instructionAudio.playWhenReady()
        stateSetters.speakerIsPlaying = true
      } else {
        await queueAudio(task.value.sentence1audio, 500)
        await queueAudio(task.value.sentence2AudioBefore)
      }
    }, 1000)
  }

  function fadeOut(long?: boolean) {
    if (!finishDone) {
      finishDone = true
      opacityWords.value = 0
      setTimeout(
        () => {
          opacity.value = 0
          completeTask()
        },
        long ? 3000 : 1500,
      )
    }
  }

  function completeTask() {
    if (!taskCompleted) {
      taskCompleted = true
      setTimeout(() => {
        emit('completed', true, tracking)
      }, 500)
    }
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  span {
    font-size: larger;
  }
  .borderedWordBox {
    border-radius: 75px;
    background-color: white;
    padding: 10px;
    outline: none;
    border: solid rgba(152, 152, 152, 1) 5px;
    cursor: pointer;
    font-size: 24pt;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  .wordDropBox {
    display: inline-block;
    width: 150px;
    height: 60px;
    margin: 0 10px;

    border-radius: 75px;
    background-color: black;
    padding: 10px;
    outline: none;
    border: solid white 5px;
    cursor: pointer;
    font-size: 24pt;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  #word-source-box {
    margin-top: 30px;
  }

  .yellowWordText {
    font-size: 24pt;
    color: #ffd700;
    margin: 0 10px;
  }

  .capitaliseDroppedItem {
    text-transform: capitalize;
  }

  .captionArea {
    position: relative;
    margin-top: 30px;
    color: white;
    vertical-align: baseline;
    display: inline-block;
  }

  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 5%;
  }

  .scanner-image {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    height: 70%;
    top: 10%;
    padding-top: 5%;
    color: white;
  }
  .scanner-padding {
    padding: 0 5%;
  }
  .scanner-word {
    margin-top: -50px;
  }
</style>
