/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { isRef, ref, Ref } from 'vue'
import MersenneTwister from 'mersenne-twister'
import { AudioContext, AudioBufferSourceNode, TAudioContextState } from 'standardized-audio-context'
import { User } from './models/main'
import { USER_ROLE } from './constants'
import useDeviceService from './composition/useDevice'

type SafariAudioContextState = TAudioContextState & 'interrupted'

interface SoundControl {
  soundList: (string | MP3Audio | Media)[]
  soundTimeout?: NodeJS.Timeout
  currentSound?: MP3Audio | WebAudio | Media
  forceStop: boolean
  endSoundCallback?: () => unknown
}

const { actions: deviceActions } = useDeviceService()

const soundControl: SoundControl = {
  soundList: [],
  soundTimeout: undefined,
  currentSound: undefined,
  forceStop: false,
  endSoundCallback: () => ({}),
}

/**
 * Wrap a variable of specified type in a Vue Ref
 * @param element to wrap
 * @returns wrapped element
 */
const wrap = <T>(element: Ref<T> | T): Ref<T> => {
  if (isRef(element)) {
    return element
  }
  return ref(element) as Ref<T>
}

/**
 * Convert a path for use in iOS Cordova
 * @param path
 * @returns converted path
 */
const convertFilePath = (path: string): string => {
  return window.WkWebView.convertFilePath(path)
}

/**
 * Shuffle the given array and return a new array
 * @param itemsArray An array of any type
 * @returns a new array of the same type
 */
const shuffleItems = <T>(itemsArray: Array<T>): Array<T> => {
  const generator = new MersenneTwister()
  const indexArray = itemsArray.map((item, index: number) => index)
  let currentIndex = indexArray.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(generator.random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = indexArray[currentIndex]
    indexArray[currentIndex] = indexArray[randomIndex]
    indexArray[randomIndex] = temporaryValue
  }
  return indexArray.map((index) => itemsArray[index])
}

// https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
/**
 * Format a date object into a string including day, month name, year and time
 * @param date
 * @returns string
 */
const dateToFormattedString = (date: Date): string => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  let hours = date.getHours().toString()
  let mins = date.getMinutes().toString()

  hours = hours.length == 1 ? '0' + hours : hours
  mins = mins.length == 1 ? '0' + mins : mins

  return day + ' ' + monthNames[monthIndex] + ' ' + year + ' | ' + hours + ':' + mins
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 *
 * @param {number} min
 * @param {number} max
 * @return {*}  {number}
 */
function getRandomInt(min: number, max: number): number {
  const generator = new MersenneTwister()
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(generator.random() * (max - min + 1)) + min
}

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Random UUID. See https://gist.github.com/jed/982883
/**
 * Generate a ranom ID
 */
const uuid = (a = ''): string =>
  a
    ? /* eslint-disable no-bitwise */
      ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, uuid)

const hasMinimumRole = (user: User, requestedRole: USER_ROLE): boolean => {
  if (!user) return false
  switch (requestedRole) {
    case USER_ROLE.user:
      return true
    case USER_ROLE.monitor:
      return user.profile.role === USER_ROLE.monitor || user.profile.role === USER_ROLE.admin || user.profile.role === USER_ROLE.logs ? true : false
    case USER_ROLE.admin:
      return user.profile.role === USER_ROLE.admin || user.profile.role === USER_ROLE.logs ? true : false
    case USER_ROLE.logs:
      return user.profile.role === USER_ROLE.logs ? true : false
    default:
      return false
  }
}

/**
 * Dispatch a regular error as an 'kmerror'
 * @param error
 */
const emitError = (error: Error): void => {
  const e = new CustomEvent<Error>('kmerror', {
    detail: error,
  })
  window.dispatchEvent(e)
}

/**
 * Async: Wait a number of milliseconds before resolving
 * @param ms length of time to wait (milliseconds)
 * @returns void
 */
const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

// This class can tell us if the sound is ready to play
// It also calls the 'ended' callback immediately if the sound is not ready to play
class MP3Audio extends Audio {
  source?: string | ArrayBuffer | undefined
  isReady = false
  playOnReady = false
  loadAttempts = 0
  maxLoadAttempts = 1
  endedCallback?: () => unknown
  errorCallback?: OnErrorEventHandler
  onReadyCallback?: () => unknown

  // ArrayBuffer should be an MP3 file
  constructor(source?: string | ArrayBuffer | undefined, onEnded?: () => unknown) {
    super()
    this.preload = 'auto'
    this.source = source

    this.addEventListener('ended', this.hasEnded)
    this.addEventListener('canplaythrough', this.canplaythrough)
    this.addEventListener('stalled', this.stalled)
    this.addEventListener('suspended', this.suspended)
    this.addEventListener('abort', this.abort)
    this.addEventListener('error', (error) => this.errorFn(error))

    if (onEnded) this.endedCallback = onEnded
    this.load()
  }

  load = () => {
    if (this.source) {
      const currentLoadTimestamp = new Date().getTime()
      if (typeof this.source !== 'string') {
        const blob = new Blob([this.source], { type: 'audio/mp3' }) // NOTE: Only MP3 supported!
        const url = window.URL.createObjectURL(blob)
        this.src = `${url}?cts=${currentLoadTimestamp}`
      } else this.src = `${this.source}?cts=${currentLoadTimestamp}`
    }
    super.load()
  }

  private hasEnded = () => {
    console.log(`Audio ended.. ${this.src}`)
    if (this.endedCallback) this.endedCallback()
  }
  private hasErrored = (error: Error) => {
    if (this.errorCallback) this.errorCallback('MP3Audio error', '', 0, 0, error)
  }
  private canplaythrough = () => {
    this.isReady = true
    if (this.playOnReady) {
      this.playOnReady = false
      console.log(`Audio playing.. ${this.src}`)
      this.play().catch((error) => console.log(`Error playing sound: ${this.src} ${error.toString()}`))
    }
    if (this.onReadyCallback) this.onReadyCallback()
  }
  private stalled = () => {
    //console.log(`Audio stalled.. ${this.src}`)
  }
  private suspended = () => {
    console.log(`Audio is suspended.. ${this.src}`)
    this.hasEnded()
  }
  private abort = () => {
    console.log(`Audio aborted.. ${this.src}`)
    this.hasEnded()
  }
  private errorFn = (error: ErrorEvent) => {
    const el = error as unknown as ErrorEvent
    const target = el.currentTarget as HTMLAudioElement
    const errorCode = target.error?.code
    const errorMessage = `MP3Audio error.. ${this.src} : MediaError code ${errorCode || 'unknown'}`
    const myError = new Error(errorMessage)
    if (this.maxLoadAttempts > 0 && this.loadAttempts < this.maxLoadAttempts) {
      this.loadAttempts++
      console.log(errorMessage + ` Retry loading ${this.loadAttempts} of ${this.maxLoadAttempts}`)
      setTimeout(() => this.load(), 2000)
    } else {
      console.log(errorMessage + ` Sorry, no more retries, I'm giving up!`)
      this.hasErrored(myError)
      this.hasEnded()
    }
  }

  set onready(callback: () => unknown) {
    this.onReadyCallback = callback
  }
  set onended(callback: () => unknown) {
    this.endedCallback = callback
  }
  set onerror(callback: OnErrorEventHandler) {
    this.errorCallback = callback
  }
  set retries(tries: number) {
    this.maxLoadAttempts = tries
  }

  playWhenReady: () => Promise<void> = async () => {
    if (!this.isReady) {
      this.playOnReady = true
      return Promise.resolve()
    } else return this.play().catch((error) => console.log(`Error playing sound: ${this.src} ${error.toString()}`))
  }
}

// This is the Web Audio API version of the above class - can tell us if the sound is ready to play
// It creates sounds from a buffer supplied by loading from local cache, or loading a URi using fetch()
// It also calls the 'ended' callback immediately if the sound is not ready to play
class WebAudio {
  audioBuffer: AudioBuffer | undefined
  audioSource: AudioBufferSourceNode<AudioContext> | undefined
  uri?: string
  isReady = false
  playOnReady = false
  loadAttempts = 0
  maxLoadAttempts = 1

  endedCallback?: () => unknown
  errorCallback?: OnErrorEventHandler
  onReadyCallback?: () => unknown

  pausedAt = 0
  startedAt = 0
  paused = false
  playing = false
  sourceWasURI = false

  idleTimer?: ReturnType<typeof setTimeout>

  useLogging = false

  static audioContext: AudioContext = new AudioContext()

  static createAudioContext() {
    WebAudio.audioContext = new AudioContext()
  }
  static closeAudioContext() {
    WebAudio.audioContext.close()
  }
  static suspendAudioContext() {
    WebAudio.audioContext.suspend()
  }
  static resumeAudioContext() {
    return WebAudio.audioContext.resume()
  }

  // ArrayBuffer should be an MP3
  constructor(arrayBuffer?: ArrayBuffer, uri?: string, onEnded?: () => unknown) {
    this.uri = uri
    if (onEnded) this.endedCallback = onEnded
    if (!arrayBuffer && !uri) console.log(`WebAudio intstantiation error - no sources supplied`)
    this.load(arrayBuffer)
  }

  // The resulting AudioBuffer is kept for reuse each time the sound is played
  async decodeFile(arrayBuffer: ArrayBuffer) {
    try {
      this.audioBuffer = await WebAudio.audioContext.decodeAudioData(arrayBuffer)
      this.isReady = true
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error decoding: ${this.uri}: ${err.message}`)
      this.hasErrored(err)
    }
  }

  // Each time we play we must create a new Node
  createSource() {
    try {
      this.audioSource = new AudioBufferSourceNode(WebAudio.audioContext, {
        buffer: this.audioBuffer,
        playbackRate: 1,
      })
      this.audioSource.onended = () => {
        this.hasEnded()
      }
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error creating source: ${this.uri}: ${err.message}`)
      this.hasErrored(err)
    }
  }

  async load(arrayBuffer?: ArrayBuffer) {
    if (!arrayBuffer && this.uri) {
      const response = await fetch(this.uri)
      arrayBuffer = await response.arrayBuffer()
      this.sourceWasURI = true
    }
    if (arrayBuffer) {
      await this.decodeFile(arrayBuffer)
      if (this.isReady) {
        if (this.onReadyCallback) this.onReadyCallback()
        if (this.playOnReady) {
          this.playOnReady = false
          this.play()
        }
      }
    } else {
      console.log('WebAudio error - no usable arrayBuffer')
    }
  }

  async play() {
    try {
      if (this.useLogging) console.log(`WebAudio attempting to play ${this.uri}. AudioContext state: ${WebAudio.audioContext.state}`)
      if (WebAudio.audioContext.state === ('interrupted' as SafariAudioContextState) || WebAudio.audioContext.state === 'suspended') {
        await WebAudio.audioContext.resume()
      }
      if (this.audioBuffer && !this.playing) {
        const duration = this.audioBuffer.duration
        if (duration === 0) {
          console.log(`WebAudio duration is zero! Ending..`)
          this.hasEnded()
        } else {
          this.createSource()
          // A backup timer in case audio for some reason does not play
          this.idleTimer = setTimeout(() => {
            console.log(`WebAudio timed out (${duration}s + 2s)`)
            this.hasEnded()
          }, duration * 1000 + 2000)

          if (this.audioSource) {
            this.audioSource.connect(WebAudio.audioContext.destination)
            if (this.pausedAt) {
              this.startedAt = Date.now() - this.pausedAt
              this.audioSource.start(0, this.pausedAt / 1000)
            } else {
              this.startedAt = Date.now()
              this.audioSource.start(0)
            }
            this.playing = true
            const ab = this.sourceWasURI ? 'a URL' : 'disk cache'
            console.log(`WebAudio playing: ${this.uri} loaded from ${ab}`)
          } else {
            console.log('WebAudio error - No source created')
          }
        }
      } else if (!this.audioBuffer) {
        console.log('WebAudio error - No buffer to play')
      }
    } catch (error) {
      const err = error as unknown as Error
      console.log(`WebAudio Error playing source ${this.uri}: ${err.message}`)
      this.hasErrored(err)
      this.hasEnded()
    }
  }

  pause() {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.audioSource && this.playing) {
      this.audioSource.stop(0)
      this.pausedAt = Date.now() - this.startedAt
      this.paused = true
      this.playing = false
    }
  }

  stop() {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.audioSource) {
      this.audioSource.stop(0)
      this.pausedAt = 0
      this.playing = false
    }
  }

  private hasEnded = () => {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    this.playing = false
    this.paused = false
    this.pausedAt = 0
    if (this.useLogging) console.log(`WebAudio ended: ${this.uri}`)
    if (this.endedCallback) this.endedCallback()
  }

  private hasErrored = (error: Error) => {
    if (this.idleTimer) clearTimeout(this.idleTimer)
    if (this.errorCallback) this.errorCallback('WebAudio error', '', 0, 0, error)
  }

  set onready(callback: () => unknown) {
    this.onReadyCallback = callback
  }
  set onended(callback: () => unknown) {
    this.endedCallback = callback
  }
  set onerror(callback: OnErrorEventHandler) {
    this.errorCallback = callback
  }
  set retries(tries: number) {
    // Currently unused in this class
    this.maxLoadAttempts = tries
  }

  public addEventListener(type: string, callback: () => unknown) {
    switch (type) {
      case 'ended':
        this.endedCallback = callback
        break
    }
  }
  public removeEventListener(type: string, callback: () => unknown) {
    switch (type) {
      case 'ended':
        if (this.endedCallback == callback) this.endedCallback = undefined
        break
    }
  }

  playWhenReady: () => void = () => {
    if (!this.isReady) {
      this.playOnReady = true
    } else this.play()
  }
}

// Create a new audio from a source URL and load it, checking first if it is in cache
const createSound = async (url: string, onEnded?: () => unknown): Promise<WebAudio> => {
  // Check for sound in media cache first
  const source = await deviceActions.getCachedMedia(url, false)
  let sound: WebAudio | MP3Audio
  if (typeof source === 'string') {
    sound = new WebAudio(undefined, source, onEnded)
  } else {
    sound = new WebAudio(source, url, onEnded)
  }
  //if (import.meta.env.DEV) console.log(`WebAudio created: ${url}`)
  return sound
}

const getLocalSoundURI = async (url: string): Promise<string> => {
  // Check for sound in media cache first
  const source = await deviceActions.getCachedMediaURI(url)
  return source
}

const playSounds = async (soundFiles?: (string | MP3Audio | Media)[], delay?: number, callback?: () => unknown): Promise<void> => {
  if (soundFiles) {
    soundControl.soundList = [...soundFiles]
    soundControl.forceStop = false // Reset this when we play a new list next time
  }
  if (callback) soundControl.endSoundCallback = callback

  const s = soundControl.soundList.shift()
  if (s) {
    if (typeof s === 'string') {
      try {
        soundControl.currentSound = await createSound(s, playSounds)
      } catch (error) {
        console.log(`Error creating sound ${s}: ${error}`)
        playSounds()
      }
    } else {
      soundControl.currentSound = s
    }
    soundControl.soundTimeout = setTimeout(async () => {
      try {
        if (soundControl.currentSound) soundControl.currentSound.play()
      } catch (error) {
        console.log(`Error playing sound ${s}: ${error}`)
        playSounds()
      }
    }, delay || 500)
  } else if (soundControl.soundList.length) playSounds()
  else if (soundControl.endSoundCallback && !soundControl.forceStop) soundControl.endSoundCallback()
}

const stopSounds = (): void => {
  soundControl.forceStop = true // Prevent callback being called when Media is stopped (rather than 'ended')
  if (soundControl.soundTimeout) clearTimeout(soundControl.soundTimeout)
  if (window.cordova && soundControl.currentSound && soundControl.currentSound instanceof Media) {
    soundControl.currentSound.stop()
  } else if (soundControl.currentSound && soundControl.currentSound instanceof HTMLAudioElement) {
    soundControl.currentSound.pause()
    soundControl.currentSound.currentTime = 0
  }
}

enum WINDOW_SIZES {
  OLD_HEIGHT = 768,
  OLD_WIDTH = 1024,
  /*  NEW_HEIGHT = window.innerHeight,
  NEW_WIDTH = window.innerWidth, */
  NEW_HEIGHT = 768,
  NEW_WIDTH = 1024,
  SCALE = Math.min(NEW_WIDTH / OLD_WIDTH, NEW_HEIGHT / OLD_HEIGHT),
  SCALE_Y = NEW_HEIGHT / OLD_HEIGHT,
  SCALE_X = NEW_WIDTH / OLD_WIDTH,
}

const scaleContent = (): number => {
  return Math.min(WINDOW_SIZES.NEW_WIDTH / WINDOW_SIZES.OLD_WIDTH, WINDOW_SIZES.NEW_HEIGHT / WINDOW_SIZES.OLD_HEIGHT)
}

interface Coordinates {
  h: number
  w: number
  x: number
  y: number
}
const getCoordinates = (coordinates: Coordinates): Coordinates => {
  const scaledCoordinates = {
    h: coordinates.h * WINDOW_SIZES.SCALE_Y,
    w: coordinates.w * WINDOW_SIZES.SCALE_X,
    x: coordinates.x * WINDOW_SIZES.SCALE_X,
    y: coordinates.y * WINDOW_SIZES.SCALE_Y,
  }
  return scaledCoordinates
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDefaultOverlayProperties = () => {
  return {
    compulsory: false,
    description: '',
    start: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      z: 1,
    },
    transition: {
      x: 0,
      y: 0,
      scale: 1,
      duration: 1,
    },
    map: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    pointer: {
      x: 0,
      y: 0,
      delay: 0,
      retain: true,
    },
    show_highlight: false,
    highlight_location: {
      x: 0,
      y: 0,
    },
    highlight_size: {
      width: 0,
      height: 0,
    },
    opacity: 1,
    visible_before: false,
    visible_after: false,
    auto_return: false,
    allow_return: false,
    auto_start: false,
    timeout: 0,
    delay: 0,
    active: false,
    completed: false,
    audio: false,
  }
}

export {
  uuid,
  dateToFormattedString,
  capitalizeFirstLetter,
  getRandomInt,
  wrap,
  convertFilePath,
  wait,
  hasMinimumRole,
  shuffleItems,
  emitError,
  getCoordinates,
  scaleContent,
  WINDOW_SIZES,
  getDefaultOverlayProperties,
  createSound,
  getLocalSoundURI,
  playSounds,
  stopSounds,
  MP3Audio,
  WebAudio,
}
