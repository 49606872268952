/* import { createApp } from 'vue'
import App from '@/App.vue'
import '@/index.css'

createApp(App).mount('#app')

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
}

String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
} */

/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { createApp, DirectiveBinding } from 'vue'
import { i18n } from './i18n'
import { DraggablePlugin } from '@braks/revue-draggable'
import router from './router'
//import * as createjs from 'createjs-module'
import { AdobeAn, XHR_REQUEST_TYPE } from '@/models/main'
import App from '@/App.vue'
import './index.css'
import './globalStyles.css'
const app = createApp(App)
import { apiRequest } from '@/api/apiRequest'
import useAppStore from './store/useAppStore'
import useUserStore from './store/useUserStore'
import useCMSStore from './store/useCMSStore'
import useDeviceService from './composition/useDevice'
import { WebAudio } from './utilities'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
    AudioContext: typeof AudioContext
    webkitAudioContext: typeof AudioContext
    ErrorToConsole: Console['error']
    playSound: (id: string, loop: boolean) => void
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
  // this variable will be set by createjs
  // declare let Media: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let AdobeAn: AdobeAn
}

// Attach createjs-custom-module script to the window object, so it can be seen by dynamically loaded scene scripts
const freeGlobal = typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : { createjs }
freeGlobal.createjs = createjs

String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

String.prototype.toCamelCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

String.prototype.padZero = function (length: number): string {
  let s = String(this)
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

// Hides an HTML element, but KEEPING THE SPACE it would have used if it were visible (css: Visibility)
// https://www.ryansouthgate.com/2020/01/30/vue-js-v-hide-element-whilst-keeping-occupied-space/
app.directive('hide', (el: HTMLElement, binding: DirectiveBinding) => (el.style.visibility = binding.value ? 'hidden' : ''))

const { actions: appActions } = useAppStore()
const { getters: userGetters } = useUserStore()
const { getters: cmsGetters } = useCMSStore()
const { actions: deviceActions } = useDeviceService()

// Redirected OAuth login for mobile devices
// Using cordova-plugin-oauth
// Called from a callback URL like
// com.example.foo://oauth_callback?code=b10a8db164e0754105b7a99be72e3fe5
// it would be received in JavaScript like this:
window.addEventListener('message', function (event) {
  const url = event.data.toString() as string
  if (url.match(/^oauth::/)) {
    if (event.origin) console.log(`Login callback event origin: ${event.origin}`)
    const data = JSON.parse(url.substring(7))
    if (data.mode == 'login' && data.code && data.code !== 'undefined') {
      // The JWT will be sent with future requests to authenticate Mobile users in case the session has expired
      localStorage.setItem('jwt', data.code)
      appActions.tokenLogin()
    } else {
      router.push('/')
    }
  }
})

// Catch slPLus errors sent via utilities.js error function
window.addEventListener('kmerror', ((event: CustomEvent) => {
  //do something
  const error = event.detail
  if (error) {
    const errorText = error.toString()
    console.log(errorText)
    deviceActions.logErrorMessage(errorText)
  }
}) as EventListener)

// Catch unhandled errors
window.addEventListener('unhandledrejection', function (event) {
  console.warn(`Uncaught promise: ${event.promise.toString()} Reason: ${event.reason.toString()}`)
})

// Register Draggables
// https://github.com/bcakmakoglu/revue-draggable
app.use(DraggablePlugin)

// Media cache Directive
// Use this to cache(save and load) CMS-based images & videos to the mobile device
// e.g:  <img src="..." v-cache>
// NO NEED to use this with local assets
// Set 'download' attribute to true to save a copy to cache (currently this interferes with the existing cached value)
app.directive('cache', (el: HTMLImageElement) => {
  if (el.src.includes('https://')) el.src = deviceActions.getCachedMediaURI(el.src)
})

// Bootstrap the Vue app when called
const initialiseApp = () => {
  app.use(router).use(i18n).mount('#app')
}

if (window.cordova) {
  // If running Cordova, start once it becomes ready & is set up, else start the app immediately
  const onDeviceReady = () => {
    deviceActions.setup()
    appActions.loadSettings().then(() => initialiseApp())
  }
  const onPause = async () => {
    WebAudio.suspendAudioContext()
    //await deviceActions.stopRecordingAudio()
  }
  const onResume = async () => {
    await WebAudio.resumeAudioContext()
    return
  }
  const onZoom = (event: Event) => {
    event.preventDefault()
  }
  document.addEventListener('deviceready', onDeviceReady, false)
  document.addEventListener('pause', onPause, false)
  document.addEventListener('resume', onResume, false)
  document.addEventListener('gesturestart', onZoom, false) // Prevent zooming the iOS screen

  // Send errors to server
  window.ErrorToConsole = window.console.error
  window.console.error = (message) => {
    window.ErrorToConsole(new Date(), message)
    const data = {
      userId: userGetters.myUser.value._id,
      activityId: cmsGetters.selectedActivity.value.cmsID,
      sessionId: cmsGetters.selectedSession.value.session?._id,
      taskId: cmsGetters.selectedTask.value.task?._id,
      error: message,
    }
    apiRequest({
      route: '/api/error',
      method: XHR_REQUEST_TYPE.POST,
      credentials: true,
      body: data,
    })
  }
} else {
  appActions.loadSettings()
  initialiseApp()
}
