<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div v-if="starAssets[1]" class="progress-star flex flex-col">
    <img v-if="starAssets[7]" :src="starAssets[7]" />
    <img v-if="starAssets[6]" :src="starAssets[6]" />
    <img v-if="starAssets[5]" :src="starAssets[5]" />
    <img v-if="starAssets[4]" :src="starAssets[4]" />
    <img v-if="starAssets[3]" :src="starAssets[3]" />
    <img v-if="starAssets[2]" :src="starAssets[2]" />
    <img v-if="starAssets[1]" :src="starAssets[1]" />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import useState from '@/composition/useState'
  import fullStarBlue from '@/assets/images/stars/blue/BlueStarFullState100x100px.png'
  import emptyStarBlue from '@/assets/images/stars/blue/BlueStarEmptyState100x100px.png'
  import animationStarBlue from '@/assets/images/stars/blue/BlueStarAnimation100x100px.gif'
  import fullStarYellow from '@/assets/images/stars/yellow/starFullState100x100px.png'
  import emptyStarYellow from '@/assets/images/stars/yellow/starEmptyState100x100px.png'
  import animationStarYellow from '@/assets/images/stars/yellow/starAnimation100x100px.gif'

  interface StarData {
    [key: number]: string
  }

  const { getters } = useState()
  const starImages = {
    blue: {
      full: fullStarBlue,
      empty: emptyStarBlue,
      animation: animationStarBlue,
    },
    yellow: {
      full: fullStarYellow,
      empty: emptyStarYellow,
      animation: animationStarYellow,
    },
  }
  const starAssets = computed(() => {
    const stars = getters.progress.value.starData.stars
    const completed = getters.progress.value.starData.completed
    const sd: StarData = {
      1: emptyStarBlue,
      2: emptyStarBlue,
      3: emptyStarBlue,
      4: emptyStarBlue,
      5: emptyStarBlue,
      6: emptyStarBlue,
      7: emptyStarBlue,
    }
    ;[1, 2, 3, 4, 5, 6, 7].forEach((key) => {
      const starNumber = key
      if (stars >= starNumber) {
        if (completed > starNumber) sd[key] = starImages[starColour].full
        else if (completed === starNumber) sd[key] = starImages[starColour].animation
        else sd[key] = starImages[starColour].empty
      } else sd[key] = ''
    })
    return sd
  })

  const starColour: keyof typeof starImages = 'yellow'
</script>

<style lang="postcss">
  .progress-star {
    position: absolute;
    right: 20px;
    top: 30px;

    background-color: rgba(0, 0, 0, 0.6);
    padding: 15px;
    border-radius: 50px;
  }

  .progress-star img {
    width: 80px;
  }

  @media (max-width: 1024px) {
    .progress-star img {
      width: 50px;
    }
  }
</style>
