/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { ref, computed, Ref, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import {
  ActivityPhase,
  SessionPhase,
  EpisodePhase,
  EpisodeScenes,
  ViewState,
  LoginMode,
  ShipMode,
  TaskMode,
  DelayMode,
  SceneMode,
  SceneType,
  StateVariables,
  LanguageCodes,
  SKIP_SESSION_PASSWORD,
  QUESTION_MODE,
} from '@/constants'
import { TaskTracking, SessionTracking, TRACKING_TYPE, Sett } from '@/models/main'
import { QuestionUnion } from '@/models/tasktypes'
import { Session, Episode } from '@/models/navigationModels'
import useCMSStore from '@/store/useCMSStore'
import useGameStore from '@/store/useGameStore'
import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useDevice from '@/composition/useDevice'

import blackBackground from '@/assets/images/BlackBackground@2x.png'
import loginBackground from '@/assets/images/login/LogInBackground@2x.jpg'
import { WebAudio, createSound } from '@/utilities'

export interface ControlState {
  state: {
    viewState: ViewState
    loginMode: LoginMode
    shipMode: ShipMode
    taskMode: TaskMode
    delayMode: DelayMode
    sceneMode: SceneMode
    sceneType: SceneType
  }

  currentTaskIndex: number

  trackings: {
    sessionTracking?: SessionTracking
    taskTracking?: TaskTracking
  }

  progress: {
    starData: {
      stars: number
      completed: number
    }
    barData: {
      completedPercent: number
    }
    shipBarData: {
      completedPercent: number
    }
    opacity: number
    visible: boolean
    starPopSound?: WebAudio
    randomMorfPlays: number
  }

  // Narration
  computerAnimating: boolean

  backgroundImage: string
  savedBackgroundImage: string
  fade: boolean
  demoMode: boolean
  inactivityTimestamp: moment.Moment | undefined
  selectedSceneSubpath: string
  sceneInProgress: boolean
  speakerIsPlaying: boolean
  speakerSound?: WebAudio
}

const _controlState: Ref<ControlState> = ref({
  state: {
    viewState: ViewState.None,
    loginMode: LoginMode.AppStarted,
    shipMode: ShipMode.SessionLocked,
    taskMode: TaskMode.Warmups,
    delayMode: DelayMode.None,
    sceneMode: SceneMode.Ready,
    sceneType: SceneType.GeneralScene,
  },

  trackings: {
    sessionResults: undefined,
    taskTracking: undefined,
  },

  progress: {
    starData: {
      stars: 0,
      completed: 0,
    },
    barData: {
      completedPercent: 1,
    },
    shipBarData: {
      completedPercent: 1,
    },
    opacity: 0,
    visible: false,
    starPopSound: undefined,
    randomMorfPlays: 0,
  },

  currentTaskIndex: 0,
  computerAnimating: false,

  backgroundImage: loginBackground,
  savedBackgroundImage: '',
  fade: false,
  demoMode: false,
  selectedSceneSubpath: '',
  sceneInProgress: false,
  inactivityTimestamp: undefined,
  speakerIsPlaying: false,
  speakerSound: undefined,
})

// ------------ External interfaces -------------

type Getters = {
  backgroundImage: ComputedRef<string>
  fade: ComputedRef<boolean>
  languageCode: ComputedRef<LanguageCodes>
  demoMode: ComputedRef<boolean>
  computerAnimating: ComputedRef<boolean>
  randomMorphTime: ComputedRef<boolean>
  state: ComputedRef<typeof _controlState.value.state>
  trackings: ComputedRef<typeof _controlState.value.trackings>
  progress: ComputedRef<typeof _controlState.value.progress>
  nextUncompletedEpisode: ComputedRef<Episode | undefined>
  nextUncompletedSession: ComputedRef<Session | undefined>
  sceneSubpath: ComputedRef<string>
  sceneInProgress: ComputedRef<boolean>
  speakerIsPlaying: ComputedRef<boolean>
}
type Setters = {
  backgroundImage: string
  fade: boolean
  state: StateVariables
  sceneInProgress: boolean
  speakerIsPlaying: boolean
}
type Actions = {
  audioPanel: () => void
  backgroundImage: (src: string) => void
  downloadAssets: (session?: Session) => Promise<void>
  playScenes: (dontFade: boolean) => void
  updateState: (dontFade?: boolean, stateVariables?: StateVariables) => void
  completeTask: (trackingDetails?: TaskTracking) => Promise<void>
  configureSession: () => void
  // speak: (key: string, callback: () => void, delay: number, animateComputer: boolean) => void
  // Speak a public asset sound that is also localised by language code
  speakLocalised: (subPaths: string, callback?: () => void, delay?: number, setSpeaker?: boolean, animateComputer?: boolean) => void
  begin: () => void // Start the state machine now that User, Game, and CMS data is available
  exitToLogin: () => void
  exitToDashboard: () => void
  startSession: () => void
  progress: {
    completeAStar: () => void
    progressShow: (data: { stars?: number }) => void
    reset: () => void
    resetBar: () => void
    resetStars: () => void
    resetRandomMorf: () => void
    calculateEpisodeProgress: () => number
  }
  updateCurrentTracking: (r: TaskTracking | SessionTracking, type: TRACKING_TYPE) => void
  resetInactiveTimer: () => void
  activateSession: (session?: Session, password?: string) => Promise<boolean>
  skipSession: (nextSession: Session, passwordText: string) => boolean
  getSessionWithTasks: (session: Session) => Promise<void>
  downloadAssetsForEpisode: () => Promise<void>
  setSpeakerSound: (sources: string[]) => void
  speakerClick: () => void
}
interface ServiceInterface {
  getters: Getters
  setters: Setters
  actions: Actions
}
function useStateService(): ServiceInterface {
  const router = useRouter()
  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { getters: deviceGetters, actions: deviceActions } = useDevice()
  // ------------ Internal functions --------------

  async function initialiseState() {
    _controlState.value.progress.starPopSound = await createSound('/assets/sounds/general/StarPopping.mp3')
  }
  initialiseState()

  // Determine which scene to play
  // Remember: By default scenes play before advancing the ViewState, so NEXT ViewState should be set before playing the scene
  function getSceneSet(type: SceneType): string[] {
    const nus = getters.nextUncompletedSession.value
    const sessionPhase = nus ? cmsActions.getSessionPhase(nus) : SessionPhase.Unknown
    const episodePhase = cmsGetters.episodePhase.value
    let sceneSet: string[] = []
    switch (type) {
      case SceneType.GeneralScene:
        if (_controlState.value.state.viewState === ViewState.Map) {
          sceneSet = ['general/2/']
        } else if (_controlState.value.state.viewState === ViewState.Ship) {
          sceneSet = ['general/1/']
        } else if (_controlState.value.state.viewState === ViewState.Tasks) {
          sceneSet = ['general/3/']
        }
        break
      case SceneType.EpisodeInScene:
        if (cmsGetters.selectedActivity.value.activity?.activityPhase === ActivityPhase.Demo) {
          sceneSet = EpisodeScenes[0].intros
        } /*  else if (cmsGetters.selectedActivity.value.activity?.activityPhase === ActivityPhase.BroadRelease) {
          sceneSet = this._appStorage.broadReleasePretestMode ? EpisodeScenes[1].intros : EpisodeScenes[2].intros;
        } */ else if (episodePhase === EpisodePhase.First) {
          sceneSet = EpisodeScenes[1].intros
        } else if (
          cmsGetters.episodePhase.value === EpisodePhase.Second &&
          (sessionPhase === SessionPhase.First || sessionPhase === SessionPhase.FirstAndLast)
        ) {
          sceneSet = EpisodeScenes[2].intros
        } else if ([EpisodePhase.Third, EpisodePhase.Fourth, EpisodePhase.Fifth].indexOf(episodePhase) > -1) {
          sceneSet = EpisodeScenes[episodePhase].intros
        }
        break
      case SceneType.EpisodeOutScene:
        if (cmsGetters.selectedActivity.value.activity?.activityPhase === ActivityPhase.Demo) {
          sceneSet = EpisodeScenes[0].outros
        } /*  else if (cmsGetters.selectedActivity.value.activity?.activityPhase === ActivityPhase.BroadRelease) {
          sceneSet = this._appStorage.broadReleasePretestMode ? EpisodeScenes[1].outros : EpisodeScenes[2].outros;
        } */ else {
          sceneSet = EpisodeScenes[episodePhase].outros
        }
        break
      case SceneType.MorfologicalScene:
        sceneSet = ['morfological/' + cmsGetters.selectedSession.value.session?.morfologicalIntro + '/']
        break
      case SceneType.ConsolidationInScene:
        switch (sessionPhase) {
          case SessionPhase.One:
            sceneSet = ['sessions/2/']
            break
          case SessionPhase.Two:
          case SessionPhase.Three:
          case SessionPhase.Four:
          case SessionPhase.Five:
          case SessionPhase.Six:
          case SessionPhase.Seven:
          case SessionPhase.Eight:
            sceneSet = ['sessions/3/']
            break
          case SessionPhase.Last:
            sceneSet = ['sessions/4/']
            break
        }
        break
      case SceneType.SessionInScene:
        sceneSet = ['sessions/1/']
        break
    }
    return sceneSet
  }

  // Complete the given Sett and any parents if necessary
  function completeSettTree(sett: Sett, skipped = false) {
    const name = skipped ? 'SKIPPED-' + sett.name : sett.name
    gameActions.completeProgressForItem(sett._id, sett.parent?._id || '', name)
    if (sett.parent) {
      // If this was the last Sett in a series, we should also complete the grandparents recursively
      const index = sett.parent.sets.findIndex((s) => s._id === sett._id)
      if (
        sett.parent &&
        index >= 0 && // Has a parent
        index === sett.parent.sets.length - 1 // This was the final child in its parent sett list
      ) {
        completeSettTree(sett.parent)
      }
    }
  }

  // Node: these are 'getters' which should be called as an attribute, not as a function
  const getters = {
    get backgroundImage(): ComputedRef<string> {
      return computed(() => _controlState.value.backgroundImage)
    },
    get languageCode(): ComputedRef<LanguageCodes> {
      return appGetters.languageCode
    },
    get fade(): ComputedRef<boolean> {
      return computed(() => _controlState.value.fade)
    },
    get demoMode(): ComputedRef<boolean> {
      return computed(() => _controlState.value.demoMode)
    },
    get sceneSubpath() {
      return computed(() => _controlState.value.selectedSceneSubpath) // This is the number of the folder on disk at  assets/scenes/
    },
    get trackings() {
      return computed(() => _controlState.value.trackings)
    },
    get state() {
      return computed(() => _controlState.value.state)
    },
    get progress() {
      return computed(() => _controlState.value.progress)
    },
    get computerAnimating(): ComputedRef<boolean> {
      return computed(() => _controlState.value.computerAnimating)
    },
    get sceneInProgress(): ComputedRef<boolean> {
      return computed(() => _controlState.value.sceneInProgress)
    },
    get speakerIsPlaying(): ComputedRef<boolean> {
      return computed(() => _controlState.value.speakerIsPlaying)
    },
    // Decides whether to show an interlude scene
    get randomMorphTime(): ComputedRef<boolean> {
      return computed(() => {
        if (_controlState.value.progress.randomMorfPlays < 1 && Math.random() < _controlState.value.progress.barData.completedPercent / 100) {
          _controlState.value.progress.randomMorfPlays++
          return true
        } else {
          return false
        }
      })
    },

    // This retrieves the next uncompleted episode. Based on the Student's progress.
    get nextUncompletedEpisode(): ComputedRef<Episode | undefined> {
      return computed(() => {
        const activity = cmsGetters.selectedActivity.value.activity
        // If we logged in, but there are no available episodes, go to the Delay route
        if (!activity) return undefined

        const completedEpisodeIDs = gameActions.getCompletedChildren(activity._id)

        // Exclude episodes that are in the Student's completed list
        const uncompletedEpisodes = activity.sets.filter((episode) => {
          return completedEpisodeIDs.indexOf(episode._id) < 0
        })

        if (uncompletedEpisodes.length > 0) {
          // Broad release has only two episodes, and they may not have been done in order
          if (activity.activityPhase === ActivityPhase.BroadRelease && activity.sets.length > 1) return activity.sets[1]
          return uncompletedEpisodes[0]
        }
        return undefined
      })
    },

    get nextUncompletedSession(): ComputedRef<Session | undefined> {
      return computed(() => {
        // If there are no sessions assigned, we can't continue
        const selectedEpisode = cmsGetters.selectedEpisode.value.episode
        if (!selectedEpisode || selectedEpisode.sets.length === 0) return undefined

        // Returns a list of session IDs for a particular episode:  string[]
        const completedSessionIDs: string[] = gameActions.getCompletedChildren(selectedEpisode._id)

        // Exclude sessions that are in the Student's completed list
        const uncompletedSessions = selectedEpisode.sets.filter((es) => {
          return (
            completedSessionIDs.length === 0 ||
            completedSessionIDs.every((csID) => {
              return csID !== es._id
            })
          )
        })

        if (uncompletedSessions.length > 0) return uncompletedSessions[0]
        else {
          console.log('No more uncompleted sessions found')
          return undefined
        }
      })
    },
  }

  const setters = {
    set backgroundImage(src: string) {
      _controlState.value.backgroundImage = src
    },
    set fade(fade: boolean) {
      _controlState.value.fade = fade
    },
    set state(stateVariables: StateVariables) {
      _controlState.value.state = { ..._controlState.value.state, ...stateVariables }
    },
    set sceneInProgress(b: boolean) {
      _controlState.value.sceneInProgress = b
    },
    set speakerIsPlaying(on: boolean) {
      _controlState.value.speakerIsPlaying = on
    },
  }

  const actions: Actions = {
    audioPanel: () => {
      router.push('/game/audiopanel')
    },
    backgroundImage: () => ({}),
    downloadAssets: async (s?: Session) => {
      const session = s ? s : cmsGetters.selectedSession.value.session
      if (session) {
        const sessionWarmupTasks = session.getTasks(QUESTION_MODE.warmup, false) || []
        const sessionTestTasks = session.getTasks(QUESTION_MODE.test, false) || []
        const warmupAssetList = sessionWarmupTasks.reduce((acc: string[], item) => acc.concat(item.assetList), [])
        const testAssetList = sessionTestTasks.reduce((acc: string[], item) => acc.concat(item.assetList), [])
        const sessionAssetList = warmupAssetList.concat(testAssetList).filter((as) => as != '')
        for (let i = 0; i < sessionAssetList.length; i++) {
          appActions.logFeedback(`Downloading asset ${i} of ${sessionAssetList.length}`)
          await deviceActions.downloadToCache(sessionAssetList[i])
        }
        await deviceActions.saveMediaCache()
      }
    },
    async speakLocalised(subPath: string, callback?: () => void, delay = 0, setSpeaker = true, animateComputer = true) {
      const language = appGetters.languageCode.value
      const url = `/assets/sounds/${language}/${subPath}`
      const newSound = await createSound(url)
      newSound.onended = () => {
        _controlState.value.computerAnimating = false
        if (callback) callback()
      }
      // Also set the 'speaker icon sound' to this sound by default
      if (setSpeaker) {
        _controlState.value.speakerSound = await createSound(url)
        _controlState.value.speakerSound.onended = () => {
          _controlState.value.computerAnimating = false
          _controlState.value.speakerIsPlaying = false
        }
      }
      setTimeout(() => {
        if (animateComputer) _controlState.value.computerAnimating = true
        newSound.playWhenReady()
      }, delay)
    },
    async setSpeakerSound(sources: string[]) {
      if (!sources.length) {
        _controlState.value.speakerSound = undefined
      } else {
        _controlState.value.speakerSound = await createSound(sources[0])
        _controlState.value.speakerSound.onended = async () => {
          // Possibly play a second sound..
          if (sources.length > 1) {
            const a = await createSound(sources[1])
            a.onended = () => (_controlState.value.speakerIsPlaying = false)
            a.playWhenReady()
          } else _controlState.value.speakerIsPlaying = false
        }
      }
    },
    speakerClick() {
      if (_controlState.value.speakerSound && !_controlState.value.speakerIsPlaying) {
        _controlState.value.speakerSound.playWhenReady()
        _controlState.value.speakerIsPlaying = true
        if (_controlState.value.trackings.taskTracking?.details.use_audio_instructions) {
          _controlState.value.trackings.taskTracking.details.use_audio_instructions++
        }
      }
    },
    updateCurrentTracking(r: TaskTracking | SessionTracking, type: TRACKING_TYPE): void {
      switch (type) {
        case TRACKING_TYPE.session:
          if (_controlState.value.trackings.sessionTracking) _controlState.value.trackings.sessionTracking.update(r as SessionTracking)
          else _controlState.value.trackings.sessionTracking = new SessionTracking(r as SessionTracking)
          break
        case TRACKING_TYPE.task:
          if (_controlState.value.trackings.taskTracking) _controlState.value.trackings.taskTracking.update(r as TaskTracking)
          else _controlState.value.trackings.taskTracking = new TaskTracking(r as TaskTracking)
          break
        default:
          break
      }
    },
    resetInactiveTimer(): void {
      if (_controlState.value.inactivityTimestamp && _controlState.value.state.taskMode !== TaskMode.Sample) {
        const diff = moment().diff(_controlState.value.inactivityTimestamp, 'seconds')
        const log = _controlState.value.trackings.sessionTracking
        if (diff > 30 && log) {
          log.details.inactive_count++
          log.details.inactive_duration += diff
        }
      }
      _controlState.value.inactivityTimestamp = moment()
    },
    // Call to start this state machine as KM begins (before routing to Ship view)
    begin(): void {
      // Check for an active Activity
      if (!cmsGetters.selectedActivity.value.cmsID) {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode = DelayMode.NoActivitiesFound
        actions.updateState()
        return
      }

      // Select Episode ready to play any intro scenes, check completions, then move to Ship (Home)
      const episode = getters.nextUncompletedEpisode.value
      if (episode) {
        cmsActions.selectEpisode(episode)
        // const session = getters.nextUncompletedSession.value
        // this.markCompletedAndAddLocationsSessions()
        _controlState.value.progress.shipBarData.completedPercent = actions.progress.calculateEpisodeProgress()

        const newState: StateVariables = {
          viewState: ViewState.Ship,
          shipMode: ShipMode.SessionLocked,
          sceneType: SceneType.EpisodeInScene,
          sceneMode: SceneMode.Ready,
        }
        this.updateState(true, newState)
      } else {
        _controlState.value.state.viewState = ViewState.Delay
        _controlState.value.state.delayMode =
          cmsGetters.activityPhase.value === ActivityPhase.Demo ? DelayMode.DemoComplete : DelayMode.NoActivitiesFound
        actions.updateState()
        return
      }
    },
    startSession(): void {
      _controlState.value.state.delayMode = DelayMode.None
      _controlState.value.state.taskMode = TaskMode.Warmups
      actions.configureSession()
      actions.updateState()
    },
    progress: {
      completeAStar() {
        _controlState.value.progress.starData.completed++
      },
      progressShow(data: { stars?: number }) {
        if (!_controlState.value.progress.visible) {
          _controlState.value.progress.visible = true
          setTimeout(() => {
            _controlState.value.progress.opacity = 1
          }, 500)
        }
        if (data.stars) {
          _controlState.value.progress.starData.stars = data.stars
          _controlState.value.progress.starData.completed = 0
        }
      },
      reset() {
        this.resetBar()
        this.resetStars()
        _controlState.value.progress.randomMorfPlays = 0
      },
      resetBar() {
        _controlState.value.progress.barData.completedPercent = 0
      },
      resetStars() {
        _controlState.value.progress.starData.stars = 0
        _controlState.value.progress.starData.completed = 0
      },
      resetRandomMorf() {
        _controlState.value.progress.randomMorfPlays = 0
      },
      calculateEpisodeProgress(): number {
        if (
          ((cmsGetters.activityPhase.value === ActivityPhase.RCT || cmsGetters.activityPhase.value === ActivityPhase.BroadRelease) &&
            cmsGetters.episodePhase.value === EpisodePhase.Second) ||
          cmsGetters.activityPhase.value === ActivityPhase.None
        ) {
          let completed = 0,
            total = 0
          const episode = cmsGetters.selectedEpisode.value.episode
          if (episode) {
            const sessions = episode.sets || []
            const completedSessionIDs: string[] = gameActions.getCompletedChildren(episode._id)
            sessions.forEach((s) => {
              const sCompleted = completedSessionIDs.includes(s._id)
              total++
              completed += sCompleted ? 1 : 0
            })
          }
          const percentage = (completed / total) * 100
          return percentage === 0 ? 1 : percentage
        } else if (
          ((cmsGetters.activityPhase.value === ActivityPhase.RCT || cmsGetters.activityPhase.value === ActivityPhase.BroadRelease) &&
            cmsGetters.episodePhase.value !== EpisodePhase.Second) ||
          cmsGetters.activityPhase.value === ActivityPhase.Demo
        ) {
          return 100
        }
        return 0
      },
    },
    exitToLogin() {
      _controlState.value.state.viewState = ViewState.Login
      _controlState.value.state.loginMode = LoginMode.LoggedOut
      _controlState.value.state.sceneMode = SceneMode.Finished
      actions.updateState()
    },
    exitToDashboard() {
      _controlState.value.state.viewState = ViewState.Login
      _controlState.value.state.loginMode = LoginMode.LoggedIn
      _controlState.value.state.sceneMode = SceneMode.Finished
      actions.updateState()
    },
    async playScenes(dontFade: boolean) {
      if (window.DontSleep) {
        try {
          window.DontSleep(
            'true',
            (success) => {
              console.log('Activated dontsleep: ' + success)
            },
            (error) => {
              console.log('Activate dontsleep error: ' + error)
            },
          )
        } catch (err) {
          // the wake lock request fails - usually system related, such being low on battery
          if (err) console.log(err)
        }
      }
      const nextScene = (scenes: string[]): string => {
        const furtherScenes = scenes.filter((scene, index) => {
          return index > scenes.indexOf(_controlState.value.selectedSceneSubpath)
        })
        if (furtherScenes.length > 0) {
          return furtherScenes[0]
        } else {
          return ''
        }
      }

      const s = nextScene(getSceneSet(_controlState.value.state.sceneType))

      // If scene is 'transparent' use the location background, otherwise use a black background
      if (_controlState.value.savedBackgroundImage === '') {
        _controlState.value.savedBackgroundImage = _controlState.value.backgroundImage
      }
      if (
        _controlState.value.state.sceneType !== SceneType.SessionInScene &&
        _controlState.value.state.sceneType !== SceneType.MorfologicalScene &&
        !(_controlState.value.state.sceneType === SceneType.GeneralScene && _controlState.value.state.viewState === ViewState.Tasks)
      ) {
        _controlState.value.backgroundImage = blackBackground
      }
      _controlState.value.fade = false

      const introWasSeen = gameGetters.introsSeen.value.includes(s)

      if (introWasSeen && s != '') {
        // Skip this scene if it was already played
        _controlState.value.selectedSceneSubpath = s
        _controlState.value.state.sceneMode = SceneMode.InProgress
        this.updateState(dontFade)
      } else if (s !== '' && !appGetters.disableScenes.value) {
        // There are more scenes to play... so go ahead and play them
        _controlState.value.selectedSceneSubpath = s
        _controlState.value.state.sceneMode = SceneMode.InProgress
        router.push('/game/scene/' + Date.now())
      } else {
        // We are done with scenes, go back to the main state update
        _controlState.value.selectedSceneSubpath = ''
        _controlState.value.state.sceneMode = SceneMode.Finished
        _controlState.value.backgroundImage = _controlState.value.savedBackgroundImage
        _controlState.value.savedBackgroundImage = ''
        //await wakeLock.release()
        if (window.DontSleep) {
          try {
            window.DontSleep(
              'false',
              (success) => {
                console.log('Deactivated dontsleep: ' + success)
              },
              (error) => {
                console.log('Dontsleep error ' + error)
              },
            )
          } catch (error) {
            console.log(error)
          }
        }
        this.updateState(dontFade)
      }
    },

    // Main state function called when State needs to be updated (_controlState.value.state)
    updateState(dontFade?: boolean, stateVariables?: StateVariables) {
      _controlState.value.fade = !dontFade
      if (stateVariables) setters.state = stateVariables

      setTimeout(
        async () => {
          // There may be more scenes to play
          if (_controlState.value.state.sceneMode !== SceneMode.Finished) {
            setTimeout(() => this.playScenes(!!dontFade), dontFade ? 0 : 500)
            // Otherwise decide which view to navigate to
          } else {
            const selectedSession = cmsGetters.selectedSession.value.session
            switch (_controlState.value.state.viewState) {
              // The app has just finished loading
              case ViewState.None:
                _controlState.value.state.loginMode = LoginMode.AppStarted
                _controlState.value.state.viewState = ViewState.Login
                actions.updateState()
                break

              case ViewState.Login:
                _controlState.value.backgroundImage = loginBackground
                cmsActions.resetStorage()
                actions.progress.reset()
                if (_controlState.value.state.loginMode === LoginMode.LoggedOut) {
                  userActions.selectUser()
                  userActions.clearMyUser()
                  gameActions.selectGame()
                  router.push('/')
                } else if (_controlState.value.state.loginMode === LoginMode.LoggedIn) router.push('/postlogin')
                break

              case ViewState.Loggedin:
                _controlState.value.backgroundImage = loginBackground
                break

              case ViewState.Ship:
                _controlState.value.backgroundImage = cmsGetters.selectedEpisode.value.episode?.location.image || ''
                //_controlState.value.backgroundImage = require('@/assets/images/map/locations/' +
                //  cmsGetters.selectedEpisode.value.episode?.location.image)

                // After playing an 'outro' we don't want to see the Ship view again
                if (
                  _controlState.value.state.shipMode === ShipMode.SessionCompleted &&
                  cmsGetters.activityPhase.value === ActivityPhase.RCT &&
                  cmsGetters.episodePhase.value !== EpisodePhase.First &&
                  selectedSession &&
                  cmsActions.getSessionPhase(selectedSession) === SessionPhase.Last
                ) {
                  _controlState.value.state.viewState = ViewState.Login
                  _controlState.value.state.loginMode = LoginMode.LoggedOut
                  _controlState.value.state.sceneMode = SceneMode.Finished
                  actions.updateState()
                } else {
                  router.push('/game/ship')

                  // Attempt to cache assets. We don't await this. Just let it run while we start the game.
                  actions.downloadAssetsForEpisode()
                }
                break

              case ViewState.Map:
                _controlState.value.fade = true
                router.push('/game/map')
                break

              case ViewState.Delay:
                // Why are we here?

                _controlState.value.fade = true
                router.push('/delay')
                break

              case ViewState.Tasks:
                cmsActions.selectTask(cmsGetters.selectedTaskSet.value[_controlState.value.currentTaskIndex])
                // Starting the first (uncompleted) task in the session
                if (cmsGetters.selectedTask.value.index === 0 && selectedSession) {
                  const tracking = new SessionTracking({
                    itemID: selectedSession._id,
                    gameID: gameGetters.selectedGame.value?._id || 'game undefined',
                    activityID: cmsGetters.selectedActivity.value.cmsID,
                    projectID: '',
                    type: TRACKING_TYPE.session,
                    description: selectedSession.name,
                    details: {
                      tasks_total: selectedSession.getTotalTaskCount(),
                      inactive_count: 0,
                      inactive_duration: 0,
                      tasks_completed: 0,
                    },
                    localSynced: false,
                    serverSynced: false,
                  })
                  actions.updateCurrentTracking(tracking, TRACKING_TYPE.session)
                  actions.progress.resetRandomMorf()
                  // Starting the last task in the session
                } else if (cmsGetters.selectedTask.value.index === cmsGetters.selectedTaskSet.value.length - 1) {
                  // Starting a task somewhere in the middle
                } else {
                  // At any time except warmups and the first time we change to Tasks: Play a random Morf popup scene if needed
                  if (getters.randomMorphTime.value && _controlState.value.state.taskMode === TaskMode.Tests) {
                    _controlState.value.state.sceneType = SceneType.GeneralScene
                    _controlState.value.state.sceneMode = SceneMode.Ready
                    this.playScenes(false)
                    break
                  }
                }

                _controlState.value.inactivityTimestamp = moment()
                router.push(`/game/task/${_controlState.value.currentTaskIndex}`)
                break
            }
            setTimeout(() => {
              _controlState.value.fade = false
            }, 500)
          }
        },
        dontFade ? 0 : 500,
      )
    },
    // Returns true/false for matching password
    // Rejects if no sessions found
    activateSession(session?: Session, password = ''): Promise<boolean> {
      const passwordsMatch = session?.password === password
      if (session && !session.activated && passwordsMatch) {
        cmsActions.selectSession(session)
        cmsActions.setSessionActivation(true)
        _controlState.value.state.shipMode = ShipMode.SessionUnlocked
        return Promise.resolve(true)
      } else {
        console.log('No session found')
        return Promise.resolve(false)
      }
    },
    skipSession(nextSession: Session, passwordText: string): boolean {
      if (SKIP_SESSION_PASSWORD === passwordText) {
        completeSettTree(nextSession, true)
        return true
      } else return false
    },
    // Go back to the server and load detailed Session and Task data for the NEXT session.
    getSessionWithTasks(session: Session): Promise<void> {
      appActions.logFeedback('Downloading session tasks..')
      const s: Session | undefined = cmsGetters.selectedEpisode.value.episode?.sets.find((ses) => ses._id === session._id)
      if (s) {
        s.clearTasks()
        return cmsActions.getQuestions(s, appGetters.languageCode.value).then(() => {
          _controlState.value.progress.shipBarData.completedPercent = actions.progress.calculateEpisodeProgress()
          return Promise.resolve()
        })
      } else return Promise.resolve()
    },
    // Download assets for the next two sessions
    async downloadAssetsForEpisode(): Promise<void> {
      const episode = cmsGetters.selectedEpisode.value.episode
      const currentSession = getters.nextUncompletedSession.value
      if (episode && episode.sets && episode.sets.length && currentSession) {
        const csIndex = episode.sets.findIndex((s) => s._id === currentSession._id)
        const downloadSets = csIndex >= 0 && csIndex < episode.sets.length - 1 ? [currentSession, episode.sets[csIndex + 1]] : [currentSession]
        for (const s of downloadSets) {
          appActions.logFeedback('Downloading next session for current episode..')
          const session = new Session() // A clean session is necessary to prevent duplicate tasks in the actual session
          session._id = s._id
          await cmsActions.getQuestions(session, appGetters.languageCode.value)
          await actions.downloadAssets(session)
        }
      }
    },
    async completeTask(trackingDetails?: TaskTracking): Promise<void> {
      let dontFade = true
      const selectedTaskSet = cmsGetters.selectedTaskSet.value
      const selectedSession = cmsGetters.selectedSession.value.session
      const selectedTask = cmsGetters.selectedTask.value.task

      // Fill in final details for a Task Tracking
      const completeQuestionTracking = (trackingDetails?: TaskTracking) => {
        const question = selectedTask
        const selectedSet = selectedSession
        const game = gameGetters.selectedGame.value
        const tracking = _controlState.value.trackings.taskTracking
        if (question && game && selectedSet && tracking && trackingDetails) {
          // Stop the Tracking and update it to the state store
          tracking.audioFile = deviceGetters.audioFilename.value
          const rootIndex = cmsGetters.root.value.indexOf(selectedSet.root)
          const trackingData: Partial<TaskTracking> = {
            details: {
              user: userGetters.myUser.value.profile.username,
              project: '',
              rootName: selectedSet.root.name,
              rootIndex,
              rootID: selectedSet.root._id,
              taskAttempts: game.itemAttempts(question._id, selectedSet._id),
              setCompletions: game.itemCompletions(selectedSet._id, selectedSet.parent?._id || ''),
              setID: selectedSet._id,
              ...trackingDetails.details,
            },
            localSynced: false,
            serverSynced: false,
          }
          tracking.update(trackingData)
          actions.updateCurrentTracking(tracking, TRACKING_TYPE.task)
        }
      }

      // Finish the tracking object (it requires Progress data)
      completeQuestionTracking(trackingDetails)

      // Only if the task is not a 'sample' task linked to from the CMS 'preview' button..
      if (_controlState.value.state.taskMode !== TaskMode.Sample && selectedSession && selectedTaskSet && selectedTask) {
        // Finish the Task log and update the Session log
        const tLog = _controlState.value.trackings.taskTracking
        if (tLog) {
          tLog.complete()
          gameActions.commitNewTracking(tLog)
          _controlState.value.trackings.taskTracking = undefined
        }
        // Update the Session log with the full duration
        const sLog = _controlState.value.trackings.sessionTracking
        if (sLog) {
          sLog.duration = moment().diff(moment(sLog.created), 'seconds')
          sLog.details.tasks_completed++
        }

        // Mark the current Task as complete
        gameActions.completeProgressForItem(selectedTask._id, selectedSession._id, selectedTask.reference)

        // If the Session is finished, also complete the Session Tracking
        if (
          selectedSession &&
          selectedTask &&
          (_controlState.value.state.taskMode !== TaskMode.Warmups || selectedSession.testTaskCount === 0) &&
          _controlState.value.currentTaskIndex === selectedTaskSet.length - 1 &&
          _controlState.value.trackings.sessionTracking
        ) {
          _controlState.value.trackings.sessionTracking.complete()
          gameActions.commitNewTracking(_controlState.value.trackings.sessionTracking)
          _controlState.value.trackings.sessionTracking = undefined
        }

        // Advance the task index
        if (_controlState.value.currentTaskIndex < selectedTaskSet.length) _controlState.value.currentTaskIndex++

        // Advance the progress bar
        //this._progress.barData.completedPercent =
        //  _controlState.value.currentTaskIndex / this._currentTaskSet.length * 100;
        const fullSessionTaskLength =
          _controlState.value.state.taskMode === TaskMode.Warmups ? selectedSession.warmupTaskCount : selectedSession.testTaskCount
        _controlState.value.progress.barData.completedPercent =
          ((fullSessionTaskLength - selectedTaskSet.length + _controlState.value.currentTaskIndex) / fullSessionTaskLength) * 100

        // Reset AudioContext. See here:
        // https://stackoverflow.com/questions/59866930/web-audio-api-not-playing-on-ios-version-13-3-works-on-older-versions-of-ios
        WebAudio.createAudioContext()
        await WebAudio.resumeAudioContext()

        setTimeout(
          async () => {
            // Was that the final Task?
            // End of the task set - all tasks complete. Allow the progress bar time to fill
            if (_controlState.value.currentTaskIndex === selectedTaskSet.length) {
              dontFade = false

              // After finishing warmup tasks, go to the next delay screen and then to test tasks
              if (_controlState.value.state.taskMode === TaskMode.Warmups) {
                _controlState.value.state.delayMode = DelayMode.WarmupsFinished
                _controlState.value.state.taskMode = TaskMode.Tests
                this.configureSession()
              } else {
                // Otherwise finish the Session and go back to the Map (unless this was a consolidation Session)
                completeSettTree(selectedSession)

                // Recalculate stardust level
                _controlState.value.progress.shipBarData.completedPercent = this.progress.calculateEpisodeProgress()

                // Pre-test & post-test: Go directly to exit scenes
                if (cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value !== EpisodePhase.Second) {
                  cmsActions.selectSession(selectedSession)
                  cmsActions.setSessionActivation(false)
                  _controlState.value.state.viewState = ViewState.Ship
                  _controlState.value.state.sceneMode = SceneMode.Ready
                  _controlState.value.state.sceneType = SceneType.EpisodeOutScene
                } else if (selectedSession.consolidation) {
                  // After Consolidation session: go directly to the Ship
                  cmsActions.selectSession(selectedSession)
                  cmsActions.setSessionActivation(false)
                  _controlState.value.state.sceneMode = SceneMode.Ready
                  _controlState.value.state.sceneType = SceneType.GeneralScene
                  _controlState.value.state.viewState = ViewState.Ship
                } else {
                  // Otherwise, to the Map
                  _controlState.value.state.viewState = ViewState.Map
                }
                _controlState.value.state.shipMode = ShipMode.SessionEnding
              }
            }
            // ( Else, there are more tasks to go; continue..)

            // Update the Game's Progress at the server
            await gameActions.updateGameProgress(false, undefined, true)

            // Send unsynced Trackings to the Server (this could also be attempted at the end of each Session)
            // We are not 'awaiting' this as there were complaints the changeover between tasks takes too long
            gameActions.sendTrackings()

            this.updateState(dontFade)
          },
          _controlState.value.currentTaskIndex === cmsGetters.selectedTaskSet.value.length ? 2000 : 1000,
        )
      }
    },

    // Check for Warmups and Test tasks, allocate them and determine appropriate states
    // If warmup tasks exist, function will be called a second time, controlled by TaskMode
    configureSession(): void {
      let shuffledTasks: QuestionUnion[]
      const selectedSession = cmsGetters.selectedSession.value.session
      if (selectedSession) {
        _controlState.value.currentTaskIndex = 0
        _controlState.value.backgroundImage = selectedSession.location?.image || ''

        // First entry only - Possible warmups, log, set up introductions and move to Test tasks
        if (_controlState.value.state.taskMode === TaskMode.Warmups) {
          // Play a Morfological Introduction + delay screens if called for
          // Else play a general intro scene and move directly to begin the Test tasks
          if (selectedSession.morfologicalIntro > 0) {
            _controlState.value.state.sceneType = SceneType.MorfologicalScene
            _controlState.value.state.delayMode = DelayMode.NoWarmups
          } else {
            if (selectedSession.consolidation) {
              _controlState.value.state.sceneType = SceneType.ConsolidationInScene
            } else {
              _controlState.value.state.sceneType = SceneType.SessionInScene
            }
          }
          _controlState.value.state.sceneMode = SceneMode.Ready

          // Check now for Warmup tasks
          shuffledTasks = selectedSession.getTasks(QUESTION_MODE.warmup, true)
          if (shuffledTasks.length > 0) {
            cmsActions.setTaskSet(shuffledTasks)

            // Exceptions for Pre-test and Post-test Episodes - no scenes or delay, go directly to warmup tasks
            if (cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value !== EpisodePhase.Second) {
              _controlState.value.state.sceneMode = SceneMode.Finished
              _controlState.value.state.viewState = ViewState.Tasks
            } else {
              _controlState.value.state.viewState = ViewState.Delay
              _controlState.value.state.delayMode = DelayMode.WarmupsStarting
            }

            // If there is no Morfological intro, but we are showing warmups, then turn off the intro scene
            if (
              _controlState.value.state.sceneType === SceneType.SessionInScene ||
              _controlState.value.state.sceneType === SceneType.ConsolidationInScene
            ) {
              _controlState.value.state.sceneMode = SceneMode.Finished
            }

            // Filter out any tasks that have already been done (in the case that this is a resumed Session)
            const completedTasks = gameActions.getCompletedChildren(selectedSession._id)
            const filteredTaskSet: QuestionUnion[] = cmsGetters.selectedTaskSet.value.filter((t) => !completedTasks.includes(t._id))
            if (filteredTaskSet.length > 0) cmsActions.setTaskSet(filteredTaskSet)
            else _controlState.value.state.taskMode = TaskMode.Tests
          } else {
            _controlState.value.state.taskMode = TaskMode.Tests
          }
        }

        // Test tasks - may be used on first or second entry to this function
        if (_controlState.value.state.taskMode === TaskMode.Tests && selectedSession) {
          shuffledTasks = selectedSession.getTasks(QUESTION_MODE.test, true)
          if (shuffledTasks.length > 0) {
            cmsActions.setTaskSet(shuffledTasks)
            if (_controlState.value.state.delayMode === DelayMode.WarmupsFinished || _controlState.value.state.delayMode === DelayMode.NoWarmups) {
              _controlState.value.state.viewState = ViewState.Delay
            } else {
              _controlState.value.state.viewState = ViewState.Tasks
            }
          } else {
            // If no tasks exist, consider the Session finished
            _controlState.value.state.viewState = ViewState.Delay
            _controlState.value.state.delayMode = DelayMode.NoSessionsFound
          }

          // Filter out any tasks that have already been done (in the case that this is a resumed Session)
          // If there are logs available, either they have not been synced or the session was not completed
          // NOTE: Only recovering from a restart during test tasks, not warm-ups
          const completedTasks = gameActions.getCompletedChildren(selectedSession._id)
          const filteredTaskSet: QuestionUnion[] = cmsGetters.selectedTaskSet.value.filter((t) => !completedTasks.includes(t._id))
          cmsActions.setTaskSet(filteredTaskSet)
        }

        setTimeout(() => {
          const fullSesisonTaskLength =
            _controlState.value.state.taskMode === TaskMode.Warmups ? selectedSession.warmupTaskCount : selectedSession.testTaskCount
          _controlState.value.progress.barData.completedPercent =
            ((fullSesisonTaskLength - cmsGetters.selectedTaskSet.value.length) / fullSesisonTaskLength) * 100
        }, 500)
      }
    },
  }

  return {
    getters,
    setters,
    actions,
  }
}
export default useStateService
