<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col">
    <label v-if="label" class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" :for="elementId" :class="{ 'ml-5': border }">
      {{ label }}
    </label>
    <p v-if="description" class="text-xs max-w-sm">{{ description }}</p>
    <div :id="elementId" class="flex flex-col">
      <div v-for="o in options" :key="o.id" class="py-1">
        <input
          :id="`${elementId}-option-${o.title.replace(/\s/g, '')}`"
          v-model="selectedMultiChoice"
          class="mr-1 mb-1"
          type="checkbox"
          :value="o.id"
          @change="valueInput"
        />
        <label class="mr-2" :for="`${elementId}-option-${o.title.replace(/\s/g, '')}`">{{ o.title }}</label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, toRefs, Ref, watch, PropType } from 'vue'

  interface OptionItem {
    id: string
    title: string
  }
  const emit = defineEmits(['change', 'update:modelValue'])
  const props = defineProps({
    modelValue: {
      type: Object as PropType<string[]>,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<OptionItem[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // Use for scaling the input size for text input
    customSize: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: true,
    },
  })
  const { modelValue, id } = toRefs(props)
  const selectedMultiChoice: Ref<string[]> = ref([])
  const elementId = id.value ? id.value : 'ai-id-' + Math.floor(Math.random() * 10000000)

  selectedMultiChoice.value = modelValue.value

  watch(
    () => props.modelValue,
    (newValue) => {
      selectedMultiChoice.value = newValue
    },
  )

  const valueInput = ($event: Event): void => {
    // const ie = $event.target as HTMLInputElement
    $event.stopImmediatePropagation()
    let value: unknown
    value = selectedMultiChoice.value
    emit('update:modelValue', value) // If using v-model on this element, this is the updated value
    emit('change', value) // Also possible to listen for this change event if not using v-model
  }
</script>

<style lang="postcss" scoped>
  .valid-entry {
    @apply bg-green-400;
  }
  .invalid-entry {
    @apply bg-red-400;
  }
  label {
    @apply pointer-events-none;
  }
</style>
