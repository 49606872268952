<!-- Copyright 2020, 2021 Richard Nesnass

 This file is part of Kaptein Morf.

 VIVA is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 VIVA is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with VIVA.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <canvas ref="drawingCanvas"></canvas>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, nextTick, Ref } from 'vue'
  import { WordIndexName } from '@/constants'
  import { MP3Audio, WebAudio } from '@/utilities'
  export interface Word {
    text: string
    correctBox?: string
    enabled: boolean
    draggable: boolean
    type?: string
    id: string
    opacity: number
    audio?: MP3Audio | WebAudio
    fontSize?: string
    holdPositionInWords?: number
    reference?: WordIndexName
  }
  export interface LinkedWord {
    word: Word
    startX: number
    'startX%'?: number
    startY: number
    'startY%'?: number
    startElement: Element
    endX: number
    'endX%'?: number
    endY: number
    'endY%'?: number
    endElement?: Element
  }
  export interface Message {
    message?: string
    word?: LinkedWord
    event?: MouseEvent | TouchEvent
  }
  export default defineComponent({
    name: 'ChildComponent',
    setup: () => {
      const drawingCanvas: Ref<HTMLCanvasElement | undefined> = ref()

      let ctx: CanvasRenderingContext2D
      let currentWidth = 0
      let currentHeight = 0
      // variable that decides if something should be drawn on mousemove
      let drawing = false
      let strokeColour = '#1DEf3F' // "rgba(255, 0, 255, 0.7)";

      // the last coordinates before the current move
      let startX: number
      let startY: number
      let confirmedLines: LinkedWord[] = []

      let choke = 0

      const arrowHead = (x: number, y: number, rot: number) => {
        ctx.save()
        ctx.translate(x, y)
        ctx.rotate(rot)
        ctx.beginPath()
        ctx.moveTo(0, 5)
        ctx.lineTo(-20, -30)
        ctx.lineTo(20, -30)
        ctx.closePath()
        ctx.fillStyle = strokeColour
        ctx.fill()
        ctx.restore()
      }

      const drawConfirmedLines = () => {
        let x1 = 0,
          x2 = 0,
          y1 = 0,
          y2 = 0

        confirmedLines.forEach((line) => {
          ctx.lineWidth = 5
          ctx.beginPath()

          // We now need to account for shuffling of items, so calculate start coordinates from the element reference

          // x1 = line['startX%'] * currentWidth;
          // y1 = line['startY%'] * currentHeight;
          // x2 = line['endX%'] * currentWidth;
          // y2 = line['endY%'] * currentHeight;

          let rect = line.startElement.getBoundingClientRect()
          x1 = rect.left + (rect.right - rect.left) / 2
          y1 = rect.top + (rect.bottom - rect.top) / 2

          if (line.endElement) {
            rect = line.endElement.getBoundingClientRect()
            x2 = rect.left + (rect.right - rect.left) / 2
            y2 = rect.top + (rect.bottom - rect.top) / 2
          }

          let rot = -Math.atan2(x1 - x2, y1 - y2)

          // start location
          ctx.moveTo(x1, y1)
          // to
          ctx.lineTo(x2, y2)

          // color
          ctx.strokeStyle = strokeColour
          // draw it
          ctx.stroke()
          // draw arrowhead at start
          // arrowHead(x1, y1, rot);
          // draw arrowhead at end
          arrowHead(x2, y2, rot + Math.PI)
        })
      }

      const draw = (lX: number, lY: number, cX: number, cY: number) => {
        // line from
        ctx.moveTo(lX, lY)
        // to
        ctx.lineTo(cX, cY)
        // color
        ctx.strokeStyle = strokeColour // Blue = #4bf
        // draw it
        ctx.stroke()
        // draw arrow head
        let rot = -Math.atan2(lX - cX, lY - cY)
        arrowHead(cX, cY, rot + Math.PI)
      }

      const confirmLine = (linkedWord: LinkedWord) => {
        linkedWord['startX%'] = linkedWord.startX / currentWidth
        linkedWord['startY%'] = linkedWord.startY / currentHeight

        linkedWord['endX%'] = linkedWord.endX / currentWidth
        linkedWord['endY%'] = linkedWord.endY / currentHeight

        confirmedLines.push(linkedWord)
      }

      const resizeWindow = () => {
        if (drawingCanvas.value) {
          drawingCanvas.value.width = window.innerWidth
          drawingCanvas.value.height = window.innerHeight

          currentWidth = drawingCanvas.value.width
          currentHeight = drawingCanvas.value.height
        }
        drawConfirmedLines()
      }

      onMounted(() => {
        nextTick(() => {
          if (drawingCanvas.value) {
            let context = drawingCanvas.value.getContext('2d')
            if (context) ctx = context
            // subscription = messageService.messageSubject.subscribe((message) => onNewMessage(message))
            confirmedLines = []
            choke = 0
            resizeWindow()
          }
        })
      })

      /* Call this function from the parent when events occur
     e.g. <Drawing ref="drawing" />
     drawing.value.message(messageString)
  */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const message = (m: Message) => {
        // do something with added item
        switch (m.message) {
          case 'morfologiTaskLineClear':
            confirmedLines = []
            myMouseUp()
            break
          case 'morfologiTaskLine':
            if (m.word) confirmLine(m.word)
            drawConfirmedLines()
            break
          case 'morfologiTaskMousedown':
            if (m.word) {
              startX = m.word.startX
              startY = m.word.startY
              drawing = true
            }
            break
          case 'morfologiTaskMouseup':
            myMouseUp()
            break
          case 'morfologiTaskMousemove':
            if (m.event) myMouseMove(m.event)
            break
          case '':
        }
      }

      const myMouseUp = () => {
        // clear canvas
        ctx.clearRect(0, 0, currentWidth, currentHeight)

        // stop drawing
        drawing = false
        drawConfirmedLines()
      }

      const myMouseMove = (event: MouseEvent | TouchEvent) => {
        choke = choke === 2 ? 0 : ++choke
        if (choke === 0 && drawing) {
          // clear canvas
          ctx.clearRect(0, 0, currentWidth, currentHeight)

          drawConfirmedLines()
          ctx.lineWidth = 5
          // begins new line
          ctx.beginPath()

          let currentX = 0
          let currentY = 0
          // get current mouse position
          if (event.type.includes('touch')) {
            const e = event as TouchEvent
            currentX = e.changedTouches[0].clientX
            currentY = e.changedTouches[0].clientY
          } else if ((event as MouseEvent).clientX) {
            const e = event as MouseEvent
            currentX = e.clientX
            currentY = e.clientY
          }

          draw(startX, startY, currentX, currentY)
        }
      }
      return {
        message,
        drawingCanvas,
      }
    },
  })
</script>

<style scoped lang="postcss"></style>
