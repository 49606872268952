<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative w-full h-full bg-gray-background" @mousedown="registerActivity()" @touchstart="registerActivity()">
    <!-- Skip Task button. No 'tracking' will be saved -->
    <div v-if="appGetters.disableDelays.value" class="absolute top-0 right-24 text-xs flex flex-col z-50">
      <button class="m-1 text-black bg-white rounded-sm p-2" @click="completed(true, undefined)">{{ t('skipOver') }}</button>
      <p class="text-yellow-400">{{ `S: ${cmsGetters.sessionIndex.value}` }}<br /></p>
      <p class="text-yellow-400">{{ `T: ${selectedTask?.reference}` }}</p>
    </div>

    <div class="absolute top-1 right-1 text-xs flex flex-col z-50 rounded" @mousedown.stop @touchstart.stop>
      <img
        v-if="!skipTaskContainerIsVisible"
        class="w-10"
        style="background-color: rgba(0, 0, 1, 0.01)"
        :src="emptyStarBlue"
        @click.stop="skipTaskContainerIsVisible = true"
      />
      <div v-else class="flex flex-col">
        <input v-model="skipPassword" class="bg-slate-200 rounded p-2" type="password" />
        <button class="mt-2 text-black rounded-sm p-2 bg-green-400" @click.stop="skipTaskWithPassword()">{{ t('skipOver') }}</button>
      </div>
    </div>

    <!-- Sample button -->
    <div v-if="!begin" class="absolute w-full h-full flex flex-row justify-center place-items-center">
      <Button :childclass="'w-32 text-black'" :backgroundcolour="'bg-white'" @click="continueSampleTask()">Sample</Button>
    </div>
    <!-- Tasktype component should show here ⬇ -->
    <component
      :is="currentTasktype"
      v-if="loaded && selectedTask && begin"
      :id="selectedTask._id"
      ref="selectedTaskTemplateRef"
      :key="selectedTask._id"
      class="absolute"
      :task="selectedTask"
      :set="selectedSet"
      @completed="completed"
      @not-completed="completed(false, undefined)"
    ></component>

    <!-- Overlaid screen e.g. progress bars -->
    <Speaker class="z-40" />
    <ProgressStars class="z-40" />
    <ProgressBar class="z-40" />
  </div>
</template>

<script setup lang="ts">
  import { ref, shallowRef, Ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter, onBeforeRouteUpdate } from 'vue-router'
  import useCMSStore from '@/store/useCMSStore'
  import useAppStore from '@/store/useAppStore'
  import useDeviceService from '@/composition/useDevice'
  import useGameStore from '@/store/useGameStore'
  import useState from '@/composition/useState'
  import {
    // AugmentedQuestionType,
    Game,
    TaskTracking,
    TRACKING_TYPE,
  } from '@/models/main'
  import emptyStarBlue from '@/assets/images/stars/blue/BlueStarEmptyState100x100px.png'
  import { Session } from '@/models/navigationModels'
  import { QuestionUnion } from '@/models/tasktypes'
  import { LanguageCodes } from '@/constants'
  import Button from '@/components/base/Button.vue'
  import Speaker from './Speaker.vue'
  import ProgressBar from './ProgressBar.vue'
  import ProgressStars from './ProgressStars.vue'
  import * as taskComponents from './tasks'

  const theComponents = {
    Tasktype1: taskComponents.Tasktype1,
    Tasktype2: taskComponents.Tasktype2,
    Tasktype3: taskComponents.Tasktype3,
    Tasktype4: taskComponents.Tasktype4,
    Tasktype5: taskComponents.Tasktype5,
    Tasktype6: taskComponents.Tasktype6,
    Tasktype7: taskComponents.Tasktype7,
    Tasktype8: taskComponents.Tasktype8,
    Tasktype9: taskComponents.Tasktype9,
    Tasktype10: taskComponents.Tasktype10,
    Tasktype11: taskComponents.Tasktype11,
    Tasktype12: taskComponents.Tasktype12,
    Tasktype13: taskComponents.Tasktype13,
  }

  type allComponentsType =
    | typeof taskComponents.Tasktype1
    | typeof taskComponents.Tasktype2
    | typeof taskComponents.Tasktype3
    | typeof taskComponents.Tasktype4
    | typeof taskComponents.Tasktype5
    | typeof taskComponents.Tasktype6
    | typeof taskComponents.Tasktype7
    | typeof taskComponents.Tasktype8
    | typeof taskComponents.Tasktype9
    | typeof taskComponents.Tasktype10
    | typeof taskComponents.Tasktype11
    | typeof taskComponents.Tasktype12
    | typeof taskComponents.Tasktype13

  const props = defineProps({
    // These props are provided by the router when a Sample task is called for
    projecttype: {
      type: String,
      default: '',
    },
    schema: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'no',
    },
  })
  const { locale, t } = useI18n({ useScope: 'global' })
  const router = useRouter()
  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()
  const { actions: deviceActions } = useDeviceService()
  const { actions: stateActions } = useState()

  // Data
  const selectedSet = cmsGetters.selectedSession.value.session
  const selectedTask: Ref<QuestionUnion | undefined> = ref()
  const selectedTaskTemplateRef = ref() // This is a 'ref' template reference to the child

  // Control
  const loaded = ref(false)
  const begin = ref(false)
  const skipTaskContainerIsVisible = ref(false)
  const skipPassword = ref('')
  //export type TaskComponentInstance = InstanceType<allComponentsType>
  const currentTasktype: Ref<allComponentsType> = shallowRef(taskComponents['Tasktype1'])

  //console.dir(questionClasses)

  appActions.setFade(false)

  const registerActivity = () => {
    skipTaskContainerIsVisible.value = false
    stateActions.resetInactiveTimer()
  }

  // When we load this component, or if the list of questions changes,
  // this will load them to a local array, shuffle if called for, and select the first item
  // Prepare this local Question component for the new incoming question
  function setupQuestion() {
    selectedTask.value = cmsGetters.selectedTask.value.task
    const game = gameGetters.selectedGame.value
    if (game && selectedTask.value) {
      const className = selectedTask.value.__typename as keyof typeof theComponents
      currentTasktype.value = taskComponents[className]
      const tracking = new TaskTracking({
        itemID: selectedTask.value._id,
        gameID: game._id,
        activityID: cmsGetters.selectedActivity.value.cmsID,
        projectID: '',
        type: TRACKING_TYPE.task,
        description: selectedTask.value.reference,
        localSynced: false,
        serverSynced: false,
      })
      stateActions.updateCurrentTracking(tracking, TRACKING_TYPE.task)
      if (selectedTask.value.recordAudio) {
        deviceActions.createAudio().then(() => deviceActions.startRecordingAudio())
      }
    }
    loaded.value = true
  }

  function skipTaskWithPassword() {
    const p = cmsGetters.selectedActivity.value.activity?.skipTaskPassword
    console.log(skipPassword.value)
    if (skipPassword.value != '' && skipPassword.value == p) {
      completeQuestion(true, undefined)
    }
    skipPassword.value = ''
    skipTaskContainerIsVisible.value = false
  }

  function continueSampleTask() {
    begin.value = true
    setupQuestion()
  }

  /* If this is a Sample question, check the props to find out.
   * The Sample question comes from a Squidex redirect of this format:
   *   https://[app.host]/#/sample/[projectType]/[projectName]/${schemaName}/${id}
   *
   * '#' Include the # character if the app router is not in 'history' mode (Engagelab server)
   * projectType -- determines templates used to display data
   * projectName -- determines project queried for data
   * ${schemaName} -- interpolated from schema data **must include ${} for Squidex**
   * ${id} -- interpolated from schema data **must include ${} for Squidex**
   */
  onMounted(() => {
    // Set up for a CMS sample question
    if (props.schema && props.id && props.language) {
      const session = new Session()
      cmsActions.selectSet(session, 0, 0)

      // Create a sample Game
      const g = new Game()
      gameActions.setGames([g])
      gameActions.selectGame(g)

      // Retrieve the question from CMS and display it
      appActions.setLanguageCode(props.language as LanguageCodes)
      locale.value = props.language as string
      cmsActions.getQuestionByID(props.schema, props.id, appGetters.languageCode.value, session).then((task) => {
        if (task) cmsActions.selectTask(task)
      })
    } else {
      begin.value = true
      setupQuestion()
    }
  })

  onBeforeRouteUpdate((to, from) => {
    if (from.path.includes('/game/task')) setupQuestion()
  })

  // Post-processing a completed question, before moving to the next or back to the Dashboard
  // If we exited the question early, 'finished' is false, and we don't store progression info
  const completeQuestion = async (finished: boolean, results?: TaskTracking) => {
    if (selectedTask.value && selectedSet) {
      // If recording audio, stop recording now
      if (selectedTask.value.recordAudio) {
        deviceActions.stopRecordingAudio()
      }

      // If we exited early, don't advance the question
      if (!finished) {
        appActions.setFade(false)
        return router.push('/dashboard/ship')
      }

      // Allow the state machine to decide what to do next. This includes completing Progress and Trackings
      stateActions.completeTask(results)
    }
  }

  // After this signal from the Question, we wait for the user to click 'forward'
  const completed = (finished: boolean, results?: TaskTracking) => {
    appActions.setFade(true)
    setTimeout(() => {
      loaded.value = false
      completeQuestion(finished, results)
    }, 350)
  }
</script>

<style lang="postcss"></style>
