/*
 Designed and developed by Richard Nesnass and Hoang Bao Ngo

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
// useCMSStore are the same across projects,
// however they provide the junction to each project's unique data structures in neighboring directories
import { ref, Ref, computed, ComputedRef } from 'vue'
import { ActivityPhase, SessionPhase, EpisodePhase, TASK_TYPES, CMS_TASK_NAMES, LanguageCodes } from '@/constants'
import { cmsRequest } from '@/api/cmsService'
import useAppStore from '@/store/useAppStore'
import { Sett, CmsGQLQuery, CmsGQLData, CmsQuestionData } from '@/models/main'
import { Activity, ActivityData, Episode, Session } from '@/models/navigationModels'
import { TaskTypes } from '@/models/tasktypes'
import { QuestionDataIntersection, QuestionUnion, QuestionQueries } from '@/models/tasktypes'

// Query strings for each project
import navigationQuery from '../graphql/navigationQuery.gql'
import questionsQuery from '../graphql/questionsQuery.gql'
import singleQuestionQuery from '../graphql/singleQuestionQuery.gql'

const cmsAppName = (import.meta.env.VITE_SQUIDEX_APP_NAME || '') as string

// ------------  State (internal) --------------

interface State {
  root: Sett[] // This is the root containing first level of Sett where we begin Layout navigation
  dict: { [id: string]: Sett } // A dictionary version of the same root data, indexed by 'id'
  selectedSet: {
    set: Activity | Episode | Session | Sett | undefined // Currently selected Sett.  In DSLPlus, this will be a Day or a Week
    index: number // Index of the selected Sett in its parent's sett[]
    level: number // level of this sett below root (root = level 0)
  }
  selectedActivity: {
    cmsID: string // ID of the Activity in Squidex
    activity?: Activity
  }
  selectedEpisode: {
    episode?: Episode
    index: number // Index of this task in the selectedActivity
  }
  selectedSession: {
    session?: Session
    index: number // Index of this task in the selectedEpisode
  }
  selectedTaskSet: QuestionUnion[] // This can be a list of warmup tasks, or a list of test tasks
  selectedTask: {
    task?: QuestionUnion
    index: number // Index of this task in the selectedTaskSet
  }
  overrideCMS: boolean
}

const state: Ref<State> = ref({
  root: [],
  dict: {},
  selectedSet: {
    set: undefined,
    index: -1,
    level: 0,
  },
  selectedActivity: {
    cmsID: '',
    activity: undefined,
  },
  selectedEpisode: {
    episode: undefined,
    index: -1,
  },
  selectedSession: {
    session: undefined,
    index: -1,
  },
  selectedTaskSet: [], // May be shuffed
  selectedTask: {
    task: undefined,
    index: -1, // Index based on selectedTaskSet, which may have been shuffled
  },
  overrideCMS: false,
})

interface Getters {
  root: ComputedRef<Sett[]>
  selectedSet: ComputedRef<State['selectedSet']>
  selectedActivity: ComputedRef<State['selectedActivity']>
  selectedEpisode: ComputedRef<State['selectedEpisode']>
  selectedSession: ComputedRef<State['selectedSession']>
  selectedTask: ComputedRef<State['selectedTask']>
  selectedTaskSet: ComputedRef<State['selectedTaskSet']>
  activityPhase: ComputedRef<ActivityPhase>
  episodePhase: ComputedRef<EpisodePhase>
  sessionIndex: ComputedRef<number>
  overrideCMS: boolean
}
interface Actions {
  resetStorage: () => void
  getSets: (language: LanguageCodes) => Promise<void>
  setById: (id: string) => Sett
  getQuestions: (set: Session, language: LanguageCodes) => Promise<void>
  getQuestionByID: (schema: string, id: string, language: LanguageCodes, session: Session) => Promise<QuestionUnion | void>
  selectSet: (set: Activity | Episode | Session | Sett | undefined, index: number, level: number) => void
  selectEpisode: (episode: Episode) => void
  selectSession: (session: Session) => void
  setSessionActivation: (activate: boolean, session?: Session) => void
  setActivityID: (activityID: string) => void
  setTaskSet: (tasks: QuestionUnion[]) => void
  selectTask: (task: QuestionUnion) => void
  getSessionPhase(session?: Session): SessionPhase
  overrideCMS(): void
}
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
function useCMSStore(): ServiceInterface {
  const appStore = useAppStore()

  // ------------  Internal functions ------------

  async function fetchSets(language: LanguageCodes): Promise<CmsGQLQuery> {
    const query = navigationQuery.loc.source.body as string
    const variables = { __activityID: state.value.selectedActivity.cmsID, __language: language }
    return cmsRequest(cmsAppName, query, variables, language)
  }

  async function fetchQuestionsForSet(sett: Session, language: LanguageCodes): Promise<CmsGQLQuery> {
    if (sett) {
      const fs = Object.values(QuestionQueries).reduce((acc, curr) => acc + '\n' + curr.loc?.source.body, '')
      //const fragments = fragmentsQuery.loc.source.body as string
      const questions = questionsQuery.loc.source.body as string
      const query = fs + questions
      const variables = { __setID: sett._id, __language: language }
      return cmsRequest(cmsAppName, query, variables, language)
    } else return Promise.resolve({})
  }

  async function fetchQuestionByID(schema: string, id: string, language: LanguageCodes): Promise<CmsGQLQuery> {
    const s = String(schema).toPascalCase() as keyof typeof QuestionQueries
    const fragment = QuestionQueries[s]
    //const fragments = fragmentsQuery.loc.source.body as string
    const question = singleQuestionQuery.loc.source.body as string
    const query = fragment.loc?.source.body + question
    const variables = {
      __findSchemaContent: `find${s}Content`,
      __taskType: s,
      __questionID: id,
      __language: language,
    }
    return cmsRequest(cmsAppName, query, variables, language)
  }

  // Add a single task type to a session
  function addQuestion(taskData: CmsGQLData, session: Session, language: LanguageCodes, type: string): QuestionUnion | void {
    if (taskData.data) {
      const className = <TASK_TYPES>taskData.__typename
      if (className) {
        const QModel = TaskTypes[className]
        const newModel = new QModel(taskData as unknown as QuestionDataIntersection, language, session)
        if (type === 'warmups') session.addWarmupQuestion(newModel, className)
        else session.addTestQuestion(newModel, className)
        return newModel
      } else {
        console.log(`Incorrect className undefined for data: ${taskData.data.__typename}`)
        return
      }
    }
  }

  // Add a collection of task types supplied by a query to a Session's 'warmup' and 'test' tasks
  function addQuestions(taskTypeLists: { [key in CMS_TASK_NAMES]: CmsGQLData[] }, session: Session, language: LanguageCodes, type: string): void {
    Object.values(CMS_TASK_NAMES).forEach((k: CMS_TASK_NAMES) => {
      const taskList = taskTypeLists[k] || []
      taskList.forEach((task) => addQuestion(task, session, language, type))
    })
  }

  // -------------- Getters -------------------

  const getters = {
    get root(): ComputedRef<Sett[]> {
      return computed(() => state.value.root)
    },
    get selectedSet(): ComputedRef<State['selectedSet']> {
      return computed(() => state.value.selectedSet)
    },
    get selectedActivity(): ComputedRef<State['selectedActivity']> {
      return computed(() => state.value.selectedActivity)
    },
    get selectedEpisode(): ComputedRef<State['selectedEpisode']> {
      return computed(() => state.value.selectedEpisode)
    },
    get selectedSession(): ComputedRef<State['selectedSession']> {
      return computed(() => state.value.selectedSession)
    },
    get selectedTask(): ComputedRef<State['selectedTask']> {
      return computed(() => state.value.selectedTask)
    },
    get selectedTaskSet(): ComputedRef<State['selectedTaskSet']> {
      return computed(() => state.value.selectedTaskSet)
    },
    get activityPhase(): ComputedRef<ActivityPhase> {
      return computed(() => state.value.selectedActivity.activity?.activityPhase || ActivityPhase.None)
    },
    get episodePhase(): ComputedRef<EpisodePhase> {
      const activity = state.value.selectedActivity.activity
      // For RCT:  1: Training on home planet    2:  Earth   3,4,5: Past-Tests
      return computed(() => {
        return activity ? state.value.selectedEpisode.index + 1 : EpisodePhase.None
      })
    },
    get sessionIndex(): ComputedRef<number> {
      return computed(() => state.value.selectedSession.index)
    },
    get overrideCMS(): boolean {
      if (state.value.overrideCMS) {
        state.value.overrideCMS = false
        return true
      } else return false
    },
  }

  // ------------  Actions --------------

  const actions = {
    overrideCMS: function (): void {
      state.value.overrideCMS = true
    },
    resetStorage(): void {
      state.value.selectedActivity = { activity: undefined, cmsID: '' }
      state.value.root.length = 0
      state.value.selectedEpisode = { episode: undefined, index: -1 }
      state.value.selectedSession = { session: undefined, index: -1 }
      state.value.selectedTask = { task: undefined, index: -1 }
      state.value.selectedTaskSet = []
    },
    // Retrieve from CMS the Set data for top levels of navigation
    getSets: async function (language: LanguageCodes): Promise<void> {
      if (state.value.root.length === 0 || state.value.overrideCMS) {
        console.log('SL: Get CMS data...')
        appStore.actions.setLoading(true)
        const response: CmsGQLQuery = await fetchSets(language)
        const newRoot: Activity[] = []
        if (response.data) {
          const topLevelDataList = response.data.results as ActivityData
          const newLevel = new Activity(topLevelDataList, 0)
          newRoot.push(newLevel)
          state.value.dict[newLevel._id] = newLevel
          newLevel.sets.forEach((d) => (state.value.dict[d._id] = d))
          // If User has an assigned Acticity ID, set it as selectedActivity
          if (state.value.selectedActivity.cmsID === newLevel._id) {
            state.value.selectedActivity.activity = newLevel
          }
          // Sort root Sets by index key
          if (newRoot.length > 0) {
            newRoot.sort((a, b) => a.index - b.index)
          }
          state.value.root = newRoot
        } else {
          const error = 'Sett query contains no records'
          appStore.actions.setError(error)
          console.log(error)
        }
        appStore.actions.setLoading(false)
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    },
    setById(id: string): Sett {
      return state.value.dict[id]
    },

    // Retrieve Question data for a given Sett
    // Relies on a Sett containing Questions being currently selected
    getQuestions: async function (session: Session, language: LanguageCodes): Promise<void> {
      appStore.actions.setLoading(true)
      // If we don't find a session in the current episode
      // const session = state.value.selectedEpisode.episode?.sets.find((ses) => ses._id === session._id)
      // If session not found we have a problem
      if (!session) return Promise.reject('Error finding Session')
      // Don't continue if we already have the questions for given Sett
      if (session && session.questions && session.questions.length > 0) return Promise.resolve()

      const response: CmsGQLQuery = await fetchQuestionsForSet(session, language)
      if (!response.data) {
        const error = 'Question query contains no records'
        console.error(response.errors ? response.errors : error)
        appStore.actions.setError(error)
        return Promise.reject()
      }
      const questions: CmsQuestionData = response.data.results as CmsQuestionData
      let tasks = questions.flatData.warmups
      addQuestions(tasks, session, language, 'warmups')
      tasks = questions.flatData.tests
      addQuestions(tasks, session, language, 'tests')
      appStore.actions.setLoading(false)
      return Promise.resolve()
    },

    // This is used to construct a 'sample' Question
    getQuestionByID: async function (schema: string, id: string, language: LanguageCodes, session: Session): Promise<QuestionUnion | void> {
      appStore.actions.setLoading(true)
      const response: CmsGQLQuery = await fetchQuestionByID(schema, id, language)
      if (response.data) {
        const taskData: CmsGQLData = response.data.results as CmsGQLData
        if (taskData) {
          const newQuestion = addQuestion(taskData, session, language, 'tests')
          return Promise.resolve(newQuestion)
        }
      }
      appStore.actions.setLoading(false)
      return Promise.resolve()
    },
    // Used by Layout vue, which changing the displayed Sett grid
    selectSet: function (set: Activity | Episode | Session | Sett | undefined, index: number, level: number): void {
      state.value.selectedSet.set = set
      state.value.selectedSet.index = index
      state.value.selectedSet.level = level
    },
    setActivityID(activityID: string): void {
      state.value.selectedActivity.cmsID = activityID
    },
    selectEpisode(episode: Episode): void {
      state.value.selectedEpisode.episode = episode
      if (state.value.selectedActivity.activity) state.value.selectedEpisode.index = state.value.selectedActivity.activity.sets.indexOf(episode)
    },
    selectSession(session: Session): void {
      if (state.value.selectedEpisode.episode) {
        const sessionIndex = state.value.selectedEpisode.episode.sets.findIndex((s) => s._id === session._id)
        if (state.value.selectedEpisode.episode && sessionIndex > -1) {
          const s = state.value.selectedEpisode.episode.sets[sessionIndex]
          state.value.selectedSession.session = s
          state.value.selectedSession.index = sessionIndex
        }
      } else {
        console.log('Session cannot be set because Episode is not defined')
      }
    },
    setSessionActivation(activate: boolean, session?: Session): void {
      const s = session || state.value.selectedSession.session
      if (s) s.activated = activate
    },
    setTaskSet(tasks: QuestionUnion[]): void {
      state.value.selectedTaskSet = tasks
    },
    selectTask(task: QuestionUnion): void {
      state.value.selectedTask.task = task
      if (state.value.selectedTaskSet.length) {
        state.value.selectedTask.index = state.value.selectedTaskSet.indexOf(task)
      }
    },
    // Session numbers are fixed to ISP's requirements
    getSessionPhase(session?: Session): SessionPhase {
      let si = -1
      const selectedEpisode = state.value.selectedEpisode.episode
      if (!session) return SessionPhase.Unknown
      if (selectedEpisode) si = selectedEpisode.sets.indexOf(session) + 1
      // const sessionCount = this.selectedEpisode.sessions.length;

      if (selectedEpisode && selectedEpisode.sets.length === 1) {
        return SessionPhase.FirstAndLast
      } else if (si === 1) {
        return SessionPhase.First
      } else if (si > 1 && si <= 5) {
        // Includes first consolidation session
        return SessionPhase.One
      } else if (si > 5 && si <= 10) {
        return SessionPhase.Two
      } else if (si > 10 && si <= 15) {
        return SessionPhase.Three
      } else if (si > 15 && si <= 20) {
        return SessionPhase.Four
      } else if (si > 20 && si <= 25) {
        return SessionPhase.Five
      } else if (si > 25 && si <= 30) {
        return SessionPhase.Six
      } else if (si > 30 && si <= 35) {
        return SessionPhase.Seven
      } else if (si > 35 && si <= 38) {
        return SessionPhase.Eight
      } else if (si == 39) {
        return SessionPhase.Penultimate
      } else if (si == 40) {
        return SessionPhase.Last
      }
      return SessionPhase.Unknown
    },
  }

  // This defines the interface used externally

  return {
    getters,
    actions,
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
export default useCMSStore
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
