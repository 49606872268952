<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative flex flex-col h-full justify-center bg-blue-button">
    <div class="flex w-3/4 flex-col self-center place-items-center">
      <img src="@/assets/images/login/Rocket.gif" style="width: 200px" />
      <img :src="logoImage" style="width: 75%; margin: 50px 0" />
    </div>
    <div class="flex flex-row justify-center place-items-center pt-4">
      <div class="flex flex-col w-52">
        <img class="w-16" src="@/assets/images/login/PasswordButton_Toggle@2x.png" @click="mode = 'userPassLogin'" />
        <img class="pt-8 w-16" src="@/assets/images/login/FeideButton_Toggle@2x.png" @click="mode = 'feide'" />
        <img class="pt-8 w-16" src="@/assets/images/login/MobilButton_Toggle@2x.png" @click="mode = 'smsLogin'" />
      </div>
      <transition mode="out-in" name="fade">
        <div v-if="mode === 'feide'" key="landing-main" class="flex flex-col place-content-center">
          <Button v-if="filteredLocalUsersWithExpiry.length > 0" id="button-pinlogin" @click="PINlogin()">{{ t('loginRemembered') }}</Button>
          <Button background-class="bg-green-highlight" :background-colour="'bg-green-highlight'" @click="DPlogin()">{{ t('loginIDPorten') }}</Button>
          <Button
            v-if="showTestLogin"
            id="button-testuser"
            class="my-2"
            background-class="bg-white"
            text-class="text-black"
            @click="DPlogin(true, 'testuser')"
            >Test User 1
          </Button>
          <Button
            v-if="showTestLogin"
            id="button-testuser2"
            class="my-2"
            background-class="bg-white"
            text-class="text-black"
            @click="DPlogin(true, 'testuser2')"
            >Test User 2
          </Button>
          <!-- <Button
          class="mb-24 focus:outline-none bg-green-500 text-white justify-self-end"
          :customWidth="'12rem'"
          @click="login()"
          >Log in Skip ID Porten
        </Button> -->
        </div>
        <div v-else-if="mode === 'userPassLogin'" key="landing-user-pass" class="flex flex-col place-content-center">
          <div class="flex flex-col justify-center place-items-center">
            <AnswerInput
              v-model="username"
              v-model:valid="validators.usename"
              mode="username"
              :label="t('enterUsername')"
              :border="true"
            ></AnswerInput>
            <AnswerInput
              v-model="password"
              v-model:valid="validators.password"
              class="mt-2"
              mode="password"
              :label="t('enterPassword')"
              :border="true"
              @keyup.enter="sendUsernamePassword()"
            ></AnswerInput>
            <Button id="button-send" class="mt-10" background-class="bg-green-highlight" @vclick="sendUsernamePassword()">{{ t('send') }} </Button>
          </div>
        </div>
        <div v-else-if="mode === 'smsLogin'" key="landing-mobil" class="flex flex-col items-center h-full justify-center pb-12">
          <div class="flex flex-col place-items-center">
            <AnswerInput
              v-model="mobileNumber"
              v-model:valid="validators.mobil"
              mode="tel"
              :label="t('enterMobilNumber')"
              :border="true"
              @keyup.enter="sendPhoneNumber()"
            ></AnswerInput>
            <Button id="button-send" class="mt-10 capitalize" background-class="bg-green-highlight" @click="sendPhoneNumber()"
              >{{ t('send') }}
            </Button>
          </div>
        </div>
        <div v-else-if="mode === 'smsCode'" key="landing-otc" class="flex flex-col items-center h-full justify-center pb-12">
          <div class="flex flex-col">
            <AnswerInput
              v-model:valid="validators.mobil"
              v-model="otc"
              class="m-2"
              mode="otc"
              :label="t('enterMobilOTC')"
              :border="true"
              @enterkey="sendOTC()"
            ></AnswerInput>
            <Button id="button-send" class="mt-16" background-class="bg-green-highlight" @click="sendOTC">{{ t('send') }} </Button>
            <Button
              id="button-back"
              class="my-2"
              background-class="bg-grey"
              text-class="text-black"
              @click="() => ((pinCode = ''), (otc = ''), (mode = 'smsLogin'))"
              >{{ t('back') }}
            </Button>
          </div>
        </div>
        <div v-else-if="mode === 'pin-user'" key="landing-pin-user" class="flex flex-col items-center h-full justify-center">
          <p class="text-white">Log in as</p>
          <div
            v-for="u in filteredLocalUsersWithExpiry"
            :key="u._id"
            class="mt-8 rounded-full w-full bg-white flex flex-col items-center p-4"
            @click="selectUser(u)"
          >
            <p>{{ u.name }}</p>
            <p class="text-xs">Expires in {{ u.expiresIn.value }}</p>
          </div>
        </div>
        <div v-else-if="mode === 'pp'" key="landing-pin-pin" class="flex flex-col items-center h-full justify-center">
          <p class="text-white">What's your PIN code?</p>
          <AnswerInput v-model="pinCode" mode="text" class="mt-8" />
          <Button text="login" class="mt-8 focus:outline-none" @click="localUserLogin()" />
        </div>
      </transition>
    </div>
    <transition mode="out-in" name="fade">
      <div class="absolute top-0 left-0 p-4 text-center text-white w-full">
        <p>{{ message }}</p>
      </div>
    </transition>
    <p class="absolute bottom-0 right-0 text-xs m-2 text-white opacity-75">v{{ appVersion }}</p>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, Ref, onBeforeUnmount, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import Button from '@/components/base/Button.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import { baseUrl, appVersion, LanguageCodes } from '@/constants'
  import useAppStore from '../../store/useAppStore'
  import useDeviceService from '@/composition/useDevice'
  import useStateService from '@/composition/useState'
  import { LocalUser } from '../../models/main'
  import gameLogo_NO from '@/assets/images/login/gameLogo_NO@2x.png'
  import gameLogo_SV from '@/assets/images/login/gameLogo_SV@2x.png'

  const jwtExpiryConstant = (import.meta.env.VITE_JWT_EXPIRY || '0') as string
  const jwtExpiry = Number.parseInt(jwtExpiryConstant)

  const messages = {
    no: {
      loginIDPorten: 'Logg inn med FEIDE',
      loginRemembered: 'Logg inn med PIN',
      enterMobilNumber: 'Mobilnummer',
      enterUsername: 'Ditt brukernavn',
      enterPassword: 'Ditt passord',
      enterMobilOTC: 'Engangskode (fra SMS)',
      invalidEntry: 'Mangler mobilnummer eller passord',
      phoneNumberError: 'Error med nummer',
      notOnline: 'Nettverk er utilgjengelig',
      'User not found': 'Bruker ikke funnet',
      'LOGIN: Incorrect password': 'Feil passord',
      userPasswordLoginError: 'Feil brukernavn eller passord',
      'wrong otc': 'Feil engangspassord',
      'sms login accepted': 'Det kan ta litt tid før du får engangskode med SMS.',
      send: 'Logg på',
    },
    sv: {
      loginIDPorten: 'Logga in med FEIDE',
      loginRemembered: 'Logga in med PIN',
      enterMobilNumber: 'Mobilnummer',
      enterUsername: 'Användarnamn',
      enterPassword: 'Lösenord',
      enterMobilOTC: 'Engångskod (från SMS)',
      invalidEntry: 'Saknar mobilnummer eller lösenord',
      phoneNumberError: 'Fel med nummer',
      notOnline: 'Nätverk ej tillgängligt',
      'User not found': 'Användaren hittades inte',
      'LOGIN: Incorrect password': 'Fel lösenord',
      userPasswordLoginError: 'felaktigt användarnamn eller lösenord',
      'wrong otc': 'Felaktigt engångslösenord',
      'sms login accepted': 'Det kan ta lite tid innan du får en engångskod via SMS.',
      send: 'Logga in',
    },
    en: {
      loginIDPorten: 'Log in with FEIDE',
      loginRemembered: 'Log in with PIN',
      enterMobilNumber: 'Mobile phone number',
      enterUsername: 'Your username',
      enterPassword: 'Your password',
      enterMobilOTC: 'One time code (from SMS)',
      invalidEntry: 'Invalid number or password',
      phoneNumberError: 'Error processing phone number',
      notOnline: 'Network unavailable',
      userPasswordLoginError: 'Incorrect username or password',
      'User not found': 'User not found',
      'LOGIN: Incorrect password': 'Incorrect password',
      'wrong otc': 'Wrong one time code',
      'sms login accepted': 'It may take some time before you get the one time code by SMS.',
      send: 'Log in',
    },
  }

  interface LocalUserWithExpiry extends LocalUser {
    expiresIn: Ref<string>
    valid: Ref<boolean>
  }

  const { t, locale } = useI18n({ messages })
  const router = useRouter()
  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: deviceGetters } = useDeviceService()
  const { setters: stateSetters } = useStateService()
  const mode = ref('userPassLogin')
  const pinCode = ref('')
  const mobileNumber = ref('')
  const username = ref('')
  const password = ref('')
  const otc = ref('')
  const message = ref('')
  const showTestLogin = ref(false)
  const selectedUser = ref()
  const loginInProgress = ref(false)
  const validators: Ref<Record<string, boolean>> = ref({})
  let timer: ReturnType<typeof setTimeout>
  const language = appGetters.languageCode
  const logoImage = computed(() => {
    switch (language.value) {
      case LanguageCodes.no:
        return gameLogo_NO
      case LanguageCodes.sv:
        return gameLogo_SV
      default:
        return gameLogo_NO
    }
  })
  const isOnline = deviceGetters.deviceOnline
  const localUsers = Object.values(appGetters.persistedLocalUsers.value)
  const localUsersWithExpiry: LocalUserWithExpiry[] = localUsers.map((lu) => ({
    ...lu,
    expiresIn: ref(''),
    valid: ref(false),
  }))
  if (!isOnline.value) feedbackMessage(t('notOnline'))

  onMounted(() => {
    stateSetters.fade = false
    if (Object.keys(LanguageCodes).some((language) => language == locale.value)) {
      const lang: LanguageCodes = locale.value as LanguageCodes
      appActions.setLanguageCode(lang)
    } else appActions.setLanguageCode(LanguageCodes.no)

    if (router.currentRoute.value.params.admin === 'admin') {
      mode.value = 'login'
    }
  })

  showTestLogin.value = false

  function PINlogin() {
    mode.value = 'pin-user'
  }

  function DPlogin(testing = false, username = 'testuser') {
    if (loginInProgress.value) return
    if (!isOnline.value) {
      return feedbackMessage(t('notOnline'))
    }
    let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
    // If using Cordova, activate the cordova-oauth plugin
    const testingString = testing ? `&testing=${username}` : ''
    if (window.cordova) {
      feideLoginUrl += `?&client=mobileApp&appVersion=${appVersion}&remember=true${testingString}`
      window.OAuth(feideLoginUrl, 'oauth:dataporten', undefined, (success) => {
        console.log(success)
      })
    } else {
      feideLoginUrl += `?&appVersion=${appVersion}&client=webApp${testingString}`
      window.location.href = feideLoginUrl
    }
    // NOTE: Call OAuth without 'oauth:' name to open the system browser
    // window.OAuth('https://www.uio.no/tjenester/it/lagring-samarbeid/gsuite/', '_system', 'location=yes');
  }
  // This logs in the user without going through the FIEDE process, so long as they have a valid token
  function localUserLogin() {
    if (loginInProgress.value) return
    if ((selectedUser.value.pin === pinCode.value && deviceGetters.deviceOnline) || import.meta.env.NODE_ENV == 'development') {
      selectedUser.value.lastLogin = new Date()
      appActions.setCurrentLocalUser(selectedUser.value)
      appActions.tokenLogin().then((success: boolean) => {
        router.push('/postlogin')
        if (!success) mode.value = 'login'
      })
    }
  }

  const allValid = computed(() => {
    return Object.values(validators.value).every((v) => v)
  })

  function feedbackMessage(m: string) {
    clearTimeout(timer)
    message.value = m
    timer = setTimeout(() => {
      message.value = ''
    }, 1500)
  }

  function selectUser(u: LocalUser) {
    localUsers.forEach((user) => (user.selected = false))
    u.selected = true
    selectedUser.value = u
    mode.value = 'pp'
  }

  async function sendPhoneNumber() {
    if (loginInProgress.value) return
    if (!isOnline.value) {
      return feedbackMessage(t('notOnline'))
    }
    if (!allValid.value || !mobileNumber.value) {
      feedbackMessage(t('invalidEntry'))
    } else {
      loginInProgress.value = true
      try {
        await appActions.smsLogin(mobileNumber.value)
      } catch (error) {
        console.log(error)
        return feedbackMessage(t('phoneNumberError'))
      }
      mode.value = 'smsCode'
      feedbackMessage(t('sms login accepted'))
      loginInProgress.value = false
    }
  }

  async function sendUsernamePassword() {
    if (loginInProgress.value) return
    if (!isOnline.value) {
      return feedbackMessage(t('notOnline'))
    }
    if (!allValid.value || !username.value || !password.value) {
      feedbackMessage(t('invalidEntry'))
    } else {
      loginInProgress.value = true
      appActions
        .userPasswordLogin(username.value, password.value)
        .then(() => {
          stateSetters.fade = true
          setTimeout(() => router.push('/postlogin'), 1000)
        })
        .catch((error) => {
          console.log(error)
          loginInProgress.value = false
          return feedbackMessage(t('userPasswordLoginError'))
        })
    }
  }

  async function sendOTC() {
    if (loginInProgress.value) return
    if (!allValid.value || !otc.value) {
      feedbackMessage(t('invalidEntry'))
    } else {
      loginInProgress.value = true
      let smsLoginUrl = `${baseUrl}/auth/sms/code`
      if (window.cordova) {
        smsLoginUrl += `?&client=mobileApp&appVersion=${appVersion}&remember=true&otc=${otc.value}&mobil=${mobileNumber.value}`
        window.OAuth(smsLoginUrl, 'oauth:dataporten', undefined, (success) => {
          console.log(success)
          loginInProgress.value = false
        })
      } else {
        smsLoginUrl += `?&client=webApp&otc=${otc.value}&mobil=${mobileNumber.value}`
        window.location.href = smsLoginUrl
      }
    }
  }

  const calculateCountdown = () => {
    const dateNow = new Date()
    localUsersWithExpiry.forEach((lu: LocalUserWithExpiry) => {
      const countdown = jwtExpiry - moment(dateNow).diff(lu.lastLogin, 'seconds')
      lu.expiresIn.value = moment.duration(countdown, 'seconds').humanize()
      lu.valid.value = countdown > 0
    })
  }
  calculateCountdown()
  const countDownInterval = setInterval(() => {
    calculateCountdown()
  }, 10000)
  onBeforeUnmount(() => {
    clearInterval(countDownInterval)
  })

  const filteredLocalUsersWithExpiry = computed(() => localUsersWithExpiry.filter((u) => u.valid.value))
</script>

<style scoped></style>
