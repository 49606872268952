<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="relative">
    <!-- Skip enter password -->
    <div v-if="appGetters.disableDelays.value" class="absolute top-0 right-0 text-xs flex flex-row z-50">
      <button class="m-1 text-black bg-white rounded-sm p-2" @click="passwordEntered()">Skip Pass</button>
    </div>

    <img class="shipBackground" :src="backgroundImage" />

    <div class="cockpitOverlay">
      <div class="stardustContainer">
        <div class="stardust" :style="{ height: barHeight, 'background-color': barColour }"></div>
        <!--img src="assets/images/ship/Stardust@2x.png" [ngStyle]="{'height': barHeight(), 'color': barColour()}"-->
      </div>

      <div class="computerContainer" :class="{ showComputer: computerAnimating }">
        <img :src="ComputerAnimated" />
      </div>

      <div class="joystickContainer">
        <img class="joystick" :src="joystickAnimating ? JoystickAnimated : JoystickStatic" @click="falseStart()" />
        <img v-if="joystickActive" class="joystickStar" :src="JoystickStarAnimated" @click="startSelectedSession()" />
      </div>

      <div class="passwordButtonContainer">
        <img class="light" :src="passwordButtonActive ? PasswordButton_Active : PasswordButton_Inactive" @click="enterPassword()" />
        <!--img class="button" src="assets/images/ship/GreyButton_Inactive@2x.png"-->
        <img
          class="button"
          :src="linkButtonActive ? LinkButton_Active : LinkButton_Inactive"
          @click="enterLink()"
          @mousedown="linkDown()"
          @mouseup="linkUp()"
          @touchstart="linkDown()"
          @touchend="linkUp()"
        />
      </div>

      <div class="mapButtonContainer">
        <img class="light" :src="mapButtonActive ? MapButton_Active : MapButton_Inactive" @click="visitMap()" />
        <img class="button" :src="GreyButton_Inactive" />
      </div>

      <div class="logoutButtonContainer">
        <img class="light" :src="logoutButtonActive ? LogOutButton_Active : LogOutButton_Inactive" @click="logout()" />
        <img v-if="appGetters.disableDelays.value" class="button" :src="YellowButton_Active" @click.stop="audioPanel()" />
        <img v-else class="button" :src="GreyButton_Inactive" />
      </div>

      <div v-if="passwordOverlayActive && nextSession" class="shipPasswordBox">
        <div v-if="nextSession" class="flex-row justify-center pt-5">
          <label for="passwordBox" style="font-size: 0.6em">Passord for {{ nextSession.name }}?</label>
          <input
            id="passwordBox"
            ref="passwordInputElement"
            v-model="passwordText"
            name="passwordBox"
            class="pinBox"
            type="password"
            autocomplete="off"
            @keyup.enter="passwordEntered()"
          />
        </div>
      </div>

      <div v-if="linkOverlayActive" class="shipPasswordBox">
        <div class="flex-row justify-center pt-5">
          <label for="linkBox" class="w-4" style="font-size: 0.6em">Link?</label>
          <input
            id="linkBox"
            ref="linkInputElement"
            v-model="linkText"
            name="linkBox"
            class="pinBox"
            type="text"
            autocomplete="off"
            @keyup.enter="linkEntered()"
          />
        </div>
      </div>
    </div>

    <div v-if="delayActive" class="absolute grid grid-cols-1 place-content-center w-full h-full top-0 left-0 pointer-events-none">
      <div class="text-center text-white">{{ t('downloading') }}</div>
    </div>
    <div v-if="noSessions" class="absolute grid grid-cols-1 place-content-center w-full h-full top-0 left-0 pointer-events-none">
      <div class="text-center text-white">{{ t('noSessions') }}</div>
    </div>
    <div v-if="incorrectPassword" class="absolute grid grid-cols-1 place-content-center w-full h-full top-0 left-0 pointer-events-none">
      <div class="text-center text-white">{{ t('incorrectPassword') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import {
    ShipMode,
    ViewState,
    SceneMode,
    ActivityPhase,
    EpisodePhase,
    SessionPhase,
    SpeechSounds,
    SceneType,
    DelayMode,
    StateVariables,
  } from '@/constants'
  import { createSound } from '@/utilities'
  import useStateService from '@/composition/useState'
  import useCMSStore from '@/store/useCMSStore'
  import useAppStore from '@/store/useAppStore'
  import useGameStore from '@/store/useGameStore'

  // Buildtime assets
  import CockpitHomePlanetBackground_NO from '@/assets/images/ship/CockpitHomePlanetBackground_NO@2x.png'
  import CockpitHomePlanetBackground_SV from '@/assets/images/ship/CockpitHomePlanetBackground_SV@2x.png'
  import ComputerAnimated from '@/assets/images/ship/ComputerAnimated_570x300.gif'
  import JoystickAnimated from '@/assets/images/ship/JoystickAnimated@2x.gif'
  import JoystickStatic from '@/assets/images/ship/JoystickStatic@2x.png'
  import JoystickStarAnimated from '@/assets/images/ship/JoystickStarAnimated154x154-2.gif'
  import PasswordButton_Active from '@/assets/images/ship/PasswordButton_Active@2x.png'
  import PasswordButton_Inactive from '@/assets/images/ship/PasswordButton_Inactive@2x.png'
  import LinkButton_Active from '@/assets/images/ship/LinkButton_Active@2x.png'
  import LinkButton_Inactive from '@/assets/images/ship/LinkButton_Inactive@2x.png'
  import MapButton_Active from '@/assets/images/ship/MapButton_Active@2x.png'
  import MapButton_Inactive from '@/assets/images/ship/MapButton_Inactive@2x.png'
  import LogOutButton_Active from '@/assets/images/ship/LogOutButton_Active@2x.png'
  import LogOutButton_Inactive from '@/assets/images/ship/LogOutButton_Inactive@2x.png'
  import GreyButton_Inactive from '@/assets/images/ship/GreyButton_Inactive@2x.png'
  import YellowButton_Active from '@/assets/images/ship/YellowButton_Active@2x.png'

  const messages = {
    no: {
      downloading: 'Vennligst vent, laster ned data...',
      noSessions: 'Alle session ferdig',
      incorrectPassword: 'Feil passord',
    },
    sv: {
      downloading: 'Vänta, laddar ner data...',
      noSessions: 'Allt arbete färdigt',
      incorrectPassword: 'Felaktigt lösenord',
    },
    en: {
      downloading: 'Please wait, downloding data...',
      noSessions: 'All sessions are completed',
      incorrectPassword: 'Incorrect password',
    },
  }

  const { t } = useI18n({ messages })
  const stateService = useStateService()
  const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
  const { getters: appGetters } = useAppStore()
  const { actions: gameActions } = useGameStore()
  const language = appGetters.languageCode.value
  const backgroundImage = language === 'no' ? CockpitHomePlanetBackground_NO : CockpitHomePlanetBackground_SV
  const joystickAnimating = ref(false)
  const passwordOverlayActive = ref(false)
  const linkOverlayActive = ref(false)
  const passwordInputElement = ref()
  const linkInputElement = ref()
  const linkText = ref('')
  const delayActive = ref(false)
  const noSessions = ref(false)
  const incorrectPassword = ref(false)
  const passwordText = ref('')
  const joystickStarVisible = ref(true)
  let idleTimer: ReturnType<typeof setTimeout>
  let linkButtonIsDown = false
  const logoutButtonInactive = ref(false)

  const selectedSession = computed(() => cmsGetters.selectedSession.value.session)
  const nextSession = stateService.getters.nextUncompletedSession

  const delayLogoutButtonActivation = () => {
    setTimeout(() => {
      logoutButtonInactive.value = false
    }, 4000)
  }

  onMounted(() => {
    joystickStarVisible.value = true
    logoutButtonInactive.value = true
    passwordText.value = ''

    if (nextSession.value) {
      stateService.actions.getSessionWithTasks(nextSession.value)
    }

    // This will be reached during page reload - exit gracefully to login page
    const shipMode = computed(() => stateService.getters.state.value.shipMode)
    if (stateService.getters.state.value.viewState === ViewState.None) {
      stateService.getters.state.value.sceneMode = SceneMode.Finished
      stateService.actions.updateState(true)
    } else {
      if (shipMode.value === ShipMode.SessionUnlocked) {
        stateService.actions.speakLocalised(SpeechSounds.computer.C41, undefined, 1000)
      }

      // We are starting a session..
      if (shipMode.value === ShipMode.SessionLocked) {
        // If this is the first session of the RCT, activate it automatically & introduce it
        if (!selectedSession.value && cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value === EpisodePhase.First) {
          stateService.actions.speakLocalised(
            SpeechSounds.computer.C10,
            () => {
              stateService.actions.speakLocalised(
                SpeechSounds.narrator.N11,
                () => {
                  passwordEntered()
                },
                1000,
                false,
                false,
              )
            },
            1000,
          )
        } else {
          // Try to unlock in case there is an empty password
          const helloSound = nextSession.value?.hasPassword ? SpeechSounds.computer.C55 : SpeechSounds.computer.C41
          stateService.actions.speakLocalised(
            helloSound,
            () => {
              if (nextSession.value && !nextSession.value.hasPassword) passwordEntered()
            },
            1000,
          )
        }
      }

      // If we are in the process of finishing a Session (progress.value update), speak 'progress.value update' feedback then show scenes
      // NOTE: The current session was just marked as complete. So the sessionPhase should be explicitly queried with the current Session
      if (shipMode.value === ShipMode.SessionEnding) {
        // We arrived here in pre/post test mode, or not as part of the RCT
        if (
          (cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value !== EpisodePhase.Second) ||
          cmsGetters.activityPhase.value !== ActivityPhase.RCT
          // || this.dataService.broadReleasePretestMode
        ) {
          stateService.setters.state = { shipMode: ShipMode.SessionCompleted }
        } else {
          // Else we need to update progress.value to the user, then possibly play an 'out' scene
          if (idleTimer) clearTimeout(idleTimer)
          let voiceKey: string = SpeechSounds.computer.C46 // SessionPhase.One
          if (selectedSession.value && selectedSession.value.consolidation) {
            if (cmsActions.getSessionPhase(selectedSession.value) === SessionPhase.Last) {
              voiceKey = SpeechSounds.computer.C54
            } else {
              voiceKey = SpeechSounds.computer.C53
            }
          } else if (selectedSession.value) {
            switch (cmsActions.getSessionPhase(selectedSession.value)) {
              case SessionPhase.Two:
                voiceKey = SpeechSounds.computer.C47
                break
              case SessionPhase.Three:
                voiceKey = SpeechSounds.computer.C59
                break
              case SessionPhase.Four:
                voiceKey = SpeechSounds.computer.C63
                break
              case SessionPhase.Five:
                voiceKey = SpeechSounds.computer.C57
                break
              case SessionPhase.Six:
                voiceKey = SpeechSounds.computer.C58
                break
              case SessionPhase.Seven:
                voiceKey = SpeechSounds.computer.C65
                break
              case SessionPhase.Eight:
                voiceKey = SpeechSounds.computer.C48
                break
              case SessionPhase.Penultimate:
                voiceKey = SpeechSounds.computer.C49
                break
            }
          }

          let continueAfterComputerVoice = () => {
            stateService.setters.state = { shipMode: ShipMode.SessionCompleted }
            let sessionPhase = cmsActions.getSessionPhase(selectedSession.value)
            delayLogoutButtonActivation()
            if (sessionPhase === SessionPhase.Last || sessionPhase === SessionPhase.FirstAndLast) {
              const newState: StateVariables = {
                sceneMode: SceneMode.Ready,
                sceneType: SceneType.EpisodeOutScene,
              }
              if (stateService.getters.demoMode) {
                newState.sceneMode = SceneMode.Finished
                newState.delayMode = DelayMode.DemoComplete
                newState.viewState = ViewState.Delay
              }
              stateService.actions.updateState(true, newState)
            } else {
              stateService.setters.state = { sceneMode: SceneMode.Finished }
              resetIdleTimeout()
            }
          }

          stateService.actions.speakLocalised(
            voiceKey,
            () => {
              if (cmsActions.getSessionPhase(selectedSession.value) !== SessionPhase.Last) {
                stateService.actions.speakLocalised(
                  SpeechSounds.computer.C60,
                  () => {
                    continueAfterComputerVoice()
                  },
                  1000,
                )
              } else {
                continueAfterComputerVoice()
              }
            },
            1000,
          )
        }
      }

      // If the session has completed, we are back to the Ship view in interactive mode after progress.value update and scenes
      // This also collects fallthrough from ShipMode.SessionEnding
      if (shipMode.value === ShipMode.SessionCompleted) {
        // Start the logout timer
        resetIdleTimeout()

        // We arrived here in pre/post test mode
        if (
          cmsGetters.activityPhase.value === ActivityPhase.RCT &&
          cmsGetters.episodePhase.value === EpisodePhase.First
          // || this.dataService.broadReleasePretestMode
        ) {
          stateService.actions.speakLocalised(SpeechSounds.computer.C69, delayLogoutButtonActivation, 1000)
        } else if (
          cmsGetters.activityPhase.value === ActivityPhase.RCT &&
          (cmsGetters.episodePhase.value === EpisodePhase.Third || cmsGetters.episodePhase.value === EpisodePhase.Fourth)
        ) {
          stateService.actions.speakLocalised(SpeechSounds.computer.C69, delayLogoutButtonActivation, 1000)
        } else if (cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value === EpisodePhase.Fifth) {
          stateService.actions.speakLocalised(SpeechSounds.computer.C70, delayLogoutButtonActivation, 1000)
        } else {
          stateService.actions.speakLocalised(SpeechSounds.computer.C60, delayLogoutButtonActivation, 1000)
        }
      }

      // After we returned from the Interactive Map, we don't play any computer sounds
      if (shipMode.value === ShipMode.ReturnedFromInteractiveMap) {
        // Start the logout timer
        resetIdleTimeout()
        delayLogoutButtonActivation()
      }
    }
  })

  onUnmounted(() => {
    if (idleTimer) clearTimeout(idleTimer)
  })

  function resetIdleTimeout() {
    if (idleTimer) clearTimeout(idleTimer)
    idleTimer = setTimeout(() => {
      // logout()
      console.log('Logout timer fired, but is disabled')
    }, 300000)
  }

  async function startSelectedSession() {
    if (idleTimer) clearTimeout(idleTimer)
    joystickAnimating.value = false // :(
    joystickStarVisible.value = false

    if (
      cmsGetters.activityPhase.value === ActivityPhase.RCT &&
      cmsGetters.episodePhase.value !== EpisodePhase.Second
      // || this.dataService.broadReleasePretestMode
    ) {
      joystickAnimating.value = true
      const a = await createSound('/assets/sounds/general/pull.mp3')
      a.playWhenReady()
      setTimeout(() => {
        joystickAnimating.value = false
        stateService.actions.startSession()
      }, 1000)
    } else {
      const newState: StateVariables = {
        sceneType: SceneType.GeneralScene,
        sceneMode: SceneMode.InProgress,
        viewState: ViewState.Map,
      }
      // Update state only to play exit the ship (scene)
      stateService.actions.updateState(false, newState)
    }
  }

  const barHeight = computed(() => {
    return stateService.getters.progress.value.shipBarData.completedPercent + '%'
  })

  const barColour = computed(() => {
    const progress = stateService.getters.progress.value.shipBarData.completedPercent
    let r = progress < 50 ? 255 : Math.floor(255 - ((progress * 2 - 100) * 255) / 100)
    let g = progress > 50 ? 255 : Math.floor((progress * 2 * 255) / 100)
    return 'rgb(' + r + ',' + g + ',0)'
  })

  const computerAnimating = stateService.getters.computerAnimating

  const joystickActive = computed(() => {
    return selectedSession.value && selectedSession.value.activated && !sessionIsCompleted.value && joystickStarVisible.value
  })

  const sessionIsCompleted = computed(() => {
    const s = selectedSession.value
    let completed = false
    if (s && s.parent) completed = gameActions.itemIsCompleted(s._id, s.parent._id)
    return completed
  })

  const passwordButtonActive = computed(() => {
    return (
      stateService.getters.state.value.shipMode !== ShipMode.SessionCompleted &&
      !computerAnimating.value &&
      nextSession.value &&
      nextSession.value.hasPassword
    )
  })

  const audioPanel = () => {
    stateService.actions.audioPanel()
  }

  const linkDown = () => {
    linkButtonIsDown = true
  }
  const linkUp = () => {
    linkButtonIsDown = false
  }

  const linkButtonActive = computed(() => {
    // How to implement student groups using SLPlus platform?
    // FIXME: return !computerAnimating.value && !this.dataService.student.myGroup && cmsGetters.activityPhase.value === ActivityPhase.BroadRelease
    return false
  })

  // Map button is not available in RCT Episode 1, or when the session has not yet been completed
  const mapButtonActive = computed(() => {
    return (
      !(cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value !== EpisodePhase.Second) &&
      stateService.getters.state.value.shipMode === ShipMode.SessionCompleted &&
      !computerAnimating.value
    )
  })

  const logoutButtonActive = computed(() => {
    return !computerAnimating.value && !logoutButtonInactive.value
  })

  function falseStart() {
    if (!joystickActive.value && !computerAnimating.value) {
      stateService.actions.speakLocalised(SpeechSounds.computer.C61, () => ({}), 0)
    }
  }

  // ---------------   Button controls

  function enterPassword() {
    if (passwordButtonActive.value || linkButtonIsDown) {
      linkOverlayActive.value = false
      passwordOverlayActive.value = !passwordOverlayActive.value
      if (passwordOverlayActive.value) {
        setTimeout(() => {
          passwordInputElement.value = document.getElementById('passwordBox')
          if (passwordInputElement.value) {
            passwordInputElement.value.focus()
          }
        }, 500)
      }
    }
  }

  function enterLink() {
    if (linkButtonActive.value) {
      passwordOverlayActive.value = false
      linkOverlayActive.value = !linkOverlayActive.value
      if (linkOverlayActive.value) {
        setTimeout(() => {
          linkInputElement.value = document.getElementById('linkBox')
          if (linkInputElement.value) {
            linkInputElement.value.focus()
          }
        }, 500)
      }
    }
  }

  function logout() {
    if (logoutButtonActive.value) {
      passwordOverlayActive.value = false
      cmsActions.setSessionActivation(false)
      if (
        !(cmsGetters.activityPhase.value === ActivityPhase.RCT && cmsGetters.episodePhase.value === EpisodePhase.Fifth) &&
        !appGetters.disableDelays
      ) {
        stateService.actions.speakLocalised(SpeechSounds.computer.C62, () => stateService.actions.exitToDashboard())
      } else {
        stateService.actions.exitToDashboard()
        //stateService.actions.exitToLogin()
      }
    }
  }

  function visitMap() {
    if (mapButtonActive.value) {
      const newState: StateVariables = {
        sceneMode: SceneMode.Finished,
        viewState: ViewState.Map,
      }
      stateService.actions.updateState(true, newState)
    }
  }

  function linkEntered() {
    // FIXME: How to connect student to a group in SL+ system?
    /* this.serverService.connectStudent(this.linkText, this.dataService.student._id).subscribe((response) => {
      if (response.message != 'Done') {
        const t = this.linkText
        this.linkText = response.message
        setTimeout(() => {
          this.linkText = t
        }, 2000)
      } else {
        this.dataService.student.myGroup = response.groupid
        this.linkOverlayActive = false
      }
    }) */
  }

  async function passwordEntered() {
    if (passwordInputElement.value) {
      passwordInputElement.value.blur()
    }
    // Skip Session function
    if (nextSession.value && stateService.actions.skipSession(nextSession.value, passwordText.value)) {
      await gameActions.updateGameProgress()
    } else {
      delayActive.value = true
      const nextSess = nextSession.value
      stateService.actions
        .activateSession(nextSess, passwordText.value)
        .then((trueResult) => {
          delayLogoutButtonActivation()
          delayActive.value = false
          passwordOverlayActive.value = false
          if (!trueResult) incorrectPassword.value = true
          setTimeout(() => {
            incorrectPassword.value = false
          }, 2000)
        })
        .catch((error) => {
          console.error(error)
          delayLogoutButtonActivation()
          noSessions.value = false
          delayActive.value = false
          incorrectPassword.value = false
          passwordOverlayActive.value = false
        })
      passwordText.value = ''
    }
  }
</script>

<style scoped>
  .shipBackground {
    width: 100vw;
    height: auto;
  }

  .shipOverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .shipOverlayContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .stardustContainer {
    position: absolute;
    top: 35vw;
    left: 11.2vw;
    width: 6vw;
    height: 25.5vw;
    overflow: hidden;
  }
  .stardustContainer img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 6vw;
  }
  .stardust {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 6vw;
    border-radius: 10px;
  }

  .computerContainer {
    position: absolute;
    top: 45vw;
    left: 37.5vw;
    height: 25vw;
    overflow: hidden;
    opacity: 0;
  }
  .computerContainer img {
    position: relative;
    top: 0;
    left: 0;
    width: 25vw;
  }
  .showComputer {
    opacity: 1;
  }

  .joystickContainer {
    position: absolute;
    top: 24vw;
    left: 73vw;
    height: 30vw;
    overflow: hidden;
  }
  .joystick {
    position: relative;
    top: 0;
    left: 0;
    width: 17vw;
  }
  .joystickStar {
    position: absolute;
    top: 2vw;
    left: 3.6vw;
    width: 10vw;
  }

  .passwordButtonContainer {
    position: absolute;
    top: 52vw;
    left: 70vw;
    height: 14vw;
    overflow: hidden;
  }
  .mapButtonContainer {
    position: absolute;
    top: 52vw;
    left: 78vw;
    height: 14vw;
    overflow: hidden;
  }
  .logoutButtonContainer {
    position: absolute;
    top: 52vw;
    left: 86vw;
    height: 14vw;
    overflow: hidden;
  }
  .light {
    position: relative;
    top: 0;
    left: 0;
    width: 6vw;
  }
  .button {
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 5vw;
  }

  .pinBox {
    border: inset 3px #888888;
    height: 80px;
    font-size: 36pt;
    text-align: center;
    border-radius: 15px;
    width: 30vw;
    margin: 8px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }

  .shipPasswordBox {
    position: absolute;
    top: 45vw;
    left: 31vw;
    height: 25vw;
    overflow: hidden;
  }
  .shipPasswordBox label {
    position: absolute;
    color: darkslategrey;
    font-size: 0.7em;
    top: 45px;
    left: 20px;
  }
</style>
