/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type5/ScannerType5@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding">
        <div id="word-source-box" class="flex flex-row justify-around fadeInOut pointer-events-none" :style="{ opacity: dragImageOpacity }">
          <!-- Draggable Item -->
          <Draggable
            v-if="draggableItem"
            class="z-10 pointer-events-auto"
            :class="`${activeDrags ? 'pointer-events-none touch-none opacity-40' : ''}`"
            :position="draggableItem.position"
            @pointerdown="onPointerDown"
            @move="(e: any) => onPointerMove(e)"
            @start="(e: any) => onStart(e)"
            @stop="(e: any) => onDrop(e, draggableItem)"
          >
            <div class="type5FixedHeight">
              <img v-cache class="borderedWordBox imageStandard draggableImage" style="padding: 0" :src="draggableItem.image" />
            </div>
          </Draggable>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-around">
      <!-- Drop Area (stem) -->
      <div class="flex flex-col justify-around">
        <div style="position: relative">
          <div
            id="word-box-1"
            ref="wordBox1"
            v-draggable="{ axis: 'none' }"
            class="task5DropArea imageSmall drop-target rounded-lg"
            :class="{ hoverhighlight1: box1Highlight }"
            @start="onStart"
            @stop="onDrop"
          ></div>
          <img v-cache class="imageSmall" :src="box1" />
          <img v-show="yellowLights" src="@/assets/images/tasks/type5/lightsYellow.gif" class="imageSmall lightBox" />
        </div>
        <span class="borderedWordBox yellow-border wordHighlight" :class="{ highlightScale: highlight1 }" @click="clickImage(1)">
          {{ task.stem }}
        </span>
      </div>

      <!-- Drop Area (morphed stem) -->
      <div class="flex flex-col justify-around relative">
        <div style="position: relative" @touchmove.stop="touchOverBox">
          <div
            id="word-box-2"
            ref="wordBox2"
            v-draggable="{ axis: 'none' }"
            class="task5DropArea imageSmall drop-target rounded-lg"
            :class="{ hoverhighlight2: box2Highlight }"
            @start="onStart"
            @stop="onDrop"
          ></div>
          <img v-cache class="imageSmall pointer-events-none" :src="box2" />
          <img v-show="blueLights" src="@/assets/images/tasks/type5/lightsBlue.gif" class="imageSmall lightBox" />
        </div>
        <span class="borderedWordBox blue-border wordHighlight" :class="{ highlightScale: highlight2 }" @click="clickImage(2)">
          {{ task.morphedStem }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { Draggable, ControlPosition, DraggableEvent } from '@braks/revue-draggable'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { TaskMode, TASK_TYPES, StemType, SpeechSounds } from '@/constants'
  import { Tasktype5 } from '@/models/tasktypes/Tasktype5'
  import yellowBox from '@/assets/images/tasks/type5/Type5_YellowBox@2x.png'
  import blueBox from '@/assets/images/tasks/type5/Type5_BlueBox@2x.png'

  // type DraggableDIVElementEvent = DraggableEvent & { event: { target: EventTarget | HTMLDivElement } }

  interface Word {
    index: number
    image: string
    word: StemType
    visible: boolean
    enabled: boolean
    draggable: boolean
    opacity: number
    element?: HTMLElement
    id: string
    position: ControlPosition
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype5> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype5,
    correct: 0,
    of: task.value.draggableImages.length,
    answerOptions: task.value.draggableImages.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const activeDrags = ref(0)
  const highlight1 = ref(false)
  const highlight2 = ref(false)

  const items: Ref<Word[]> = ref([])
  const draggableItem: Ref<Word | undefined> = ref()
  const wordBox1 = ref()
  const wordBox2 = ref()
  const dragImageOpacity = ref(0)
  const blueLights = ref(false)
  const yellowLights = ref(false)
  const box1 = ref('')
  const box2 = ref('')
  const box1Highlight = ref(false)
  const box2Highlight = ref(false)
  let instructionAudio: MP3Audio | WebAudio
  let instructionAudio2: MP3Audio | WebAudio

  const boxAudio: {
    1?: MP3Audio | WebAudio
    2?: MP3Audio | WebAudio
  } = {}

  const movedItems: {
    1: Word[]
    2: Word[]
  } = { 1: [], 2: [] }

  const getBoxIndex = (target: Element) => {
    return parseInt(target.id.substring(9), 10) || undefined
  }

  const touchOverBox = () => {
    console.log('moved')
  }

  // A 'dropbox' element should incude the class 'drop-target'
  const getDropboxElement = (ev: DraggableEvent): Element | undefined => {
    let x = 0
    let y = 0
    const e = ev.event
    if (!e) return
    //const e = e.event || e.detail.event
    if (e.type && e.type.includes('touch')) {
      const te = e as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    return el && el.classList.contains('drop-target') ? el : undefined
  }

  const onStart = (e: DraggableEvent) => {
    if (!e.event) return
    e.event.preventDefault()
    activeDrags.value = 1
  }

  const onPointerDown = (e: PointerEvent) => {
    const el = e.target as HTMLDivElement
    if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
  }

  const onPointerMove = (ev: DraggableEvent) => {
    const n = pointerInDropArea(ev)
    if (n === 1) box1Highlight.value = true
    else if (n === 2) box2Highlight.value = true
    else {
      box1Highlight.value = false
      box2Highlight.value = false
    }
  }

  function rectContainsPoint(rect: DOMRect, x: number, y: number) {
    const isTrue = rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height
    if (isTrue) {
      return true
    }
    return false
  }

  function pointerInDropArea(ev: DraggableEvent): number {
    let x = 0
    let y = 0
    const e = ev.event
    if (!e) return 0
    const box1Rect = wordBox1.value.getBoundingClientRect()
    const box2Rect = wordBox2.value.getBoundingClientRect()
    if (e.type && e.type.includes('touch')) {
      const te = e as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    if (rectContainsPoint(box1Rect, x, y)) return 1
    if (rectContainsPoint(box2Rect, x, y)) return 2
    else return 0
  }

  /*   function onDropAreaMouseEnter(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'word-box-1') wordBox1.value.classList.add('bg-red-400')
      else wordBox2.value.classList.add('bg-red-400')
    }
  }

  function onDropAreaMouseLeave(e: MouseEvent | TouchEvent) {
    const el = e && (e.target as HTMLElement)
    if (activeDrags.value && el) {
      if (el.id === 'word-box-2') wordBox1.value.classList.remove('bg-red-400')
      else wordBox2.value.classList.remove('bg-red-400')
    }
  }
*/

  const onDrop = (ev: DraggableEvent, theWord?: Word) => {
    activeDrags.value = 0
    box1Highlight.value = false
    box2Highlight.value = false
    const el = getDropboxElement(ev)
    if (theWord && el) {
      const boxIndex = (getBoxIndex(el) as keyof typeof boxAudio) || undefined

      const correctItem = (item: Word, boxId: keyof typeof movedItems) => {
        tracking.details.correct++
        tracking.details.answer_details.push({
          attempt: item.word,
          correct: true,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        stateActions.progress.completeAStar()
        item.enabled = false
        item.visible = false
        movedItems[boxId].push(item)
        dragImageOpacity.value = 0

        setTimeout(() => {
          let newItem = items.value.pop()
          if (newItem) {
            newItem.visible = true
            draggableItem.value = newItem
            setTimeout(() => {
              blueLights.value = false
              yellowLights.value = false
              dragImageOpacity.value = 1
            }, 500)
          } else {
            if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
              stateActions.speakLocalised(
                SpeechSounds.instructions.warmups.T5,
                () => {
                  completeTask()
                },
                1000,
                false,
              )
            } else completeTask()
          }
        }, 500)
      }

      const incorrectItem = (item: Word) => {
        tracking.details.incorrectAttempts++
        tracking.details.answer_details.push({
          attempt: item.index.toString(),
          correct: false,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        items.value.push(item)
        items.value = shuffleItems(items.value)
        dragImageOpacity.value = 0
        setTimeout(() => {
          item.position = { x: 0, y: 0 }
          let newItem = items.value.pop()
          if (newItem) {
            draggableItem.value = newItem
            setTimeout(() => {
              dragImageOpacity.value = 1
            }, 500)
          }
        }, 500)
      }

      if (boxIndex === 1) {
        // Item landed in box 1
        // The item moved matches box1 ('stem' box)
        if (theWord.word === StemType.Stem && theWord.enabled) {
          yellowLights.value = true
          correctItem(theWord, 1)
        } else incorrectItem(theWord)
      } else if (boxIndex === 2) {
        // Item landed in box 2
        // The item moved matches box2 ('morphedStem' box)
        if (theWord.word === StemType.MorphedStem && theWord.enabled) {
          blueLights.value = true
          correctItem(theWord, 2)
        } else incorrectItem(theWord)
      }

      //----------------
    } else if (theWord) theWord.position = { x: 0, y: 0 }
  }

  const clickImage = (boxAudioIndex: keyof typeof boxAudio) => {
    const audio = boxAudio[boxAudioIndex]
    if (audio) {
      audio.playWhenReady()
      tracking.details.use_audio_content_items++
    }
  }

  const setupTask = async () => {
    box1.value = yellowBox
    box2.value = blueBox
    // box1 = typeof task.box1.image !== 'undefined' && task.box1.image.url !== null ? task.box1.image.url : 'assets/images/tasks/type5/Type5_YellowBox@2x.png';
    // box2 = typeof task.box2.image !== 'undefined' && task.box2.image.url !== null ? task.box2.image.url : 'assets/images/tasks/type5/Type5_BlueBox@2x.png';
    boxAudio[1] = task.value.box1.audio ? await createSound(task.value.box1.audio) : undefined
    boxAudio[2] = task.value.box2.audio ? await createSound(task.value.box2.audio) : undefined

    const wordItems: Word[] = task.value.draggableImages.map((di, i) => ({
      index: i,
      image: di.image || '',
      word: di.word || '',
      enabled: true,
      visible: false,
      draggable: true,
      opacity: 1,
      id: 'draggable-word-' + i,
      position: { x: 0, y: 0 },
    }))
    items.value = shuffleItems(wordItems)
    stateActions.progress.progressShow({ stars: items.value.length })

    let newItem = items.value.pop()
    if (newItem) {
      newItem.visible = true
      draggableItem.value = newItem
    }

    if (task.value.instruction1Audio) {
      instructionAudio = await createSound(task.value.instruction1Audio)
      instructionAudio.onended = () => {
        if (task.value.instruction2Audio) {
          highlight1.value = false
          setTimeout(() => {
            highlight2.value = true
            instructionAudio2.playWhenReady()
          }, 500)
        } else {
          stateActions.setSpeakerSound([task.value.instruction1Audio])
          stateSetters.speakerIsPlaying = false
        }
      }
    }

    if (task.value.instruction2Audio) {
      instructionAudio2 = await createSound(task.value.instruction2Audio)
      instructionAudio2.onended = () => {
        stateActions.setSpeakerSound([task.value.instruction1Audio, task.value.instruction2Audio])
        stateSetters.speakerIsPlaying = false
        highlight2.value = false
      }
    }

    introduceChallenge()
  }

  const introduceChallenge = () => {
    dragImageOpacity.value = 1
    opacity.value = 1
    if (!task.value.instruction1Audio || !task.value.instruction2Audio) {
      setTimeout(() => {
        setTimeout(() => {
          highlight1.value = false
          highlight2.value = true
          setTimeout(() => {
            highlight2.value = false
          }, 1000)
        }, 1000)
        highlight1.value = true
      }, 1000)
    }
    setTimeout(() => {
      if (task.value.instruction1Audio) {
        highlight1.value = true
        stateSetters.speakerIsPlaying = true
        instructionAudio.playWhenReady()
      }
    }, 1000)
  }

  const completeTask = () => {
    opacity.value = 0
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  .task5DropArea {
    position: absolute;
    height: 100%;
  }
  .hoverhighlight1 {
    background-color: rgba(255, 255, 0, 0.3);
  }
  .hoverhighlight2 {
    background-color: rgba(0, 255, 255, 0.3);
  }

  .task5DropArea div img {
    width: 100%;
  }

  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 2%;
  }

  .scanner-image {
    width: 36%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
  }
  .scanner-padding {
    padding: 4% 3%;
    position: relative;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
  .scanner-word {
    margin-top: -50px;
  }

  .yellow-border {
    border-color: #fff000;
  }

  .blue-border {
    border-color: #02c3e4;
  }

  .lightBox {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
