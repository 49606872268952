/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type6/ScannerType6@2x.png" />

    <div class="scanner-content">
      <div class="scanner-padding" @mouseup="checkMouseEnd" @touchend="checkTouchEnd">
        <div class="fixed top-0 left-0 w-full">
          <div class="taskNoUserSelect flex flex-col" @mousemove="mouseMoving" @touchmove="mouseMoving">
            <div class="flex flex-col justify-around" style="height: 30vh; margin: 100px 10% 20px 10%; z-index: 200">
              <div class="flex flex-row justify-around w-full h-full">
                <!-- Drawable Words column 1-->
                <div
                  v-for="(word, i) in displayedWords1"
                  :key="`word-index-${i}`"
                  @mousedown="mouseDownOnWord($event, word)"
                  @touchstart="mouseDownOnWord($event, word)"
                >
                  <span class="task6BoxWord fadeInOut" :style="{ opacity: word.opacity, 'font-size': word.fontSize }">{{ word.text }}</span>
                </div>
              </div>

              <div class="flex flex-row justify-around w-full h-full">
                <!-- Drawable Words column 2 -->
                <div
                  v-for="(word, j) in displayedWords2"
                  :key="`word-index-${j}`"
                  @mousedown="mouseDownOnWord($event, word)"
                  @touchstart="mouseDownOnWord($event, word)"
                >
                  <span class="task6BoxWord fadeInOut" :style="{ opacity: word.opacity, 'font-size': word.fontSize }">{{ word.text }}</span>
                </div>
              </div>
            </div>

            <Drawing ref="drawing" class="type6canvas"></Drawing>

            <div class="z-50">
              <div class="flex flex-row justify-around">
                <!-- Release Area (stem) -->
                <div>
                  <div id="box1" class="relative p-5 cursor-pointer">
                    <img id="box1img" v-cache class="borderedImage imageLarge" :src="box1Image" style="border: solid 5px #9370db" />
                  </div>
                </div>

                <!-- Release Area (morphed stem) -->
                <div>
                  <div id="box2" class="relative p-5 cursor-pointer">
                    <img id="box2img" v-cache class="borderedImage imageLarge" :src="box2Image" style="border: solid 5px #9370db" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { MP3Audio, WebAudio, createSound, shuffleItems } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES } from '@/constants'
  import { Tasktype6 } from '@/models/tasktypes/Tasktype6'
  import Drawing from '@/components/task/Drawing.vue'
  import type { LinkedWord, Word } from '@/components/task/Drawing.vue'

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype6> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype6,
    correct: 0,
    of: 2,
    answerOptions: task.value.words.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const drawing = ref<InstanceType<typeof Drawing>>()
  const opacity = ref(0)

  let challengeActive = false

  // 'correct' is the count of correctly answered items. 'of' is the total allocated correct items in each set
  let box1Image = ''
  let box2Image = ''
  let words = []

  const displayedWords1: Ref<Word[]> = ref([])
  const displayedWords2: Ref<Word[]> = ref([])

  let linkedWord: LinkedWord | undefined
  let tappedFilename: MP3Audio | WebAudio | undefined

  async function setupTask() {
    // Choose random locations
    //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
    //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';

    if (!task.value) {
      alert('A Type 6 task does not exist - check your Session layout in the CMS')
      return
    }

    box1Image = task.value.box1image ? task.value.box1image : ''
    box2Image = task.value.box2image ? task.value.box2image : ''

    let tempWords: Word[] = []
    for (let i = 0; i < task.value.words.length; i++) {
      const word = task.value.words[i]
      const audio = word.audio ? await createSound(word.audio) : undefined
      if (word.text) {
        const correctBox = word.type === task.value.box1word ? 'box1' : word.type === task.value.box2word ? 'box2' : 'none'
        let w: Word = {
          text: word.text,
          correctBox: correctBox,
          audio,
          enabled: true,
          draggable: true,
          id: `word-${i}`,
          opacity: 0,
          fontSize: word.text.length > 10 ? '1em' : '2em',
          holdPositionInWords: -1, // Track the index of this word in the 'words' array.
          // Next time it is shuffled, we have the chance to position correct words at the same index
        }
        tempWords.push(w)
      }
    }

    stateActions.progress.progressShow({ stars: tracking.details.of })

    words = shuffleItems(tempWords)
    words.forEach((w, i) => {
      w.holdPositionInWords = i
    })
    displayedWords1.value = words
    displayedWords2.value = words.splice(3, 2)

    introduceChallenge()
  }

  function introduceChallenge() {
    opacity.value = 1
    stateSetters.speakerIsPlaying = true
    setTimeout(() => {
      stateActions.speakLocalised(SpeechSounds.instructions.tasks.T6)
      setTimeout(() => {
        stateSetters.speakerIsPlaying = false
        if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
          stateActions.speakLocalised(SpeechSounds.instructions.warmups.T6, undefined, 1000, false)
        }
        challengeActive = true
      }, 2000)

      let showWord = (wordArray: Word[], index: number) => {
        setTimeout(() => {
          wordArray[index].opacity = 1
          if (index < wordArray.length - 1) {
            showWord(wordArray, ++index)
          }
        }, 500)
      }
      showWord(displayedWords1.value.concat(displayedWords2.value), 0)
    }, 1000)
  }

  function checkTouchEnd(event: TouchEvent) {
    event.preventDefault()
    event.stopPropagation()
    let changedTouch = event.changedTouches[0]
    let element = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY)

    if ((element && element.id === 'box1') || (element && element.id === 'box1img')) {
      mouseUpOnBox(event, 'box1', element)
    } else if ((element && element.id === 'box2') || (element && element.id === 'box2img')) {
      mouseUpOnBox(event, 'box2', element)
    } else {
      mouseUpOutsideBox()
    }
  }

  function checkMouseEnd(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    let element = document.elementFromPoint(event.clientX, event.clientY)

    if ((element && element.id === 'box1') || (element && element.id === 'box1img')) {
      mouseUpOnBox(event, 'box1', element)
    } else if ((element && element.id === 'box2') || (element && element.id === 'box2img')) {
      mouseUpOnBox(event, 'box2', element)
    } else {
      mouseUpOutsideBox()
    }
  }

  function mouseMoving(event: MouseEvent | TouchEvent) {
    event.preventDefault()
    event.stopPropagation()
    tappedFilename = undefined
    if (linkedWord && drawing.value) {
      drawing.value.message({ event, message: 'morfologiTaskMousemove' })
    }
  }

  function mouseDownOnWord(event: MouseEvent | TouchEvent, item: Word) {
    tappedFilename = item.audio
    if (item.enabled) {
      let startX = 0,
        startY = 0

      if (event.type.includes('touch')) {
        const e = event as TouchEvent
        startX = e.changedTouches[0].clientX
        startY = e.changedTouches[0].clientY
      } else if ((event as MouseEvent).clientX) {
        const e = event as MouseEvent
        startX = e.clientX
        startY = e.clientY
      }

      let element = document.elementFromPoint(startX, startY)
      if (drawing.value && element) {
        linkedWord = {
          word: item,
          startX: startX,
          startY: startY,
          startElement: element,
          endElement: undefined,
          endX: 0,
          endY: 0,
        }
        drawing.value.message({ message: 'morfologiTaskMousedown', word: linkedWord })
      }
    }
  }

  function mouseUpOutsideBox() {
    if (tappedFilename) {
      tappedFilename.onended = () => {
        tappedFilename = undefined
      }
      tappedFilename.playWhenReady()
      tracking.details.use_audio_content_items++
    }
    if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
    linkedWord = undefined
  }

  function mouseUpOnBox(event: MouseEvent | TouchEvent, boxType: string, el: Element) {
    tappedFilename = undefined
    const correctAnswer = linkedWord && linkedWord.word.correctBox === boxType
    if (challengeActive && linkedWord && (correctAnswer || task.value.unforgiving)) {
      // Correct combination

      if (event.type.includes('touch')) {
        const e = event as TouchEvent
        linkedWord.endX = e.changedTouches[0].clientX
        linkedWord.endY = e.changedTouches[0].clientY
      } else if ((event as MouseEvent).clientX) {
        const e = event as MouseEvent
        linkedWord.endX = e.clientX
        linkedWord.endY = e.clientY
      }

      linkedWord.endElement = el

      if (drawing.value) drawing.value.message({ message: 'morfologiTaskLine', word: linkedWord })
      tracking.details.correct++

      tracking.details.answer_details.push({
        attempt: linkedWord.word.text,
        correct: correctAnswer,
        elapsed: tracking.elapsedTimeSinceLastCall,
      })
      stateActions.progress.completeAStar()
      linkedWord.word.audio?.playWhenReady()
      linkedWord.word.enabled = false
      const allUnforgivingAttemptsUsed = task.value.unforgiving && tracking.details.answer_details.length === tracking.details.of
      if (tracking.details.of === tracking.details.correct || allUnforgivingAttemptsUsed) {
        challengeActive = false
        setTimeout(() => {
          completeTask()
        }, 1500)
      }
    } else if (linkedWord) {
      if (challengeActive && linkedWord !== null && linkedWord.word.correctBox !== boxType) {
        tracking.details.incorrectAttempts++
        tracking.details.answer_details.push({
          attempt: linkedWord.word.text,
          correct: false,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        const allUnforgivingAttemptsUsed = task.value.unforgiving && tracking.details.answer_details.length === tracking.details.of
        if (allUnforgivingAttemptsUsed) {
          challengeActive = false
          setTimeout(() => {
            completeTask()
          }, 1500)
        }
      }
      reshuffleUnusedWords()
      // dataService.tryAgain();
    }
    linkedWord = undefined
    if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
  }

  const reshuffleUnusedWords = () => {
    let firstCorrectSelection: { index: number; item: Word | undefined } = { index: -1, item: undefined }
    let tempWords = displayedWords1.value.concat(displayedWords2.value)
    tempWords = shuffleItems(tempWords)
    tempWords.forEach((w, i) => {
      if (!w.enabled) {
        firstCorrectSelection = { index: i, item: w }
      } else {
        w.holdPositionInWords = i
      }
    })
    if (firstCorrectSelection.index > -1 && firstCorrectSelection.item) {
      tempWords.splice(firstCorrectSelection.item.holdPositionInWords || 0, 0, tempWords.splice(firstCorrectSelection.index, 1)[0])
    }
    displayedWords1.value = tempWords
    displayedWords2.value = tempWords.splice(3, 2)
  }

  const completeTask = () => {
    opacity.value = 0
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }

  setupTask()
</script>

<style scoped lang="postcss">
  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 2%;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: 82vh;
  }

  .scanner-content {
    position: absolute;
    width: 100%;
    top: 10%;
  }

  .scanner-padding {
    padding: 5%;
    position: relative;
    z-index: 0;
  }

  .scanner-word {
    margin-top: -45px;
  }

  .task6BoxWord,
  .task6BoxWord:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 1);
    border: solid #9370db 5px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5em;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline-style: none; /*IE*/
  }

  .type6drawingCanvas {
    z-index: 100;
    pointer-events: none;
  }
  .type6canvas canvas {
    overflow: hidden;
    position: fixed;
    top: 0;
  }

  .type6canvas {
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 100;
    pointer-events: none;
  }
</style>
