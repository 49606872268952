/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <div class="scanner-content">
      <img class="scanner-image" src="@/assets/images/tasks/type12/ScannerType12-3@2x.png" />

      <div class="scanner-padding">
        <div class="results-container">
          <transition mode="out-in" name="fade">
            <div v-if="showNumberCount" class="type12-number-box">
              <span>{{ answerList.length + 1 }}</span>
            </div>
          </transition>

          <div class="type12-results">
            <ul id="scrollingList">
              <li v-for="word in answerList" :key="`word-index-${word}`" :class="{ editingWord: editingWord === word }" @click="editWord(word)">
                {{ word }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="type12-input-container">
      <div class="type12-input-box flex flex-row justify-between">
        <div class="w-4/5">
          <InputCarat
            v-model="wordInput"
            :caret-location="caretLocation"
            :the-morfem="inputWordForCaret"
            :input-colour="inputColour"
            @answer="addAnswer"
          />
        </div>
        <button class="w-14 h-14 rounded-full text-black text-4xl border-4 bg-white self-center mr-2" @click="addAnswer">⬇︎</button>
      </div>
    </div>

    <div class="sendInButton flex flex-row justify-center relative">
      <img
        style="height: 120px"
        :src="submitActive ? finishActive : finishDisabled"
        :class="{ 'sendInButton-active': submitActive }"
        @click="finish()"
      />
      <div class="absolute w-full h-full flex place-items-center justify-center text-3xl pointer-events-none">
        <p class="pointer-events-none" :class="[submitActive ? 'text-white' : 'text-gray-300']">{{ t('done') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { TASK_TYPES } from '@/constants'
  import InputCarat from '@/components/task/InputCarat.vue'
  import { Tasktype12 } from '@/models/tasktypes/Tasktype12'

  import finishActive from '@/assets/images/tasks/type12/FinishButtonActiveG@2x.png'
  import finishDisabled from '@/assets/images/tasks/type12/FinishButtonDisabledG@2x.png'
  import { MP3Audio, WebAudio, createSound } from '@/utilities'

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype12> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const messages = {
    no: {
      done: 'ferdig',
    },
    sv: {
      done: 'Klar',
    },
    en: {
      done: 'done',
    },
  }
  const { t } = useI18n({ messages })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype12,
    correct: 0,
    of: 0,
    answerOptions: 0,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)

  const submitActive = ref(false)
  const inputWordForCaret = ref('')
  const wordInput = ref('')
  const caretLocation = ref('start')
  const showNumberCount = ref(false)
  const answerList = ref<string[]>([])
  const inputColour = ref('#FFFFFF')
  const editingWord = ref('')
  let instructionAudio: MP3Audio | WebAudio

  let addIsActive = false
  let startTime = Date.now()

  async function setupTask() {
    if (task.value.affix === 'prefix') {
      caretLocation.value = 'end'
    } else if (task.value.affix === 'suffix') {
      caretLocation.value = 'start'
    }
    stateActions.progress.progressShow({ stars: 1 })

    if (task.value.introductionAudio) {
      instructionAudio = await createSound(task.value.introductionAudio)
      instructionAudio.onended = () => {
        stateActions.setSpeakerSound([task.value.introductionAudio])
        stateSetters.speakerIsPlaying = false
      }
    }

    opacity.value = 1
    setTimeout(() => {
      showNumberCount.value = true
      beepTheMorfem(task.value.word)
      setTimeout(() => {
        introduceChallenge()
      }, 1000)
    }, 1000)
  }

  function introduceChallenge() {
    if (task.value.introductionAudio) {
      stateSetters.speakerIsPlaying = true
      instructionAudio.playWhenReady()
    }
  }

  function editWord(word: string) {
    inputColour.value = '#00ff45'
    editingWord.value = word
    beepTheMorfem(word)
  }

  function confirmEdit(newWord: string) {
    if (newWord.length > 0 && newWord.length < 30 && answerList.value.indexOf(newWord) === -1) {
      const i = answerList.value.indexOf(editingWord.value)
      answerList.value[i] = newWord
      const j = tracking.details.answer_details.find((t) => {
        return t.attempt === editingWord.value
      })
      if (j) {
        j.attempt = newWord
        j.edited = true
        j.elapsed = Date.now() - startTime
      }
      startTime = Date.now()
    }
    cancelEdit()
  }

  function cancelEdit() {
    editingWord.value = ''
    wordInput.value = ''
    inputColour.value = '#FFFFFF'
    beepTheMorfem(task.value.word)
  }

  function scrollResultsToBottom() {
    let el = document.getElementById('scrollingList')
    if (el) el.scrollTop = el.scrollHeight
  }

  function showTypedResponse(response: string) {
    inputColour.value = '#FF0000'
    wordInput.value = response
    setTimeout(() => {
      inputColour.value = '#FFFFFF'
      wordInput.value = ''
      beepTheMorfem(task.value.word)
    }, 2000)
  }

  function addAnswer() {
    const value: string = wordInput.value
    // vett the answer
    // if ok add to answerList
    if (!addIsActive) {
      return
    } else if (editingWord.value) {
      confirmEdit(value)
    } else if (value.length > 0 && value.length < 30 && answerList.value.indexOf(value) === -1) {
      addIsActive = false
      stateActions.progress.completeAStar()
      answerList.value.push(value.toLowerCase())
      tracking.details.answer_details.push({ attempt: value, edited: false, elapsed: Date.now() - startTime })
      startTime = Date.now()
      inputWordForCaret.value = ''
      wordInput.value = ''
      if (answerList.value.length > 0) {
        submitActive.value = true
      }
      showNumberCount.value = true
      setTimeout(() => {
        scrollResultsToBottom()
        stateActions.progress.progressShow({ stars: 1 }) // reset to one empty star
        beepTheMorfem(task.value.word)
      }, 1500)
    } else {
      addIsActive = false
      let response = ''
      /* if (value.length >= 50) {
        response = 'for langt!'
      } else  */ if (value.length === 0) {
        response = 'for kort!'
      } else if (answerList.value.indexOf(value) > -1) {
        response = 'ordet brukt!'
      }
      showTypedResponse(response)
    }
  }

  function beepTheMorfem(sourceWord: string) {
    let beepAudio = [
      new MP3Audio('/assets/sounds/general/beep.mp3'),
      new MP3Audio('/assets/sounds/general/beep.mp3'),
      new MP3Audio('/assets/sounds/general/beep.mp3'),
    ]
    let counter = 0
    let constructedWord = ''
    let theInterval = setInterval(() => {
      if (counter === sourceWord.length) {
        clearInterval(theInterval)
        inputWordForCaret.value = sourceWord
        showNumberCount.value = false
        addIsActive = true
      } else {
        beepAudio[counter % 3].play()
        if (caretLocation.value === 'end') {
          constructedWord += sourceWord.charAt(counter)
        } else {
          constructedWord = sourceWord.charAt(sourceWord.length - counter - 1) + constructedWord
        }
        wordInput.value = constructedWord
        counter++
      }
    }, 200)
  }

  function finish() {
    if (submitActive.value) {
      completeTask()
    }
  }

  function completeTask() {
    opacity.value = 0
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 500)
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  .sendInButton {
    margin-top: 30px;
  }

  .type12-input-container {
    position: absolute;
    width: 100%;
    height: 12vh;
    top: 0;
    padding-top: 2%;
  }
  .type12-input-box {
    margin-right: auto;
    margin-left: auto;
    width: 53%;
    background-color: black;

    border-radius: 75px;
    outline: none;
    border: solid rgba(152, 152, 152, 1) 5px;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    color: #000000;
  }
  .type12-input {
    font-size: 6vh;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 400;
    outline: none;
    margin-top: 3%;
    text-align: center;
    width: 80%;
  }

  .type12-number-box {
    font-size: 10em;
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: 400px;
    position: absolute;
    top: 0%;
    left: 42%;
    color: rgb(254, 246, 52);
  }

  .type12-results {
    font-size: 2em;
    width: 100%;
    color: #0ee5ff;
    text-align: center;

    max-height: 40vh;
    overflow: hidden;
    display: block;
  }
  .type12-results ul {
    max-height: 40vh;
    overflow: auto;
    list-style-type: none;
    font-size: 0.7em;
    margin: 0;
    line-height: 2em;
  }

  .task-container {
    width: 100vw;
    position: relative;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: relative;
    width: 100%;
    top: 0;
    padding-top: 2%;
  }
  .scanner-padding {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .results-container {
    position: relative;
    height: 70%;
    margin: 12% auto 5%;
    width: 80%;
  }
  .scanner-word {
    margin-top: -50px;
  }

  .editingWord {
    color: #00ff45;
  }
</style>
