/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="task-container fadeInOut" :style="{ opacity: opacity }">
    <img class="scanner-image" src="@/assets/images/tasks/type7/ScannerType7_NoCircles@2x.png" />
    <div class="scanner-content">
      <div class="scanner-padding">
        <div class="star-container">
          <div class="star-left star-transition" :style="starLeftCss">
            <div class="star-content">
              <img :src="combinationBoxes === 3 ? star3partsLeft : star2partsLeft" />
              <div
                v-if="!droppedItem1"
                id="word-box-1"
                v-draggable="{ axis: 'none' }"
                class="wordDropBox wordDropBoxLeft drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>
              <transition mode="out-in" name="fade">
                <div v-if="droppedItem1 && combinedWord === ''" class="wordDropBox wordDropBoxMiddle">
                  <span @click="clickWord(droppedItem1)">{{ droppedItem1.text }}</span>
                </div>
              </transition>
            </div>
          </div>

          <div v-if="combinationBoxes === 3" class="star-middle star-transition" :style="starMiddleCss">
            <div class="star-content">
              <img src="@/assets/images/tasks/type7/star3parts/StarMiddle@2x.png" />
              <div
                v-if="!droppedItem2"
                id="word-box-2"
                v-draggable="{ axis: 'none' }"
                class="wordDropBox wordDropBoxMiddle drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>
              <transition mode="out-in" name="fade">
                <div v-if="droppedItem2 && combinedWord === ''" class="wordDropBox wordDropBoxMiddle">
                  <span @click="clickWord(droppedItem2)">{{ droppedItem2 ? droppedItem2.text : '' }}</span>
                </div>
              </transition>
            </div>
          </div>

          <div class="star-right star-transition" :style="starRightCss">
            <div class="star-content">
              <img :src="combinationBoxes === 3 ? star3partsRight : star2partsRight" />
              <div
                v-if="!droppedItem3"
                id="word-box-3"
                v-draggable="{ axis: 'none' }"
                :style="{ left: combinationBoxes === 3 ? '30px' : '65px' }"
                class="wordDropBox wordDropBoxRight drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>
              <transition mode="out-in" name="fade">
                <div v-if="droppedItem3 && combinedWord === ''" class="wordDropBox wordDropBoxMiddle">
                  <span @click="clickWord(droppedItem3)">{{ droppedItem3 ? droppedItem3.text : '' }}</span>
                </div>
              </transition>
            </div>
          </div>

          <!-- Combined word -->
          <transition mode="out-in" name="fade">
            <div v-if="combinedWord" class="wordDropBox combinedWord">
              <span>{{ combinedWord }}</span>
            </div>
          </transition>
        </div>

        <div v-if="discoveredCombinations.length > 0" class="completedWords">
          <ul>
            <li v-for="(word, index) in discoveredCombinations" :key="`word-${index}`">{{ word }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div id="word-source-box" class="fadeInOut flex flex-row justify-around" :style="{ opacity: opacityWords }" style="margin: 50px 0">
      <!-- Draggable Items -->
      <template v-for="word in words" :key="word.reference">
        <Draggable
          :id="`word-index-{{word.reference}}`"
          class="z-10"
          :class="`${activeDrags ? 'pointer-events-none touch-none' : ''}`"
          :position="word.position"
          @pointerdown="onPointerDown"
          @start="(e: any) => onStart(e)"
          @stop="(e: any) => onDrop(e, word)"
        >
          <span class="taskNoUserSelect borderedWordBox touch-auto pointer-events-auto">{{ word['text'] }}</span>
        </Draggable>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, PropType, toRefs, Ref } from 'vue'
  import { Draggable, ControlPosition, DraggableEvent } from '@braks/revue-draggable'
  import { TaskTracking } from '@/models/main'
  import useState from '@/composition/useState'
  import { shuffleItems, createSound, MP3Audio, WebAudio } from '@/utilities'
  import { SpeechSounds, TaskMode, TASK_TYPES, WordIndexName } from '@/constants'
  import { Tasktype7 } from '@/models/tasktypes/Tasktype7'
  import type { Type7Correct } from '@/models/tasktypes/Tasktype7'
  import star2partsLeft from '@/assets/images/tasks/type7/star2parts/StarLeft@2x.png'
  import star3partsLeft from '@/assets/images/tasks/type7/star3parts/StarLeft@2x.png'
  import star2partsRight from '@/assets/images/tasks/type7/star2parts/StarRight@2x.png'
  import star3partsRight from '@/assets/images/tasks/type7/star3parts/StarRight@2x.png'

  type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

  interface Word {
    index: number
    visible: boolean
    enabled: boolean
    draggable: boolean
    opacity: number
    element?: HTMLElement
    id: string
    position: ControlPosition

    audio: MP3Audio | WebAudio
    text: string
    reference: WordIndexName
  }

  const emit = defineEmits(['completed'])
  const props = defineProps({
    task: { required: true, type: Object as PropType<Tasktype7> },
    myIndex: { required: false, type: Number, default: 0 },
  })
  const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
  const { task } = toRefs(props)
  const tracking = new TaskTracking(stateGetters.trackings.value.taskTracking)
  tracking.details = {
    taskType: TASK_TYPES.Tasktype7,
    correct: 0,
    of: task.value.correct.length,
    answerOptions: task.value.words.length,
    incorrectAttempts: 0,
    answer_details: [],
    use_audio_instructions: 0,
    use_audio_content_items: 0,
  }
  const opacity = ref(0)
  const opacityWords = ref(0)
  const activeDrags = ref(0)

  let unforgivingTestMode = false
  let attempts = 0
  let cancelDropEvent = false

  // Models for words
  let words: Ref<Word[]> = ref([])
  const discoveredCombinations: Ref<string[]> = ref([])

  // Divide the correct combined word answers into twos and threes, so we know how many answer boxes to show on screen
  const combinationBoxes = ref(2)
  let correctCombinations2: Type7Correct[] = []
  let correctCombinations3: Type7Correct[] = []

  // Temp models for Dragula
  const droppedItem1: Ref<Word | undefined> = ref(undefined)
  const droppedItem2: Ref<Word | undefined> = ref(undefined)
  const droppedItem3: Ref<Word | undefined> = ref(undefined)
  const combinedWord = ref('')

  let draggingNow = false
  let finishDone = false
  let taskCompleted = false
  let audioPlaying = false

  const starLeftCss = ref({})
  let starMiddleCss = ref({})
  let starRightCss = ref({})

  // A 'dropbox' element should incude the class 'drop-target'
  const getDropboxElement = (e: DraggableEvent): Element | undefined => {
    let x = 0
    let y = 0
    if (!e.event) return
    if (e.event.type.includes('touch')) {
      const te = e.event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = e.event as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    return el && el.classList.contains('drop-target') ? el : undefined
  }

  const onStart = (e: DraggableEvent) => {
    if (!e.event) return
    e.event.preventDefault()
    activeDrags.value++
  }

  const onPointerDown = (e: PointerEvent) => {
    const el = e.target as HTMLDivElement
    if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
  }

  const setupTask = async () => {
    finishDone = false
    audioPlaying = false
    taskCompleted = false
    combinedWord.value = ''
    if (typeof task === 'undefined' || task === null) {
      alert('A Type 7 task does not exist - check your Session layout in the CMS')
      return
    }

    let c2 = []
    let c3 = []
    for (let ci = 0; ci < task.value.totalCorrect; ci++) {
      let correct: Type7Correct = task.value.correct[ci]
      if (correct.firstWord !== WordIndexName.None || correct.secondWord !== WordIndexName.None || correct.thirdWord !== WordIndexName.None) {
        if (correct.thirdWord !== WordIndexName.None) {
          c3.push(correct)
        } else {
          c2.push(correct)
        }
      }
    }
    for (let i = 0; i < task.value.correct.length; i++) {
      const correctAudio = await createSound(task.value.correct[i].audioURL)
      task.value.correct[i].audio = correctAudio
    }
    correctCombinations2 = shuffleItems(c2)
    correctCombinations3 = shuffleItems(c3)
    chooseRandomBoxes()

    let tempWords: Word[] = []
    for (let i = 0; i < task.value.words.length; i++) {
      const w = task.value.words[i]
      const audio = await createSound(w.audioURL)
      if (w.text)
        tempWords.push({
          index: i,
          visible: true,
          enabled: true,
          draggable: true,
          opacity: 1,
          id: `drag-word-${i}`,
          position: { x: 0, y: 0 },
          audio,
          text: w.text,
          reference: w.reference,
        })
    }
    tracking.details.of = task.value.totalCorrect
    stateActions.progress.progressShow({ stars: tracking.details.of })
    words.value = shuffleItems(tempWords)
    /* words.sort((a, b) => {
      return a.reference - b.reference
    }) */
    introduceChallenge()
  }

  function preventDropOnTap() {
    cancelDropEvent = true
    setTimeout(() => {
      cancelDropEvent = false
    }, 1000)
  }

  function removeAllWords() {
    if (droppedItem1.value) {
      words.value.push(droppedItem1.value)
      droppedItem1.value = undefined
    }
    if (droppedItem2.value) {
      words.value.push(droppedItem2.value)
      droppedItem2.value = undefined
    }
    if (droppedItem3.value) {
      words.value.push(droppedItem3.value)
      droppedItem3.value = undefined
    }
    draggingNow = false
  }

  const onDrop = async (e: DraggableDIVElementEvent, theWord?: Word) => {
    activeDrags.value = 0
    const el = getDropboxElement(e)
    if (theWord && el) {
      const boxIndex = getBoxIndex(el)
      if (cancelDropEvent || boxIndex === 0) {
        theWord.position = { x: 0, y: 0 }
        return
      } else {
        const container = boxIndex === 1 ? droppedItem1 : boxIndex == 2 ? droppedItem2 : droppedItem3
        container.value = theWord
        const i = words.value.indexOf(theWord)
        words.value.splice(i, 1)
      }

      let cc: Type7Correct
      if (combinationBoxes.value === 2) {
        cc = {
          firstWord: droppedItem1.value ? droppedItem1.value.reference : WordIndexName.None,
          secondWord: droppedItem3.value ? droppedItem3.value.reference : WordIndexName.None,
          thirdWord: WordIndexName.None,
          audioURL: '',
          audio: undefined,
        }
      } else {
        cc = {
          firstWord: droppedItem1.value ? droppedItem1.value.reference : WordIndexName.None,
          secondWord: droppedItem2.value ? droppedItem2.value.reference : WordIndexName.None,
          thirdWord: droppedItem3.value ? droppedItem3.value.reference : WordIndexName.None,
          audioURL: '',
          audio: undefined,
        }
      }

      let result: { status: string; correctRef?: Type7Correct } = task.value.hasCombinationStatus(
        cc,
        combinationBoxes.value === 2 ? correctCombinations2 : correctCombinations3,
      ) // Result is the correct combination including audio

      if (result.status === 'correct') {
        combinedWord.value = task.value.combinedWordFromCC(result.correctRef)
        if (discoveredCombinations.value.indexOf(combinedWord.value) === -1) {
          tracking.details.correct++
          tracking.details.answer_details.push({
            attempt: task.value.wordsFromCC(cc),
            correct: true,
            elapsed: tracking.elapsedTimeSinceLastCall,
          })
          stateActions.progress.completeAStar()
          // Fuse into one word
          closeStars()
          // Read completed word audio
          if (result.correctRef) {
            if (result.correctRef.audioURL) {
              const correctAudio = await createSound(result.correctRef.audioURL)
              correctAudio.playWhenReady()
            }
            // Add completed word to completed list
            discoveredCombinations.value.push(combinedWord.value)
            // Remove this combination from the list of correct combinations
            const comboBox = combinationBoxes.value === 2 ? correctCombinations2 : correctCombinations3
            const correctIndex = comboBox.indexOf(result.correctRef)
            comboBox.splice(correctIndex, 1)
          }
          if (tracking.details.correct >= tracking.details.of) {
            opacityWords.value = 0
            setTimeout(() => {
              fadeOut()
            }, 1000)
          }
        }
        // Place all words back into 'words'
        setTimeout(() => {
          combinedWord.value = ''
          removeAllWords()
          chooseRandomBoxes()
        }, 2000)
      } else if (result.status === 'some') {
        draggingNow = false
        // Deactivate the boxIndex position
      } else if (result.status === 'incorrect') {
        tracking.details.incorrectAttempts++
        tracking.details.answer_details.push({
          attempt: task.value.wordsFromCC(cc),
          correct: false,
          elapsed: tracking.elapsedTimeSinceLastCall,
        })
        setTimeout(() => {
          removeAllWords()
        }, 100)
      }

      // Check the allowed number of attempts here - at the moment it is matched with the number of correct answers
      // If number of attempts are limited to correct answers we will finish the task
      if (unforgivingTestMode && attempts >= tracking.details.of && !finishDone) {
        opacityWords.value = 0
        fadeOut()
      }
    } else if (theWord) {
      clickWord(theWord)
      theWord.position = { x: 0, y: 0 }
    }
  }

  function chooseRandomBoxes() {
    openStars()
    setTimeout(() => {
      if (correctCombinations3.length === 0) {
        combinationBoxes.value = 2
      } else if (correctCombinations2.length === 0) {
        combinationBoxes.value = 3
      } else {
        combinationBoxes.value = Math.floor(Math.random() * 2) + 2
      }
      openStars()
    }, 1000)
  }

  function getBoxIndex(target: Element) {
    return parseInt(target.id.substring(9), 10) || 0
  }

  function openStars() {
    if (combinationBoxes.value === 3) {
      starLeftCss.value = {
        left: '70px',
      }
      starMiddleCss.value = {
        left: '265px',
      }
      starRightCss.value = {
        left: '460px',
      }
    } else {
      starLeftCss.value = {
        left: '60px',
      }
      starRightCss.value = {
        left: '399px',
      }
    }
  }

  function closeStars() {
    if (combinationBoxes.value === 3) {
      starLeftCss.value = {
        left: '160px',
      }
      starMiddleCss.value = {
        left: '265px',
      }
      starRightCss.value = {
        left: '371px',
      }
    } else {
      starLeftCss.value = {
        left: '170px',
      }
      starRightCss.value = {
        left: '309px',
      }
    }
  }

  function clickWord(item?: Word) {
    if (item && !audioPlaying && !draggingNow) {
      audioPlaying = true
      item.audio.onended = () => (audioPlaying = false)
      item.audio.onerror = (error) => {
        audioPlaying = false
        console.log(error.toString())
      }
      item.audio.playWhenReady()
      tracking.details.use_audio_content_items++
    } else {
      draggingNow = false
    }
  }

  function introduceChallenge() {
    audioPlaying = true
    attempts = 0
    setTimeout(() => {
      opacity.value = 1
      opacityWords.value = 1
      stateActions.speakLocalised(
        SpeechSounds.instructions.tasks.T7,
        () => {
          audioPlaying = false
          stateSetters.speakerIsPlaying = false
        },
        1000,
      )
      stateSetters.speakerIsPlaying = true
    }, 1000)
  }

  function fadeOut() {
    if (!finishDone) {
      finishDone = true
      if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
        stateActions.speakLocalised(
          SpeechSounds.instructions.warmups.T7,
          () => {
            finish()
          },
          1000,
          false,
        )
      } else finish()
    }
  }

  function finish() {
    setTimeout(() => {
      opacity.value = 0
      if (!taskCompleted) {
        taskCompleted = true
        setTimeout(() => {
          emit('completed', true, tracking)
        }, 500)
      }
    }, 500)
  }

  setupTask()

  // ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
  .gu-mirror span {
    font-size: 1em;
  }
  .gu-transit span {
    font-size: 1em;
  }

  .wordDropBox {
    min-width: 150px;
    height: 70px;
    position: absolute;

    border-radius: 75px;
    background-color: #330670;

    outline: none;
    border: solid #7714ff 5px;
    cursor: pointer;
    font-size: 24pt;
    font-weight: 500;
    text-align: center;
    color: #ffd700;
    padding: 0 10px;
  }

  .wordDropBox span {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    line-height: 60px;
  }

  .wordDropBoxLeft {
    top: 170px;
    left: 0;
  }
  .wordDropBoxMiddle {
    top: 170px;
    left: 15px;
  }
  .wordDropBoxRight {
    top: 170px;
    left: 30px;
  }

  .task-container {
    width: 100vw;
    position: relative;
    padding-top: 2%;
  }

  .scanner-image {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
  .scanner-content {
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 2%;
  }
  .scanner-padding {
    margin: 5%;
    position: relative;
  }
  .scanner-word {
    margin-top: -45px;
  }

  .completedWords {
    position: absolute;
    top: 80%;
    right: 5%;
    color: #0ee5ff;
    font-size: 14pt;
    text-align: left;

    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 15px 10px 10px;
    border-radius: 10px;
  }
  .completedWords ul {
    margin: 0;
  }

  .combinedWord {
    top: 170px;
    left: 260px;
  }

  .star-container {
    min-height: 300px;
    position: relative;
    display: block;
    width: 80%;
  }

  .star-transition {
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -ms-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
    transition: left 0.5s ease;
  }

  .star-left {
    position: absolute;
    top: 0;
  }
  .star-left img {
    height: 400px;
  }

  .star-middle {
    position: absolute;
    top: 0px;
  }
  .star-middle img {
    height: 400px;
  }

  .star-right {
    position: absolute;
    top: 0px;
  }
  .star-right img {
    height: 400px;
  }

  .star-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
