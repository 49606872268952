<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-row">
    <div class="pt-2 px-2 border-r-2 border-grey">
      <div v-if="theUser" class="bg-blue-200 rounded p-1 text-sm">
        <p>
          <span class="text-slate-500">Selected user:&nbsp;</span>
          <strong>{{ theUser.profile.username }}</strong>
        </p>
        <p>
          <span class="text-slate-500">Groups:&nbsp;</span>
          <strong>{{ theUser.groups.length }}</strong>
        </p>
        <p>
          <span class="text-slate-500">Last login:&nbsp;</span>
          <strong>{{ lastLogin }}</strong>
        </p>
      </div>
      <div class="flex flex-col pt-3 text-left">
        <Button :textcolour="'text-black text-xs text-blue-500'" :childclass="'w-24 h-6'" class="m-2 place-self-end" @click="selectMyUser()"
          >min bruker</Button
        >

        <hr class="py-1" />
        <SelectionBox
          id="select-locationfilter"
          v-model="selectedActivityFilter"
          :label="'Filter Users by activity'"
          :options="activityFilterOptionList"
          class="m-2"
        ></SelectionBox>
        <p
          v-for="(us, i) in allUsers"
          :id="`p-selectuser-${us.profile.username}`"
          :key="i"
          :class="{ 'bg-blue-200': us._id === theUser._id }"
          class="cursor-pointer"
          @click="selectAUser(us)"
        >
          <span v-if="theUser && us._id === theUser._id">&nbsp;✔︎&nbsp;</span><span v-else></span>
          <span class="text-xs">{{ us.profile.username }}</span>
        </p>
      </div>
    </div>
    <div class="flex-col w-full">
      <div class="flex flex-row flex-grow py-4 text-left px-2 overflow-y-auto justify-between">
        <div class="mb-2">
          <h1 class="mb-2">User Details</h1>
          <AnswerInput
            v-if="hasAdminRole"
            v-model="theUser.profile.username"
            v-model:valid="validators.username"
            mode="text"
            label="Brukernavn"
            :border="false"
            placeholder="91234567"
            :underline="false"
            class="p-1 m-1"
            @change="saveUser()"
          />
          <AnswerInput
            v-if="hasAdminRole"
            v-model="theUser.profile.email"
            v-model:valid="validators.email"
            mode="email"
            label="e-post"
            :border="false"
            placeholder="user@example.com"
            :underline="false"
            class="p-1 m-1"
            @change="saveUser()"
          />
          <div v-if="hasAdminRole" :class="{ 'border border-dashed p-1': editPassword }">
            <AnswerInput
              v-if="editPassword"
              v-model="password"
              mode="password"
              label="new password"
              :border="false"
              placeholder="password"
              :underline="false"
              class="p-1 m-1"
            />
            <div v-if="editPassword">
              <Button class="focus:outline-none text-white mb-1" :textcolour="'text-black'" @click="editPassword = false">Cancel</Button>
              <Button :disabled="!password" class="focus:outline-none bg-green-400 text-white" :textcolour="'text-black'" @click="updatePassword()"
                >Update</Button
              >
            </div>
            <Button v-if="!editPassword" class="focus:outline-none bg-blue-button text-white" :textcolour="'text-black'" @click="editPassword = true">
              Change password
            </Button>
          </div>
          <AnswerInput
            v-if="hasAdminRole"
            v-model="theUser.profile.mobil"
            v-model:valid="validators.mobil"
            mode="tel"
            label="Mobilnummer"
            :border="false"
            placeholder="91234567"
            :underline="false"
            class="p-1 m-1"
            @change="saveUser()"
          />
          <SelectionBox
            v-if="hasAdminRole"
            id="select-role"
            v-model="currentUserRole"
            class="p-1 m-1"
            :label="'Role..'"
            :options="roleOptionList"
            :reset-on-choose="false"
            @change="updateRole"
          ></SelectionBox>
          <SelectionBox
            v-if="hasAdminRole"
            id="select-activity"
            v-model="activitySelected"
            class="p-1 m-1"
            :label="'Activity..'"
            :options="activityOptionList"
            :reset-on-choose="false"
            @change="updateActivity"
          ></SelectionBox>
        </div>
        <div v-if="hasAdminRole" class="flex flex-col mb-2">
          <h1 class="mb-2">Admin-only controls</h1>
          <AnswerInput
            v-model:booleanValue="delaysDisabled"
            class="my-2"
            mode="binaryChoice"
            :border="false"
            label="Disable delays (not stored!)"
            description="Remove deliberate time delays. This is only for testing."
            @change="disableDelays"
          ></AnswerInput>
          <AnswerInput
            v-model:booleanValue="scenesDisabled"
            class="my-2"
            mode="binaryChoice"
            :border="false"
            label="Disable scenes (not stored!)"
            description="Disable scenes. This is only for testing."
            @change="disableScenes"
          ></AnswerInput>
          <AnswerInput
            v-model:booleanValue="theUser.status.canEditPlayers"
            class="my-2"
            mode="binaryChoice"
            :border="false"
            description="This user can: create new Players and change the avatar's name"
            label="Can edit Players"
            @change="saveUser()"
          ></AnswerInput>
          <AnswerInput
            v-model:booleanValue="theUser.status.canEditGames"
            class="my-2"
            mode="binaryChoice"
            :border="false"
            label="Can edit Games"
            description="This user can: change a Game's name and shared groups, add / remove Players from Games"
            @change="saveUser()"
          ></AnswerInput>
          <AnswerInput
            v-model="theUser.profile.language"
            class="my-2"
            mode="singleChoice"
            description="Choose 'system' to allow the user's device to decide"
            :border="false"
            :options="languageList"
            label="Language"
            @change="saveUser()"
          ></AnswerInput>
        </div>
        <div class="flex flex-col justify-end">
          <Button
            id="button-add"
            class="mb-8 focus:outline-none bg-blue-button text-white justify-self-end"
            :textcolour="'text-black'"
            @click="addUser()"
          >
            Add new User
          </Button>
        </div>
      </div>
      <div class="flex flex-col flex-grow py-4 text-left px-2 overflow-y-auto">
        <hr />

        <!-- Group Selection -->
        <h1 class="font-bold">Locations / Kindergartens</h1>
        <div class="flex flex-col">
          <div class="flex flex-row w-full items-center">
            <div class="flex flex-row w-full justify-around">
              <p>Name</p>
              <p>location</p>
            </div>
            <p class="mr-6">Controls</p>
          </div>
          <div v-for="(g, i) in selectedGroups" :key="i" class="flex flex-row">
            <MonitorGroupItem :edit="false" :group="g" class="my-2 w-full">
              <Button
                :id="`button-remove-group-${g._id}`"
                class="my-1 mr-2"
                :childclass="'w-32'"
                :backgroundcolour="'bg-white'"
                :textcolour="'text-black'"
                @vclick="removeGroup(i)"
                >Remove</Button
              >
            </MonitorGroupItem>
          </div>
          <div class="flex flex-row justify-end mb-2">
            <SelectionBox
              id="select-group"
              v-model="groupSelected"
              :label="'Add a Group..'"
              :options="groupOptionList"
              :reset-on-choose="true"
              @change="addGroupToUser"
            ></SelectionBox>
          </div>
        </div>
        <hr />
      </div>
      <div class="flex flex-col flex-grow py-4 text-left px-2 overflow-y-auto">
        <!-- Games -->
        <h1 class="font-bold">Games owned by this User</h1>
        <div class="flex flex-row flex-wrap">
          <div v-for="g in myGames" :key="g._id" class="flex flex-col m-1">
            <MonitorGameItem :game="g" :edit="editingGame == g._id" @gamedeleted="editingGame = ''">
              <Button
                v-if="!editingGame"
                :id="`button-edit-player-${g._id}`"
                :childclass="'w-32'"
                :backgroundcolour="'bg-white'"
                :textcolour="'text-black'"
                class="my-1 mr-2"
                @vclick="editingGame = g._id"
                >Edit</Button
              >
              <template #closeEdit>
                <Button
                  v-if="editingGame"
                  id="`button-doneediting"
                  :backgroundcolour="'bg-white'"
                  :textcolour="'text-black'"
                  class="col-start-2 my-2 mr-2"
                  @vclick="editingGame = ''"
                  >Close</Button
                >
              </template>
            </MonitorGameItem>
          </div>
        </div>
        <Button id="`button-new-player" class="my-1 mr-2 place-self-end" :textcolour="'text-black'" @vclick="newGame()">Add new Game</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, Ref } from 'vue'
  import { USER_ROLE, ACTIVITY_IDS, ACTIVITY_NAMES_BY_ID, LanguageNames } from '@/constants'
  import { dateToFormattedString } from '@/utilities'
  import { User, Group } from '@/models/main'
  import useAppStore from '@/store/useAppStore'
  import useUserStore from '@/store/useUserStore'
  import useGameStore from '@/store/useGameStore'
  import useCMSStore from '@/store/useCMSStore'
  import MonitorGameItem from '@/components/admin/MonitorGameItem.vue'
  import MonitorGroupItem from '@/components/admin/MonitorGroupItem.vue'
  import SelectionBox from '@/components/base/SelectionBox.vue'
  import AnswerInput from '@/components/base/AnswerInput.vue'
  import Button from '@/components/base/Button.vue'

  interface RoleOptionListItem {
    itemName: string
    item: USER_ROLE
  }
  interface GroupOptionListItem {
    itemName: string
    item: Group
  }
  interface ActivityOptionListItem {
    itemName: string
    item: ACTIVITY_IDS | undefined
  }

  const { getters: appGetters, actions: appActions } = useAppStore()
  const { getters: userGetters, actions: userActions } = useUserStore()
  const { getters: gameGetters, actions: gameActions } = useGameStore()
  const { actions: cmsActions } = useCMSStore()
  const u = new User(userGetters.selectedUser.value)
  const theUser = ref(u)
  const validators: Ref<Record<string, boolean>> = ref({})
  const groupSelected = ref({ item: new Group(), itemName: 'none selected' })
  const activitySelected = ref({ item: ACTIVITY_IDS['2023 Andrea (Norway)'], itemName: 'rct' })
  const editingParticipant: Ref<string> = ref('')
  const editingGame: Ref<string> = ref('')
  const password = ref('')
  const editPassword = ref(false)

  const currentUserRole = ref({
    item: u.profile.role,
    itemName: u.profile.role,
  })
  const showSave = ref(false)
  const delaysDisabled = ref(false)
  const scenesDisabled = ref(false)
  delaysDisabled.value = appGetters.disableDelays.value
  scenesDisabled.value = appGetters.disableScenes.value

  const activityFilterOptionList = computed((): ActivityOptionListItem[] => {
    const gol: ActivityOptionListItem[] = Object.entries(ACTIVITY_IDS).map((e) => ({ itemName: e[0], item: e[1] }))
    gol.unshift({
      item: undefined,
      itemName: '(none)',
    })
    return gol
  })

  const selectedActivityFilter: Ref<ActivityOptionListItem> = ref({
    item: undefined,
    itemName: 'no group selected',
  })

  const saveUser = () => {
    return userActions.updateUser(theUser.value).then(() => (showSave.value = false))
  }

  const updatePassword = async () => {
    await userActions.updateUserPassword(password.value, theUser.value._id)
    password.value = ''
    editPassword.value = false
  }

  const hasAdminRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.admin))
  const hasLogsRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.logs))

  const selectAUser = (us: User) => {
    userActions.selectUser(us)
    theUser.value = new User(userGetters.selectedUser.value)
    currentUserRole.value.item = theUser.value.profile.role
    currentUserRole.value.itemName = theUser.value.profile.role
    gameActions.getGames(undefined, us._id)
    editingParticipant.value = ''
    editingGame.value = ''
    const activityID = theUser.value.status.currentActivityId
    activitySelected.value = { item: activityID as ACTIVITY_IDS, itemName: ACTIVITY_NAMES_BY_ID[activityID] }
  }
  const selectMyUser = () => {
    selectAUser(userGetters.myUser.value)
  }
  const lastLogin = computed(() => {
    if (userGetters.selectedUser.value.status.lastLogin) return dateToFormattedString(userGetters.selectedUser.value.status.lastLogin)
    else return 'Unknown'
  })

  const addUser = () => {
    userActions.createUser().then((u) => selectAUser(u))
  }

  const languageNames = Object.keys(LanguageNames)
  const languageList = languageNames.map((v, i) => ({
    id: '' + i,
    title: v,
  }))

  // -------------- Role -----------------
  const roleOptionList = computed((): RoleOptionListItem[] => {
    const roles = Object.values(USER_ROLE)
      .filter((r) => hasLogsRole.value || (hasAdminRole.value && r !== USER_ROLE.logs) || (r !== USER_ROLE.logs && r !== USER_ROLE.admin))
      .map((r) => ({ item: r, itemName: r }))
    return roles
  })

  const updateRole = (role: RoleOptionListItem) => {
    theUser.value.profile.role = role.item
    saveUser()
  }
  // -------------- Activity --------------
  const activityOptionList = computed((): ActivityOptionListItem[] => {
    return Object.entries(ACTIVITY_IDS).map((e) => ({ itemName: e[0], item: e[1] }))
  })
  const updateActivity = (a: ActivityOptionListItem) => {
    theUser.value.status.currentActivityId = a.item || ''
    saveUser()
    cmsActions.overrideCMS()
  }

  // -------------- Groups --------------

  const groupOptionList = computed((): GroupOptionListItem[] => {
    return userGetters.allGroups.value
      .filter((g) => !theUser.value.groups.some((up: Group) => up._id === g._id))
      .map((g) => {
        return { item: g, itemName: g.name }
      })
  })

  const addGroupToUser = (value: GroupOptionListItem) => {
    theUser.value.groups.push(value.item)
    saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(theUser.value))
  }

  const removeGroup = async (index: number) => {
    theUser.value.groups.splice(index, 1)
    saveUser().then(() => userActions.syncUserGroupsToGamesPlayers(theUser.value))
  }

  const selectedGroups = computed(() => theUser.value.groups)

  const disableDelays = (value: boolean) => {
    appActions.setDisableDelays(value)
  }
  const disableScenes = (value: boolean) => {
    appActions.setDisableScenes(value)
  }

  // ---------------- Games -------------------

  const myGames = computed(() => gameGetters.games.value.filter((g) => g.owner === theUser.value._id))
  const newGame = () => {
    gameActions.addGame(theUser.value)
  }

  // ------------  Initial data --------------

  // Request data to populate the Monitor views
  userActions
    .getAllUsers()
    .then(() => selectAUser(userGetters.myUser.value))
    .then(() => userActions.getAllGroups())

  const allUsers = computed(() => {
    const sa = selectedActivityFilter.value.item
    return userGetters.allUsers.value.filter((u) => !sa || (sa && u.status.currentActivityId === sa))
  })
</script>

<style scoped></style>
