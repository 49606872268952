<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<script setup lang="ts">
  import { computed } from 'vue'
  import useAppStore from './store/useAppStore'
  import useUserStore from './store/useUserStore'
  import useGameStore from './store/useGameStore'
  import useCMSStore from './store/useCMSStore'
  import useStateService from '@/composition/useState'
  import { useRouter } from 'vue-router'
  import { USER_ROLE /*, LoginMode, ViewState, StateVariables */ } from '@/constants'
  import DialogBox from '@/components/base/DialogBox.vue'

  const userStore = useUserStore()
  const appStore = useAppStore()
  const gameStore = useGameStore()
  const cmsStore = useCMSStore()

  const stateService = useStateService()
  const disableDelays = appStore.getters.disableDelays
  const dialogConfig = appStore.getters.dialogConfig
  const isMobileApp = appStore.getters.status.value.isMobileApp

  const router = useRouter()
  const monitor = () => router.push('/monitor')

  const fadeState = computed(() => {
    return {
      opacity: stateService.getters.fade.value ? '0' : '1',
      'background-image': "url('" + stateService.getters.backgroundImage.value + "')",
    }
  })

  const canSeeMonitor = computed(() => {
    const hasRights =
      userStore.getters.myUser.value.profile.role === USER_ROLE.monitor ||
      userStore.getters.myUser.value.profile.role === USER_ROLE.admin ||
      userStore.getters.myUser.value.profile.role === USER_ROLE.logs
    return hasRights && !router.currentRoute.value.path.includes('monitor')
  })

  const feedback = computed(() => {
    const sessionPercent = stateService.getters.progress.value.barData.completedPercent.toFixed(0)
    const shipPercent = stateService.getters.progress.value.shipBarData.completedPercent.toFixed(0)
    if (appStore.getters.feedback.value) return appStore.getters.feedback.value
    let text = userStore.getters.myUser.value.profile.username
    if (gameStore.getters.selectedGame.value) text += ' : ' + gameStore.getters.selectedGame.value.profile.name + ` ${shipPercent}%`
    if (cmsStore.getters.selectedSession.value.session) text += ' : ' + cmsStore.getters.selectedSession.value.session.name + ` ${sessionPercent}%`
    if (cmsStore.getters.selectedTask.value.task) {
      const split = cmsStore.getters.selectedTask.value.task.name.split('_')
      text += ' : ' + (split.length > 0 ? split[1] : split[0])
    }
    return text
  })

  // If we arrived here from a page reload, make sure to set up again
  if (router && router.currentRoute.value.path !== '/') {
    router.push('/postlogin')
  }
  appStore.actions.detectOldApp()

  const stateVars = computed(() => {
    return Object.entries(stateService.getters.state.value).map((e) => `${e[0]}: ${e[1]}`)
  })
</script>

<template>
  <div id="app" class="w-screen h-screen-75 relative bg-cover bg-no-repeat">
    <div
      v-if="!isMobileApp && (canSeeMonitor || disableDelays) && !router.currentRoute.value.fullPath.includes('/monitor')"
      class="absolute top-0 right-0 text-xs flex flex-row z-50 m-1 mr-24 pointer-events-none"
    >
      <div class="flex flex-col">
        <button id="button-monitor" class="bg-black text-green-400 rounded-sm p-2 pointer-events-auto" @click="monitor()">Monitor</button>
        <p v-if="disableDelays" class="text-red-400">Delays & locks disabled</p>
      </div>

      <div v-if="disableDelays" class="text-red-600 text-xs flex flex-row place-items-center">
        <p class="bg-black bg-opacity-50 p-1 text-yellow-300 ml-2 max-w-md">
          <template v-for="(s, i) in stateVars" :key="`sVar-${i}`">
            <span>{{ s }}</span>
            <br />
          </template>
        </p>
      </div>
    </div>
    <router-view class="fadeInOut" :style="fadeState" />
    <DialogBox v-if="dialogConfig && dialogConfig.visible" />
    <div class="absolute bottom-1 left-1">
      <p class="text-white opacity-75 text-xs font-mono">{{ feedback }}</p>
    </div>
  </div>
</template>

<style lang="postcss">
  html {
    @apply font-playful;
  }
  .fadeInOut {
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  /* ---- Transition effects available throughout the app ---- */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .slidenext-enter-from {
    transform: translateX(100%);
  }
  .slidenext-enter-active,
  .slidenext-leave-active {
    transition: all 0.25s ease-out;
  }
  .slidenext-leave-to {
    transform: translateX(-100%);
  }

  .slideprev-enter-from {
    transform: translateX(-100%);
  }
  .slideprev-enter-active,
  .slideprev-leave-active {
    transition: all 0.25s ease-out;
  }
  .slideprev-leave-to {
    transform: translateX(100%);
  }

  .slideup-enter-from {
    transform: translateY(100%);
  }
  .slideup-enter-active,
  .slideup-leave-active {
    transition: all 0.25s ease-out;
  }
  .slideup-leave-to {
    transform: translateY(-100%);
  }
  /* @media screen and (prefers-reduced-motion: reduce) {
    .next-enter {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
    .next-enter-active,
    .next-leave-active {
      transition: 0.5s;
    }
    .next-leave-to {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }

    .prev-enter {
      opacity: 0;
      transform: translate3d(-100px, 0, 0);
    }
    .prev-enter-active,
    .prev-leave-active {
      transition: 0.5s;
    }
    .prev-leave-to {
      opacity: 0;
      transform: translate3d(100px, 0, 0);
    }
  } */
</style>
