<!-- Copyright 2020 Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="speaker taskNoUserSelect" @click="clickSpeaker()">
    <img v-if="getters.speakerIsPlaying" src="@/assets/images/speaker/SpeakerShadowActive@2x.png" />
    <img v-else src="@/assets/images/speaker/SpeakerShadowUnactive@2x.png" />
  </div>
</template>

<script setup lang="ts">
  import useState from '@/composition/useState'
  const { actions, getters } = useState()
  const clickSpeaker = () => {
    actions.speakerClick()
  }
</script>

<style lang="postcss">
  .speaker {
    outline: none;
    position: absolute;
    left: 10px;
    top: 30px;
    cursor: pointer;
    width: 64pt;
  }
  .speaker img {
    width: 80px;
  }
</style>
